import React, {useEffect, useRef, useState} from "react";

import DatepickerHelper from "../helpers/DatepickerHelper";
import {useTopCustomerReportQuery} from "../../redux/api/reports/reportsApi";
import Loader from "../../components/loader/Loader";
import Currency from "../../Shared/Currency";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const TopCustomers = () => {
  const store = useSelector((state) => state.store);
  const {t} = useTranslation();
  const [startDate, setStartDate] = useState(new Date());
  const [startDateFieldValue, setStartDateFieldValue] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [endDateFieldValue, setEndDateFieldValue] = useState(new Date());

  const [filter, setFilter] = useState({
    startDate,
    endDate,
    storeId: store?.storeId,
  });

  const {data, isLoading, refetch} = useTopCustomerReportQuery(filter, {
    refetchOnMountOrArgChange: true,
  });
  const initialTopCustomerData = data?.data;

  useEffect(() => {
    const updatedFilter = {...filter};

    if (!startDateFieldValue) {
      delete updatedFilter.startDate;
    }
    if (startDate) {
      updatedFilter.startDate = startDate;
    }
    if (!endDateFieldValue) {
      delete updatedFilter.endDate;
    }
    if (endDate) {
      updatedFilter.endDate = endDate;
    }

    setFilter(updatedFilter);

    refetch();
  }, [
    refetch,
    setStartDate,
    startDateFieldValue,
    endDate,
    endDateFieldValue,
    startDate,
  ]);

  // const getFormatedDate = (originalDate) => {
  //   const year = originalDate.getFullYear();
  //   const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
  //   const day = originalDate.getDate().toString().padStart(2, "0");

  //   const formattedDate = `${year}/${month}/${day}`;

  //   return formattedDate;
  // };

  const handleSubmit = () => {
    setStartDate(moment(startDateFieldValue).format("YYYY-MM-DD"));
    setEndDate(moment(endDateFieldValue).format("YYYY-MM-DD"));
  };

  const handlePrint = () => {
    const printWindow = window.open("Top Customer", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>${t("Top Customer")}</title>
          <style>
            body {
              font-family: 'Arial', sans-serif;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              border: 1px solid #ddd;
              padding: 10px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
            @media print {
              header::before {
                content: "Sales List";
                display: block;
              
              }
              table {
                font-size: 12px;
              }
            }
          </style>
        </head>
        <body>
       <h1 style={{ width: '100%', textAlign: 'center' }}>${t(
         "Top Customer"
       )}</h1>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
          <thead>
          <tr className="text-center">
          <th>${t("Serial")}</th>
          <th>${t("Name")}</th>
          <th>${t("Email")}</th>
          <th>${t("Phone")}</th>
          <th>${t("Address")}</th>
          <th>${t("total")} ${t("sale")}</th>
        </tr>
          </thead>
          ${initialTopCustomerData?.map((item, index) => {
            return `
            <tr key=${index} className="text-center">
            <td>${index + 1}</td>
            <td>${item?.customer?.name}</td>
            <td>${item?.customer?.email}</td>
            <td>${item?.customer?.mobile}</td>
            <td>${item?.customer?.address}</td>

            <td className="text-success">
              ${Currency} : ${item?.totalSales}
            </td>
          </tr>
            `;
          })}
        </table>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };
  return (
    <div>
      <div>
        <h1 className="page-header">{t("Top Customer")}</h1>
        <hr className="mb-4" />
        <div className="row gap-3 w-100">
          <div className="d-flex col-lg-5 gap-2">
            <div className="col-md-6">
              <label htmlFor="start_date">Start Date</label>
              <DatepickerHelper
                handleDateChange={(date) => setStartDateFieldValue(date)}
                selectedDate={startDateFieldValue}
                placeHolder="Select start date"
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="end_date">End Date</label>
              <DatepickerHelper
                handleDateChange={(date) => setEndDateFieldValue(date)}
                selectedDate={endDateFieldValue}
                placeHolder="Select end date"
              />
            </div>
          </div>

          <div className="d-flex justify-content-between mt-2">
            <div className="d-flex gap-2">
              <div>
                <button
                  onClick={handleSubmit}
                  className="btn btn-primary"
                  type="submit">
                  <i className="fa fa-sliders"></i> {t("Filter")}
                </button>
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    setStartDate(moment(new Date()).format("YYYY-MM-DD"));
                    setStartDateFieldValue(new Date());
                    setEndDate(moment(new Date()).format("YYYY-MM-DD"));
                    setEndDateFieldValue(new Date());
                    const updatedFilter = {...filter};
                    setFilter(updatedFilter);
                    refetch();
                  }}
                  style={{width: "100px", height: "35px"}}
                  className="btn btn-outline-theme">
                  <i
                    className="bi bi-x-circle"
                    style={{marginRight: "5px"}}></i>
                  {t("Reset")}
                </button>
              </div>
            </div>
            <div className="">
              <button
                onClick={handlePrint}
                style={{width: "120px", height: "35px"}}
                className="btn btn-outline-theme">
                <i
                  className="bi bi-printer pr-2"
                  style={{marginRight: "5px"}}></i>
                {t("Print")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* <p className="text-center fs-2">Top Customers (Based on Sell Amount)</p>
        <p className="text-center pb3-4 fs-4">
          Report From get {getFormatedDate(startDate)} to{" "}
          {getFormatedDate(endDate)}
        </p> */}
      </div>
      <div className="data-management table-responsive mt-3" data-id="table">
        {isLoading ? (
          <Loader />
        ) : (
          <table
            id="datatable"
            className="table table-bordered table-xs w-100 fw-semibold text-nowrap mb-3">
            <thead>
              <tr className="text-center">
                <th>{t("Serial")}</th>
                <th>{t("Name")}</th>
                <th>{t("Email")}</th>
                <th>{t("Phone")}</th>
                <th>{t("Address")}</th>
                <th>
                  {t("total")} {t("sale")}
                </th>
              </tr>
            </thead>
            <tbody className="text-body">
              {initialTopCustomerData?.map((item, index) => (
                <tr key={index} className="text-center">
                  <td>{index + 1}</td>
                  <td>{item?.customer?.name}</td>
                  <td>{item?.customer?.email}</td>
                  <td>{item?.customer?.mobile}</td>
                  <td>{item?.customer?.address}</td>

                  <td className="text-success">
                    {Currency} : {item?.totalSales}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default TopCustomers;

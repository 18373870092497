import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDeleteBrandMutation } from "../../redux/api/brands/brandsApi";
import toast from "react-hot-toast";
import EditModal from "./EditModal";
import DeleteConfirmationModal from "../deleteConfirmationModal/DeleteConfirmationModal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const BrandList = ({ data }) => {
  const storeId = useSelector((state) => state.store.storeId)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [selectedItemId, setSelectedItemId] = useState();
  const [deleteBrand] = useDeleteBrandMutation({});
  const { t } = useTranslation();
  const handleDelete = async (id) => {
    setIsAddingLoading(true);
    const res = await deleteBrand({id,storeId});
    if (res?.data?.success === true) {
      toast.success(res?.data?.message);
    }
    setIsAddingLoading(false);
    setIsDeleteConfirmationModalOpen(false);
  };

  const openModal = (id) => {
    setIsModalOpen(true);
    setSelectedItemId(id);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openDeleteConfirmationModal = (id) => {
    setIsDeleteConfirmationModalOpen(true);
    setSelectedItemId(id);
  };

  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(false);
  };

  return (
    <div>
      <div className="table-responsive">
        <table className="table table-hover text-nowrap">
          <thead>
            <tr>
              <th className="border-top-0 pt-0 pb-2">{t("Serial")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Description")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Image")}</th>
              <th className="border-top-0 pt-0 pb-2">
                {" "}
                {t("product")} {t("Count")}
              </th>
              <th className="border-top-0 pt-0 pb-2">{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td className="align-middle">
                  <Link>{index + 1}</Link>
                </td>
                <td className="align-middle">{item?.name}</td>
                <td className="align-middle">
                  <img
                    style={{
                      objectFit: "cover",
                      height: "40px",
                      width: "40px",
                    }}
                    src={
                      item?.logo
                        ? item.logo
                        : "https://i.ibb.co/KjdtK7T/download.png"
                    }
                    alt=""
                  />
                </td>
                <td>{item?.Products?.length || 0}</td>

                <td className="align-middle">
                  <span
                    className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center cursor-pointer "
                    data-bs-toggle="dropdown"
                  >
                    <i className="bi bi-gear fs-9px fa-fw me-5px"></i> E/D
                  </span>

                  <div className="dropdown-menu">
                    <button
                      onClick={() => openModal(item?.id)}
                      className="dropdown-item"
                    >
                      <i
                        className="bi bi-pencil-square"
                        style={{ marginRight: "5px" }}
                      ></i>
                     {t("Edit")}
                    </button>
                    <button
                      onClick={() => openDeleteConfirmationModal(item?.id)}
                      className="dropdown-item"
                    >
                      <i
                        className="bi bi-trash pr-2"
                        style={{ marginRight: "5px" }}
                      ></i>
                      {t("Delete")}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isDeleteConfirmationModalOpen && (
        <DeleteConfirmationModal
          isAddingLoading={isAddingLoading}
          closeModal={closeDeleteConfirmationModal}
          confirmDelete={() => handleDelete(selectedItemId)}
        />
      )}
      {isModalOpen && (
        <EditModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          id={selectedItemId}
        />
      )}
    </div>
  );
};

export default BrandList;

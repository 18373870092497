import DatepickerHelper from "../helpers/DatepickerHelper";
import { Card, CardBody, CardExpandToggler } from "../../components/card/card";

import React, { useEffect, useState } from "react";
import moment from "moment";
import { useAllMonthlyReportGetQuery } from "../../redux/api/reports/reportsApi";

import Loader from "../../components/loader/Loader";
import ReceiveFromCustomer from "./ReceiveFromCustomer";
import PayToSupplierTable from "./PayToSupplierTable";
import ExpenseTable from "./ExpenseTable";
import Currency from "../../Shared/Currency";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";


const CurrentMonthReport = () => {
  const store = useSelector((state) => state.store);
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState();
  const [startDateFieldValue, setStartDateFieldValue] = useState();
  const [endDate, setEndDate] = useState();
  const [endDateFieldValue, setEndDateFieldValue] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({
    limit: pageSize,
    storeId: store?.storeId
  });
  const { data, isLoading, refetch } = useAllMonthlyReportGetQuery(filter, {
    refetchOnMountOrArgChange: true,
  });
  const initialData = data?.data;
  const meta = data?.meta;
  useEffect(() => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      storeId: store.storeId,
    }));
  }, [store.storeId]);
  useEffect(() => {
    const updatedFilter = { ...filter };
    updatedFilter.page = page;

    if (!startDateFieldValue) {
      delete updatedFilter.startDate;
    }
    if (startDate) {
      updatedFilter.startDate = startDate;
    }
    if (!endDateFieldValue) {
      delete updatedFilter.endDate;
    }
    if (endDate) {
      updatedFilter.endDate = endDate;
    }

    setFilter(updatedFilter);

    refetch();
  }, [
    refetch,
    page,
    setStartDate,
    startDateFieldValue,
    endDate,
    endDateFieldValue,
  ]);

  const handleSubmit = () => {
    const formattedStartDate = startDateFieldValue
      ? moment(startDateFieldValue).format("YYYY-MM-DD")
      : "";
    const formattedEndDate = endDateFieldValue
      ? moment(endDateFieldValue).format("YYYY-MM-DD")
      : "";

    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  };

  return (
    <div>
      <div>
        <h1 className="page-header">{t("Current Month Report")}</h1>
        <hr className="mb-4" />

        <div className="d-flex flex-wrap gap-2">
          <div style={{ zIndex: 24 }}>
            <DatepickerHelper
              handleDateChange={(date) => setStartDateFieldValue(date)}
              selectedDate={startDateFieldValue}
              placeHolder="Select start date"
            />
          </div>
          <div style={{ zIndex: 24 }}>
            <DatepickerHelper
              handleDateChange={(date) => setEndDateFieldValue(date)}
              selectedDate={endDateFieldValue}
              placeHolder="Select end date"
            />
          </div>
          <div className="form-group ">
            <button
              onClick={handleSubmit}
              className="btn btn-primary"
              type="submit">
              <i className="fa fa-sliders"></i> {t("Filter")}
            </button>
          </div>
          <div className="form-group ">
            <button
              className="btn btn-info"
              type="button"
              onClick={() => {
                setStartDate("");
                setStartDateFieldValue("");
                setEndDate("");
                setEndDateFieldValue("");
                const updatedFilter = { ...filter };
                delete updatedFilter.startDate;
                delete updatedFilter.endDate;
                setFilter(updatedFilter);
                refetch();
              }}>
              {t("Reset")}
            </button>
          </div>
        </div>
      </div>
      <div className="mt-4">
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <div className="row py-3">
              <div className="col-xl-3 col-lg-6">
                <Card className="mb-3">
                  <CardBody>
                    <div className="d-flex fw-bold small mb-3">
                      <span className="flex-grow-1">{t("sale")} {t("Amount")}</span>
                      <CardExpandToggler />
                    </div>
                    <div className="row align-items-center mb-2">
                      <div >
                        <h3 className="mb-0">{Currency} {(initialData?.salesAmount)?.toFixed(2)}
                        </h3>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="col-xl-3 col-lg-6">
                <Card className="mb-3">
                  <CardBody>
                    <div className="d-flex fw-bold small mb-3">
                      <span className="flex-grow-1">{t("purchases")} {t("Cost")}</span>
                      <CardExpandToggler />
                    </div>
                    <div className="row align-items-center mb-2">
                      <div>
                        <h3 className="mb-0">
                          {Currency} {(initialData?.purchaseAmount)?.toFixed(2)}
                        </h3>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="col-xl-3 col-lg-6">
                <Card className="mb-3">
                  <CardBody>
                    <div className="d-flex fw-bold small mb-3">
                      <span className="flex-grow-1">{t("Expense")}</span>
                      <CardExpandToggler />
                    </div>
                    <div className="row align-items-center mb-2">
                      <div>
                        <h3 className="mb-0">
                          {Currency} {(initialData?.expenseAmount)?.toFixed(2)}
                        </h3>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="col-xl-3 col-lg-6">
                <Card className="mb-3">
                  <CardBody>
                    <div className="d-flex fw-bold small mb-3">
                      <span className="flex-grow-1">{("sale")} {t("Profit")}</span>
                      <CardExpandToggler />
                    </div>
                    <div className="row align-items-center mb-2">
                      <div>
                        <h3 className="mb-0">
                          {" "}
                          {Currency} {(initialData?.profitAmount)?.toFixed(2)}
                        </h3>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>

            <div className="row py-3">
              <div className="col-xl-6">
                <Card className="mb-3">
                  <CardBody>
                    <div className="d-flex fw-bold small mb-3">
                      <span className="flex-grow-1">{t("TOP SALE PRODUCT")}</span>
                      <CardExpandToggler />
                    </div>
                    <div>
                      {/* <TopSaleProduct initialData={initialData} /> */}
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="col-xl-6">
                <Card className="mb-3">
                  <CardBody>
                    <div className="d-flex fw-bold small mb-3">
                      <span className="flex-grow-1 text-uppercase">
                        {t("Expense")}
                      </span>
                      <CardExpandToggler />
                    </div>
                    <div className=" mb-3">
                      <div
                        id="world-map"
                        className="jvectormap-without-padding">
                        <ExpenseTable />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="col-xl-6">
                <Card className="mb-3">
                  <CardBody>
                    <div className="d-flex fw-bold small mb-3">
                      <span className="flex-grow-1 text-uppercase">
                        {t("Pay To Supplier")}
                      </span>
                      <CardExpandToggler />
                    </div>
                    <div className="table-responsive">
                      <PayToSupplierTable initialData={initialData} />
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="col-xl-6">
                <Card className="mb-3">
                  <CardBody>
                    <div className="d-flex fw-bold small mb-3">
                      <span className="flex-grow-1 text-uppercase">
                       {t(" Receive From Customer")}
                      </span>
                      <CardExpandToggler />
                    </div>
                    <div className="table-responsive">
                      <ReceiveFromCustomer initialData={initialData} />
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CurrentMonthReport;

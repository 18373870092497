import { Link, useParams } from "react-router-dom";
import { useCustomerSaleListMutation } from "../../redux/api/customer/customerApi";

import moment from "moment";
import React, { useEffect, useRef, useState } from "react";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import toast from "react-hot-toast";

import DeleteConfirmationModal from "../deleteConfirmationModal/DeleteConfirmationModal";

import Loader from "../../components/loader/Loader";
import PurchasePaymentModal from "../purchases/PurchasePaymentModal";
import { useDeletePurchaseMutation } from "../../redux/api/purchase/purchaseApi";
import { useAllUnitGetQuery } from "../../redux/api/units/unitsApi";
import { useTranslation } from "react-i18next";
import Currency from "../../Shared/Currency";
import { useSelector } from "react-redux";
const SupplierPurchaseList = () => {
  const store = useSelector((state) => state.store);
  const filter = {
    storeId: store.storeId,
  }
  const { t } = useTranslation();
  const [purchasesData, setPurchasesData] = useState([]);
  const { id } = useParams();
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [selectedItemId, setSelectedItemId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [deletePurchase] = useDeletePurchaseMutation({});
  const { data: unit } = useAllUnitGetQuery(filter,{
    refetchOnMountOrArgChange: true,
  });
  const initialData = unit?.data;
  const handleDelete = async (id) => {
    const res = await deletePurchase(id);
    if (res?.data?.success === true) {
      toast.success(res?.data?.message);
    }
    setIsDeleteConfirmationModalOpen(false);
  };
  const [customerSaleList] = useCustomerSaleListMutation();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await customerSaleList({
          id,
          values: { isCustomer: false,storeId: store.storeId },
        });
        setIsLoading(false);

        setPurchasesData(response?.data?.data);
      } catch (error) {
        console.error("Error fetching Purchase list:", error);
      }
    };

    fetchData();
  }, [id, customerSaleList]);

  const openModal = (id, customerId) => {
    setIsModalOpen(true);
    setSelectedId(id);
    setSelectedUserId(customerId);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const tableRef = useRef(null);

  const exportAsPDF = async () => {
    const input = tableRef.current;

    if (!input) {
      return;
    }
    const canvas = await html2canvas(input);

    const tableWidth = canvas.width;
    const tableHeight = canvas.height;
    const pdfWidth = tableWidth / 9;
    const pdfHeight = tableHeight / 6.84;

    const pdf = new jsPDF("p", "mm", "a4");
    pdf.addImage(canvas, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("table.pdf");
  };

  const openDeleteConfirmationModal = (id) => {
    setIsDeleteConfirmationModalOpen(true);
    setSelectedItemId(id);
  };

  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(false);
  };
  const getUnitName = (id) => {
    const unit = initialData?.find((data) => data.id === id);
    console.log(unit);
    return unit;
  };
  console.log(purchasesData,"fhdsfhkj");
  return (
    <div>
      <header className="header text-white">
        <div className="header-info">
          <h1 className="page-header">{t("Purchase List")}</h1>
          <hr className="mb-2" />
        </div>
      </header>
      <div>
        <button
          onClick={exportAsPDF}
          style={{ width: "120px", height: "35px" }}
          className="btn btn-outline-theme"
        >
          <i className="bi bi-printer pr-2" style={{ marginRight: "5px" }}></i>
          {t("Print")}
        </button>
      </div>
      <div className="my-4">
        <div className="table-responsive">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="my-4">
              <div className="table-responsive">
                <table
                  ref={tableRef}
                  id="datatable"
                  className="table table-hover text-nowrap"
                >
                  <thead>
                    <tr>
                      <th className="border-top-0 pt-0 pb-2">
                        {" "}
                        {t("supplier")}{" "}
                      </th>

                      <th className="border-top-0 pt-0 pb-2">{t("Bill")}</th>
                      <th className="border-top-0 pt-0 pb-2">
                        {t("supplier")}
                      </th>
                      <th className="border-top-0 pt-0 pb-2">{t("Date")}</th>
                      <th className="border-top-0 pt-0 pb-2">{t("product")}</th>
                      <th className="border-top-0 pt-0 pb-2">{t("payable")}</th>
                      <th className="border-top-0 pt-0 pb-2">{t("Paid")}</th>
                      <th className="border-top-0 pt-0 pb-2">{t("Due")}</th>
                      <th className="border-top-0 pt-0 pb-2">{t("Actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchasesData?.map((item, index) => (
                      <tr>
                        <td className="align-middle">
                          <Link >{index + 1}</Link>
                        </td>
                        <td className="align-middle">{item?.id}</td>
                        <td className="align-middle">{item?.supplier?.name}</td>
                        <td className="align-middle">
                          {moment(item?.purchaseDate).format("DD MMMM YYYY")}
                        </td>
                        <td>
                          <ul>
                            {item?.products.map((product) => (
                              <li>
                                {product?.name} |{" "}
                                {product?.subUnitsId ? (
                                  <>
                                    {Math?.floor(
                                      product?.qty /
                                        parseInt(
                                          getUnitName(product?.unitId)
                                            ?.relatedByValue
                                        )
                                    )}{" "}
                                    {getUnitName(product?.unitId)?.name}{" "}
                                    {Math?.floor(
                                      product?.qty %
                                        parseInt(
                                          getUnitName(product?.unitId)
                                            .relatedByValue
                                        )
                                    )}{" "}
                                    {getUnitName(product?.subUnitsId)?.name}
                                  </>
                                ) : (
                                  <>
                                    {product?.qty}{" "}
                                    {getUnitName(product?.unitId)?.name}
                                  </>
                                )}{" "}
                              </li>
                            ))}
                          </ul>
                        </td>
                        <td className="py-1 align-middle">
                          <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                            {Currency}: {item?.payable}
                          </span>
                        </td>
                        <td className="align-middle">
                          <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                            {Currency}: {item?.paid}
                          </span>
                        </td>
                        <td className="align-middle">
                          <span className="badge border border-danger text-danger px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                            {Currency}: {item?.due.toFixed(2)}
                          </span>
                        </td>
                        <td className="align-middle">
                          <span
                            className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center cursor-pointer "
                            data-bs-toggle="dropdown"
                          >
                            <i className="bi bi-gear fs-9px fa-fw me-5px"></i>{" "}
                            E/D
                          </span>

                          <div className="dropdown-menu">
                            {/* <Link
                              className="dropdown-item"
                              to={`/edit-purchases/${item?.id}`}
                            >
                              <i
                                class="bi bi-pencil-square"
                                style={{ marginRight: "5px" }}
                              ></i>
                              {t("Edit")}
                            </Link> */}
                            <a className="dropdown-item" href="#/">
                              <i
                                class="bi bi-tv"
                                style={{ marginRight: "5px" }}
                              ></i>
                              {t("Show")}
                            </a>

                            <button
                              onClick={() =>
                                openModal(item?.id, item?.supplier?.id)
                              }
                              className="dropdown-item"
                            >
                              <i
                                className="bi bi-cash-coin pr-2"
                                style={{ marginRight: "5px" }}
                              ></i>
                              {t("Add Payment")}
                            </button>
                            <a className="dropdown-item" href="#/">
                              <i
                                class="bi bi-printer pr-2"
                                style={{ marginRight: "5px" }}
                              ></i>
                              {t("Invoice")}
                            </a>

                            <button
                              onClick={() =>
                                openDeleteConfirmationModal(item?.id)
                              }
                              className="dropdown-item"
                            >
                              <i
                                className="bi bi-trash pr-2"
                                style={{ marginRight: "5px" }}
                              ></i>
                              {t("Delete")}
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>

      {isModalOpen && (
        <PurchasePaymentModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          id={selectedId}
          userId={selectedUserId}
        />
      )}

      {isDeleteConfirmationModalOpen && (
        <DeleteConfirmationModal
          closeModal={closeDeleteConfirmationModal}
          confirmDelete={() => handleDelete(selectedItemId)}
        />
      )}
    </div>
  );
};

export default SupplierPurchaseList;

import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";

import moment from "moment";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import toast from "react-hot-toast";
import {
  useDeletePaymentMutation,
  usePaymentListSupplierAndCustomerMutation,
} from "../../redux/api/payments/paymentsApi";
import DeleteConfirmationModal from "../deleteConfirmationModal/DeleteConfirmationModal";
import Loader from "../../components/loader/Loader";
import { useTranslation } from "react-i18next";
import Currency from "../../Shared/Currency";
import { useSelector } from "react-redux";
const SupplierPaymentList = () => {
  const store = useSelector((state) => state.store);
  const { t } = useTranslation();
  const [paymentData, setPaymentData] = useState([]);
  const { id } = useParams();
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [selectedItemId, setSelectedItemId] = useState();

  const tableRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);

  const [deletePayment] = useDeletePaymentMutation({});

  const handleDelete = async (id) => {
    const res = await deletePayment({id, storeId: store.storeId });
    if (res?.data?.success === true) {
      toast.success(res?.data?.message);
    }
    setIsDeleteConfirmationModalOpen(false);
  };
  const [paymentListSupplierAndCustomer] =
    usePaymentListSupplierAndCustomerMutation();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await paymentListSupplierAndCustomer({
          id,
          values: { isCustomer: false, storeId: store.storeId, },
        });
        setIsLoading(false);

        setPaymentData(response?.data?.data);
      } catch (error) {
        console.error("Error fetching Payment list:", error);
      }
    };

    fetchData();
  }, [id, paymentListSupplierAndCustomer]);


  const exportAsPDF = async () => {
    const input = tableRef.current;

    if (!input) {
      return;
    }
    const canvas = await html2canvas(input);

    const tableWidth = canvas.width;
    const tableHeight = canvas.height;
    const pdfWidth = tableWidth / 9;
    const pdfHeight = tableHeight / 6.84;

    const pdf = new jsPDF("p", "mm", "a4");
    pdf.addImage(canvas, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("table.pdf");
  };
  const openDeleteConfirmationModal = (id) => {
    setIsDeleteConfirmationModalOpen(true);
    setSelectedItemId(id);
  };

  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(false);
  };
  return (
    <div>
      <header className="header text-white ">
        <div className="header-info">
          <h1 className="page-header">{t("Payment")} {t("List")}{" "}</h1>
          <hr className="mb-2" />
        </div>
      </header>
      <div className="mt-4">
        <button
          onClick={exportAsPDF}
          style={{ width: "100px", height: "35px" }}
          className="btn btn-outline-theme">
          <i className="bi bi-printer pr-2" style={{ marginRight: "5px" }}></i>
          Print
        </button>
      </div>
      <div className="table-responsive my-4">
        {isLoading ? (
          <Loader />
        ) : (
          <table
            ref={tableRef}
            id="datatable"
            className="table table-hover text-nowrap">
            <thead>
              <tr>

              <th className="border-top-0 pt-0 pb-2">{t("Serial")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Details")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Payment Date")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Amount")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Payment Type")}</th>
                <th className="border-top-0 pt-0 pb-2">
                  {t("Wallet Payment")}{" "}
                </th>
                <th className="border-top-0 pt-0 pb-2">{t("Note")} </th>
                <th className="border-top-0 pt-0 pb-2">{t("Actions")} </th>
              </tr>
            </thead>
            <tbody>
              {paymentData?.map((item, index) => (
                <tr key={index}>

                  <td className="align-middle">
                    <Link >{index + 1}</Link>
                  </td>
                  <td className="align-middle">
                    <table className="table table-bordered table-xs w-100 fw-semibold text-nowrap   mb-3">
                      <tbody>
                        <tr>
                          {<th>{t("supplier")} {t("Name")}</th>}

                          <td>{item?.supplierAccount?.name}</td>
                        </tr>
                        <tr>
                          <th>{t("Phone")}</th>
                          <td>{item?.supplierAccount?.mobile}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td className="align-middle">
                    <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      {moment(item?.paymentDate).format("DD MMMM YYYY")}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      {Currency}: {item?.amount}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      {item?.paymentType?.name}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      {item?.isWallet === true ? "YES" : "NO"}
                    </span>
                  </td>
                  <td className="align-middle">{item?.note}</td>

                  <td className="align-middle">
                    <span
                      className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center cursor-pointer "
                      data-bs-toggle="dropdown">
                      <i className="bi bi-gear fs-9px fa-fw me-5px"></i> E/D
                    </span>

                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#/">
                        <i
                          class="bi bi-printer pr-2"
                          style={{ marginRight: "5px" }}></i>
                        {t("Receipt")}
                      </a>
                      <button
                        onClick={() => openDeleteConfirmationModal(item?.id)}
                        className="dropdown-item">
                        <i
                          className="bi bi-trash pr-2"
                          style={{ marginRight: "5px" }}></i>
                        {t("Delete")}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {isDeleteConfirmationModalOpen && (
        <DeleteConfirmationModal
          closeModal={closeDeleteConfirmationModal}
          confirmDelete={() => handleDelete(selectedItemId)}
        />
      )}
    </div>
  );
};

export default SupplierPaymentList;

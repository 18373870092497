import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DatepickerHelper from "../helpers/DatepickerHelper";
import { useAllProductGetQuery } from "../../redux/api/product/productApi";
import Loader from "../../components/loader/Loader";
import { useDamageCreateMutation } from "../../redux/api/damages/damagesApi";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { calculateProducuUnitSubUnit } from "../../utils/product";

const DamageModal = ({ closeModal, isModalOpen }) => {
  const store = useSelector((state) => state.store);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSubunit, setSelectedSubunit] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchedProduct, setSearchedProduct] = useState("");
  const [matchingProducts, setMatchingProducts] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [unitsValue, setUnitsValue] = useState({ mainValue: 0, subValue: 0 });

  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [damageCreate] = useDamageCreateMutation();
  const calculateStock = () => {
    let mainValue;
    let subValue;

    if (selectedProduct?.subUnitsId) {
      mainValue = Math.floor(
        selectedProduct?.stock / parseInt(selectedProduct?.unit?.relatedByValue)
      );
      subValue =
        selectedProduct?.stock %
        parseInt(selectedProduct?.unit?.relatedByValue);
    } else {
      mainValue = selectedProduct?.stock;
    }
    unitsValue.mainValue = mainValue;
    unitsValue.subValue = subValue;
  };
  useEffect(() => {
    if (selectedProduct) {
      calculateStock();
    }
  }, [selectedProduct, selectedSubunit, selectedUnit]);
  const filter={
    storeId: store.storeId,
  }
  const { data: productData, isLoading: productLoading } =
    useAllProductGetQuery(filter,{
      refetchOnMountOrArgChange: true,
    });

  const initialData = productData?.data;
  const { t } = useTranslation();
  const handleStartDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleProductSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchedProduct(searchTerm);

    const filteredProducts = initialData?.filter((product) =>
      product?.productName.toLowerCase().includes(searchTerm)
    );
    setMatchingProducts(filteredProducts);
  };

  const handleProductSelection = (selectedProduct) => {
    setSelectedProduct(selectedProduct);
    setSelectedSubunit(selectedProduct?.subUnits);
    setSelectedUnit(selectedProduct?.unit);

    setSearchedProduct(
      `${selectedProduct?.productName} - Stock: ${calculateProducuUnitSubUnit(selectedProduct)?.stock}`
    );

    setMatchingProducts([]);
  };

  if (productLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (!initialData) {
    return <div>No data available</div>;
  }

  const validationSchema = Yup.object({
    product: Yup.string().optional("product is required"),
    date: Yup.date().optional("date is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsAddingLoading(true);
    let quantity;
    if (values?.unit && values?.subunit) {
      quantity =
        values.unit * parseInt(selectedProduct?.unit?.relatedByValue) +
        values?.subunit;
    } else if (values?.unit && selectedProduct?.unit?.relatedByValue) {
      quantity = values.unit * parseInt(selectedProduct?.unit?.relatedByValue);
    } else if (values?.unit) {
      quantity = values?.unit;
    } else {
      quantity = values?.subunit;
    }
    const data = {
      productsId: selectedProduct?.id,
      qty: quantity,
      damageDate: selectedDate,
      note: values?.note,
      storeId: store.storeId
    };

    const response = await damageCreate(data);

    toast.success(response?.data?.message);
    setSubmitting(false);
    setIsAddingLoading(false);
    closeModal();
  };

  return (
    <div
      className={`modal fade mt-5 py-5  ${isModalOpen ? "show" : ""}`}
      tabIndex="-1"
      role="dialog"
      style={{ display: isModalOpen ? "block" : "none" }}
    >
      <div style={{ display: "block" }} className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title"> {t("Add Damage")}</h5>
            <button
              type="button"
              onClick={() => closeModal()}
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <div className="px-3 ">
            <Formik
              validationSchema={validationSchema}
              initialValues={{
                product: "",
                date: "",
                note: "",
              }}
              onSubmit={handleSubmit}
            >
              {() => (
                <Form>
                  <div className="my-2">
                    <div className="d-flex gap-2">
                      <div className="form-group col-md-6">
                        <label htmlFor="Product">Product</label>
                        <input
                          type="text"
                          id="product_search"
                          value={searchedProduct}
                          onChange={handleProductSearch}
                          className="form-control ui-autocomplete-input"
                          placeholder="Write product."
                          autoComplete="off"
                        />
                        {matchingProducts.length > 0 && (
                          <ul className="list-group mt-2">
                            {matchingProducts?.map((product) => (
                              <li
                                key={product.id}
                                className="list-group-item"
                                onClick={() => handleProductSelection(product)}
                              >
                                {product?.productName}
                              </li>
                            ))}
                          </ul>
                        )}
                        <ErrorMessage
                          name="product"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="form-group col-md-6 px-2">
                        <label htmlFor="date">
                          Date<span className="field_required">*</span>
                        </label>
                        <DatepickerHelper
                          handleDateChange={handleStartDateChange}
                          selectedDate={selectedDate}
                          placeHolder="Select start date"
                          name="date"
                        />
                        <ErrorMessage
                          name="date"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="d-flex gap-2 mt-2">
                      {selectedUnit && (
                        <div className="form-group col-md-6">
                          <label htmlFor="unit">{selectedUnit?.name}</label>
                          <Field
                            className="form-control"
                            name="unit"
                            type="number"

                            // value={calculateStock().mainValue}
                          />
                        </div>
                      )}
                      {selectedSubunit && (
                        <div className="form-group col-md-6">
                          <label htmlFor="subunit">
                            {selectedSubunit?.name}
                          </label>
                          <Field
                            className="form-control"
                            name="subunit"
                            type="number"
                            // value={calculateStock().subValue}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-group col-lg-12 mt-2 pb-4">
                    <label htmlFor="note">Note</label>
                    <Field
                      as="textarea"
                      name="note"
                      className="form-control"
                      placeholder="Write Note..."
                    />
                  </div>

                  <div className="modal-footer">
                    <button
                      className="btn btn-outline-default"
                      onClick={() => closeModal()}
                      disabled={isAddingLoading}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-outline-theme"
                      disabled={isAddingLoading}
                    >
                      {isAddingLoading ? (
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        "Add Damage"
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DamageModal;

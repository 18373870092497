import React, {useEffect} from "react";

import ReactSelect from "../helpers/ReactSelect";
import DatepickerHelper from "../helpers/DatepickerHelper";
import { useTranslation } from "react-i18next";

const CustomerLedger = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState(null);

 

  const options = [
    {value: "option1", label: "Option 1"},
    {value: "option2", label: "Option 2"},
    {value: "option3", label: "Option 3"},
  ];
  const handleChange = (values) => {
    setSelected(values.value);

  };

  // const handlePrint = () => {
  //   const printWindow = window.open("Low Stock Report", "_blank");
  //   printWindow.document.write(`
  //     <html>
  //       <head>
  //         <title>${t("Low Stock Report")}</title>
  //         <style>
  //           body {
  //             font-family: 'Arial', sans-serif;
  //           }
  //           table {
  //             width: 100%;
  //             border-collapse: collapse;
  //             margin-bottom: 20px;
  //           }
  //           th, td {
  //             border: 1px solid #ddd;
  //             padding: 10px;
  //             text-align: left;
  //           }
  //           th {
  //             background-color: #f2f2f2;
  //           }
  //           @media print {
  //             header::before {
  //               content: "Sales List";
  //               display: block;
              
  //             }
  //             table {
  //               font-size: 12px;
  //             }
  //           }
  //         </style>
  //       </head>
  //       <body>
  //      <h1 style={{ width: '100%', textAlign: 'center' }}>${t(
  //        "Low Stock Report"
  //      )}</h1>
  //         <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
  //         <thead>
  //         <tr className="text-center">
  //         <th>${t("Serial")}</th>
  //         <th>${t("Image")}</th>
  //         <th>${t("product")}</th>
  //         <th>${t("Category")}</th>
  //         <th>${t("Price")}</th>
  //         <th>${t("sale")}</th>
  //         <th>${t("purchases")}</th>
  //         <th>${t("Stock")}</th>
  //         <th>
  //           ${t("sale")} ${t("Value")}
  //         </th>
  //       </tr>
  //         </thead>
  //         ${initialLowStockData?.map((item, index) => {
  //           return `
  //           <tr key=${item?.id}>
  //                 <td className="align-middle">
  //                   <Link>${index + 1}</Link>
  //                 </td>

  //                 <td className="align-middle">
  //                   <img
  //                     src={
  //                       item?.image || "https://i.ibb.co/KjdtK7T/download.png"
  //                     }
  //                     alt="ProductImage"
  //                     style={{
  //                       width: "50px",
  //                       height: "50px",
  //                       objectFit: "cover",
  //                       objectPosition: "center",
  //                     }}
  //                   />
  //                 </td>
  //                 <td className="align-middle">${item?.productName}</td>
  //                 <td className="align-middle">
  //                   <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
  //                     ${item?.category?.name}
  //                   </span>
  //                 </td>

  //                 <td className="align-middle">
  //                   <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
  //                     ${Currency} : ${item?.salePrice}
  //                   </span>
  //                 </td>
  //                 <td className="align-middle">
  //                   <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
  //                     ${calculateProducuUnitSubUnit(item)?.totalPurchased}
  //                   </span>
  //                 </td>
  //                 <td className="align-middle">
  //                   <span className="px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
  //                     ${calculateProducuUnitSubUnit(item)?.sold}
  //                   </span>
  //                 </td>

  //                 <td className="align-middle">
  //                   <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
  //                     ${calculateProducuUnitSubUnit(item)?.stock}
  //                   </span>
  //                 </td>
  //                 <td className="align-middle">
  //                   <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
  //                     ${Currency} :
  //                     ${calculateProducuUnitSubUnit(item)?.sellValue}
  //                   </span>
  //                 </td>
  //               </tr>
  //           `;
  //         })}
  //       </table>
  //       </body>
  //     </html>
  //   `);
  //   printWindow.document.close();
  //   printWindow.print();
  // };

  return (
    <div>
      <div>
        <h1 className="page-header">{t("Purchases Report")}</h1>
        <hr className="mb-4" />
        <div className="row gap-3 w-100">
          <div className="col-lg-4 ">
            <ReactSelect
              placeHolder="Select a product"
              options={options}
              handleChange={handleChange}
            />
          </div>

          <div className="d-flex justify-content-between mt-2">
            <div className="d-flex gap-2">
              <div>
                <button
                  onClick={() => {
                    setSelected("");
                  }}
                  style={{width: "100px", height: "35px"}}
                  className="btn btn-outline-theme">
                  {t("Filter")}
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    setSelected("");
                  }}
                  style={{width: "100px", height: "35px"}}
                  className="btn btn-outline-theme">
                  <i class="bi bi-x-circle" style={{marginRight: "5px"}}></i>
                  {t("Reset")}
                </button>
              </div>
            </div>
            <div className="">
              <button
                // onClick={handlePrint}
                style={{width: "100px", height: "35px"}}
                className="btn btn-outline-theme">
                <i class="bi bi-printer pr-2" style={{marginRight: "5px"}}></i>
                {t("Print")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="data-management table-responsive mt-3" data-id="table">
        <table
         
          id="datatable"
          className="table table-bordered table-xs w-100 fw-semibold text-nowrap mb-3">
          <thead>
            <tr className="text-center">
              <th>{t("Serial")}</th>
              <th>{t("Invoice")}</th>
              <th>{t("Date")}</th>
              <th>{t("Name")}</th>
              <th>{t("Quantity")}</th>
              <th>{t("Unit")} {t("Price")}</th>
              <th>{t("Subtotal")}</th>
            </tr>
          </thead>
          <tbody className="text-body">
            {/* <tr className="text-center">
              <td>1.</td>
              <td>Mobile Phone</td>
              <td>iPhone 14 Pro Max - 256gb - Deep Purple</td>
              <td className="text-success">
                <i className="bi-check-circle-fill"></i>
              </td>
              <td>25</td>
              <td>$999</td>

              <td className="text-success">$5,000</td>
            </tr> */}
          </tbody>
          {/* <tfoot>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>$923,462</th>
              <th>$751,792</th>
              <th className="text-success">$171,670</th>{" "}
           
            </tr>
          </tfoot> */}
        </table>
      </div>
    </div>
  );
};

export default CustomerLedger;

/* eslint-disable no-sequences */
import { baseApi } from "../baseApi";

export const owners = baseApi.injectEndpoints({
  endpoints: (build) => ({
    ownersAdd: build.mutation({
      query: (unitData) => ({
        url: "/user",
        method: "POST",
        data: unitData,
      }),
      invalidatesTags: ["user"],
    }),

    allOwners: build.query({
      query: (params) => ({
        url: "/user",
        method: "GET",
        params,
      }),
      providesTags: ["user"],
    }),
  }),
});

export const { useAllOwnersQuery, useOwnersAddMutation } = owners;

import moment from "moment";
import React, { useState } from "react";
import toast from "react-hot-toast";

import { Link } from "react-router-dom";
import { useDeleteDamageMutation } from "../../redux/api/damages/damagesApi";
import EditModal from "./EditModal";
import { calculateQty } from "../../utils/product";
import { useAllProductGetQuery } from "../../redux/api/product/productApi";
import DeleteConfirmationModal from "../deleteConfirmationModal/DeleteConfirmationModal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const DamageList = ({ data, refetch }) => {
  const store = useSelector((state) => state.store);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const { data: Products } = useAllProductGetQuery({ storeId: store.storeId });
  const initialData = Products?.data;
  const [deleteDamage] = useDeleteDamageMutation({});
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const handleDelete = async (id) => {
    setIsAddingLoading(true);
    const res = await deleteDamage(id);
    if (res?.data?.success === true) {
      toast.success(res?.data?.message);
    }
    setIsAddingLoading(false);
    setIsDeleteConfirmationModalOpen(false);
  };
  const { t } = useTranslation();
  const openModal = (id) => {
    setIsModalOpen(true);
    setSelectedItemId(id);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openDeleteConfirmationModal = (id) => {
    setIsDeleteConfirmationModalOpen(true);
    setSelectedItemId(id);
  };

  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(false);
  };
  return (
    <div>
      <div className="table-responsive pt-3">
        <table className="table table-hover text-nowrap">
          <thead>
            <tr>
              <th className="border-top-0 pt-0 pb-2">{t("Serial")}</th>
              <th className="border-top-0 pt-0 pb-2">
                {t("product")} {t("Id")}
              </th>
              <th className="border-top-0 pt-0 pb-2"> {t("Date")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("product")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Quantity")}</th>

              <th className="border-top-0 pt-0 pb-2">{t("Actions")} </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                
                <td className="align-middle">
                  <Link>{item?.id}</Link>
                </td>
                <td className="align-middle">{item?.productsId}</td>
                <td className="align-middle">
                  <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                    {moment(item?.damageDate).format("DD MMMM YYYY")}
                  </span>
                </td>
                <td className="align-middle">
                  <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                    {item?.Products?.productName}
                  </span>
                </td>
                <td className="align-middle">
                  <span
                    style={{ width: "100px" }}
                    className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"
                  >
                    {calculateQty(item, initialData)}
                    {/* {item?.qty} */}
                  </span>
                </td>

                <td className="align-middle">
                  <span
                    className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center cursor-pointer "
                    data-bs-toggle="dropdown"
                  >
                    <i className="bi bi-gear fs-9px fa-fw me-5px"></i> E/D
                  </span>

                  <div className="dropdown-menu">
                    <button
                      onClick={() => openModal(item?.id)}
                      className="dropdown-item"
                    >
                      <i
                        className="bi bi-pencil-square pr-2"
                        style={{ marginRight: "5px" }}
                      ></i>
                      Edit
                    </button>
                    <button
                      onClick={() => openDeleteConfirmationModal(item?.id)}
                      className="dropdown-item"
                    >
                      <i
                        className="bi bi-trash pr-2"
                        style={{ marginRight: "5px" }}
                      ></i>
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <EditModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          id={selectedItemId}
        />
      )}

      {isDeleteConfirmationModalOpen && (
        <DeleteConfirmationModal
          isAddingLoading={isAddingLoading}
          closeModal={closeDeleteConfirmationModal}
          confirmDelete={() => handleDelete(selectedItemId)}
        />
      )}
    </div>
  );
};

export default DamageList;

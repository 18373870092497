import React from "react";
import {Link} from "react-router-dom";
import DatepickerHelper from "../helpers/DatepickerHelper.jsx";
import ReactSelect from "../helpers/ReactSelect.jsx";
import {useTranslation} from "react-i18next";
const FilterSales = ({
  searchTerm,
  handleFilterChange,
  customerId,
  setCustomerId,
  options,
  setSalesDate,
  salesDate,
  openModal,
}) => {
  const {t} = useTranslation();
  const handleCustomerChange = (values) => {
    setCustomerId(parseInt(values.value));
  };
  return (
    <div>
      <div className="d-flex align-items-center flex-wrap gap-3">
        <Link
          to="/dashboard"
          className="btn btn-outline-default text-nowrap btn-sm px-3 rounded-pill me-3">
          <i className="fa fa-arrow-left me-1 ms-n1"></i> {t("Back")}
        </Link>

     
          <div
            className="form-group   w-260px"
            style={{zIndex: 1040}}>
            <DatepickerHelper
              handleDateChange={(date) => setSalesDate(date)}
              selectedDate={salesDate}
              placeHolder="Select date"
            />
          </div>
          <div className="form-group  w-300px">
            <input
              type="text"
              className="form-control"
              placeholder="Filter Products"
              value={searchTerm}
              onChange={handleFilterChange}
            />
          </div>
          <div
            className="form-group  w-300px"
            style={{zIndex: 1000}}>
            <ReactSelect
              placeHolder="Select Customer"
              options={options}
              handleChange={handleCustomerChange}
              selectedOption={customerId}
            />
          </div>
          <div className="pt-3 ">
            <button className="btn btn-outline-theme mb-3" onClick={openModal}>
              <i className="fa fa-plus-circle me-1"></i>
              {t("AddCustomer")}
            </button>
          </div>
      
      </div>
    </div>
  );
};

export default FilterSales;

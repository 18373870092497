import { Navigate, useLocation } from "react-router";
import { useState, useEffect } from "react";
import Loader from "../components/loader/Loader";

const PrivateRoute = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState("");

  const location = useLocation();

  useEffect(() => {
    const storedAccessToken = localStorage.getItem("access-token");

    if (storedAccessToken) {
      setAccessToken(`Bearer ${storedAccessToken}`);
    }

    setLoading(false);
  }, []); 

  if (loading) {
    return <Loader />;
  }

  if (accessToken) {
    return children;
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default PrivateRoute;

import React, { useState } from "react";
import toast from "react-hot-toast";

import { Link } from "react-router-dom";
import { useDeleteProductMutation } from "../../redux/api/product/productApi";
import DeleteConfirmationModal from "../deleteConfirmationModal/DeleteConfirmationModal";
import ViewBarcodeModal from "./ViewBarcodeModal";
import EditProductModal from "./EditProductModal";
import { useTranslation } from "react-i18next";
import Currency from "../../Shared/Currency";
import { useSelector } from "react-redux";

const ProductsList = ({ data }) => {
  const store = useSelector((state)=>state.store)
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [selectedItemId, setSelectedItemId] = useState();
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [viewBarcode, setViewBarcode] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [id, setId] = useState();

  const [item, setItem] = useState();
  const [deleteProduct] = useDeleteProductMutation({});
  const { t } = useTranslation();
  const handleDelete = async (id) => {
    setIsAddingLoading(true);
    const res = await deleteProduct({id, storeId:store.storeId});
    if (res?.data?.success === true) {
      toast.success(res?.data?.message);
    }
    setIsAddingLoading(false);
    setIsDeleteConfirmationModalOpen(false);
  };

  const openDeleteConfirmationModal = (id) => {
    setIsDeleteConfirmationModalOpen(true);
    setSelectedItemId(id);
  };

  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(false);
  };
  return (
    <div>
      <div className="table-responsive my-3">
        <table className="table table-hover text-nowrap">
          <thead>
            <tr>
              <th className="border-top-0 pt-0 pb-2">{t("Serial")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Image")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Code")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Name")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Category")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Brand")} </th>
              <th className="border-top-0 pt-0 pb-2">{t("Self")} </th>
              <th className="border-top-0 pt-0 pb-2">{t("Price")} </th>
              <th className="border-top-0 pt-0 pb-2">{t("Cost")} </th>
              <th className="border-top-0 pt-0 pb-2">{t("Actions")} </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td className="align-middle">
                  <Link >{index + 1}</Link>
                </td>
                <td className="align-middle">
                  <img
                    style={{ objectFit: "cover", height: "40px", width: "40px" }}
                    src={
                      item?.image
                        ? item?.image
                        : "https://i.ibb.co/KjdtK7T/download.png"
                    }
                    alt=""
                  />
                </td>
                <td className="align-middle">{item?.productCode}</td>
                <td className="align-middle">{item?.productName}</td>
                <td className="align-middle">{item?.category?.name}</td>
                <td className="align-middle">{item?.brand?.name}</td>
                <td className="align-middle">{item?.Selfs?.name}</td>
                <td className="align-middle">
                  <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center cursor-pointer ">
                    {Currency}: {item?.salePrice}
                  </span>
                </td>
                <td className="align-middle">
                  <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center cursor-pointer ">
                  {Currency} : {item?.purchaseCost}
                  </span>
                </td>

                <td className="align-middle">
                  <span
                    className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center cursor-pointer "
                    data-bs-toggle="dropdown">
                    <i className="bi bi-gear fs-9px fa-fw me-5px"></i> E/D
                  </span>
                  <div className="dropdown-menu">
                    <button
                      className="dropdown-item"
                      onClick={() => {
                        setItem(item);
                        setViewBarcode(true);
                      }}>
                      <i
                        className="bi bi-upc-scan"
                        style={{ marginRight: "5px" }}></i>
                      {t("View Barcode")}
                    </button>
                    <Link
                      onClick={() => {
                        setId(item?.id)
                        setEditModal(true)
                      }}
                      className="dropdown-item"
                    // to={`/edit-product/${item?.id}`}

                    >

                      <i
                        className="bi bi-pencil-square"
                        style={{ marginRight: "5px" }}></i>
                      {t("Edit")}
                    </Link>

                    <button
                      onClick={() => openDeleteConfirmationModal(item?.id)}
                      className="dropdown-item">
                      <i
                        className="bi bi-trash pr-2"
                        style={{ marginRight: "5px" }}></i>
                      {t("Delete")}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isDeleteConfirmationModalOpen && (
        <DeleteConfirmationModal
          isAddingLoading={isAddingLoading}
          closeModal={closeDeleteConfirmationModal}
          confirmDelete={() => handleDelete(selectedItemId)}
        />
      )}
      {editModal && (
        <EditProductModal
          closeModal={() => setEditModal(false)}
          id={id}
          isModalOpen={editModal}
        />
      )}
      {viewBarcode && (
        <ViewBarcodeModal
          closeModal={() => setViewBarcode(false)}
          item={item}
          show={viewBarcode}
        />
      )}
    </div>
  );
};

export default ProductsList;

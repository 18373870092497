import { t } from "i18next";

const role = localStorage.getItem("role");

const admin = [
  { is_header: true, title: "Navigation" },
  { path: "/dashboard", icon: "bi bi-cpu", title: "Dashboard" },
  // { path: "/owners", icon: "bi bi-people", title: "Owner" },
  // {path: "/bank-accounts", icon: "bi bi-bank", title: "Bank Accounts"},
  {
    path: "/sms",
    icon: "bi bi-envelope",
    title: "Promotional SMS",
  },
  { is_divider: true },
  { is_header: true, title: "SALE & PURCHASE" },

  {
    path: "/customer-order",
    icon: "bi bi-bag-check",
    title: "POS System",
    highlight: true,
    // children: [{path: "/pos/customer-order", title: "Customer Order"}],
  },
  {
    path: "/sales",
    icon: "bi bi-cart-plus-fill",
    title: "Sales",
    children: [
      {
        path: "/sales/sale",
        icon: "bi bi-cart-plus-fill",
        title: "Sales",
      },
      {
        path: "/sales/returnlist",
        icon: "bi bi-bootstrap-reboot",
        title: "Returns",
      },
      { path: "/sales/damages", icon: "bi bi-folder-minus", title: "Damages" },
      { path: "/sales/stock", icon: "bi bi-database-add", title: "Stock" },
    ],
  },
  {
    path: "/add-purchase",
    icon: "bi bi-bag-fill",
    title: "Purchases",
    children: [
      {
        path: "/add-purchase",
        icon: "bi bi-plus-circle-fill",
        title: "Add Purchase",
      },

      {
        path: "/purchase",
        icon: "bi bi-plus-circle-fill",
        title: "Purchase",
      },
    ],
  },
  { is_divider: true },
  { is_header: true, title: "PRODUCTS INFORMATIONS" },

  {
    path: "/unit",
    icon: "fas  fa-balance-scale",
    title: "Unit",
  },
  {
    path: "/products",
    icon: "bi bi-window-plus",
    title: "Products",
  },

  {
    path: "/categories ",
    icon: "bi bi-grid-3x3-gap-fill",
    title: "Categories",
  },
  {
    path: "/brands",
    icon: "bi bi-award-fill",
    title: "Brands",
  },
  {
    path: "/self",
    icon: "bi bi-hdd-rack",
    title: "Self",
  },

  { is_divider: true },
  { is_header: true, title: "Expense and Payments" },
  {
    path: "/expenses",
    icon: "bi bi-wallet2",
    title: "Expenses",
    children: [
      {
        path: "/expenses",
        icon: "bi bi-plus-circle-fill",
        title: "Expenses",
      },

      {
        path: "/expense-category",
        icon: "bi bi-plus-circle-fill",
        title: "Expense Category",
      },
    ],
  },
  {
    path: "/payments",
    icon: " bi bi-currency-dollar",
    title: "Payments",
  },
  { is_divider: true },
  { is_header: true, title: "PEOPLES" },
  {
    path: "/peoples",
    icon: "bi bi-people",
    title: "SR Manage",
  },
  {
    path: "/customers",
    icon: "bi bi-person-add",
    title: "Customers",
  },
  {
    path: "/suppliers",
    icon: "bi bi-truck",
    title: "Suppliers",
  },
  { is_divider: true },
  { is_header: true, title: "REPORTS" },

  {
    path: "/report",
    icon: "bi bi-bar-chart-steps",
    title: "Reports",
    children: [
      {
        path: "/profit-loss",
        icon: "bi bi-cash-coin",
        title: "Profit Loss Report",
      },
      { path: "/today", icon: "bi bi-clock-fill", title: "Today Report" },
      {
        path: "/current-month",
        icon: "bi bi-calendar3-event",
        title: "Current Month Report",
      },
      {
        path: "/summery",
        icon: "bi bi-file-earmark-spreadsheet-fill",
        title: "Summary Report",
      },
      {
        path: "/daily",
        icon: "bi bi-clock-history",
        title: "Daily Report",
      },
      {
        path: "/customer-due",
        icon: "bi bi-currency-exchange",
        title: "Customer Due Report",
      },
      {
        path: "/supplier-due",
        icon: "bi bi-currency-exchange",
        title: "Supplier Due Report",
      },
      {
        path: "/low-stock",
        icon: "bi bi-database-down",
        title: "Low Stock Report",
      },
      {
        path: "/top-customer",
        icon: "bi bi-person-fill-up",
        title: "Top Customer",
      },
      { path: "/top-product", icon: "bi bi-boxes", title: "Top product" },
      {
        path: "/top-product-all-time",
        icon: "bi bi-justify-left",
        title: "Top Products All-Time",
      },
      // {
      //   path: "/purchase-report",
      //   icon: "bi bi-receipt-cutoff",
      //   title: "Purchase Report",
      // },
      {
        path: "/customer-ledger",
        icon: "bi bi-journal-medical",
        title: "Customer Ledger",
      },
      {
        path: "/supplier-ledger",
        icon: "bi bi-kanban",
        title: "Supplier Ledger",
      },
    ],
  },

  { is_divider: true },
  { is_header: true, title: "Users" },

  { path: "/", icon: "bi bi-arrow-down-square-fill", title: "Backup" },

  { path: "/settings", icon: "bi bi-gear", title: "Settings" },
];
const SR = [
  { is_header: true, title: "Navigation" },
  { path: "/dashboard", icon: "bi bi-cpu", title: "Dashboard" },

  { is_divider: true },
  { is_header: true, title: "SALE & PURCHASE" },

  {
    path: "/pos",
    icon: "bi bi-bag-check",
    title: "POS System",
    highlight: true,
    children: [{ path: "/customer-order", title: "Customer Order" }],
  },
  {
    path: "/sales",
    icon: "bi bi-cart-plus-fill",
    title: "Sales",
    children: [
      {
        path: "/sales/sale",
        icon: "bi bi-cart-plus-fill",
        title: "Sales",
      },
      {
        path: "/sales/returnlist",
        icon: "bi bi-bootstrap-reboot",
        title: "Returns",
      },
      { path: "/sales/damages", icon: "bi bi-folder-minus", title: "Damages" },
      { path: "/sales/stock", icon: "bi bi-database-add", title: "Stock" },
    ],
  },
  {
    path: "/add-purchase",
    icon: "bi bi-bag-fill",
    title: "Purchases",
    children: [
      {
        path: "/add-purchase",
        icon: "bi bi-plus-circle-fill",
        title: "Add Purchase",
      },

      {
        path: "/purchase",
        icon: "bi bi-plus-circle-fill",
        title: "Purchase",
      },
    ],
  },
  { is_divider: true },
  { is_header: true, title: "Expense and Payments" },
  {
    path: "/expenses",
    icon: "bi bi-wallet2",
    title: "Expenses",
    children: [
      {
        path: "/expenses",
        icon: "bi bi-plus-circle-fill",
        title: "Expense",
      },

      {
        path: "/expense-category",
        icon: "bi bi-plus-circle-fill",
        title: "Expense Category",
      },
    ],
  },
];
const superAdmin = [
  { is_header: true, title: "Navigation" },
  { path: "/dashboard", icon: "bi bi-cpu", title: "Dashboard" },
  { path: "/clients", icon: "bi bi-cpu", title: "Clients" },

  {
    path: "/pos",
    icon: "bi bi-bag-check",
    title: "POS System",
    highlight: true,
    children: [{ path: "/customer-order", title: "Customer Order" }],
    
  },
  { path: "/settings", icon: "bi bi-gear", title: "Settings" },
];
const Menu = role === "SR" ? SR : role === "SUPERADMIN" ? superAdmin : admin;

export default Menu;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { singleImageUpload } from "../../hooks/ImageUpload";
import { useProductCreateMutation } from "../../redux/api/product/productApi";
import { useAllCategoryGetQuery } from "../../redux/api/categories/categoriesApi";
import { useAllBrandGetQuery } from "../../redux/api/brands/brandsApi";
import {
  useAllUnitGetQuery,
  useSingleUnitGetQuery,
} from "../../redux/api/units/unitsApi";
import CategoriesModal from "../categories/CategoriesModal";
import BrandsModal from "../brands/BrandsModal";
import { ErrorMessage, Field, Form, Formik } from "formik";
import QuillField from "./QuillField";
import Select from "react-select";
import { Card, CardBody } from "../../components/card/card";
import { useTranslation } from "react-i18next";
import Webcam from "react-webcam";
import { useSelector } from "react-redux";
import { useAllSelfQuery } from "../../redux/api/self/selfApi";
import AddSelfModal from "../self/AddSelfModal";
import EditModal from "../unit/EditModal";
import AddUnitModal from "../unit/AddUnitModal";

const WebcamComponent = () => <Webcam />;
const videoConstraints = {
  width: 200,
  height: 200,
  facingMode: "user",
};
const AddProductModal = ({ show, hide }) => {
  const store = useSelector((state) => state.store);
  const filter = {
    storeId: store?.storeId,
  };
  const [picture, setPicture] = useState("");
  const [isUpload, setIsUpload] = useState(true);
  const webcamRef = React.useRef(null);
  function dataURLtoBlob(dataURL) {
    const splitData = dataURL.split(",");
    const contentType = splitData[0].match(/:(.*?);/)[1];
    const byteCharacters = atob(splitData[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }
  const capture = React.useCallback(async () => {
    const pictureSrc = webcamRef.current.getScreenshot();
    const blobImage = await dataURLtoBlob(pictureSrc);
    const formData = new FormData();

    formData.append("image", blobImage);

    try {
      await singleImageUpload(formData, setImage);
    } catch (error) {
      alert(error.message);
    }
    setPicture(pictureSrc);
  });
  const [addcategoryIdModal, setAddcategoryIdModal] = useState(false);
  const [addbrandIdModal, setAddbrandIdModal] = useState(false);
  const [skip, setSkip] = useState(true);
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [subUnitsIdId, setsubUnitsIdId] = useState(null);
  const [image, setImage] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddUnitModal, setIsAddUnitModal] = useState(false);
  const handleChangeUploadImage = async (event) => {
    const image = event.target.files[0];

    const formData = new FormData();

    formData.append("image", image);

    try {
      await singleImageUpload(formData, setImage);
    } catch (error) {
      alert(error.message);
    }
  };
  const { t } = useTranslation();
  const initialValues = {
    productName: "",
    productCode: "",
    categoryId: null,
    brandId: null,
    unitId: null,
    selfsId: null,
    subUnitsId: null,
    stock: "",
    salePrice: "",
    purchaseCost: "",
    productDetails: "",
    image: "",
  };

  const [productCreate] = useProductCreateMutation();

  const { data: categoryIdData } = useAllCategoryGetQuery(filter, {
    refetchOnMountOrArgChange: true,
  });
  const initialcategoryIdData = categoryIdData?.data;
  const { data: seltData } = useAllSelfQuery(filter, {
    refetchOnMountOrArgChange: true,
  });
  const initialSelfData = seltData?.data;

  const { data: brandIdData } = useAllBrandGetQuery(filter, {
    refetchOnMountOrArgChange: true,
  });
  const initialbrandIdData = brandIdData?.data;

  const { data: unitId } = useAllUnitGetQuery(filter, {
    refetchOnMountOrArgChange: true,
  });
  const initialunitIdData = unitId?.data;

  const { data } = useSingleUnitGetQuery(
    { id: subUnitsIdId, params: { storeId: store?.storeId } },
    {
      skip,
      refetchOnMountOrArgChange: true,
    }
  );

  const initialsubUnitsIdData = data?.data;

  const unitIdOptions = initialunitIdData?.map((item) => {
    return {
      value: item?.id,
      label: item?.name,
    };
  });
  const selfsOptions = initialSelfData?.map((item) => {
    return {
      value: item?.id,
      label: item?.name,
    };
  });

  const subUnitsIdOptions = initialsubUnitsIdData?.map((item) => {
    return {
      value: item?.id,
      label: item?.name,
    };
  });
  const categoryIdOptions = initialcategoryIdData?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const brandIdOptions = initialbrandIdData?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const validationSchema = Yup.object({
    productName: Yup.string().required("Product Name is required"),
    categoryId: Yup.string().required("Category is required"),
    productCode: Yup.string().required("Product Code is required"),
    purchaseCost: Yup.number()
      .required("Purchase Cost is required")
      .positive("Purchase Cost must be a positive number"),
    salePrice: Yup.number()
      .required("Selling Price is required")
      .positive("Selling Price must be a positive number"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    setIsAddingLoading(true);
    let stock;
    const mainU = parseFloat(values.stock);
    const subU = parseFloat(values.substock);
    const findSubUbit = initialunitIdData.find(
      (item) => item.id === values?.unitId
    );
    if (findSubUbit?.relatedToUnits) {
      stock = mainU * parseInt(findSubUbit.relatedByValue) + subU;
    } else {
      stock = mainU;
    }
    const data = {
      productName: values.productName,
      productCode: values.productCode,
      categoryId: values.categoryId,
      brandId: values.brandId,
      unitId: values.unitId,
      selfsId: values.selfsId,
      subUnitsId: values.subUnitsId,
      stock: stock,
      salePrice: parseInt(values.salePrice),
      purchaseCost: parseInt(values.purchaseCost),
      productDetails: values.productDetails,
      image: (values.image = image),
      storeId: store?.storeId,
    };

    const res = await productCreate(data);

    if (res?.data) {
      toast.success("Product Create Successful");
      resetForm(initialValues);
      setIsAddingLoading(false);
      // navigate("/products");
    }
    hide();
  };
  return (
    <Modal show={show} onHide={hide} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{t("Add Product")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-3 p-2">
          <CardBody>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="d-flex  gap-2 ">
                    <div className="form-group col-md-4 ">
                      <label htmlFor="productName">{t("Name")}</label>
                      <Field
                        type="text"
                        name="productName"
                        className="form-control"
                        placeholder="Enter Product Name"
                      />
                      <ErrorMessage
                        name="productName"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="form-group col-md-2"></div>
                    <div className="form-group col-md-4">
                      <label htmlFor="productCode">{t("Code")}</label>
                      <Field
                        type="text"
                        name="productCode"
                        className="form-control"
                        placeholder="Enter Product Code"
                      />
                      <ErrorMessage
                        name="productCode"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="d-flex align-items-center  gap-2 py-2">
                    <div className="form-group col-md-4 ">
                      <label htmlFor="productCode">{t("Self")}</label>
                      <Field className="form-control" name="selfsId">
                        {(props) => {
                          const handleChange = (option) => {
                            props.form.setFieldValue("selfsId", option.value);
                          };
                          return (
                            <Select
                              classNamePrefix="react-select"
                              options={selfsOptions}
                              isSearchable
                              onChange={handleChange}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-group col-md-2">
                      <span
                        style={{
                          marginTop: "20px",
                        }}
                        className="btn btn-outline-theme"
                        onClick={() => setIsModalOpen(true)}
                      >
                        <i className="fa fa-plus-circle me-1"></i>
                        {t("Add")}
                      </span>
                    </div>
                    <div className="form-group col-md-4 ">
                      <label htmlFor="categoryId">{t("Category")}</label>
                      <Field className="form-control" name="categoryId">
                        {(props) => {
                          const handleChange = (option) => {
                            props.form.setFieldValue(
                              "categoryId",
                              option.value
                            );
                          };
                          return (
                            <Select
                              classNamePrefix="react-select"
                              options={categoryIdOptions}
                              isSearchable
                              onChange={handleChange}
                            />
                          );
                        }}
                      </Field>
                      <ErrorMessage
                        name="categoryId"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="form-group col-md-2 ">
                      <span
                        style={{
                          marginTop: "20px",
                        }}
                        className="btn btn-outline-theme"
                        onClick={() => setAddcategoryIdModal(true)}
                      >
                        <i className="fa fa-plus-circle me-1"></i>
                        {t("Add")}
                      </span>
                    </div>
                  </div>
                  {/* <div className="d-flex align-items-center  gap-2 py-2">
                    <div className="form-group col-md-4 ">
                      <label htmlFor="categoryId">{t("Category")}</label>
                      <Field className="form-control" name="categoryId">
                        {(props) => {
                          const handleChange = (option) => {
                            props.form.setFieldValue(
                              "categoryId",
                              option.value
                            );
                          };
                          return (
                            <Select
                              classNamePrefix="react-select"
                              options={categoryIdOptions}
                              isSearchable
                              onChange={handleChange}
                            />
                          );
                        }}
                      </Field>
                      <ErrorMessage
                        name="categoryId"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="form-group col-md-2 ">
                      <span
                        style={{
                          marginTop: "20px",
                        }}
                        className="btn btn-outline-theme"
                        onClick={() => setAddcategoryIdModal(true)}
                      >
                        <i className="fa fa-plus-circle me-1"></i>
                        {t("Add")}
                      </span>
                    </div>
                  </div> */}

                  <div className="d-flex justify-content-between  gap-2 py-2">
                    <div className="form-group col-md-4 ">
                      <label htmlFor="categoryId">{t("Brand")}</label>
                      <Field className="form-control" name="brandId">
                        {(props) => {
                          const handleChange = (option) => {
                            props.form.setFieldValue("brandId", option.value);
                          };
                          return (
                            <Select
                              classNamePrefix="react-select"
                              options={brandIdOptions}
                              isSearchable
                              onChange={handleChange}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-group col-md-2 ">
                      <span
                        style={{
                          marginTop: "20px",
                        }}
                        className="btn btn-outline-theme"
                        onClick={() => setAddbrandIdModal(true)}
                      >
                        <i className="fa fa-plus-circle me-1"></i>
                        {t("Add")}
                      </span>
                    </div>
                    <div className="form-group col-md-4 ">
                      <label htmlFor="unitId">
                        {t("Main")} {t("Unit")}
                      </label>
                      <Field className="form-control" name="unitId">
                        {(props) => {
                          const handleChange = (option) => {
                            props.form.setFieldValue("unitId", option.value);

                            const unit = initialunitIdData?.find(
                              (item) => item.id === option.value
                            );
                            if (unit?.relatedToUnits) {
                              setSkip(false);
                              setsubUnitsIdId(unit?.relatedToUnits);
                            }
                          };
                          return (
                            <Select
                              classNamePrefix="react-select"
                              options={unitIdOptions}
                              isSearchable
                              onChange={handleChange}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-group col-md-2 ">
                      <span
                        style={{
                          marginTop: "20px",
                        }}
                        className="btn btn-outline-theme"
                        onClick={() => setIsAddUnitModal(true)}
                      >
                        <i className="fa fa-plus-circle me-1"></i>
                        {t("Add")}
                      </span>
                    </div>
                  </div>

                  {/* <div className="d-flex justify-content-between  gap-2 py-2">
                    <div className="form-group col-md-6 ">
                      <label htmlFor="unitId">
                        {t("Main")} {t("Unit")}
                      </label>
                      <Field className="form-control" name="unitId">
                        {(props) => {
                          const handleChange = (option) => {
                            props.form.setFieldValue("unitId", option.value);

                            const unit = initialunitIdData?.find(
                              (item) => item.id === option.value
                            );
                            if (unit?.relatedToUnits) {
                              setSkip(false);
                              setsubUnitsIdId(unit?.relatedToUnits);
                            }
                          };
                          return (
                            <Select
                              classNamePrefix="react-select"
                              options={unitIdOptions}
                              isSearchable
                              onChange={handleChange}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-group col-md-6 ">
                      <span
                        style={{
                          marginTop: "20px",
                        }}
                        className="btn btn-outline-theme"
                        onClick={() => setAddbrandIdModal(true)}
                      >
                        <i className="fa fa-plus-circle me-1"></i>
                        {t("Add")}
                      </span>
                    </div>
                  </div> */}
                  <div className="d-flex justify-content-between  gap-2 py-2">
                    <div className="form-group col-md-4 ">
                      <label htmlFor="subUnitsId">
                        {t("Sub")} {t("Unit")}
                      </label>
                      <Field className="form-control" name="subUnitsId">
                        {(props) => {
                          const handleChange = (option) => {
                            props.form.setFieldValue(
                              "subUnitsId",
                              option.value
                            );
                          };
                          return (
                            <Select
                              classNamePrefix="react-select"
                              options={subUnitsIdOptions}
                              isSearchable
                              onChange={handleChange}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    {/* <div className="form-group col-md-6 ">
                      <span
                        style={{
                          marginTop: "20px",
                        }}
                        className="btn btn-outline-theme"
                        onClick={() => setAddbrandIdModal(true)}
                      >
                        <i className="fa fa-plus-circle me-1"></i>
                        {t("Add")}
                      </span>
                    </div> */}
                  </div>

                  <div className="d-flex gap-2">
                    {values.unitId && (
                      <div className="form-group col-md-4 ">
                        <label htmlFor="stock">
                          {
                            initialunitIdData.find(
                              (item) => item.id === values.unitId
                            )?.name
                          }
                        </label>
                        <Field
                          type="text"
                          name="stock"
                          className="form-control"
                          placeholder={`Enter Opening Stock for ${
                            initialunitIdData.find(
                              (item) => item.id === values.unitId
                            )?.name
                          }`}
                        />
                        <ErrorMessage
                          name={`stock-${values.unitId}`}
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    )}

                    {values.subUnitsId && (
                      <div className="form-group col-md-6 ">
                        <label htmlFor="stock">
                          {
                            initialunitIdData.find(
                              (item) => item.id === values.subUnitsId
                            )?.name
                          }
                        </label>
                        <Field
                          type="text"
                          name="substock"
                          className="form-control"
                          placeholder={`Enter Opening Stock for ${
                            initialunitIdData.find(
                              (item) => item.id === values.subUnitsId
                            )?.name
                          }`}
                        />
                        <ErrorMessage
                          name={`stock-${values.subUnitsId}`}
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    )}
                  </div>
                  <div className="d-flex  gap-2 py-2">
                    <div className="form-group col-md-4 ">
                      <label htmlFor="purchaseCost">
                        {t("purchases")} {t("Cost")}
                      </label>
                      <Field
                        type="text"
                        name="purchaseCost"
                        className="form-control"
                        placeholder="Enter Purchase Cost"
                      />
                      <ErrorMessage
                        name="purchaseCost"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="form-group col-md-2"></div>
                    <div className="form-group col-md-4 ">
                      <label htmlFor="salePrice">
                        {t("sale")} {t("Price")}
                      </label>
                      <Field
                        type="text"
                        name="salePrice"
                        className="form-control"
                        placeholder="Enter Selling Price"
                      />
                      <ErrorMessage
                        name="salePrice"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="form-group col-12 ">
                    <label htmlFor="productDetails">
                      {t("product")} {t("Details")}
                    </label>
                    <Field
                      name="productDetails"
                      as="div"
                      component={QuillField}
                    />
                  </div>
                  <div className="d-flex gap-3 mt-5">
                    <span
                      className={`cursor-pointer${
                        isUpload ? " border-bottom" : ""
                      }`}
                      onClick={() => setIsUpload(true)}
                    >
                      Upload
                    </span>
                    <span
                      className={`cursor-pointer${
                        !isUpload ? " border-bottom" : ""
                      }`}
                      onClick={() => setIsUpload(false)}
                    >
                      Take Photo
                    </span>
                  </div>

                  <div className="d-flex gap-4  mt-3 mb-4">
                    {isUpload ? (
                      <div className="form-group">
                        <label htmlFor="image">
                          {t("product")} {t("Image")}
                        </label>
                        <input
                          type="file"
                          name="image"
                          onChange={handleChangeUploadImage}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="image"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    ) : (
                      <div>
                        <div>
                          {picture === "" ? (
                            <Webcam
                              audio={false}
                              height={200}
                              ref={webcamRef}
                              width={200}
                              screenshotFormat="image/jpeg"
                              videoConstraints={videoConstraints}
                            />
                          ) : (
                            <img src={picture} />
                          )}
                        </div>
                        <div>
                          {picture === "" && (
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                capture();
                              }}
                              className="btn btn-danger"
                            >
                              Capture
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  <button
                    type="submit"
                    className="btn btn-outline-theme"
                    disabled={isAddingLoading}
                  >
                    {isAddingLoading ? (
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      t("Add Product")
                    )}
                    <i className="fa fa-save px-2"></i>
                  </button>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
        {addcategoryIdModal && (
          <CategoriesModal
            isModalOpen={addcategoryIdModal}
            closeModal={() => setAddcategoryIdModal(false)}
          />
        )}
        {addbrandIdModal && (
          <BrandsModal
            isModalOpen={addbrandIdModal}
            closeModal={() => setAddbrandIdModal(false)}
          />
        )}
        {isModalOpen && (
          <AddSelfModal
            isModalOpen={isModalOpen}
            closeModal={() => setIsModalOpen(false)}
            mode={"add"}
          />
        )}
        {isAddUnitModal && (
          <AddUnitModal
            isModalOpen={isAddUnitModal}
            closeModal={() => setIsAddUnitModal(false)}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddProductModal;

/* eslint-disable no-sequences */
import {baseApi} from "../baseApi";

export const damageApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    damageCreate: build.mutation({
      query: (damageData) => ({
        url: "/damage",
        method: "POST",
        data: damageData,
      }),
      invalidatesTags: ["damage"],
    }),

    allDamageGet: build.query({
      query: (params) => ({
        url: "/damage",
        method: "GET",
        params,
      }),
      providesTags: ["damage"],
    }),
    singleDamageGet: build.query({
      query: (id) => ({
        url: `/damage/${id}`,
        method: "GET",
      }),
      providesTags: ["damage"],
    }),

    updateDamage: build.mutation({
      query: ({id, values}) => ({
        url: `/damage/${id}`,
        method: "PATCH",
        data: values,
      }),
      invalidatesTags: ["damage"],
    }),
    deleteDamage: build.mutation({
      query: (id) => ({
        url: `/damage/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["damage"],
    }),
  }),
});

export const {
  useDamageCreateMutation,
  useAllDamageGetQuery,
  useSingleDamageGetQuery,
  useUpdateDamageMutation,
  useDeleteDamageMutation,
} = damageApi;

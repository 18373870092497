import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSrCreateMutation } from "../../redux/api/sr/srApi";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("SR Name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  address: Yup.string().required("Address is required"),
  mobile: Yup.string().required("Mobile is required"),
});

const SRManagesModal = ({ isModalOpen, closeModal }) => {
  const store = useSelector((state) => state.store)
  const { t } = useTranslation();

  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const initialValues = {
    name: "",
    email: "",
    address: "",
    mobile: "",
  };
  const [srCreate] = useSrCreateMutation();

  const handleSubmit = async (values, { resetForm }) => {
    setIsAddingLoading(true);
    const data ={
      ...values,
      storeId: store.storeId
    }
    const res = await srCreate(data);
    if (res?.data) {
      toast.success("SR Create Successful");
      resetForm(initialValues);
    }
    setIsAddingLoading(false);
    closeModal();
  };
  const validateMobile = (value) => {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^\d+$/.test(value)) {
      error = "Please enter only numbers";
    }
    return error;
  };
  return (
    <Modal show={isModalOpen} onHide={closeModal} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("Add SR")} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="d-flex flex-column flex-md-row  gap-1">
              <div className="form-group flex-grow-1">
                <label htmlFor="name" className="py-2">{t("Name")}</label>
                <Field
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Enter SR Name..."
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group flex-grow-1">
                <label htmlFor="email" className="py-2">{t("Email")}</label>
                <Field
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Enter SR Email..."
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row gap-1">
              <div className="form-group flex-grow-1">
                <label htmlFor="address" className="py-2">{t("Address")}</label>
                <Field
                  as="textarea"
                  className="form-control"
                  name="address"
                  placeholder="Write SR Address"
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group flex-grow-1">
                <label htmlFor="mobile" className="py-2">{t("Phone")}</label>
                <Field
                  type="text"
                  className="form-control"
                  name="mobile"
                  placeholder="Enter Customer Phone"
                  validate={validateMobile}
                />
                <ErrorMessage
                  name="mobile"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="modal-footer mt-3">
              <button
                className="btn btn-outline-default"
                onClick={() => closeModal()}
                disabled={isAddingLoading}
              >
                {t("Cancel")}
              </button>
              <button
                type="submit"
                className="btn btn-outline-theme"
                disabled={isAddingLoading}
              >
                {isAddingLoading ? (
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  t("Save")
                )}
              </button>
            </div>
          </Form>
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default SRManagesModal;

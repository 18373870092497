import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import Loader from "../../components/loader/Loader";
import {
  useAllUnitGetQuery,
  useAddUnitsMutation,
} from "../../redux/api/units/unitsApi";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const AddUnitModal = ({ isModalOpen, closeModal }) => {
  const store = useSelector((state) => state.store);
  const [isAddingUnit, setIsAddingUnit] = useState(false);
  const [addUnits] = useAddUnitsMutation();
  const filter = {
    storeId: store?.storeId,
  };
  const { data, isLoading } = useAllUnitGetQuery(filter, {
    refetchOnMountOrArgChange: true,
  });
  const initialData = data?.data;
  const { t } = useTranslation();
  const initialValues = {
    name: "",
    relatedToUnits: "",
    operator: "",
    relatedByValue: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required("Unit Name is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsAddingUnit(true);

      values.relatedToUnits = parseInt(values.relatedToUnits);
      values.storeId = parseInt(store?.storeId)
      const res = await addUnits(values);

      if (res?.data) {
        toast.success("Unit Created Successfully");
        resetForm(initialValues);
      }

      setIsAddingUnit(false);
      closeModal();
    },
  });

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (!data) {
    return <div>{t("No data available")}</div>;
  }

  const relatedToUnit = initialData?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const getRelatedToUnit = (value) => {
    const data = relatedToUnit.find((unit) => unit.value === value);
    return data?.label;
  };

  return (
    <Modal show={isModalOpen} onHide={closeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("Add Unit")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="card-body p-3">
            <div className="form-row">
              <div className="d-flex justify-content-center gap-2">
                <div className="form-group col-md-6 py-2">
                  <label style={{ paddingBottom: "5px" }} htmlFor="name">
                    {t("Name")}
                  </label>
                  <input
                    type="text"
                    className="form-control py-2"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    placeholder="e.g. Kg"
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="text-danger">{formik.errors.name}</div>
                  )}
                </div>
                <div className="form-group col-md-6 py-2">
                  <label
                    style={{ paddingBottom: "5px" }}
                    htmlFor="relatedToUnits"
                  >
                    {t("Related To Unit")}
                  </label>
                  <select
                    name="relatedToUnits"
                    id="relatedToUnits"
                    className="form-select form-select-lg bg-white bg-opacity-5"
                    onChange={formik.handleChange}
                    value={formik.values.relatedToUnits}
                  >
                    <option value="">
                      {t("Select")} {t("Unit")}
                    </option>
                    {relatedToUnit?.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  {formik.touched.relatedToUnits &&
                    formik.errors.relatedToUnits && (
                      <div className="text-danger">
                        {formik.errors.relatedToUnits}
                      </div>
                    )}
                </div>
              </div>
              <div className="d-flex justify-content-center gap-2">
                <div className="form-group col-md-6 py-2">
                  <label style={{ paddingBottom: "5px" }} htmlFor="operator">
                    {t("Operator")}
                  </label>
                  <select
                    name="operator"
                    id="operator"
                    className="form-select form-select-lg bg-white bg-opacity-5 py-2"
                    onChange={formik.handleChange}
                    value={formik.values.operator}
                  >
                    <option value="">{t("Select Operator Sign")}</option>
                    <option value="*">(*) Multiply Operator</option>
                    {/* Add other options here */}
                  </select>
                  {formik.touched.operator && formik.errors.operator && (
                    <div className="text-danger">{formik.errors.operator}</div>
                  )}
                </div>
                <div className="form-group col-md-6 py-2">
                  <label
                    style={{ paddingBottom: "5px" }}
                    htmlFor="relatedByValue"
                  >
                    {t("Related By Value")}
                  </label>
                  <input
                    type="text"
                    name="relatedByValue"
                    onChange={formik.handleChange}
                    value={formik.values.relatedByValue}
                    className="form-control py-2"
                  />
                  {formik.touched.relatedByValue &&
                    formik.errors.relatedByValue && (
                      <div className="text-danger">
                        {formik.errors.relatedByValue}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
          <p className="text-center">
            1 {formik.values.name} = 1
            {getRelatedToUnit(formik.values.relatedToUnits)}
            {formik.values.operator}
            {formik.values.relatedByValue}
          </p>

          <div className="modal-footer">
            <button
              className="btn btn-outline-default"
              onClick={() => closeModal()}
              disabled={isAddingUnit}
            >
              {t("Cancel")}
            </button>
            <button
              type="submit"
              className="btn btn-outline-theme"
              disabled={isAddingUnit}
            >
              {isAddingUnit ? (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                t("Add")
              )}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddUnitModal;

import React, { useEffect, useContext, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Card, CardBody } from "./../../components/card/card.jsx";
import { AppSettings } from "./../../config/app-settings.js";
import { Modal } from "bootstrap";
import { useAllCategoryGetQuery } from "../../redux/api/categories/categoriesApi.js";
import { useAllProductGetQuery } from "../../redux/api/product/productApi.js";
import Loader from "../../components/loader/Loader.jsx";

import { useAllCustomerGetQuery } from "../../redux/api/customer/customerApi.js";
import AddtoCartModal from "./AddtoCartModal.jsx";
import AllCategory from "./AllCategory.jsx";
import ProductPagenation from "./ProductPagenation.jsx";
import FilterSales from "./FilterSales.jsx";
import AllProducts from "./AllProducts.jsx";
import OrderSidbar from "./OrderSidbar.jsx";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

function PosCustomerOrder() {
  const store = useSelector((state) => state.store);
  const context = useContext(AppSettings);
  const [posMobileSidebarToggled, setPosMobileSidebarToggled] = useState(false);
  const [categoryType, setCategoryType] = useState("all");
  const [tableData, setTableData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [modal, setModal] = useState();
  const [modalData, setModalData] = useState();
  const [modalQuantity, setModalQuantity] = useState("0");
  const [modalSubUnitQuantity, setModalSubUnitQuantity] = useState("0");
  const [modalSelectedSize, setModalSelectedSize] = useState();
  const [modalSelectedAddon, setModalSelectedAddon] = useState([]);
  const [selectCategory, setSelectCategory] = useState(0);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [productName, setName] = useState();
  const [nameFieldValue, setNameFieldValue] = useState();
  const [salesDate, setSalesDate] = useState(new Date());
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({
    page,
    limit: pageSize,
    storeId: store.storeId,
  });
  const { data, refetch } = useAllCategoryGetQuery(
    { storeId: store.storeId },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const initialData = data?.data;
  const meta = data?.meta;
  const {
    data: pdata,
    isLoading: ploading,
    refetch: prefetch,
  } = useAllProductGetQuery(filter, {
    refetchOnMountOrArgChange: true,
  });

  const { data: initalCData } = useAllCustomerGetQuery(
    { storeId: store.storeId },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  useEffect(() => {
    const modalElement = document.getElementById("modalPosItem");
    if (modalElement) {
      setModal(new Modal(modalElement));
    }
    if (pdata) {
      const initialPData = pdata?.data;

      const result = initialPData;
      setTableData(result);
      // setOrderData(result);
    }
    context.setAppHeaderNone(true);
    context.setAppSidebarNone(true);
    context.setAppContentFullHeight(true);
    context.setAppContentClass("p-1 ps-xl-4 pe-xl-4 pt-xl-3 pb-xl-3");

    return function cleanUp() {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
      context.setAppContentFullHeight(false);
      context.setAppContentClass("");
    };

    // eslint-disable-next-line
  }, [pdata]);

  useEffect(() => {
    const updatedFilter = { ...filter };
    updatedFilter.page = page;
    if (productName) {
      updatedFilter.productName = productName;
    }
    if (searchTerm) {
      updatedFilter.searchTerm = searchTerm;
    }
    if (!searchTerm) {
      delete updatedFilter.searchTerm;
    }
    if (selectCategory) {
      updatedFilter.category = selectCategory;
    }
    if (!selectCategory) {
      delete updatedFilter.category;
    }
    if (!nameFieldValue) {
      delete updatedFilter?.productName;
    }
    setFilter(updatedFilter);
    prefetch();
  }, [productName, prefetch, searchTerm, page, nameFieldValue, selectCategory]);

  const handleFilterChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const options = initalCData?.data?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));
  const handlePayment = () => {
    // setPayable(total);
    // setDue(total - payAmount);
    if (!customerId) {
      toast.error("Please Select Customer");
    }
    else if(!orderData?.length > 0){
      toast.error("Please add product");
    } else {
      setPaymentModalVisible(true);
    }
  };

  const handleClosePaymentModal = () => {
    setPaymentModalVisible(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleMobileSidebar = (event) => {
    event.preventDefault();

    setPosMobileSidebarToggled(true);
  };

  const dismissMobileSidebar = (event) => {
    event.preventDefault();

    setPosMobileSidebarToggled(false);
  };

  const showType = (event, id) => {
    console.log(id);
    event.preventDefault();

    if (tableData && tableData?.category) {
      for (let i = 0; i < tableData?.category?.length; i++) {
        console.log(i);
        if (tableData?.category?.id === id || id === 0) {
          tableData.category = false;
        } else {
          tableData.category = true;
        }
      }

      setTableData(tableData);
      setCategoryType(id);
    }
  };

  const showPosItemModal = (event, item) => {
    event.preventDefault();
    if (modal && item?.stock) {
      setModalData(item);
      setModalQuantity("0");
      setModalSubUnitQuantity("0");
      setModalSelectedAddon([]);

      modal?.show();
    }
  };

  const getOrderTotal = () => {
    return orderData ? orderData?.length : 0;
  };

  const deductQty = (event, id) => {
    event.preventDefault();

    if (orderData) {
      const newData = orderData?.map((obj) => {
        if (obj?.id === id) {
          let newQty = parseInt(obj?.quantity) - 1;

          if (newQty < 1) {
            newQty = 1;
          }
          return { ...obj, quantity: newQty };
        }

        return obj;
      });

      setOrderData(newData);
    }
  };
  const deductSubQty = (event, id) => {
    event.preventDefault();

    if (orderData) {
      const newData = orderData?.map((obj) => {
        if (obj?.id === id) {
          let newQty = parseInt(obj?.subQuantity) - 1;

          if (newQty < 1) {
            newQty = 1;
          }
          return { ...obj, subQuantity: newQty };
        }

        return obj;
      });

      setOrderData(newData);
    }
  };

  const addQty = (event, id) => {
    event.preventDefault();
    if (orderData) {
      const newData = orderData.map((obj) => {
        if (obj.id === id) {
          let newQty = parseInt(event.target.value);
          return { ...obj, quantity: newQty };
        }

        return obj;
      });

      setOrderData(newData);
    }
  };
  const addSubQty = (event, id) => {
    event.preventDefault();

    if (orderData) {
      const newData = orderData.map((obj) => {
        if (obj.id === id) {
          let newQty = parseInt(event.target.value);
          return { ...obj, subQuantity: newQty };
        }

        return obj;
      });

      setOrderData(newData);
    }
  };

  const getSubTotalPrice = () => {
    let value = 0;

    if (orderData) {
      for (let i = 0; i < orderData?.length; i++) {
        value +=
          parseFloat(orderData[i].salePrice) * parseInt(orderData[i]?.quantity);
      }
    }
    return value.toFixed(2);
  };

  const getTaxesPrice = () => {
    let value = 0;

    if (orderData) {
      for (let i = 0; i < orderData?.length; i++) {
        value +=
          parseFloat(orderData[i]?.salePrice) *
          parseInt(orderData[i]?.quantity) *
          0.0;
      }
    }
    return value?.toFixed(2);
  };

  let getTotalPrice = () => {
    let value = 0;

    if (orderData) {
      for (let i = 0; i < orderData?.length; i++) {
        value +=
          parseFloat(orderData[i]?.salePrice) *
          parseInt(orderData[i]?.quantity);
      }
    }

    return value?.toFixed(2);
  };

  const toggleConfirmation = (event, id, value) => {
    event.preventDefault();

    if (orderData) {
      const newData = orderData?.map((obj) => {
        if (obj.id === id) {
          return { ...obj, confirmation: value };
        }
        return obj;
      });

      setOrderData(newData);
    }
  };

  const removeOrder = (event, id) => {
    event.preventDefault();

    if (orderData) {
      const newData = orderData?.filter(function (order) {
        return order?.id !== id;
      });

      setOrderData(newData);
    }
  };

  const addModalQty = (event) => {
    event.preventDefault();
    setModalQuantity(event.target.value);
    return;
    // if (modalQuantity) {
    //   let newQty = parseInt(modalQuantity) + 1;
    //   setModalQuantity(newQty);
    // }
  };
  const addModalSubQty = (event) => {
    event.preventDefault();
    setModalSubUnitQuantity(event.target.value);
    return;
    // if (modalSubUnitQuantity) {
    //   let newQty = parseInt(modalSubUnitQuantity) + 1;

    //   setModalSubUnitQuantity(newQty);
    // }
  };

  const deductModalQty = (event) => {
    event.preventDefault();

    if (modalQuantity) {
      let newQty = parseInt(modalQuantity) - 1;

      if (newQty < 1) {
        newQty = 1;
      }
      setModalQuantity(newQty);
    }
  };

  const deductModalSubQty = (event) => {
    event.preventDefault();

    if (modalSubUnitQuantity) {
      let newQty = parseInt(modalSubUnitQuantity) - 1;

      if (newQty < 1) {
        newQty = 1;
      }
      setModalSubUnitQuantity(newQty);
    }
  };

  const addToCart = (event, userSelectedItem) => {
    event.preventDefault();
    // modal.hide();

    const existingProduct = orderData?.find(
      (product) => product.id === userSelectedItem?.id
    );

    if (existingProduct) {
      const updatedCart = orderData?.map((product) =>
        product?.id === userSelectedItem?.id
          ? {
              ...product,
              quantity: (
                parseInt(product?.quantity) + parseInt(modalQuantity)
              )?.toString(),
              subQuantity: (
                parseInt(product?.subQuantity) + parseInt(modalSubUnitQuantity)
              )?.toString(),
            }
          : product
      );
      setModalQuantity("0");
      setModalSubUnitQuantity("0");
      setOrderData(updatedCart);
    } else {
      let options = [];
      if (modalSelectedSize) {
        let option = {
          key: "size",
          value: modalSelectedSize,
        };
        options?.push(option);
      }
      if (modalSelectedAddon) {
        for (let i = 0; i < modalSelectedAddon?.length; i++) {
          const option2 = {
            key: "addon",
            value: modalSelectedAddon[i],
          };
          options.push(option2);
        }
      }

      setTimeout(() => {
        setOrderData([
          ...orderData,
          {
            ...userSelectedItem,
            quantity: modalQuantity,
            subQuantity: modalSubUnitQuantity,
            options: options,
          },
        ]);
        setModalQuantity("0");
        setModalSubUnitQuantity("0");
      }, 500);
    }
  };
  const { t } = useTranslation();
  return (
    <>
      {ploading ? (
        <Loader />
      ) : (
        <div className="h-100">
          <FilterSales
            searchTerm={searchTerm}
            handleFilterChange={handleFilterChange}
            customerId={customerId}
            setCustomerId={setCustomerId}
            options={options}
            setSalesDate={setSalesDate}
            salesDate={salesDate}
            openModal={openModal}
          />
          <Card
            className={
              "pos " +
              (posMobileSidebarToggled ? "pos-mobile-sidebar-toggled" : "")
            }
            id="pos"
          >
            <CardBody className="pos-container">
              <div className="pos-menu mt-2">
                <div className="nav-container">
                  <PerfectScrollbar className="">
                    <ul className="nav nav-tabs  d-flex flex-column align-items-center gap-2">
                      <li className="nav-item">
                        <div
                          className={
                            "nav-link cursor-pointer" +
                            (selectCategory === 0 ? " active" : "")
                          }
                          onClick={() => setSelectCategory(0)}
                        >
                          <Card>
                            <CardBody>
                              <div className="">
                                <span className="me-2">All</span>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      </li>

                      <AllCategory
                        initialData={initialData}
                        selectCategory={selectCategory}
                        setSelectCategory={setSelectCategory}
                        showType={showType}
                      />
                    </ul>
                  </PerfectScrollbar>
                </div>
              </div>

              <div className="pos-content">
                <PerfectScrollbar className="pos-content-container p-4">
                  {/* allprodcuts  */}
                  <AllProducts
                    tableData={tableData}
                    showPosItemModal={addToCart}
                  />
                </PerfectScrollbar>

                <ProductPagenation
                  pageSize={pageSize}
                  meta={meta}
                  page={page}
                  setPage={setPage}
                />
              </div>

              <div className="pos-sidebar" id="pos-sidebar">
                <div className="h-100 d-flex flex-column p-0">
                  <div className="pos-sidebar-header">
                    <div className="back-btn">
                      <button
                        type="button"
                        onClick={dismissMobileSidebar}
                        className="btn"
                      >
                        <i className="bi bi-chevron-left"></i>
                      </button>
                    </div>
                  </div>

                  <div className="pos-sidebar-nav">
                    <ul className="nav nav-tabs nav-fill">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          href="#/"
                          data-bs-toggle="tab"
                          data-bs-target="#newOrderTab"
                        >
                          {t("NewSell")} ({getOrderTotal()})
                        </a>
                      </li>
                    </ul>
                  </div>

                  {/* order list  */}
                  <OrderSidbar
                    handlePayment={handlePayment}
                    orderData={orderData}
                    setOrderData={setOrderData}
                    deductQty={deductQty}
                    addQty={addQty}
                    deductSubQty={deductSubQty}
                    addSubQty={addSubQty}
                    toggleConfirmation={toggleConfirmation}
                    removeOrder={removeOrder}
                    getSubTotalPrice={getSubTotalPrice}
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                    handleClosePaymentModal={handleClosePaymentModal}
                    paymentModalVisible={paymentModalVisible}
                    getTotalPrice={getTotalPrice}
                    getTaxesPrice={getTaxesPrice}
                    customerId={customerId}
                    setCustomerId={setCustomerId}
                    salesDate={salesDate}
                  />
                </div>
              </div>
            </CardBody>
          </Card>

          <button
            className="pos-mobile-sidebar-toggler"
            onClick={toggleMobileSidebar}
          >
            <i className="bi bi-bag"></i>
            <span className="badge">{getOrderTotal()}</span>
          </button>

          <div className="modal modal-pos fade" id="modalPosItem">
            <div className="modal-dialog modal-lg">
              <div className="modal-content border-0">
                <AddtoCartModal
                  modalData={modalData}
                  modalQuantity={modalQuantity}
                  modalSubUnitQuantity={modalSubUnitQuantity}
                  deductModalQty={deductModalQty}
                  addModalQty={addModalQty}
                  deductModalSubQty={deductModalSubQty}
                  addModalSubQty={addModalSubQty}
                  addToCart={addToCart}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PosCustomerOrder;

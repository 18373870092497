import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the Quill CSS

const QuillField = ({ field, form }) => {
  const handleChange = (value) => {
    form.setFieldValue(field.name, value);
  };
  const quillStyle = {
    height: '150px', // Adjust the height to your preference
  };
  return (
    <ReactQuill theme="snow" value={field.value} onChange={handleChange} style={quillStyle} />
  );
};

export default QuillField;

import DatepickerHelper from "../helpers/DatepickerHelper";
import { Card, CardBody, CardExpandToggler } from "../../components/card/card";

import React, { useEffect, useState } from "react";
import moment from "moment";
import { useAllTodayReportGetQuery } from "../../redux/api/reports/reportsApi";
import Loader from "../../components/loader/Loader";
import PayToSupplierTable from "./PayToSupplierTable";
import ExpenseTable from "./ExpenseTable";
import ReceiveFromCustomer from "./ReceiveFromCustomer";
import Currency from "../../Shared/Currency";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const TodayReport = () => {
  const store = useSelector((state) => state.store);
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState();
  const [startDateFieldValue, setStartDateFieldValue] = useState();
  const [endDate, setEndDate] = useState();
  const [endDateFieldValue, setEndDateFieldValue] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [filter, setFilter] = useState({
    limit: pageSize,
  });
  const reportFilter = {
    storeId: store?.storeId,
  };
  const { data, isLoading, refetch } = useAllTodayReportGetQuery(reportFilter,{
    refetchOnMountOrArgChange: true,
  });
  const todayReport = data?.data;

  const meta = data?.meta;

  useEffect(() => {
    const updatedFilter = { ...filter };
    updatedFilter.page = page;

    if (!startDateFieldValue) {
      delete updatedFilter.startDate;
    }
    if (startDate) {
      updatedFilter.startDate = startDate;
    }
    if (!endDateFieldValue) {
      delete updatedFilter.endDate;
    }
    if (endDate) {
      updatedFilter.endDate = endDate;
    }

    setFilter(updatedFilter);

    refetch();
  }, [
    refetch,
    page,
    setStartDate,
    startDateFieldValue,
    endDate,
    endDateFieldValue,
  ]);

  const handleSubmit = () => {
    const formattedStartDate = startDateFieldValue
      ? moment(startDateFieldValue).format("YYYY-MM-DD")
      : "";
    const formattedEndDate = endDateFieldValue
      ? moment(endDateFieldValue).format("YYYY-MM-DD")
      : "";

    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  };

  return (
    <div>
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <h1 className="page-header">{t("Today Report")}</h1>
            <hr className="mb-2" />

            <div className="row py-3">
              <div className="col-xl-3 col-lg-3">
                <Card className="mb-3">
                  <CardBody>
                    <div className="d-flex fw-bold small mb-3">
                      <span className="flex-grow-1">
                        {t("sale")} {t("Amount")}
                      </span>
                      <CardExpandToggler />
                    </div>
                    <div className="row align-items-center mb-2">
                      <div>
                        <h3 className="mb-0">
                          {Currency} {todayReport?.salesAmount?.toFixed(2)}
                        </h3>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="col-xl-3 col-lg-3">
                <Card className="mb-3">
                  <CardBody>
                    <div className="d-flex fw-bold small mb-3">
                      <span className="flex-grow-1">
                        {t("purchases")} {t("Cost")}
                      </span>
                      <CardExpandToggler />
                    </div>
                    <div className="row align-items-center mb-2">
                      <div>
                        <h3 className="mb-0">
                          {Currency} {todayReport?.purchaseAmount?.toFixed(2)}
                        </h3>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="col-xl-3 col-lg-3">
                <Card className="mb-3">
                  <CardBody>
                    <div className="d-flex fw-bold small mb-3">
                      <span className="flex-grow-1">{t("Expense")}</span>
                      <CardExpandToggler />
                    </div>
                    <div className="row align-items-center mb-2">
                      <div>
                        <h3 className="mb-0">
                          {" "}
                          {Currency} {todayReport?.expenseAmount?.toFixed(2)}
                        </h3>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="col-xl-3 col-lg-3">
                <Card className="mb-3">
                  <CardBody>
                    <div className="d-flex fw-bold small mb-3">
                      <span className="flex-grow-1">
                        {"sale"} {t("Profit")}
                      </span>
                      <CardExpandToggler />
                    </div>
                    <div className="row align-items-center mb-2">
                      <div>
                        <h3 className="mb-0">
                          {" "}
                          {Currency} {todayReport?.profitAmount?.toFixed(2)}
                        </h3>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>

            <div className="row py-3">
              <div className="col-xl-6">
                <Card className="mb-3">
                  <CardBody>
                    <div className="d-flex fw-bold small mb-3">
                      <span className="flex-grow-1">
                        {t("TOP SALE PRODUCT")}
                      </span>
                      <CardExpandToggler />
                    </div>
                    <div>{/* <TopSaleProduct /> */}</div>
                  </CardBody>
                </Card>
              </div>

              <div className="col-xl-6">
                <Card className="mb-3">
                  <CardBody>
                    <div className="d-flex fw-bold small mb-3">
                      <span className="flex-grow-1 text-uppercase">
                        {t("Expense")}
                      </span>
                      <CardExpandToggler />
                    </div>
                    <ExpenseTable />
                  </CardBody>
                </Card>
              </div>

              <div className="col-xl-6">
                <Card className="mb-3">
                  <CardBody>
                    <div className="d-flex fw-bold small mb-3">
                      <span className="flex-grow-1 text-uppercase">
                        {t("Pay To Supplier")}
                      </span>
                      <CardExpandToggler />
                    </div>
                    <div className="table-responsive">
                      <PayToSupplierTable />
                    </div>
                  </CardBody>
                </Card>
              </div>

              <div className="col-xl-6">
                <Card className="mb-3">
                  <CardBody>
                    <div className="d-flex fw-bold small mb-3">
                      <span className="flex-grow-1 text-uppercase">
                        {t("Receive From Customer")}
                      </span>
                      <CardExpandToggler />
                    </div>
                    <div className="table-responsive">
                      <ReceiveFromCustomer />
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TodayReport;

import React, { useEffect, useState } from "react";

import OwnersList from "./OwnersList";
import OwnerAddModal from "./OwnerAddModal";
import { useAllOwnersQuery } from "../../redux/api/owners/ownersApi";
import Pagination from "../../utils/Pagination";
import Loader from "../../components/loader/Loader";
import { useTranslation } from "react-i18next";

const Owners = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState({
    limit: pageSize,
  });
  const { data, isLoading } = useAllOwnersQuery(filter);

  const owners = data?.data;
  const meta = data?.meta;
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleFilterChange = (event) => {
    console.log(event.target.value, "empty");
    setSearchTerm(event.target.value);
  };
  useEffect(() => {
    const updatedFilter = { ...filter };
    updatedFilter.page = page;

    if (searchTerm) {
      updatedFilter.searchTerm = searchTerm;
    }
    if (!searchTerm) {
      delete updatedFilter.searchTerm;
    }

    setFilter(updatedFilter);
  }, [searchTerm, page]);

  if (isLoading) {
    return <Loader />;
  }
 
  return (
    <div>
      <header className="header text-white">
        <div className="header-info">
          <h1 className="page-header">{t("Owner")}</h1>
          <hr className="mb-4" />
        </div>
      </header>

      <div className="d-flex gap-5">
        <div className="input-group mb-4">
          <div className=" position-relative">
            <div className="input-group">
              <input
                type="text"
                value={searchTerm}
                onChange={handleFilterChange}
                className="form-control px-35px"
                placeholder="Filter Owners"
              />
              <div
                className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
                style={{ zIndex: 1020 }}
              >
                <i className="fa fa-search opacity-5"></i>
              </div>
            </div>
          </div>
        </div>

        <button
          style={{ height: "40px",width:"200px" }}
          className="btn btn-outline-theme"
          onClick={openModal}
        >
          <i className="fa fa-plus-circle me-1"></i>{t('AddOwner')}
        </button>
      </div>
      <div className="tab-content p-4">
        {isModalOpen && (
          <OwnerAddModal isModalOpen={isModalOpen} closeModal={closeModal} />
        )}

        <div className="tab-pane fade show active">
          <OwnersList owners={owners} meta={meta} />
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          meta={meta}
          pageSize={pageSize}
        />
      </div>
    </div>
  );
};

export default Owners;

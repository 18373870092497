import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SupplierModal from "../supplier/SupplierModal";
import PurchaseModal from "./PurchaseModal";
import { useAllSupplierGetQuery } from "../../redux/api/supplier/supplierApi";
import { useAllProductGetQuery } from "../../redux/api/product/productApi";
import Loader from "../../components/loader/Loader";
import DatepickerHelper from "../helpers/DatepickerHelper";
import { useAllUnitGetQuery } from "../../redux/api/units/unitsApi";
import ReactSelect from "../helpers/ReactSelect";
import toast from "react-hot-toast";
import AddProductModal from "../products/AddProductModal";
import { useTranslation } from "react-i18next";
import Currency from "../../Shared/Currency";
import { useSelector } from "react-redux";
import moment from "moment";

function AddPurchases() {
  const store = useSelector((state) => state.store);
  const filter = {
    storeId: store.storeId,
  };
  const [grandTotal, setGrandTotal] = useState(0);

  const [supplierId, setSupplierId] = useState("");
  const [purchaseDate, setPurchaseDate] = useState(new Date());
  const [expiryDate, setExpiryDate] = useState();
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [payable, setPayable] = useState(0);
  const [due, setDue] = useState(0);
  const [payAmount, setPayAmount] = useState(0);

  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddProductOpen, setIsAddProductOpen] = useState(false);
  const [searchedProduct, setSearchedProduct] = useState("");
  const [matchingProducts, setMatchingProducts] = useState([]);
  const { t } = useTranslation();
  const calculateTotal = (newProducts) => {
    let newTotal = 0;
    if (Array.isArray(newProducts)) {
      newProducts.forEach((product) => {
        const totalCost = product?.subUnitsId
          ? product.purchaseCost *
            (product.qty /
              parseInt(
                unitsData?.data?.find((u) => u.id === product?.unitId)
                  ?.relatedByValue
              ))
          : product.purchaseCost * product.qty;
        newTotal += totalCost;
      });
    } else {
      newTotal += newProducts?.total;
    }
    setTotal(newTotal.toFixed(3));
    setGrandTotal(newTotal.toFixed(3));
  };

  const { data: productData, isLoading: productLoading } =
    useAllProductGetQuery(filter, {
      refetchOnMountOrArgChange: true,
    });
  const { data: unitsData } = useAllUnitGetQuery(filter, {
    refetchOnMountOrArgChange: true,
  });
  const initialData = productData?.data;

  const addProduct = (selectedProduct) => {
    const newProduct = {
      name: selectedProduct.productName,
      id: selectedProduct.id,
      productCode: selectedProduct.productCode,
      categoryId: parseInt(selectedProduct.categoryId),
      unitId: parseInt(selectedProduct.unitId),
      subUnitsId: parseInt(selectedProduct.subUnitsId),
      iamge: selectedProduct.image,
      purchaseCost: parseInt(selectedProduct.purchaseCost),
      salePrice: parseInt(selectedProduct.salePrice),
      qty: 0,
      mainqty: 0,
      subqty: 0,
      total: 0,
      expireDate: null,
    };
    setProducts((prevProducts) => {
      const newProducts = [...prevProducts, newProduct];
      setSearchedProduct("");
      setMatchingProducts([]);
      calculateTotal(newProducts);
      return newProducts;
    });
  };

  const handlePayment = () => {
    if (!supplierId) {
      toast.error("Please select a supplier");
    } else {
      setPayable(total);
      setDue(total - payAmount);
      setPaymentModalVisible(true);
    }
  };

  const handleClosePaymentModal = () => {
    setPaymentModalVisible(false);
  };

  const removeProduct = (index) => {
    setProducts((prevProducts) => {
      const updatedProducts = prevProducts.filter((_, i) => i !== index);
      calculateTotal(updatedProducts);
      return updatedProducts;
    });
  };

  const { data, isLoading } = useAllSupplierGetQuery(filter, {
    refetchOnMountOrArgChange: true,
  });

  if (isLoading || productLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (!data) {
    return <div>No data available</div>;
  }

  const options = data?.data?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const openModal = (e) => {
    setIsModalOpen(true);
  };
  const handleAddProductModal = () => {
    setIsAddProductOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleProductSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchedProduct(searchTerm);

    const filteredProducts = initialData?.filter((product) =>
      product?.productName.toLowerCase().includes(searchTerm)
    );
    setMatchingProducts(filteredProducts);
  };

  const handleProductSelection = (selectedProduct) => {
    addProduct(selectedProduct);
  };

  const productRows = products?.map((product, index) => (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>{product.name}</td>
      <td>
        <DatepickerHelper
          handleDateChange={(date) => {
            const updatedProduct = {
              ...product,
              expireDate: date,
            };

            const updatedProducts = [...products];
            updatedProducts[index] = updatedProduct;
            setProducts(updatedProducts);
            calculateTotal(updatedProduct);
          }}
          selectedDate={product?.expireDate}
          placeHolder="Select expiry date"
        />
      </td>
      <td>
        <input
          type="number"
          value={product.purchaseCost}
          onChange={(e) => {
            const updatedProduct = {
              ...product,
              purchaseCost: parseInt(e.target.value),
            };

            const updatedProducts = [...products];
            updatedProducts[index] = updatedProduct;
            setProducts(updatedProducts);
            calculateTotal(updatedProduct);
          }}
        />
      </td>
      <td>
        <input
          type="number"
          value={product.salePrice}
          onChange={(e) => {
            const updatedProduct = {
              ...product,
              salePrice: parseInt(e.target.value),
            };
            const updatedProducts = [...products];
            updatedProducts[index] = updatedProduct;
            setProducts(updatedProducts);
          }}
        />
      </td>
      <td>
        <div>
          <input
            type="number"
            placeholder={
              unitsData?.data?.find((u) => u.id === product.unitId)?.name
            }
            onChange={(e) => {
              const qtyInSub = product?.subUnitsId
                ? parseInt(e.target.value) *
                  parseInt(
                    unitsData?.data?.find((u) => u.id === product?.unitId)
                      ?.relatedByValue
                  )
                : parseInt(e.target.value);
              const updatedProduct = {
                ...product,
                mainqty: qtyInSub,
              };

              setProducts((prevProducts) => {
                const updatedProducts = [...prevProducts];
                updatedProducts[index] = updatedProduct;
                calculateTotal(updatedProducts);
                return updatedProducts;
              });
            }}
            onBlur={(e) => {
              const totalCost = product?.subUnitsId
                ? product.purchaseCost *
                  (product.qty /
                    parseInt(
                      unitsData?.data?.find((u) => u.id === product?.unitId)
                        .relatedByValue
                    ))
                : product.purchaseCost * product.qty;

              const updatedProduct = {
                ...product,
                qty: product?.mainqty + product?.subqty,
                total: totalCost,
              };

              setProducts((prevProducts) => {
                const updatedProducts = [...prevProducts];
                updatedProducts[index] = updatedProduct;
                calculateTotal(updatedProducts);
                return updatedProducts;
              });
            }}
          />
        </div>
        {product?.subUnitsId ? (
          <div className="mt-2">
            <input
              type="number"
              placeholder={
                unitsData?.data?.find((u) => u.id === product.subUnitsId)?.name
              }
              onChange={(e) => {
                const updatedProduct = {
                  ...product,
                  subqty: parseInt(e.target.value),
                };

                setProducts((prevProducts) => {
                  const updatedProducts = [...prevProducts];
                  updatedProducts[index] = updatedProduct;
                  calculateTotal(updatedProducts);
                  return updatedProducts;
                });
              }}
              onBlur={(e) => {
                const totalCost = product?.subUnitsId
                  ? product.purchaseCost *
                    (product.qty /
                      parseInt(
                        unitsData?.data?.find((u) => u.id === product?.unitId)
                          .relatedByValue
                      ))
                  : product.purchaseCost * product.qty;

                const updatedProduct = {
                  ...product,
                  qty: product?.mainqty + product?.subqty,
                  total: totalCost,
                };

                setProducts((prevProducts) => {
                  const updatedProducts = [...prevProducts];
                  updatedProducts[index] = updatedProduct;
                  calculateTotal(updatedProducts);
                  return updatedProducts;
                });
              }}
            />
          </div>
        ) : (
          ""
        )}
      </td>
      <td>
        {product?.subUnitsId
          ? (
              product.purchaseCost *
              (product.qty /
                parseInt(
                  unitsData?.data?.find((u) => u.id === product?.unitId)
                    .relatedByValue
                ))
            ).toFixed(3)
          : (product.purchaseCost * product.qty).toFixed(3)}
      </td>
      <td>
        <button onClick={() => removeProduct(index)}>
          <i className="fa fa-trash"></i>
        </button>
      </td>
    </tr>
  ));
  const handleChange = (values) => {
    setSupplierId(parseInt(values.value));
  };
  return (
    <div className="main-content">
      <header className="header text-white">
        <div className="header-info">
          <h1 className="page-header">
            {t("purchases")} {t("List")}
          </h1>
          <hr className="mb-2" />
        </div>
      </header>

      <div className="row">
        <div className="col-12">
          <div>
            <div>
              <div className="d-flex gap-3 my-2 items-center">
                <div className=" col-md-6">
                  {/* <label htmlFor="supplier">Supplier</label> */}
                  <ReactSelect
                    placeHolder="Select a supplier"
                    options={options}
                    handleChange={handleChange}
                    placeholder="Filter Damage"
                  />
                </div>
                <div className="col-md-2">
                  <button
                    style={{ width: "210px", height: "35px" }}
                    className="btn btn-outline-theme mb-3"
                    onClick={openModal}
                  >
                    <i className="fa fa-plus-circle me-1"></i>
                    {t("Add Supplier")}
                  </button>
                </div>
                <div
                  style={{
                    marginTop: "-16px",
                  }}
                  className="col-md-3"
                >
                  <label htmlFor="">Purchase Date</label>
                  <DatepickerHelper
                    handleDateChange={(date) => setPurchaseDate(date)}
                    selectedDate={purchaseDate}
                    placeHolder="Select purchase date"
                  />
                </div>
              </div>
              <div className="gap-3 d-flex">
                <div className="col-md-6">
                  {/* <label htmlFor="Product">Product</label> */}
                  <input
                    type="text"
                    id="product_search"
                    value={searchedProduct}
                    onChange={handleProductSearch}
                    className="form-control ui-autocomplete-input"
                    placeholder="Select product..."
                    autoComplete="off"
                  />
                  {matchingProducts.length > 0 && (
                    <ul className="list-group mt-2">
                      {matchingProducts?.map((product) => (
                        <li
                          key={product.id}
                          className="list-group-item"
                          onClick={() => handleProductSelection(product)}
                        >
                          {product?.productName}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="col-md-2">
                  <button
                    style={{ width: "210px", height: "35px" }}
                    className="btn btn-outline-theme mb-3"
                    onClick={handleAddProductModal}
                  >
                    <i className="fa fa-plus-circle me-1"></i>
                    {t("Add Product")}
                  </button>
                </div>
                {/* <div
                  style={{
                    marginTop: "-16px",
                  }}
                  className="col-md-3 "
                >
                  <label htmlFor="">Expiry Date</label>
                 
                </div> */}
              </div>

              <div className="row mt-2">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>{t("Serial")}</th>
                      <th>{t("product")}</th>
                      <th>Expiry Date</th>
                      <th>
                        {t("purchases")} {t("Cost")}
                      </th>
                      <th>
                        {t("sale")} {t("Price")}
                      </th>
                      <th>{t("Quantity")}</th>
                      <th>{t("Subtotal")}</th>
                      <th>
                        <i className="fa fa-trash"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{productRows}</tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="3"></td>

                      <td colSpan="2">
                        <strong>
                          {t("Grand Total")}: {grandTotal} {Currency}
                        </strong>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div className="form-row">
                <button
                  type="button"
                  onClick={handlePayment}
                  className="btn btn-primary"
                >
                  <i className="bi bi-cash-coin"></i> Payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <SupplierModal isModalOpen={isModalOpen} closeModal={closeModal} />
      )}
      {isAddProductOpen && (
        <AddProductModal
          show={isAddProductOpen}
          hide={() => setIsAddProductOpen(false)}
        />
      )}
      {paymentModalVisible && (
        <PurchaseModal
          paymentModalVisible={paymentModalVisible}
          grandTotal={grandTotal}
          supplierId={supplierId}
          products={products}
          purchaseDate={purchaseDate}
          handleClosePaymentModal={handleClosePaymentModal}
          expireDate={expiryDate}
        />
      )}
    </div>
  );
}

export default AddPurchases;

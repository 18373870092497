/* eslint-disable no-sequences */
import {baseApi} from "../baseApi";

export const transactionTypeApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    allTransactionTypeApiGet: build.query({
      query: (params) => ({
        url: "/transactionType",
        method: "GET",
      }),
      providesTags: ["transactionType"],
    }),
  }),
});

export const {useAllTransactionTypeApiGetQuery} = transactionTypeApi;

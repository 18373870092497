import React, { useEffect, useState } from "react";

import ExpenseCategoryList from "./ExpenseCategoryList";

import { Link } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import { useAllExpenseCategoryGetQuery } from "../../redux/api/expenseCategory/expenseCategory";
import ExpenseCategoryAddModal from "./ExpenseCategoryAddModal copy";
import Pagination from "../../utils/Pagination";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const ExpenseCategory = () => {
  const store = useSelector((state) => state.store);
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [name, setName] = useState();
  const [nameFieldValue, setNameFieldValue] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filter, setFilter] = useState({
    page,
    limit: pageSize,
    storeId: store.storeId,
  });
  const initialValues = {
    name: "",
  };

  const { data, isLoading, refetch } = useAllExpenseCategoryGetQuery(filter, {
    refetchOnMountOrArgChange: true,
  });
  const initialData = data?.data;
  const meta = data?.meta;

  useEffect(() => {
    const updatedFilter = { ...filter };
    updatedFilter.page = page;
    if (name) {
      updatedFilter.name = name;
    }
    if (searchTerm) {
      updatedFilter.searchTerm = searchTerm;
    }
    if (!searchTerm) {
      delete updatedFilter.searchTerm;
    }
    if (!nameFieldValue) {
      delete updatedFilter.name;
    }
    setFilter(updatedFilter);
    refetch();
  }, [name, refetch, searchTerm, page]);

  const handleFilterChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const openModal = (id) => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="col-12">
      <header className="header text-white">
        <div className="header-info">
          <h1 className="page-header">{t("Expense Category")}</h1>
          <hr className="mb-3" />
        </div>
      </header>

      <div className="input-group mb-2 d-flex justify-content-between">
        <div className=" position-relative">
          <div className="input-group">
            <input
              type="text"
              className="form-control px-35px"
              placeholder="Filter Expense Category"
              value={searchTerm}
              onChange={handleFilterChange}
            />
            <div
              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
              style={{ zIndex: 1020 }}
            >
              <i className="fa fa-search opacity-5"></i>
            </div>
          </div>
        </div>
        <button className="btn btn-outline-theme" onClick={openModal}>
          <i className="fa fa-plus-circle me-1"></i>
          {t("Add Expense Category")}
        </button>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <ExpenseCategoryList data={initialData} refetch={refetch} />
      )}

      <Pagination
        page={page}
        setPage={setPage}
        meta={meta}
        pageSize={pageSize}
      />

      {isModalOpen && (
        <ExpenseCategoryAddModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default ExpenseCategory;

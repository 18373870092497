import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import DatepickerHelper from "../helpers/DatepickerHelper";
import ReactSelect from "../helpers/ReactSelect";
import { useAllTransactionTypeApiGetQuery } from "../../redux/api/transactionType/transactionTypeApi";
import { useExpenseCreateMutation } from "../../redux/api/expense/expenseApi";
import toast from "react-hot-toast";
import { useAllExpenseCategoryGetQuery } from "../../redux/api/expenseCategory/expenseCategory";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
const ExpenseModal = ({ closeModal, isModalOpen }) => {
  const store = useSelector((state) => state.store);
  const filter ={
  
    storeId: store.storeId,
  }
  const [isAdding, setIsAdding] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [selectExpenceCategory, setSelectExpenceCategory] = useState(null);

  const [selecteTransection, setselecteTransection] = useState(null);
  const { data: transactionTypeIdData } = useAllTransactionTypeApiGetQuery();

  const { data: expenseCategoryIdData } = useAllExpenseCategoryGetQuery(filter,{
    refetchOnMountOrArgChange:true,
  });
  const initialTransactionTypeIdData = transactionTypeIdData?.data;

  const initialExpenseCategoryIdData = expenseCategoryIdData?.data;

  const transactionTypeIdOptions = initialTransactionTypeIdData?.map(
    (item) => ({
      value: item?.id,
      label: item?.name,
    })
  );
  const expenseCategoryIdOptions = initialExpenseCategoryIdData?.map(
    (item) => ({
      value: item?.id,
      label: item?.name,
    })
  );

  const handleStartDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleExpenceCategotyChange = (values) => {
    setSelectExpenceCategory(values.value);
  };
  const handleTransectionChange = (values) => {
    setselecteTransection(values.value);
  };

  const initialValues = {
    expenseName: "",
    amount: "",
    expenseDate: "",
    expenseCategoryId: "",
    transactionTypeId: "",
    expenseNote: "",
  };
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({});
  const [expenseCreate] = useExpenseCreateMutation();
  const handleSubmit = async (values, { resetForm }) => {
    setIsAdding(true);
    const combainData = {
      ...values,
      expenseDate: selectedDate,
      expenseCategoryId: selectExpenceCategory,
      transactionTypeId: selecteTransection,
      storeId: store.storeId,
    };
    combainData.amount = parseInt(combainData.amount);
    const res = await expenseCreate(combainData);

    if (res?.data) {
      toast.success("Expense Create Successful");
      resetForm();
    }
    setIsAdding(false);
    closeModal();
  };

  return (
    <Modal show={isModalOpen} onHide={closeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title> {t("Add Expense")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <div className="form-group col-lg-12 px-2 my-2">
                <label htmlFor="expenseName">
                  {t("Name")}*<span className="field_required"></span>
                </label>
                <Field
                  type="text"
                  name="expenseName"
                  className="form-control"
                  placeholder="Enter Expense Name"
                  id="expenseName"
                />
              </div>
              <div className="d-flex my-2">
                <div className="form-group col-lg-6 px-2">
                  <label htmlFor="amount">
                    {t("Amount")}*<span className="field_required"></span>
                  </label>
                  <Field
                    type="text"
                    name="amount"
                    className="form-control"
                    placeholder="Enter Expense Amount"
                    id="amount"
                  />
                </div>
                <div className="form-group col-lg-6 px-2 ">
                  <label htmlFor="expenseDate">
                    {t("Date")}*<span className="field_required"></span>
                  </label>
                  <DatepickerHelper
                    handleDateChange={handleStartDateChange}
                    selectedDate={selectedDate}
                    placeHolder="Select start date"
                  />
                </div>
              </div>
              <div className="d-flex my-2 ">
                {" "}
                <div className="form-group col-lg-6 px-2">
                  <label htmlFor="expenseCategoryId">
                    {t("Category")}*<span className="field_required"></span>
                  </label>
                  <ReactSelect
                    placeHolder="Select Category"
                    options={expenseCategoryIdOptions}
                    handleChange={handleExpenceCategotyChange}
                  />
                </div>
                <div className="form-group col-lg-6 px-2">
                  <label htmlFor="transactionTypeId">
                    {t("Transaction Type")}
                    <span className="field_required"></span>
                  </label>
                  <ReactSelect
                    placeHolder="Select"
                    options={transactionTypeIdOptions}
                    handleChange={handleTransectionChange}
                  />
                </div>
              </div>

              <div className="form-group col-lg-12 px-2 my-2">
                <label htmlFor="expenseNote">{t("Note")}</label>
                <Field
                  as="textarea"
                  name="expenseNote"
                  className="form-control"
                  placeholder="Write Note. (optional)"
                />
              </div>

              <div className="modal-footer">
                <button
                  className="btn btn-outline-default"
                  onClick={() => closeModal()}
                  disabled={isAdding}
                >
                  {t("Cancel")}
                </button>
                <button
                  type="submit"
                  className="btn btn-outline-theme"
                  disabled={isAdding}
                >
                  {isAdding ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    t("Save")
                  )}
                  <i className="fa fa-save px-2"></i>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
    // <div
    //   className={`modal fade mt-5 py-5  ${isModalOpen ? "show" : ""}`}
    //   tabIndex="-1"
    //   role="dialog"
    //   style={{display: isModalOpen ? "block" : "none"}}>
    //   <div style={{display: "block"}} className="modal-dialog modal-lg ">
    //     <div className="modal-content">
    //       <div className="modal-header">
    //         <h5 className="modal-title"></h5>
    //         <button
    //           type="button"
    //           onClick={() => closeModal()}
    //           className="btn-close"
    //           data-bs-dismiss="modal"></button>
    //       </div>

    //       <div className="">

    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default ExpenseModal;

import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import Modal from "react-bootstrap/Modal";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  useSelfCreateMutation,
  useUpdateSelfMutation,
} from "../../redux/api/self/selfApi";

const AddSelfModal = ({ isModalOpen, closeModal, mode, self }) => {
  const store = useSelector((state) => state.store);
  const [isAddingUnit, setIsAddingUnit] = useState(false);
  const [selfCreate] = useSelfCreateMutation();
  const [updateSelf] = useUpdateSelfMutation();
  const { t } = useTranslation();
  const initialValues = {
    name: mode === "edit" ? self.name : "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required("Self Name is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      //   setIsAddingUnit(true);
      values.storeId = parseInt(store?.storeId);
      if (mode === "edit") {
        const res = await updateSelf({ id: self?.id, values });
        if (res?.data) {
          toast.success("Self Updated Successfully");
          resetForm(initialValues);
        }
      } else {
        const res = await selfCreate(values);

        if (res?.data) {
          toast.success("Self Created Successfully");
          resetForm(initialValues);
        }
      }

      setIsAddingUnit(false);
      closeModal();
    },
  });

  //   if (isLoading) {
  //     return (
  //       <div>
  //         <Loader />
  //       </div>
  //     );
  //   }

  //   if (!data) {
  //     return <div>{t("No data available")}</div>;
  //   }

  //   const relatedToUnit = initialData?.map((item) => ({
  //     value: item?.id,
  //     label: item?.name,
  //   }));

  //   const getRelatedToUnit = (value) => {
  //     const data = relatedToUnit.find((unit) => unit.value === value);
  //     return data?.label;
  //   };

  return (
    <Modal show={isModalOpen} onHide={closeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>{mode === "add" ? t("Add Shelf") : "Update"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="card-body pb-3 px-2">
            <div className="form-row">
              <div className="d-flex justify-content-center gap-2">
                <div className="form-group col-md-12">
                  <label style={{ paddingBottom: "5px" }} htmlFor="name">
                    {t("Name")}
                  </label>
                  <input
                    type="text"
                    className="form-control py-2"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="text-danger">{formik.errors.name}</div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-outline-default"
              onClick={() => closeModal()}
              //   disabled={isAddingUnit}
            >
              {t("Cancel")}
            </button>
            <button
              type="submit"
              className="btn btn-outline-theme"
              //   disabled={isAddingUnit}
            >
              {isAddingUnit ? (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : mode === "add" ? (
                t("Add")
              ) : (
                "Update"
              )}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddSelfModal;

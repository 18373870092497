import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../helpers/axios/axiosBaseQuery";

export const baseApi = createApi({
  reducerPath: "api",
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: () => ({}),
  tagTypes: [
    "user",
    "customer",
    "supplier",
    "sr",
    "category",
    "brand",
    "units",
    "expenseCategory",
    "products",
    "expense",
    "transactionType",
    "purchase",
    "sms",
    "damage",
    "report",
    "sales",
    "paymentType",
    "accountType",
    "payments",
    "returns",
    "auth",
    "currency",
    "dashboard",
    "store",
    "self"
  ],
});

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  useCurrencyCreateMutation,
} from "../../../redux/api/currency/currencyApi";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const AddCurrencyModal = ({ closeModal, isModalOpen }) => {
  const { t } = useTranslation();
  const [label, setLabel] = useState("");
  const [value, setValue] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [currencyCreate] = useCurrencyCreateMutation();

  const handleLabelChange = (event) => {
    setLabel(event.target.value);
  };

  const handleValueChange = (event) => {
    setValue(event.target.value);
  };

  const handleSaveChanges = async () => {
    setIsAdding(true);
    const res = await currencyCreate({
      label: label,
      value: value,
    });
    if (res?.data?.success) {
      toast.success(res?.data?.message);
    }
    console.log(res?.data?.message, "res");
    setIsAdding(false);
    closeModal();
  };
  return (
    <Modal show={isModalOpen} onHide={closeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("Add new currency")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <label className="form-label">{t("label")}</label>
          <div className="row row-space-10">
            <div className="col-12">
              <input
                className="form-control"
                value={label}
                onChange={handleLabelChange}
                placeholder={t("label")}
              />
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label">{t("value")}</label>
          <div className="row row-space-10">
            <div className="col-12">
              <input
                className="form-control"
                value={value}
                onChange={handleValueChange}
                placeholder={t("value")}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          {t("Cancel")}
        </Button>
        <button
          type="button"
          className="btn btn-outline-theme"
          disabled={isAdding}
          onClick={handleSaveChanges}
        >
          {isAdding ? (
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            t("Save")
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCurrencyModal;



/* eslint-disable no-sequences */
import {baseApi} from "../baseApi";

export const purchaseApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    purchaseCreate: build.mutation({
      query: (purchaseData) => ({
        url: "/purchase",
        method: "POST",
        data: purchaseData,
      }),
      invalidatesTags: ["purchase"],
    }),

    userProfileGet: build.query({
      query: (params) => ({
        url: "/user/profile",
        method: "GET",
        params,
      }),
      providesTags: ["profile"],
    }),

    updateProfile: build.mutation({
      query: ({id, values}) => ({
        url: `/user/${id}`,
        method: "PATCH",
        data: values,
      }),
      invalidatesTags: ["profile"],
    }),
    updatePassword: build.mutation({
      query: (values) => ({
        url: `/user/change-password`,
        method: "PATCH",
        data: values
      }),
      invalidatesTags: ["profile"],
    }),
  }),
});

export const {
 useUserProfileGetQuery,
 useUpdateProfileMutation,
 useUpdatePasswordMutation
} = purchaseApi;

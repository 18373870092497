import React, { useState } from "react";
import toast from "react-hot-toast";

import { Link } from "react-router-dom";
import { useDeleteCustomerMutation } from "../../redux/api/customer/customerApi";
import EditModal from "./EditModal";
import DeleteConfirmationModal from "../deleteConfirmationModal/DeleteConfirmationModal";
import Currency from "../../Shared/Currency";
import AddCustomerWalletBalanceModal from "./AddCustomerWalletBalanceModal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const CustomerList = ({ data }) => {
  const store = useSelector((state) => state.store);
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddWalletBalanceModalOpen, setIsAddWalletBalanceModalOpen] =
    useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [deleteSr] = useDeleteCustomerMutation({});

  const handleDelete = async (id) => {
    setIsAddingLoading(true);
    const res = await deleteSr({ id, storeId: store.storeId });
    if (res?.data?.success === true) {
      toast.success(res?.data?.message);
    }
    setIsAddingLoading(false);
    setIsDeleteConfirmationModalOpen(false);
  };

  const openModal = (id) => {
    setIsModalOpen(true);
    setSelectedItemId(id);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openDeleteConfirmationModal = (id) => {
    setIsDeleteConfirmationModalOpen(true);
    setSelectedItemId(id);
  };

  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(false);
  };
  return (
    <div className="">
      <div className="table-responsive my-3">
        <table className="table table-hover text-nowrap">
          <thead>
            <tr>
              <th className="border-top-0 pt-0 pb-2">{t("Serial")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Name")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Email")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Phone")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Address")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("SR")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("receiveable")}</th>
              <th className="border-top-0 pt-0 pb-2"> {t("Paid")} </th>
              {/* <th className="border-top-0 pt-0 pb-2">Sale Due </th> */}
              <th className="border-top-0 pt-0 pb-2">{t("Wallet Balance")} </th>
              <th className="border-top-0 pt-0 pb-2">
                {t("total")} {t("Due")}{" "}
              </th>
              <th className="border-top-0 pt-0 pb-2">{t("Actions")} </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td className="align-middle">
                  <Link>{index + 1}</Link>
                </td>
                <td className="align-middle">{item?.name}</td>
                <td className="align-middle">{item?.email}</td>
                <td className="align-middle">
                  <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                    {item?.mobile}
                  </span>
                </td>

                <td className="align-middle">{item?.address}</td>
                <td className="align-middle">{item?.sr?.name}</td>
                <td className="align-middle">
                  <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                    {Currency} : {item?.receivable?.toFixed(2)}
                  </span>
                </td>
                <td className="align-middle">
                  <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                    {Currency} : {item?.paid?.toFixed(2)}
                  </span>
                </td>
                {/* <td className="align-middle">
                  <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                    TK - {item?.saleDue}
                  </span>
                </td> */}
                <td className="align-middle">
                  <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                    {Currency} : {item?.walletBalance?.toFixed(2)}
                  </span>
                </td>
                <td className="align-middle">
                  <span
                    className={`badge border 
                      ${
                        item?.totalDue > 0
                          ? "border-danger text-danger"
                          : "border-success text-success"
                      } 
                        px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center`}
                  >
                    {Currency} : {item?.totalDue?.toFixed(2)}
                  </span>
                </td>

                <td className="align-middle">
                  <span
                    className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center cursor-pointer "
                    data-bs-toggle="dropdown"
                  >
                    <i className="bi bi-gear fs-9px fa-fw me-5px"></i> E/D
                  </span>

                  <div className="dropdown-menu">
                    <a
                      onClick={() => openModal(item?.id)}
                      className="dropdown-item"
                      href="#/"
                    >
                      <i
                        class="bi bi-pencil-square"
                        style={{ marginRight: "5px" }}
                      ></i>
                      {t("Edit")}
                    </a>
                    {/* <a className="dropdown-item" href="#/">
                      <i
                        class="bi bi-cash-coin"
                        style={{ marginRight: "5px" }}
                      ></i>
                      Wallet Payment
                    </a> */}
                    <button
                      onClick={() => {
                        setSelectedItemId(item?.id);
                        setIsAddWalletBalanceModalOpen(true);
                      }}
                      className="dropdown-item"
                    >
                      <i
                        class="bi bi-cash-coin"
                        style={{ marginRight: "5px" }}
                      ></i>
                      {t("Add Balance")}
                    </button>
                    <Link
                      className="dropdown-item"
                      to={`/customers/sales-list/${item?.id}`}
                    >
                      <i
                        class="bi bi-card-list pr-2"
                        style={{ marginRight: "5px" }}
                      ></i>
                      {t("Sales List")}
                    </Link>
                    <Link
                      to={`/customers/payment-list/${item?.id}`}
                      className="dropdown-item"
                    >
                      <i
                        class="bi bi-cash-coin pr-2"
                        style={{ marginRight: "5px" }}
                      ></i>
                      {t("Payment List")}
                    </Link>
                    <Link
                      to={`/customers/report-list/${item?.id}`}
                      className="dropdown-item"
                    >
                      <i
                        class="bi bi-printer pr-2"
                        style={{ marginRight: "5px" }}
                      ></i>
                      {t("Report")}
                    </Link>
                    <a className="dropdown-item" href="#/">
                      <i
                        class="bi bi-file-earmark-ruled pr-2"
                        style={{ marginRight: "5px" }}
                      ></i>
                      {t("Ledger")}
                    </a>

                    <button
                      onClick={() => openDeleteConfirmationModal(item?.id)}
                      className="dropdown-item"
                    >
                      <i
                        className="bi bi-trash pr-2"
                        style={{ marginRight: "5px" }}
                      ></i>
                      {t("Delete")}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <EditModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          id={selectedItemId}
        />
      )}

      {isDeleteConfirmationModalOpen && (
        <DeleteConfirmationModal
          isAddingLoading={isAddingLoading}
          closeModal={closeDeleteConfirmationModal}
          confirmDelete={() => handleDelete(selectedItemId)}
        />
      )}

      {isAddWalletBalanceModalOpen && (
        <AddCustomerWalletBalanceModal
          isModalOpen={isAddWalletBalanceModalOpen}
          closeModal={() => setIsAddWalletBalanceModalOpen(false)}
          id={selectedItemId}
        />
      )}
    </div>
  );
};

export default CustomerList;

import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import {
  useSingleBrandGetQuery,
  useUpdateBrandMutation,
} from "../../redux/api/brands/brandsApi";
import Loader from "../../components/loader/Loader";
import { singleImageUpload } from "../../hooks/ImageUpload";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const EditModal = ({ closeModal, isModalOpen, id }) => { 
  const store = useSelector((state) => state.store);
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const { data, isLoading } = useSingleBrandGetQuery( { id, params: { storeId: store.storeId } }, {
    refetchOnMountOrArgChange: true,
  });

  const [updateBrand] = useUpdateBrandMutation();
  const [logo, setIogo] = useState();
  const { t } = useTranslation();
  const handleChangeUploadImage = async (event) => {
    const logo = event.target.files[0];

    const formData = new FormData();

    formData.append("image", logo);

    try {
      await singleImageUpload(formData, setIogo);
    } catch (error) {
      alert(error.message);
    }
  };
  const handleSubmit = async (values, { resetForm }) => {
    setIsAddingLoading(true);
    values.logo = logo;
    values.storeId= parseInt(store?.storeId) 
    const res = await updateBrand({ id, values });

    if (res?.data) {
      toast.success(res?.data?.message);
      resetForm();
    }
    setIsAddingLoading(false);
    closeModal();
  };

  const initialValues = {
    name: data?.data?.name,
    logo: data?.data?.logo,
    description: data?.data?.description,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Brand Name is required"),
  });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Modal show={isModalOpen} onHide={closeModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>{t("Update Brand")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {() => (
                <Form>
                  <div className="d-flex justify-content-center gap-2 py-2">
                    {" "}
                    <div className="form-group col-md-6 px-2">
                      <label htmlFor="name">
                       {t("Name")}
                        <span className="field_required">*</span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Enter Brand Name..."
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="form-group col-md-6 form-type-line file-group px-2">
                      <label htmlFor="logo">{t("Image")}</label>
                      <input
                        type="file"
                        name="logo"
                        onChange={handleChangeUploadImage}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="logo"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-12 px-2 py-2">
                    <label htmlFor="description">{t("Description")}</label>
                    <Field
                      as="textarea"
                      name="description"
                      className="form-control"
                      placeholder="Enter Brand Description"
                    />
                  </div>

                  <div className="modal-footer">
                    <button
                      className="btn btn-outline-default"
                      onClick={() => closeModal()}
                      disabled={isAddingLoading}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-outline-theme"
                      disabled={isAddingLoading}
                    >
                      {isAddingLoading ? (
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        t("Update Brand")
                      )}
                      <i className="fa fa-save px-2"></i>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default EditModal;

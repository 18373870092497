import React, { useState } from "react";
import { Formik, Field, Form } from "formik";

import DatepickerHelper from "../helpers/DatepickerHelper";
import ReactSelect from "../helpers/ReactSelect";
import { useAllTransactionTypeApiGetQuery } from "../../redux/api/transactionType/transactionTypeApi";
import {
  useSingleExpenseGetQuery,
  useUpdateExpenseMutation,
} from "../../redux/api/expense/expenseApi";
import toast from "react-hot-toast";
import { useAllExpenseCategoryGetQuery } from "../../redux/api/expenseCategory/expenseCategory";
import Loader from "../../components/loader/Loader";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
const EditExpenseModal = ({ closeModal, isModalOpen, id }) => {
  const store = useSelector((state) => state.store);
  const filter = {
    storeId: store.storeId,
  };
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isAdding, setIsAdding] = useState(false);
  const [selectExpenceCategory, setSelectExpenceCategory] = useState(null);

  const [selecteTransection, setselecteTransection] = useState(null);
  const { data: transactionTypeIdData } = useAllTransactionTypeApiGetQuery();

  const { data: expenseCategoryIdData } = useAllExpenseCategoryGetQuery(
    filter,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const initialTransactionTypeIdData = transactionTypeIdData?.data;

  const initialExpenseCategoryIdData = expenseCategoryIdData?.data;

  const transactionTypeIdOptions = initialTransactionTypeIdData?.map(
    (item) => ({
      value: item?.id,
      label: item?.name,
    })
  );
  const expenseCategoryIdOptions = initialExpenseCategoryIdData?.map(
    (item) => ({
      value: item?.id,
      label: item?.name,
    })
  );

  const handleStartDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleExpenceCategotyChange = (values) => {
    setSelectExpenceCategory(values.value);
  };
  const handleTransectionChange = (values) => {
    setselecteTransection(values.value);
  };

  const { data, isLoading } = useSingleExpenseGetQuery(
    { id, params: { storeId: store.storeId } },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const intialECatOp = expenseCategoryIdOptions?.find(
    (category) => category?.value === data?.data?.expenseCategoryId
  );
  const intialTPatOp = transactionTypeIdOptions?.find(
    (transactionType) =>
      transactionType?.value === data?.data?.transactionTypeId
  );

  const initialValues = {
    expenseName: data?.data?.expenseName,
    amount: data?.data?.amount,
    expenseDate: data?.data?.expenseDate,
    expenseNote: data?.data?.expenseNote,
  };

  const [updateExpense] = useUpdateExpenseMutation();

  const handleSubmit = async (values, { resetForm }) => {
    setIsAdding(true);
    const combainData = {
      ...values,
      expenseDate: selectedDate,
      expenseCategoryId: data?.data?.expenseCategoryId,
      transactionTypeId: data?.data?.transactionTypeId,
      storeId: parseInt(store.storeId),
    };

    combainData.amount = parseInt(combainData.amount);

    const res = await updateExpense({ id, values: combainData });

    if (res?.data) {
      toast.success("Expense Update Successful");
      resetForm();
    }
    setIsAdding(false);
    closeModal();
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Modal show={isModalOpen} onHide={closeModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>{t("Update Expense")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {() => (
                <Form>
                  <div className="form-group col-lg-12 px-2 my-2">
                    <label htmlFor="expenseName">
                      {t("Name")}*<span className="field_required"></span>
                    </label>
                    <Field
                      type="text"
                      name="expenseName"
                      className="form-control"
                      placeholder="Enter Expense Name"
                      id="expenseName"
                    />
                  </div>
                  <div className="d-flex my-2">
                    <div className="form-group col-lg-6 px-2">
                      <label htmlFor="amount">
                        {t("Amount")}*<span className="field_required"></span>
                      </label>
                      <Field
                        type="number"
                        name="amount"
                        className="form-control"
                        placeholder="Enter Expense Amount"
                        id="amount"
                      />
                    </div>
                    <div className="form-group col-lg-6 px-2 ">
                      <label htmlFor="expenseDate">
                        {t("Date")}*<span className="field_required"></span>
                      </label>

                      <Field className="form-control" name="expenseDate">
                        {(props) => {
                          const handleChange = (option) => {
                            props.form.setFieldValue(
                              "expenseDate",
                              option.value
                            );
                          };
                          return (
                            <DatepickerHelper
                              handleDateChange={handleChange}
                              selectedDate={selectedDate}
                              placeHolder="Select start date"
                            />
                          );
                        }}
                      </Field>
                    </div>
                  </div>
                  <div className="d-flex my-2 ">
                    {" "}
                    <div className="form-group col-lg-6 px-2">
                      <label htmlFor="expenseCategoryId">
                        {t("Category")}*<span className="field_required"></span>
                      </label>

                      <Field className="form-control" name="expenseCategoryId">
                        {(props) => {
                          const handleChange = (option) => {
                            props.form.setFieldValue(
                              "expenseCategoryId",
                              option.value
                            );
                          };
                          return (
                            <ReactSelect
                              placeHolder="Select Category"
                              selectedOption={intialECatOp}
                              options={expenseCategoryIdOptions}
                              handleChange={handleChange}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-group col-lg-6 px-2">
                      <label htmlFor="transactionTypeId">
                        {t("Transaction Type")}
                        <span className="field_required"></span>
                      </label>

                      <Field className="form-control" name="transactionTypeId">
                        {(props) => {
                          const handleChange = (option) => {
                            props.form.setFieldValue(
                              "transactionTypeId",
                              option.value
                            );
                          };
                          return (
                            <ReactSelect
                              placeHolder="Select"
                              options={transactionTypeIdOptions}
                              selectedOption={intialTPatOp}
                              handleChange={handleChange}
                            />
                          );
                        }}
                      </Field>
                    </div>
                  </div>

                  <div className="form-group col-lg-12 px-2 my-2">
                    <label htmlFor="expenseNote">{t("Note")}</label>
                    <Field
                      as="textarea"
                      name="expenseNote"
                      className="form-control"
                      placeholder="Write Note. (optional)"
                    />
                  </div>

                  <div className="modal-footer">
                    <button
                      className="btn btn-outline-default"
                      onClick={() => closeModal()}
                      disabled={isAdding}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-outline-theme"
                      disabled={isAdding}
                    >
                      {isAdding ? (
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        t("Update")
                      )}
                      <i className="fa fa-save px-2"></i>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default EditExpenseModal;

import React, {useEffect, useState} from "react";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";

import toast from "react-hot-toast";
import Loader from "../../components/loader/Loader";
import {
  useSingleDamageGetQuery,
  useUpdateDamageMutation,
} from "../../redux/api/damages/damagesApi";
import DatepickerHelper from "../helpers/DatepickerHelper";
const EditModal = ({isModalOpen, closeModal, id}) => {
  const [selectedDate, setSelectedDate] = useState();
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const {data, isLoading} = useSingleDamageGetQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const initialData = data?.data;

  useEffect(() => {
    if (initialData?.damageDate) {
      setSelectedDate(new Date(initialData?.damageDate));
    }
  }, [initialData]);
  const [updateDamage] = useUpdateDamageMutation();

  const handleSubmit = async (values, {setSubmitting}) => {
    setIsAddingLoading(true);
    let quantity;
    if (values?.unit && values?.subunit) {
      quantity =
        values.unit * parseInt(initialData?.Products?.unit.relatedByValue) +
        parseInt(values?.subunit);
    } else if (values?.unit && initialData?.Products?.unit?.relatedByValue) {
      quantity =
        values.unit * parseInt(initialData?.Products?.unit?.relatedByValue);
    } else if (values?.unit) {
      quantity = parseInt(values?.unit);
    } else {
      quantity = parseInt(values?.subunit);
    }
    const data = {
      id,
      values: {
        productsId: initialData?.Products?.id,
        qty: quantity,
        damageDate: selectedDate,
        note: values?.note,
      },
    };

    const response = await updateDamage(data);

    toast.success(response?.data?.message);
    setSubmitting(false);
    setIsAddingLoading(false);
    closeModal();
  };

  const handleStartDateChange = (date) => {
    setSelectedDate(date);
  };

  const calculateQuantity = () => {
    if (
      initialData &&
      initialData?.Products &&
      initialData?.Products?.subUnits &&
      initialData?.Products?.unit?.relatedByValue
    ) {
      const relatedByValue = parseInt(
        initialData?.Products?.unit?.relatedByValue
      );
      const quantity = initialData?.qty / relatedByValue;

      return {
        unit: Math.floor(quantity),
        subunit: ((quantity % 1) * relatedByValue).toFixed(2),
      };
    } else {
      return {
        unit: initialData?.qty,
        subunit: 0,
      };
    }
  };
  const quantityValues = calculateQuantity();
  const initialValues = {
    product: initialData?.Products?.productName,
    unit: quantityValues?.unit,
    subunit: quantityValues?.subunit,
    note: initialData?.note,
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div
          className={`modal fade mt-5 ${isModalOpen ? "show" : ""}`}
          tabIndex="-1"
          role="dialog"
          style={{display: isModalOpen ? "block" : "none"}}>
          <div style={{display: "block"}} className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update Damage</h5>
                <button
                  type="button"
                  onClick={() => closeModal()}
                  className="btn-close"
                  data-bs-dismiss="modal"></button>
              </div>
              <div className="tab-pane fade show active px-2 py-2">
                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                  {() => (
                    <Form>
                      <div className="my-2">
                        <div className="d-flex gap-2">
                          <div className="form-group col-md-6">
                            <label htmlFor="Product">Product</label>

                            <Field
                              className="form-control"
                              name="product"
                              type="text"
                              readOnly
                            />
                            {/* {matchingProducts.length > 0 && (
                              <ul className="list-group mt-2">
                                {matchingProducts?.map((product) => (
                                  <li
                                    key={product?.id}
                                    className="list-group-item"
                                    onClick={() =>
                                      handleProductSelection(product)
                                    }
                                    >
                                    {product?.productName}
                                  </li>
                                ))}
                              </ul>
                            )} */}
                          </div>
                          <div className="form-group col-md-6 px-2">
                            <label htmlFor="date">
                              Date<span className="field_required">*</span>
                            </label>

                            <DatepickerHelper
                              handleDateChange={handleStartDateChange}
                              selectedDate={selectedDate}
                              placeHolder="Select start date"
                              name="damageDate" // Make sure the name matches the field name
                            />
                          </div>
                        </div>
                        <div className="d-flex gap-2 mt-2">
                          {initialData?.Products?.unit && (
                            <div className="form-group col-md-6">
                              <label htmlFor="unit">
                                {initialData?.Products?.unit.name}
                              </label>
                              <Field
                                className="form-control"
                                name="unit"
                                type="number"
                              />
                            </div>
                          )}
                          {initialData?.Products?.subUnits && (
                            <div className="form-group col-md-6">
                              <label htmlFor="subunit">
                                {initialData?.Products?.subUnits.name}
                              </label>
                              <Field
                                className="form-control"
                                name="subunit"
                                type="number"
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-group col-lg-12 mt-2 pb-4">
                        <label htmlFor="note">Note</label>
                        <Field
                          as="textarea"
                          name="note"
                          className="form-control"
                          placeholder="Write Note..."
                        />
                      </div>
                      <div className="modal-footer">
                        <button
                          className="btn btn-outline-default"
                          onClick={() => closeModal()}
                          disabled={isAddingLoading}>
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn btn-outline-theme"
                          disabled={isAddingLoading}>
                          {isAddingLoading ? (
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"></span>
                          ) : (
                            "Update Damage"
                          )}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditModal;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const OwnersList = ({ owners }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="input-group mb-4">
        <div className="dropdown-menu">
          <Link className="dropdown-item" to="addowner">
            Edit
          </Link>
          <a className="dropdown-item" href="#/">
            Delete
          </a>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-hover text-nowrap">
          <thead>
            <tr>
              <th className="border-top-0 pt-0 pb-2">{t("Serial")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Name")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Email")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {owners?.map((owner, index) => (
              <tr>
                <td className="align-middle">
                  <Link>{index + 1}</Link>
                </td>
                <td className="align-middle">{owner?.name}</td>
                <td className="align-middle">{owner?.email}</td>

                <td className="align-middle">
                  <span
                    className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center cursor-pointer "
                    data-bs-toggle="dropdown"
                  >
                    <i className="bi bi-gear fs-9px fa-fw me-5px"></i> E/D
                  </span>

                  <div className="dropdown-menu">
                    {/* <a className="dropdown-item" href="#/">
                      Edit
                    </a> */}
                    <a className="dropdown-item" href="#/">
                      {t("Delete")}
                    </a>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OwnersList;

import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as Yup from "yup";
import { useOwnersAddMutation } from "../../redux/api/owners/ownersApi";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string().required("Email is required"),
});

const OwnerAddModal = ({ closeModal, isModalOpen }) => {
  const storeId = localStorage.getItem("storeId");
  const { t } = useTranslation();
  const [ownersAdd] = useOwnersAddMutation();
  const handleSubmit = async (values) => {
    const data = {
      name: values.name,
      email: values.email,
      password: "123456",
      storeId
    };
    const res = await ownersAdd(data);
    if (res?.data?.success === true) {
      toast.success(res.data.message);
    }
    closeModal();
  };

  return (
    <Modal show={isModalOpen} onHide={closeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('AddOwner')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-2">
        <Formik
          initialValues={{
            name: "",
            email: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="d-flex justify-content-center gap-2 py-3  ">
              <div className="form-group col-md-12 ">
                <label htmlFor="name">{t("Name")}</label>
                <Field
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Enter name..."
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            <div className="form-group col-md-12 ">
              <label htmlFor="address">{t("Email")}</label>
              <Field
                name="email"
                placeholder="Enter email..."
                className="form-control"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-danger"
              />
            </div>

            <div className="modal-footer mt-3">
              <button
                className="btn btn-outline-default"
                onClick={() => closeModal()}
              >
                {t('Cancel')}
              </button>
              
              <button type="submit" className="btn btn-info">
                <i className="fa fa-save px-2"></i>
                {t('AddOwner')}
              </button>
            </div>
          </Form>
        </Formik>
      </Modal.Body>
      {/* <Modal.Footer>
      <Button variant="secondary" onClick={closeModal}>
        Cancel
      </Button>
      <Button
        variant="danger"
        disabled={isAddingLoading}
        onClick={confirmDelete}>
        {isAddingLoading ? (
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"></span>
        ) : (
          "Delete"
        )}
      </Button>
    </Modal.Footer> */}
    </Modal>
  );
};

export default OwnerAddModal;

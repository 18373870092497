import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/css/jsvectormap.min.css";
import "jsvectormap/dist/maps/world.js";
import React, { useEffect, useState } from "react";
import Currency from "../../Shared/Currency.js";
import Loader from "../../components/loader/Loader";
import { useGetDashboardDataQuery } from "../../redux/api/dashboard/dashboardApi.js";
import {
  Card,
  CardBody,
  CardExpandToggler,
} from "./../../components/card/card.jsx";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
function Dashboard() {
  const [skip, setSkip] = useState(true);
  const token = localStorage.getItem("access-token")
  const role = localStorage.getItem("role")
  console.log(role)
  useEffect(() => {
    if (role === 'SUPERADMIN') {
      <Navigate to="/clients" />
    }
  }, [role])
  useEffect(() => {
    if (token) {
      setSkip(false)
    }
    setSkip(true)
  }, [token])
  const store = useSelector((state) => state.store);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const filter = {
    storeId: store.storeId,
  };
  const { data, isLoading } = useGetDashboardDataQuery(filter, {
    refetchOnMountOrArgChange: true,
    skip
  });
  const dashboardData = data?.data;

  const { t } = useTranslation();
  if (isLoading) {
    return <Loader />;
  }


  return (
    <div>
      {
        role === 'SUPERADMIN' ?
          <>
            super
          </>
          :
          <div className="row">
            <div>
              <div className="row gap-1">
                <div className="row">
                  <div className="col-xl-3 col-lg-3">
                    <Card className="mb-2">
                      <CardBody>
                        <div className="d-flex fw-bold small mb-3">
                          <span className="flex-grow-1">
                            {t("today")} {t("sold")}
                          </span>
                          <CardExpandToggler />
                        </div>
                        <div className="row align-items-center mb-2">
                          <div className="col-12">
                            <h4 className="mb-0">
                              {Currency}{" "}
                              {dashboardData?.today?.salesAmount?.toFixed(2)}
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="col-xl-3 col-lg-3">
                    <Card className="mb-3">
                      <CardBody>
                        <div className="d-flex fw-bold small mb-3">
                          <span className="flex-grow-1">
                            {t("today")} {t("purchases")}
                          </span>
                          <CardExpandToggler />
                        </div>
                        <div className="row align-items-center mb-2">
                          <div className="col-12">
                            <h4 className="mb-0">
                              {Currency}{" "}
                              {dashboardData?.today?.purchaseAmount?.toFixed(2)}
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="col-xl-3 col-lg-3">
                    <Card className="mb-3">
                      <CardBody>
                        <div className="d-flex fw-bold small mb-3">
                          <span className="flex-grow-1">
                            {t("today")} {t("expense")}
                          </span>
                          <CardExpandToggler />
                        </div>
                        <div className="row align-items-center mb-2">
                          <div className="col-12">
                            <h4 className="mb-0">
                              {Currency}{" "}
                              {dashboardData?.today?.expenseAmount?.toFixed(2)}
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="col-xl-3 col-lg-3">
                    <Card className="mb-3">
                      <CardBody>
                        <div className="d-flex fw-bold small mb-3">
                          <span className="flex-grow-1">
                            {" "}
                            {t("today")} {t("profit")}
                          </span>
                          <CardExpandToggler />
                        </div>
                        <div className="row align-items-center mb-2">
                          <div className="col-12">
                            <h4 className="mb-0">
                              {Currency}{" "}
                              {dashboardData?.today?.profitAmount?.toFixed(2)}
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-3">
                    <Card className="mb-2">
                      <CardBody>
                        <div className="d-flex fw-bold small mb-3">
                          <span className="flex-grow-1">
                            {monthNames[moment().month()]} - {t("sold")}
                          </span>
                          <CardExpandToggler />
                        </div>
                        <div className="row align-items-center mb-2">
                          <div className="col-12">
                            <h4 className="mb-0">
                              {Currency}{" "}
                              {dashboardData?.currentMonth?.salesAmount?.toFixed(2)}
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="col-xl-3 col-lg-3">
                    <Card className="mb-3">
                      <CardBody>
                        <div className="d-flex fw-bold small mb-3">
                          <span className="flex-grow-1">
                            {monthNames[moment().month()]} - {t("purchases")}
                          </span>
                          <CardExpandToggler />
                        </div>
                        <div className="row align-items-center mb-2">
                          <div className="col-12">
                            <h4 className="mb-0">
                              {Currency}{" "}
                              {dashboardData?.currentMonth?.purchaseAmount?.toFixed(
                                2
                              )}
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="col-xl-3 col-lg-3">
                    <Card className="mb-3">
                      <CardBody>
                        <div className="d-flex fw-bold small mb-3">
                          <span className="flex-grow-1">
                            {monthNames[moment().month()]} - {t("expense")}
                          </span>
                          <CardExpandToggler />
                        </div>
                        <div className="row align-items-center mb-2">
                          <div className="col-12">
                            <h4 className="mb-0">
                              {Currency}{" "}
                              {dashboardData?.currentMonth?.expenseAmount?.toFixed(
                                2
                              )}
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="col-xl-3 col-lg-3">
                    <Card className="mb-3">
                      <CardBody>
                        <div className="d-flex fw-bold small mb-3">
                          <span className="flex-grow-1">
                            {monthNames[moment().month()]} - {t("profit")}
                          </span>
                          <CardExpandToggler />
                        </div>
                        <div className="row align-items-center mb-2">
                          <div className="col-12">
                            <h4 className="mb-0">
                              {Currency}{" "}
                              {dashboardData?.currentMonth?.profitAmount?.toFixed(
                                2
                              )}
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-3">
                    <Card className="mb-2">
                      <CardBody>
                        <div className="d-flex fw-bold small mb-3">
                          <span className="flex-grow-1">
                            {t("total")} - {t("sold")}
                          </span>
                          <CardExpandToggler />
                        </div>
                        <div className="row align-items-center mb-2">
                          <div className="col-12">
                            <h4 className="mb-0">
                              {Currency}{" "}
                              {dashboardData?.lifetime?.salesAmount?.toFixed(2)}
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="col-xl-3 col-lg-3">
                    <Card className="mb-3">
                      <CardBody>
                        <div className="d-flex fw-bold small mb-3">
                          <span className="flex-grow-1">
                            {t("total")} - {t("purchases")}
                          </span>
                          <CardExpandToggler />
                        </div>
                        <div className="row align-items-center mb-2">
                          <div className="col-12">
                            <h4 className="mb-0">
                              {Currency}{" "}
                              {dashboardData?.lifetime?.purchaseAmount?.toFixed(2)}
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="col-xl-3 col-lg-3">
                    <Card className="mb-3">
                      <CardBody>
                        <div className="d-flex fw-bold small mb-3">
                          <span className="flex-grow-1">
                            {t("total")} - {t("expense")}
                          </span>
                          <CardExpandToggler />
                        </div>
                        <div className="row align-items-center mb-2">
                          <div className="col-12">
                            <h4 className="mb-0">
                              {Currency}{" "}
                              {dashboardData?.lifetime?.expenseAmount?.toFixed(2)}
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="col-xl-3 col-lg-3">
                    <Card className="mb-3">
                      <CardBody>
                        <div className="d-flex fw-bold small mb-3">
                          <span className="flex-grow-1">
                            {t("total")} - {t("profit")}
                          </span>
                          <CardExpandToggler />
                        </div>
                        <div className="row align-items-center mb-2">
                          <div className="col-12">
                            <h4 className="mb-0">
                              {Currency}{" "}
                              {dashboardData?.lifetime?.profitAmount?.toFixed(2)}
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-lg-6">
                    <Card className="mb-2">
                      <CardBody>
                        <div className="d-flex fw-bold small mb-3">
                          <span className="flex-grow-1">
                            {t("total")} {t("receiveable")}
                          </span>
                          <CardExpandToggler />
                        </div>
                        <div className="row align-items-center mb-2">
                          <div className="col-12">
                            <h4 className="mb-0">
                              {Currency}{" "}
                              {dashboardData?.totalReceiveAble?.toFixed(2)}
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <Card className="mb-3">
                      <CardBody>
                        <div className="d-flex fw-bold small mb-3">
                          <span className="flex-grow-1">
                            {t("total")} {t("payable")}
                          </span>
                          <CardExpandToggler />
                        </div>
                        <div className="row align-items-center mb-2">
                          <div className="col-12">
                            <h4 className="mb-0">
                              {Currency} {dashboardData?.totalPayable?.toFixed(2)}
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <Card className="mb-2">
                      <CardBody>
                        <div className="d-flex fw-bold small mb-3">
                          <span className="flex-grow-1">
                            {t("total")} {t("customer")}
                          </span>
                          <CardExpandToggler />
                        </div>
                        <div className="row align-items-center mb-2">
                          <div className="col-12">
                            <h4 className="mb-0">{dashboardData?.totalCustomer}</h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="col-xl-4 col-lg-4">
                    <Card className="mb-3">
                      <CardBody>
                        <div className="d-flex fw-bold small mb-3">
                          <span className="flex-grow-1">
                            {t("total")} {t("supplier")}
                          </span>
                          <CardExpandToggler />
                        </div>
                        <div className="row align-items-center mb-2">
                          <div className="col-12">
                            <h4 className="mb-0">{dashboardData?.totalSupplier}</h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="col-xl-4 col-lg-4">
                    <Card className="mb-3">
                      <CardBody>
                        <div className="d-flex fw-bold small mb-3">
                          <span className="flex-grow-1">
                            {t("total")} {t("product")}
                          </span>
                          <CardExpandToggler />
                        </div>
                        <div className="row align-items-center mb-2">
                          <div className="col-12">
                            <h4 className="mb-0">{dashboardData?.totalProduct}</h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>

                {/* <div className="row">
                <div className="col-xl-4 col-lg-4">
                  <Card className="mb-3">
                    <CardBody>
                      <div className="d-flex fw-bold small mb-3">
                        <span className="flex-grow-1">Today Sell Profit</span>
                        <CardExpandToggler />
                      </div>
                      <div className="row align-items-center mb-2">
                        <div className="col-7">
                          <h3 className="mb-0">
                            {Currency} {(todayReport?.profitAmount).toFixed(2)}
                          </h3>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="col-xl-4 col-lg-4">
                  <Card className="mb-2">
                    <CardBody>
                      <div className="d-flex fw-bold small mb-3">
                        <span className="flex-grow-1">Today Sold</span>
                        <CardExpandToggler />
                      </div>
                      <div className="row align-items-center mb-2">
                        <div className="col-7">
                          <h3 className="mb-0">
                            {Currency} {todayReport?.salesAmount}
                          </h3>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="col-xl-4 col-lg-4">
                  <Card className="mb-3">
                    <CardBody>
                      <div className="d-flex fw-bold small mb-3">
                        <span className="flex-grow-1">
                          Today Sold - Purchase Cost
                        </span>
                        <CardExpandToggler />
                      </div>
                      <div className="row align-items-center mb-2">
                        <div className="col-7">
                          <h3 className="mb-0">
                            {Currency} {todayReport?.salesAmount}
                          </h3>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div> */}
              </div>
            </div>
          </div>
      }

    </div>
  );
}

export default Dashboard;

import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

const DeleteConfirmationModal = ({
  closeModal,
  confirmDelete,
  isAddingLoading,
}) => {
  const { t } = useTranslation();
  return (
    <Modal show={true} onHide={closeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("Delete Confirm")} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("Delete Message")}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          {t("Cancel")}
        </Button>
        <Button
          variant="danger"
          disabled={isAddingLoading}
          onClick={confirmDelete}>
          {isAddingLoading ? (
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>
          ) : (
            t("Delete")
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmationModal;

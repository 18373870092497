import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DatepickerHelper from "../helpers/DatepickerHelper";
import ReactSelect from "../helpers/ReactSelect";
import { useAllTransactionTypeApiGetQuery } from "../../redux/api/transactionType/transactionTypeApi";

import { useAllPaymentTypeApiGetQuery } from "../../redux/api/paymentType/paymentTypeApi";
import { useAllAccountTypeApiGetQuery } from "../../redux/api/accountType/accountTypeApi";
import { useAllSupplierGetQuery } from "../../redux/api/supplier/supplierApi";
import { useAllCustomerGetQuery } from "../../redux/api/customer/customerApi";
import { usePaymentCreateMutation } from "../../redux/api/payments/paymentsApi";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const PaymentsModal = ({ closeModal, isModalOpen }) => {
  const store = useSelector((state) => state.store)
  const filter = {
    storeId: store?.storeId,
  };
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectePaymentType, setselectePaymentType] = useState(null);
  const [selecteAccoutType, setselecteAccoutType] = useState(null);
  const [selecteWallet, setselecteWallet] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [transactionTypeId, setTransactionTypeId] = useState(null);
  const [supplierId, setSupplierId] = useState(null);
  const [customerId, setCustomerId] = useState(null);

  const { data: PaymentTypeIdData } = useAllPaymentTypeApiGetQuery(filter,{
    refetchOnMountOrArgChange:true
  });
  const initialPaymentTypeIdData = PaymentTypeIdData?.data;
  const paymentTypeIdDataIdOptions = initialPaymentTypeIdData?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const { data: transactionTypeIdData } = useAllTransactionTypeApiGetQuery();
  const initialTransactionTypeIdData = transactionTypeIdData?.data;
  const transactionTypeIdOptions = initialTransactionTypeIdData?.map(
    (item) => ({
      value: item?.id,
      label: item?.name,
    })
  );

  const { data: accountTypeIdData } = useAllAccountTypeApiGetQuery(filter,{
    refetchOnMountOrArgChange:true
  });
  const initialAccountTypeIdDataIdData = accountTypeIdData?.data;
  const accountTypeIdOptions = initialAccountTypeIdDataIdData?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));
  const { data: supplierIdData } = useAllSupplierGetQuery(filter,{
    refetchOnMountOrArgChange:true
  });
  const initialSupplierIdDataIdData = supplierIdData?.data;
  const supplierOptions = initialSupplierIdDataIdData?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));
 
  const { data: customerIdData } = useAllCustomerGetQuery(filter,{
    refetchOnMountOrArgChange:true
  });
  const initialCustomerIdDataIdData = customerIdData?.data;
  const customerOptions = initialCustomerIdDataIdData?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const handleStartDateChange = (date) => {
    setSelectedDate(date);
  };

  const options4 = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const handleSupplierChange = (values) => {
    setSupplierId(values.value);
  };
  const handleCustomerChange = (values) => {
    setCustomerId(values.value);
  };
  const initialValues = {
    isWallet: selecteWallet,
    paymentDate: selectedDate,
    paymentTypeId: selectePaymentType,
    accountTypeId: selecteAccoutType,
    transactionTypeId: transactionTypeId,
    amount: "",
    note: "",
  };

  const validationSchema = Yup.object().shape({
    paymentTypeId: Yup.number().required("Payment Type is required"),
    accountTypeId: Yup.number().required("Account Type is required"),
    transactionTypeId: Yup.number().required("Transaction Type is required"),

    // isWallet: Yup.boolean()
    //   .required("Please select wallet")
    //   .test("is-true", "Please select wallet", (value) => value === true),
  });
  const [paymentCreate] = usePaymentCreateMutation();
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setIsAdding(true);
    if (selecteAccoutType === 1) {
      values.supplierAccountId = supplierId;
    } else {
      values.customerAccountId = customerId;
    }
    const data = {
      ...values,
      isWallet: selecteWallet,
      paymentDate: selectedDate,
      paymentTypeId: selectePaymentType,
      accountTypeId: selecteAccoutType,
      transactionTypeId: transactionTypeId,
      storeId:store.storeId
    };

    const res = await paymentCreate(data);

    if (res?.data) {
      toast.success("Payment Create Successful");
    
    }
    setIsAdding(false);
    closeModal();
  };
  return (
    <Modal show={isModalOpen} onHide={closeModal} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("Add Payment")} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <div className="d-flex my-2">
                <div className="form-group col-lg-6 px-2">
                  <label htmlFor="isWallet">{t("Wallet/Direct Transaction")}</label>
                  <Field className="form-control" name="isWallet">
                    {(props) => {
                      const handleChange = (option) => {
                        props.form.setFieldValue("isWallet", option.value);
                        setselecteWallet(option.value);
                      };
                      return (
                        <ReactSelect
                          placeHolder="Select "
                          options={options4}
                          handleChange={handleChange}
                        />
                      );
                    }}
                  </Field>
                </div>
                <div className="form-group col-lg-6 px-2 ">
                  <label htmlFor="payment_date">
                   {t("Payment Date")}<span className="field_required"></span>
                  </label>
                  <DatepickerHelper
                    handleDateChange={handleStartDateChange}
                    selectedDate={selectedDate}
                    placeHolder="Select start date"
                  />
                </div>
              </div>
              <div className="d-flex my-2 ">
                {" "}
                <div className="form-group col-lg-6 px-2">
                  <label htmlFor="paymentTypeId">
                   {t("Payment Type")}<span className="field_required"></span>
                  </label>
                  <Field className="form-control" name="paymentTypeId">
                    {(props) => {
                      const handleChange = (option) => {
                        setselectePaymentType(option.value);
                        props.form.setFieldValue("paymentTypeId", option.value);
                      };
                      return (
                        <ReactSelect
                          placeHolder="Select Payment Type"
                          options={paymentTypeIdDataIdOptions}
                          handleChange={handleChange}
                        />
                      );
                    }}
                  </Field>
                  <ErrorMessage
                    name="paymentTypeId"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group col-lg-6 px-2">
                  <label htmlFor="accountTypeId">
                    {t("Account Type")}<span className="field_required"></span>
                  </label>

                  <Field className="form-control" name="accountTypeId">
                    {(props) => {
                      const handleChange = (option) => {
                        setselecteAccoutType(option.value);
                        props.form.setFieldValue("accountTypeId", option.value);
                      };
                      return (
                        <ReactSelect
                          placeHolder="Select Account Type"
                          options={accountTypeIdOptions}
                          handleChange={handleChange}
                        />
                      );
                    }}
                  </Field>
                  <ErrorMessage
                    name="accountTypeId"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="d-flex my-2">
                {" "}
                <div className="form-group col-lg-6 px-2">
                  <label htmlFor="account_id">
                    {t("Account ID")}<span className="field_required"></span>
                  </label>

                  <ReactSelect
                    placeHolder="Select Account ID"
                    options={
                      selecteAccoutType === 2
                        ? customerOptions
                        : selecteAccoutType === 1
                        ? supplierOptions
                        : []
                    }
                    handleChange={
                      selecteAccoutType === 2
                        ? handleCustomerChange
                        : selecteAccoutType === 1
                        ? handleSupplierChange
                        : () => {}
                    }
                    isDisabled={!selecteAccoutType}
                  />
                </div>
                <div className="form-group col-lg-6 px-2">
                  <label htmlFor="amount">
                    {t("Amount")}<span className="field_required"></span>
                  </label>
                  <Field
                    type="number"
                    name="amount"
                    className="form-control"
                    placeholder="Enter Amount"
                    id="amount"
                  />
                </div>
              </div>
              <div className="form-group col-6 px-2">
                <label>{t("Transaction Type")}</label>

                <Field className="form-control" name="transactionTypeId">
                  {(props) => {
                    const handleChange = (option) => {
                      setTransactionTypeId(option.value);
                      props.form.setFieldValue(
                        "transactionTypeId",
                        option.value
                      );
                    };
                    return (
                      <ReactSelect
                        placeHolder="Select Account"
                        options={transactionTypeIdOptions}
                        handleChange={handleChange}
                      />
                    );
                  }}
                </Field>
                <ErrorMessage
                  name="transactionTypeId"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="form-group col-lg-12 px-2 my-2">
                <label htmlFor="note">{t("Note")}</label>
                <Field
                  as="textarea"
                  name="note"
                  className="form-control"
                  placeholder="Write Note. (optional)"
                />
              </div>

              <div className="modal-footer">
                <button
                  className="btn btn-outline-default"
                  onClick={() => closeModal()}
                  disabled={isAdding}
                >
                  {t("Cancel")}
                </button>
                <button
                  type="submit"
                  className="btn btn-outline-theme"
                  disabled={isAdding}
                >
                  {isAdding ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    t("Save")
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentsModal;

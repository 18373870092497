import React, { useState } from "react";
import toast from "react-hot-toast";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  useSingleExpenseCategoryGetQuery,
  useUpdateExpenseCategoryMutation,
} from "../../redux/api/expenseCategory/expenseCategory";
import Loader from "../../components/loader/Loader";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const ExpenseCategoryEditModal = ({ isModalOpen, id, closeModal }) => {
  const store = useSelector((state) => state.store);
  const [isAdding, setIsAdding] = useState(false);
  const { t } = useTranslation();
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
  });

  const { data, isLoading } = useSingleExpenseCategoryGetQuery({id,params:{storeId: store.storeId,}}, {
    refetchOnMountOrArgChange: true,
  });
  const initialValues = {
    name: data?.data?.name,
  };

  const [updateExpenseCategory] = useUpdateExpenseCategoryMutation();
  const handleSubmit = async (values, { resetForm }) => {
    setIsAdding(true);
    values.storeId= parseInt(store.storeId) 
    const res = await updateExpenseCategory({ id, values });
    if (res?.data) {
      toast.success("Expense Category Update Successful");
      resetForm();
    }
    setIsAdding(false);
    closeModal();
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Modal show={isModalOpen} onHide={closeModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>{t("Update Expense Category")} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div>
                  <div className="form-group  px-2 ">
                    <Field
                      type="text"
                      name="name"
                      placeholder="Enter Expense Category"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group d-flex gap-2 justify-content-end px-2 pt-4">
                    <button
                      className="btn btn-outline-default"
                      onClick={() => closeModal()}
                      disabled={isAdding}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-outline-theme"
                      disabled={isAdding}
                    >
                      {isAdding ? (
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        t("Update")
                      )}
                      <i className="fa fa-save px-2"></i>
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ExpenseCategoryEditModal;

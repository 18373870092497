/* eslint-disable no-sequences */
import { baseApi } from "../baseApi";

export const loginApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation({
      query: (authData) => ({
        url: "/auth/login",
        method: "POST",
        data: authData,
      }),
      invalidatesTags: ["auth"],
    }),
    getClients: build.query({
      query: () => ({
        url: "/auth/admin",
        method: "GET",

      }),
      providesTags: ["auth"],
    }),
    createClients: build.mutation({
      query: (data) => ({
        url: "/auth/admin",
        method: "POST",
        data
      }),
      invalidatesTags: ["auth"],
    }),
  }),
});

export const { useLoginMutation, useGetClientsQuery,useCreateClientsMutation } = loginApi;

import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {Link} from "react-router-dom";
import {useDeleteExpenseCategoryMutation} from "../../redux/api/expenseCategory/expenseCategory";
import ExpenseCategoryEditModal from "./ExpenseCategoryEditModal";
import DeleteConfirmationModal from "../deleteConfirmationModal/DeleteConfirmationModal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ExpenseCategoryList = ({data, refetch}) => {
  const store = useSelector((state) => state.store);
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [deleteExpenseCategory] = useDeleteExpenseCategoryMutation({});
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const handleDelete = async (id) => {
    setIsAddingLoading(true)
    const res = await deleteExpenseCategory({id,storeId: store.storeId,});

    if (res?.data?.success === true) {
      toast.success(res?.data?.message);
    }
    setIsAddingLoading(false)
    setIsDeleteConfirmationModalOpen(false);
  };

  const openModal = (id) => {
    setIsModalOpen(true);
    setSelectedItemId(id);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openDeleteConfirmationModal = (id) => {
    setIsDeleteConfirmationModalOpen(true);
    setSelectedItemId(id);
  };

  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(false);
  };

  return (
    <>
      <div className="table-responsive mt-3">
        <table className="table table-hover text-nowrap">
          <thead>
            <tr>
             
              <th className="border-top-0 pt-0 pb-2"> {t("Serial")} </th>
              <th className="border-top-0 pt-0 pb-2">{t("Name")}</th>

              <th className="border-top-0 pt-0 pb-2">{t("Actions")} </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                
                <td className="align-middle">
                  <Link >{index + 1}</Link>
                </td>
                <td className="align-middle">{item?.name}</td>

                <td className="align-middle">
                  <span
                    className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center cursor-pointer "
                    data-bs-toggle="dropdown">
                    <i className="bi bi-gear fs-9px fa-fw me-5px"></i> E/D
                  </span>

                  <div className="dropdown-menu">
                    <button
                      onClick={() => openModal(item?.id)}
                      className="dropdown-item">
                      <i
                        class="bi bi-pencil-square pr-2"
                        style={{marginRight: "5px"}}></i>
                      {t("Edit")}
                    </button>
                    <button
                      onClick={() => openDeleteConfirmationModal(item?.id)}
                      className="dropdown-item">
                      <i
                        className="bi bi-trash pr-2"
                        style={{marginRight: "5px"}}></i>
                      {t("Delete")}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <ExpenseCategoryEditModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          id={selectedItemId}
        />
      )}

      {isDeleteConfirmationModalOpen && (
        <DeleteConfirmationModal
        isAddingLoading={isAddingLoading}
          closeModal={closeDeleteConfirmationModal}
          confirmDelete={() => handleDelete(selectedItemId)}
        />
      )}
    </>
  );
};

export default ExpenseCategoryList;

/* eslint-disable no-sequences */
import {baseApi} from "../baseApi";

export const purchaseApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    purchaseCreate: build.mutation({
      query: (purchaseData) => ({
        url: "/purchase",
        method: "POST",
        data: purchaseData,
      }),
      invalidatesTags: ["purchase"],
    }),

    allPurchaseGet: build.query({
      query: (params) => ({
        url: "/purchase",
        method: "GET",
        params,
      }),
      providesTags: ["purchase"],
    }),
    singlePurchaseGet: build.query({
      query: ({id,params}) => ({
        url: `/purchase/${id}`,
        method: "GET",
        params
      }),
      providesTags: ["purchase"],
    }),

    updatePurchase: build.mutation({
      query: ({id, values}) => ({
        url: `/purchase/${id}`,
        method: "PATCH",
        data: values,
      }),
      invalidatesTags: ["purchase"],
    }),
    deletePurchase: build.mutation({
      query: ({id,storeId}) => ({
        url: `/purchase/${id}`,
        method: "DELETE",
        data:{storeId:storeId},
      }),
      invalidatesTags: ["purchase"],
    }),
  }),
});

export const {
  usePurchaseCreateMutation,
  useAllPurchaseGetQuery,
  useSinglePurchaseGetQuery,
  useUpdatePurchaseMutation,
  useDeletePurchaseMutation,
} = purchaseApi;

import axios from "axios";
import toast from "react-hot-toast";

export const singleImageUpload = async (formData, setImage) => {
  const apiKey = `${process.env.REACT_APP_IMAGE_BB_API}`;
  const imageBBUrl = `${process.env.REACT_APP_IMAGE_BB_URL}`;

  formData.set("key", apiKey);

  const { data } = await axios.post(imageBBUrl, formData);

  if (data.success === true) {
    setImage(data?.data?.display_url);
  } else {
    toast.error("Failed to upload image. Please try again.");
  }
};

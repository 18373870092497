import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

import toast from "react-hot-toast";
import Loader from "../../components/loader/Loader";
import {
  useSingleSupplierGetQuery,
  useUpdateSupplierBalanceMutation,
} from "../../redux/api/supplier/supplierApi";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
const AddSupplierWalletBalanceModal = ({ isModalOpen, closeModal, id }) => {
  const store = useSelector((state) => state.store);
  const { t } = useTranslation();
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const { data, isLoading } = useSingleSupplierGetQuery({id,params:{storeId: store.storeId}}, {
    refetchOnMountOrArgChange: true,
  });

  const [updateSupplierBalance] = useUpdateSupplierBalanceMutation();

  const handleSubmit = async (values, { resetForm }) => {
    setIsAddingLoading(true);
    values.storeId =  parseInt(store.storeId);
    const res = await updateSupplierBalance({ id, values });

    if (res?.data) {
      toast.success("Supplier Balance Added Successful");
      resetForm();
    }
    setIsAddingLoading(false);
    closeModal();
  };
  const initialData = data?.data;
  const initialValues = {
    id: initialData?.id,
    name: initialData?.name,
    amount: 0,
  };

  const validationSchema = Yup.object().shape({
    id: Yup.number().required("Supplier Id is required"),
    name: Yup.string().required("Supplier Name is required"),
    amount: Yup.number().required("Amount is required"),
  });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Modal show={isModalOpen} onHide={closeModal} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{t("Add Balance")} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {() => (
                <Form>
                  <div>
                    <div className="form-group col-md-12">
                      <label htmlFor="name" className="py-2">
                        {t("Name")}
                        <span className="field_required">*</span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Enter Supplier Name"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <label htmlFor="email" className="py-2">{t("Amount")}</label>
                      <Field
                        type="number"
                        className="form-control"
                        name="amount"
                        placeholder="Enter Amount"
                      />
                    </div>
                  </div>

                  <div className="modal-footer mt-3">
                    <button
                      className="btn btn-outline-default"
                      onClick={() => closeModal()}
                      disabled={isAddingLoading}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-outline-theme"
                      disabled={isAddingLoading}
                    >
                      {isAddingLoading ? (
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        t("Save")
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default AddSupplierWalletBalanceModal;

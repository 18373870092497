export const productSubUnitStock = (item, totalPurchased) => {
  const data = totalPurchased / parseInt(item?.unit?.relatedByValue);
  var a = Math.floor(data);
  const rightSide = data - a;
  const b = Math.round(rightSide * parseInt(item?.unit?.relatedByValue)) ;

  if (a && b) {
    return `${a} ${item?.unit?.name} ${b} ${item?.subUnits?.name}`;
  } else if (a) {
    return `${a} ${item?.unit?.name} `;
  } else if (b) {
    return `${b} ${item?.subUnits?.name}`;
  } else {
    return 0;
  }
};
export const calculateUnitStock = (item, stock) => {
  return `${stock} ${item?.unit?.name}`;
};
export const calculateProducuUnitSubUnit = (item) => {
  const data = item?.totalPurchased / parseInt(item?.unit?.relatedByValue);

  let totalPurchased = "";
  let sold = "";
  let totalDamaged = "";
  let totalReturned = "";
  let stock = "";
  let sellValue = "";
  if (item?.subUnitsId) {
    totalPurchased = productSubUnitStock(item, item.totalPurchased);
    sold = productSubUnitStock(item, item.totalSold);
    totalDamaged = productSubUnitStock(item, item.totalDamaged);
    totalReturned = productSubUnitStock(item, item.totalReturned);
    stock = productSubUnitStock(item, item.stock);
    sellValue = (parseFloat(data) * item?.salePrice).toFixed(3);
  } else {
    totalPurchased = calculateUnitStock(item, item.totalPurchased);
    sold = calculateUnitStock(item, item?.totalSold);
    totalDamaged = calculateUnitStock(item, item.totalDamaged);
    totalReturned = calculateUnitStock(item, item.totalReturned);
    stock = calculateUnitStock(item, item.stock);
    sellValue = item.stock * item?.salePrice;
  }
  return {
    totalPurchased,
    sold,
    totalDamaged,
    totalReturned,
    stock,
    sellValue,
  };
};
export const calculateQty = (damagedItem, products) => {
  let stock = "";
  const findProduct = products?.find(
    (product) => product.id === damagedItem?.productsId
  );
  if (findProduct?.subUnitsId) {
    stock = productSubUnitStock(findProduct, damagedItem.qty);
  } else {
    stock = calculateUnitStock(findProduct, damagedItem.qty);
  }
  return stock;
};

async function fetchCurrency() {
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("access-token")}`,
      "Content-Type": "application/json",
    };
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/store`, {
      method: "GET",
      headers: headers,
    });
    if (localStorage.getItem("access-token")) {
      const data = await response.json();
      return data?.data[0]?.Currency?.value;
    }
  } catch (error) {
    console.error("Error fetching currency:", error);
    throw error;
  }
}
const Currency = await fetchCurrency();
export default Currency;

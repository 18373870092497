import React, { useEffect } from "react";
import { useState } from "react";
import CustomerModal from "./CustomerModal";
import CustomerList from "./CustomerList";
import { useAllCustomerGetQuery } from "../../redux/api/customer/customerApi";

import Loader from "../../components/loader/Loader";
import Pagination from "../../utils/Pagination";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Customers = () => {
  const store = useSelector((state) => state.store);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [name, setName] = useState();
  const [mobile, setMobile] = useState();
  const [nameFieldValue, setNameFieldValue] = useState();
  const [mobileFieldValue, setMobileFieldValue] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({
    page,
    limit: pageSize,
    storeId: store.storeId,
  });
  const { t } = useTranslation();
  const { data, isLoading, refetch } = useAllCustomerGetQuery(filter, {
    refetchOnMountOrArgChange: true,
  });

  const initialData = data?.data;
  const meta = data?.meta;
  useEffect(() => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      storeId: store.storeId,
    }));
  }, [store.storeId]);
  useEffect(() => {
    const updatedFilter = { ...filter };
    updatedFilter.page = page;
    if (name) {
      updatedFilter.name = name;
    }
    if (searchTerm) {
      updatedFilter.searchTerm = searchTerm;
    }
    if (!searchTerm) {
      delete updatedFilter.searchTerm;
    }
    if (!nameFieldValue) {
      delete updatedFilter.name;
    }
    if (!mobileFieldValue) {
      delete updatedFilter.mobile;
    }
    if (mobile) {
      updatedFilter.mobile = mobile;
    }

    setFilter(updatedFilter);

    refetch();
  }, [name, mobile, refetch, searchTerm, page]);

  const handleSubmit = () => {
    setMobile(mobileFieldValue);
    setName(nameFieldValue);

    // resetForm();
  };

  const handleFilterChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <header className="header text-white">
        <div className="header-info">
          <h1 className="page-header">{t("CustomerList")}</h1>
          <hr className="mb-2" />
        </div>
      </header>

      <div className="d-flex gap 5">
        <div className="input-group mb-3">
          <div className=" position-relative">
            <div className="input-group">
              <input
                type="text"
                className="form-control px-35px"
                placeholder="Filter Customer"
                value={searchTerm}
                onChange={handleFilterChange}
              />
              <div
                className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
                style={{ zIndex: 1020 }}
              >
                <i className="fa fa-search opacity-5"></i>
              </div>
            </div>
          </div>
        </div>

        <button
          style={{ width: "210px", height: "35px" }}
          className="btn btn-outline-theme"
          onClick={openModal}
        >
          <i className="fa fa-plus-circle me-1"></i>
          {t("AddCustomer")}
        </button>
      </div>

      <div>
        <div className="d-flex gap-4">
          <input
            type="text"
            className="form-control"
            name="name"
            value={nameFieldValue}
            onChange={(e) => {
              setNameFieldValue(e.target.value);
            }}
            placeholder="Name"
          />
          <input
            type="text"
            className="form-control"
            name="mobile"
            value={mobileFieldValue}
            onChange={(e) => {
              setMobileFieldValue(e.target.value);
            }}
            placeholder="Mobile Number"
          />
        </div>

        <div className="mt-2 d-flex gap-2">
          <div className="form-group">
            <button
              onClick={handleSubmit}
              className="btn btn-primary"
              type="submit"
            >
              <i className="fa fa-sliders"></i> {t("Filter")}
            </button>
          </div>
          <div className="form-group">
            <button
              className="btn btn-info"
              type="button"
              onClick={() => {
                setName("");
                setMobile("");
                setNameFieldValue("");
                setMobileFieldValue("");
                const updatedFilter = { ...filter };
                delete updatedFilter.name;
                delete updatedFilter.mobile;
                setFilter(updatedFilter);

                refetch();
              }}
            >
              {t("Reset")}
            </button>
          </div>
        </div>
      </div>
      <div className="tab-content">
        {isModalOpen && (
          <CustomerModal isModalOpen={isModalOpen} closeModal={closeModal} />
        )}
        {isLoading ? (
          <Loader />
        ) : (
          <div className="mt-1">
            <CustomerList data={initialData} refetch={refetch} />
          </div>
        )}
      </div>

      <Pagination
        page={page}
        setPage={setPage}
        meta={meta}
        pageSize={pageSize}
      />
    </div>
  );
};

export default Customers;

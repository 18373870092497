/* eslint-disable no-sequences */
import { baseApi } from "../baseApi";
const values = {
  storeId: 1,
};
export const srApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    srCreate: build.mutation({
      query: (srData) => ({
        url: "/sr",
        method: "POST",
        data: srData,
      }),
      invalidatesTags: ["sr"],
    }),

    allSrGet: build.query({
      query: (params) => ({
        url: "/sr",
        method: "GET",
        params,
      }),
      providesTags: ["sr"],
    }),
    singleSrGet: build.query({
      query: ({id,params}) => ({
        url: `/sr/${id}`,
        method: "GET",
        params
      }),
      providesTags: ["sr"],
    }),

    updateSr: build.mutation({
      query: ({ id, values }) => ({
        url: `/sr/${id}`,
        method: "PATCH",
        data: values,
      }),
      invalidatesTags: ["sr"],
    }),
    deleteSr: build.mutation({
      query: ({ id, storeId }) => ({
        url: `/sr/${id}`,
        method: "DELETE",
        data:{storeId:storeId},
      }),
      invalidatesTags: ["sr"],
    }),
  }),
});

export const {
  useSrCreateMutation,
  useAllSrGetQuery,
  useSingleSrGetQuery,
  useUpdateSrMutation,
  useDeleteSrMutation,
} = srApi;

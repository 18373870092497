import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useSupplierCreateMutation } from "../../redux/api/supplier/supplierApi";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const SupplierModal = ({ closeModal, isModalOpen }) => {
  const store = useSelector((state)=>state.store)
  const { t } = useTranslation();
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const initialValues = {
    name: "",
    email: "",
    address: "",
    mobile: "",
    paid: 0,
    payable: 0,
    walletBalance: 0,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Supplier Name is required"),
    mobile: Yup.string().required("Phone is required"),
  });
  const [supplierCreate] = useSupplierCreateMutation();

  const handleSubmit = async (values, { resetForm }) => {
    setIsAddingLoading(true);
    values.storeId = store.storeId
    const res = await supplierCreate(values);
    if (res?.data) {
      toast.success("Supplier Create Successful");
      resetForm(initialValues);
    }
    setIsAddingLoading(false);
    closeModal();
  };
  const validateMobile = (value) => {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^\d+$/.test(value)) {
      error = "Please enter only numbers";
    }
    return error;
  };
  return (
    <Modal show={isModalOpen} onHide={closeModal} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("Add Supplier")} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="d-flex gap-1">
              <div className="form-group col-md-6">
                <label htmlFor="name">
                  {t("Name")}
                  <span className="field_required"></span>
                </label>
                <Field
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Enter Supplier Name..."
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="email">{t("Email")}</label>
                <Field
                  type="text"
                  className="form-control"
                  name="email"
                  placeholder="Enter Supplier Email..."
                />
              </div>
            </div>
            <div className="d-flex gap-1">
              <div className="form-group col-md-6">
                <label htmlFor="mobile">
                  {"Phone"}
                  <span className="field_required"></span>
                </label>
                <Field
                  type="text"
                  className="form-control"
                  name="mobile"
                  placeholder="Enter Customer Phone"
                  validate={validateMobile}
                />
                <ErrorMessage
                  name="mobile"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            <div className="d-flex gap-1 my-2">
              <div className="form-group col-md-4">
                <label htmlFor="paid">Opening Receivable</label>
                <Field type="number" className="form-control" name="paid" />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="opening_payable">Opening Payable</label>
                <Field type="number" className="form-control" name="payable" />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="walletBalance">{t("Wallet Balance")}</label>
                <Field
                  type="number"
                  className="form-control"
                  name="walletBalance"
                />
              </div>
            </div>
            <div className="d-flex gap-1">
              {" "}
              <div className="form-group col-md-12">
                <label htmlFor="address">{t("Address")}</label>
                <Field
                  as="textarea"
                  className="form-control"
                  name="address"
                  placeholder="Write Supplier Address"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-outline-default"
                onClick={() => closeModal()}
                disabled={isAddingLoading}
              >
                {t("Cancel")}
              </button>
              <button
                type="submit"
                className="btn btn-outline-theme"
                disabled={isAddingLoading}
              >
                {isAddingLoading ? (
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  t("Save")
                )}
                <i className="fa fa-save px-2"></i>
              </button>
            </div>
          </Form>
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default SupplierModal;

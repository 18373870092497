import React, { useState } from "react";
import Loader from "../../components/loader/Loader";
import { t } from "i18next";
import { useGetClientsQuery } from "../../redux/api/auth/loginApi";
import AddClientModal from "./AddClientModal";

const Clients = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data, isLoading } = useGetClientsQuery();
  console.log(data);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const clientsData = data?.data;
 
  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <header className="header text-white d-flex justify-content-between w-full">
            <div className="header-info">
              <h1 className="page-header">Client's</h1>
            </div>
            <button
              style={{ width: "210px", height: "35px" }}
              className="btn btn-outline-theme"
              onClick={openModal}
            >
              <i className="fa fa-plus-circle me-1"></i>
              Add New
            </button>
          </header>
          <hr className="mb-2" />
          <div className="table-responsive mt-5">
            <table className="table table-hover text-nowrap">
              <thead>
                <tr>
                  <th className="border-top-0 pt-0 pb-2">{t("Serial")}</th>
                  <th className="border-top-0 pt-0 pb-2">{t("Name")}</th>
                  <th className="border-top-0 pt-0 pb-2">{t("Email")}</th>
                  <th className="border-top-0 pt-0 pb-2">{t("Store's")}</th>
                  <th className="border-top-0 pt-0 pb-2">{t("Status")}</th>
                  <th className="border-top-0 pt-0 pb-2">{t("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {clientsData?.map((owner, index) => (
                  <tr>
                    <td className="align-middle">
                      <p>{index + 1}</p>
                    </td>
                    <td className="align-middle">{owner?.name}</td>
                    <td className="align-middle">{owner?.email}</td>
                    <td className="align-middle">{owner?.stores?.length}</td>
                    <td className="align-middle">{owner?.email}</td>

                    <td className="align-middle">
                      <span
                        className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center cursor-pointer "
                        data-bs-toggle="dropdown"
                      >
                        <i className="bi bi-gear fs-9px fa-fw me-5px"></i> E/D
                      </span>

                      <div className="dropdown-menu">
                        {/* <a className="dropdown-item" href="#/">
                      Edit
                    </a> */}
                        <a className="dropdown-item" href="#/">
                          {t("Delete")}
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
        </div>
      )}
        {isModalOpen && (
          <AddClientModal isModalOpen={isModalOpen} closeModal={closeModal} />
        )}
    </div>
  );
};

export default Clients;

import React, { useEffect, useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PerfectScrollbar from "react-perfect-scrollbar";
import ReactQuill from "react-quill";
import { AppSettings } from "./../../config/app-settings.js";
import { Link } from "react-router-dom";
import { Card } from "./../../components/card/card.jsx";
import Select from "react-select";
import "react-quill/dist/quill.snow.css";
import { useAllCustomerGetQuery } from "../../redux/api/customer/customerApi.js";
import Loader from "../../components/loader/Loader.jsx";
import { useSmsCreateMutation } from "../../redux/api/sms/smsApi.js";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const parser = new DOMParser();

function EmailCompose() {
  const store= useSelector((state) => state.store)
  const context = useContext(AppSettings);
  //   const [selectedCustomers, setSelectedCustomers] = useState([]);
const filter ={
  storeId: store.storeId,
}
  const { data, isLoading } = useAllCustomerGetQuery(filter,{
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    context.setAppContentFullHeight(true);
    context.setAppContentClass("p-3");

    return function cleanUp() {
      context.setAppContentFullHeight(false);
      context.setAppContentClass("");
    };

    // eslint-disable-next-line
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "theme-focused-background"
        : "theme-default-background",
      borderColor: state.isFocused
        ? "theme-focused-border"
        : "theme-default-border",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "white",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "theme-option-background"
        : "theme-selected-background",
      color: "black",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "theme-single-value-text",
    }),
    input: (provided) => ({
      ...provided,
      color: "theme-search-text-color",
    }),
  };

  const validationSchema = Yup.object().shape({
    contacts: Yup.array().required("contacts field is required"),
    msg: Yup.string().required("msg field is required"),
  });

  const initialValues = {
    contacts: [],
    msg: "",
  };

  const [smsCreate] = useSmsCreateMutation();
  const { t } = useTranslation();
  const onSubmit = async (values) => {
    const doc = parser.parseFromString(values?.msg, "text/html");

    const plaintext = doc.body.innerText || "";
    const data = {
      contacts: values?.contacts,
      msg: plaintext,
      storeId: store.storeId
    };
    const res = await smsCreate(data);

    if (res?.data) {
      toast.success("Send Message Successfully");
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (!data) {
    return <div>No data available</div>;
  }

  const options = data?.data?.map((item) => ({
    value: item?.mobile,
    label: item?.name,
  }));

  const handleCustomerChange = (selectedOption) => {
    const valuesArray = selectedOption?.map((option) => option.value) || [];

    formik.setFieldValue("contacts", valuesArray);
    // formik.setFieldValue("contacts", selectedOption);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mailbox">
        <div className="mailbox-toolbar">
          <div className="mailbox-toolbar-item">
            <span className="mailbox-toolbar-text">
              {t("New")} {t("Message")}
            </span>
          </div>

          {/* <div className="mailbox-toolbar-item">
              <a href="#/" className="mailbox-toolbar-link">
                Attachment
              </a>
            </div> */}
          {/* <div className="mailbox-toolbar-item">
              <Link to="/email/inbox" className="mailbox-toolbar-link">
                Discard
              </Link>
            </div> */}
          {/* <div className="mailbox-toolbar-item dropdown">
              <a
                href="#/"
                className="mailbox-toolbar-link"
                data-bs-toggle="dropdown"
              >
                <i className="fa fa-ellipsis-h"></i>
              </a>
              <div className="dropdown-menu ms-n1">
                <a href="#/" className="dropdown-item">
                  Save draft
                </a>
                <a href="#/" className="dropdown-item">
                  Show From
                </a>
                <a href="#/" className="dropdown-item">
                  Check names
                </a>
                <a href="#/" className="dropdown-item">
                  Set importance
                </a>
                <a href="#/" className="dropdown-item">
                  Switch to plain text
                </a>
                <a href="#/" className="dropdown-item">
                  Check for accessibility issues
                </a>
              </div>
            </div>
            <div className="mailbox-toolbar-item ms-auto">
              <a href="#/" className="mailbox-toolbar-link">
                <i className="fa fa-redo fa-fw fs-12px me-1"></i> Undo
              </a>
            </div> */}
          <div className="mailbox-toolbar-item">
            <Link to="/dashboard" className="mailbox-toolbar-link">
              <i className="fa fa-times fa-fw"></i> {t("Cancel")}
            </Link>
          </div>
          <div className="mailbox-toolbar-item">
            <button type="submit" className="mailbox-toolbar-link active">
              {t("Send")}
            </button>
          </div>
        </div>
        <div className="mailbox-body">
          <div className="mailbox-content">
            <div className="mailbox-form h-100">
              <div className="mailbox">
                <div className="row mb-2 mt-2">
                  <label className="col-form-label w-50px  px-2 fw-500 text-lg-end">
                    {t("To")}:
                  </label>
                  <div className="col-md-11">
                    <Select
                      options={options}
                      placeholder="Enter Name"
                      isSearchable={true}
                      isMulti
                      styles={customStyles}
                      onChange={handleCustomerChange}
                    />
                    {formik.touched.contacts && formik.errors.contacts ? (
                      <div className="text-danger">
                        {formik.errors.contacts}
                      </div>
                    ) : null}
                  </div>
                </div>

                <ReactQuill
                  theme="snow"
                  className="h-50"
                  value={formik.values.msg}
                  onChange={(value) => formik.setFieldValue("msg", value)}
                  modules={{ toolbar: false }}
                  formats={null}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default EmailCompose;

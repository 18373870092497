import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Loader from "../../components/loader/Loader.jsx";
import {
  useGetCurrencyQuery,
  useGetSingleSettingsQuery,
  useUpdateSettingsMutation,
} from "../../redux/api/currency/currencyApi.js";
import ReactSelect from "../helpers/ReactSelect.jsx";
import { Card } from "./../../components/card/card.jsx";
import { NavScrollTo } from "./../../components/nav-scroll-to/nav-scroll-to.jsx";
import AddCurrencyModal from "./currency/AddCurrencyModal.js";
import { useCreateStoreMutation } from "../../redux/api/store/storeApi.js";
import { useTranslation } from "react-i18next";
function Settings() {
  const role = localStorage.getItem("role");
  const { t } = useTranslation();
  const store = useSelector((state) => state.store);
  const [selectedCurrencyId, setSelectedCurrencyId] = useState({
    label: "Choose a Currency",
    value: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const { data } = useGetCurrencyQuery();
  const [rightNav, setRightNav] = useState("currency");
  const { data: settings, refetch } = useGetSingleSettingsQuery(store.storeId, {
    refetchOnMountOrArgChange: true,
  });
  const [updateSettings] = useUpdateSettingsMutation();
  const [createStore] = useCreateStoreMutation();
  const settingsData = settings?.data;
  const initialValues = {
    shopName: settingsData?.shopName,
    email: settingsData?.email,
    phone: settingsData?.phone,
    address: settingsData?.address,
  };
  const createStoreInitialValues = {
    shopName: "",
    email: "",
    phone: "",
    address: "",
    currencyId: "1",
  };
  const options = data?.data?.map((item) => ({
    value: item?.id,
    label: `${item?.value} ${item?.label}`,
  }));

  useEffect(() => {
    refetch();
  }, [refetch, store.storeId]);
  const handleChange = async (values) => {
    const data = {
      id: settingsData?.id,
      currency: {
        currencyId: values.value,
      },
    };
    const res = await updateSettings(data);
    if (res?.data?.success) {
      toast.success(res?.data?.message);
    }
  };
  const handleSubmit = async (values, { resetForm }) => {
    setIsAddingLoading(true);
    console.log(values, "shop details here");
    const data = {
      id: settingsData?.id,
      currency: { ...values },
    };

    const res = await updateSettings(data);
    if (res?.data?.success === true) {
      toast.success(res.data.message);
    }
    resetForm(initialValues);
    setIsAddingLoading(false);
  };
  const handleCreateSubmit = async (values, { resetForm }) => {
    setIsAddingLoading(true);
    const data = {
      ...values,
      currencyId: selectedCurrencyId?.value,
    };

    const res = await createStore(data);
    if (res?.data?.success === true) {
      toast.success(res.data.message);
    }
    resetForm();
    setIsAddingLoading(false);
  };

  if (!settingsData) {
    return <Loader />;
  }

  return (
    <div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="row">
              <div className="col-xl-9">
                <div
                  className={`mb-5 ${
                    rightNav === "currency" ? "d-block" : "d-none"
                  } p-3 `}
                >
                  <h4>
                    <i className="bi bi-cash-coin text-theme"></i>{" "}
                    {t("Currency")}
                  </h4>
                  <p>{t("Add and Set Currency")}.</p>
                  <Card className="p-3">
                    <div className="list-group list-group-flush">
                      <div className="d-flex justify-content-between">
                        <span className="d-flex align-items-center gap-3">
                          <p>{t("Current Currency")}:</p>{" "}
                          <p>
                            {" "}
                            {settingsData?.Currency?.label}(
                            {settingsData?.Currency?.value})
                          </p>
                        </span>
                        <div>
                          <button
                            onClick={() => setIsModalOpen(true)}
                            className="btn btn-outline-theme"
                          >
                            <i className="fa fa-plus-circle me-1"></i>{" "}
                            {t("Add new currency")}
                          </button>
                        </div>
                      </div>
                      <div>
                        <label className="py-2">{t("Change Currency")}:</label>
                        <div className="flex-1 text-break">
                          <div className="text-inverse text-opacity-50">
                            <ReactSelect
                              handleChange={handleChange}
                              options={options}
                              placeHolder={t("Select currency")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
                <div
                  className={`mb-5 ${
                    rightNav === "shop" ? "d-block" : "d-none"
                  } p-3 `}
                >
                  <h4>
                    <i className="bi bi-cash-coin text-theme"></i> {t("Shop Details")}
                  </h4>
                  <p>{t("Update your shop details")}.</p>
                  <Card className="p-3">
                    <div>
                      <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                      >
                        <Form>
                          <div className="d-flex justify-content-center gap-2 ">
                            <div className="form-group col-md-12 ">
                              <label className="py-2" htmlFor="shopName">
                               {t("Shop Name")}
                              </label>
                              <Field
                                type="text"
                                name="shopName"
                                className="form-control"
                                placeholder="Enter name..."
                              />
                            </div>
                          </div>
                          <div className="form-group col-md-12 ">
                            <label className="py-2" htmlFor="address">
                              {t("Shop Address")}
                            </label>
                            <Field
                              name="address"
                              placeholder="Enter address..."
                              className="form-control"
                            />
                          </div>
                          <div className="form-group col-md-12 ">
                            <label className="py-2" htmlFor="email">
                              {t("Email")}
                            </label>
                            <Field
                              name="email"
                              placeholder="Enter email..."
                              className="form-control"
                            />
                          </div>
                          <div className="form-group col-md-12 ">
                            <label className="py-2" htmlFor="phone">
                              {t("Phone")}
                            </label>
                            <Field
                              name="phone"
                              placeholder="Enter phone..."
                              className="form-control"
                            />
                          </div>
                          <div className="d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn btn-outline-theme mt-3"
                              disabled={isAddingLoading}
                            >
                              {isAddingLoading ? (
                                <span
                                  class="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                t("Update")
                              )}
                            </button>
                          </div>
                        </Form>
                      </Formik>
                    </div>
                  </Card>
                </div>
                <div
                  className={`mb-5 ${
                    rightNav === "add-shop" ? "d-block" : "d-none"
                  } p-3 `}
                >
                  <h4>
                    <i className="bi bi-cash-coin text-theme"></i> {t("Add Shop")}
                  </h4>
                  <p>{t("Create New Shop")}</p>
                  <Card className="p-3">
                    <div>
                      <Formik
                        initialValues={createStoreInitialValues}
                        onSubmit={handleCreateSubmit}
                      >
                        <Form>
                          <div className="d-flex justify-content-center gap-2 ">
                            <div className="form-group col-md-12 ">
                              <label className="py-2" htmlFor="shopName">
                               {t("Shop Name")}
                              </label>
                              <Field
                                type="text"
                                name="shopName"
                                className="form-control"
                                placeholder="Enter name..."
                              />
                            </div>
                          </div>
                          <div className="form-group col-md-12 ">
                            <label className="py-2" htmlFor="address">
                              {t("Shop Address")}
                            </label>
                            <Field
                              name="address"
                              placeholder="Enter address..."
                              className="form-control"
                            />
                          </div>
                          <div className="form-group col-md-12 ">
                            <label className="py-2" htmlFor="email">
                              {t("Email")}
                            </label>
                            <Field
                              name="email"
                              placeholder="Enter email..."
                              className="form-control"
                            />
                          </div>
                          <div className="form-group col-md-12 ">
                            <label className="py-2" htmlFor="phone">
                              {t("Phone")}
                            </label>
                            <Field
                              name="phone"
                              placeholder="Enter phone..."
                              className="form-control"
                            />
                          </div>
                          <div>
                            <label className="py-2">{t("Change Currency")}:</label>
                            <div className="flex-1 text-break">
                              <div className="text-inverse text-opacity-50">
                                <ReactSelect
                                  handleChange={(e) => {
                                    setSelectedCurrencyId(e);
                                  }}
                                  options={options}
                                  placeHolder="Select currency"
                                  selectedOption={selectedCurrencyId}
                                />
                              </div>
                            </div>
                          </div>
                          {role === "ADMIN" && (
                            <div className="d-flex justify-content-end">
                              <button
                                type="submit"
                                className="btn btn-outline-theme mt-3"
                                disabled={isAddingLoading}
                              >
                                {isAddingLoading ? (
                                  <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                ) : (
                                  t("Save")
                                )}
                              </button>
                            </div>
                          )}
                        </Form>
                      </Formik>
                    </div>
                  </Card>
                </div>
              </div>

              <div className="col-xl-3">
                <NavScrollTo>
                  <nav className="nav">
                    <button
                      onClick={() => setRightNav("currency")}
                      className={`nav-link ${
                        rightNav === "currency" ? "active" : ""
                      }`}
                    >
                      {t("Currency")}
                    </button>
                    <button
                      onClick={() => setRightNav("shop")}
                      className={`nav-link ${
                        rightNav === "shop" ? "active" : ""
                      }`}
                    >
                      {t("Shop Details")}
                    </button>
                    {role === "ADMIN" && (
                      <button
                        onClick={() => setRightNav("add-shop")}
                        className={`nav-link ${
                          rightNav === "add-shop" ? "active" : ""
                        }`}
                      >
                        {t("Add Shop")}
                      </button>
                    )}
                  </nav>
                </NavScrollTo>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        <AddCurrencyModal
          closeModal={() => setIsModalOpen(false)}
          isModalOpen={isModalOpen}
        />
      }
    </div>
  );
}

export default Settings;

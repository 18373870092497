import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Modal from "react-bootstrap/Modal";

import * as Yup from "yup";

import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useCreateClientsMutation } from "../../redux/api/auth/loginApi";
const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string().required("Email is required"),
  storeEmail: Yup.string().required("Store email is required"),
  storeName: Yup.string().required("Store name is required"),
  storePhone: Yup.string().required("Store phone is required"),

});

const AddClientModal = ({ closeModal, isModalOpen }) => {
  const { t } = useTranslation();
  const [createClients] = useCreateClientsMutation();
  const handleSubmit = async (values) => {
    const data = {
      name: values.name,
      email: values.email,
      password: "123456",
      storeName: values.storeName,
      storeEmail: values.storeEmail,
      storePhone: values.storePhone,
    };
    console.log(data)
    const res = await createClients(data);
    if (res?.data?.success === true) {
      toast.success(res.data.message);
    }
    closeModal();
  };

  return (
    <Modal show={isModalOpen} onHide={closeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add New Client</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-2">
        <Formik
          initialValues={{
            name: "",
            email: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="d-flex justify-content-center gap-2 py-3  ">
              <div className="form-group col-md-12 ">
                <label htmlFor="name">User Name</label>
                <Field
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Enter name..."
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            <div className="form-group col-md-12 ">
              <label htmlFor="address">{t("Email")}</label>
              <Field
                name="email"
                placeholder="Enter email..."
                className="form-control"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group col-md-12 mt-3">
              <label htmlFor="address">Store Name</label>
              <Field
                name="storeName"
                placeholder="Enter store name..."
                className="form-control"
              />
              <ErrorMessage
                name="storeName"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group col-md-12 mt-3">
              <label htmlFor="address">Store Email</label>
              <Field
                name="storeEmail"
                placeholder="Enter store email..."
                className="form-control"
              />
              <ErrorMessage
                name="storeEmail"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group col-md-12 mt-3 ">
              <label htmlFor="address">Store Phone</label>
              <Field
                name="storePhone"
                placeholder="Enter store name..."
                className="form-control"
              />
              <ErrorMessage
                name="storePhone"
                component="div"
                className="text-danger"
              />
            </div>

            <div className="modal-footer mt-3">
              <button
                className="btn btn-outline-default"
                onClick={() => closeModal()}
              >
                {t("Cancel")}
              </button>

              <button type="submit" className="btn btn-info">
                <i className="fa fa-save px-2"></i>
                Add
              </button>
            </div>
          </Form>
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddClientModal;

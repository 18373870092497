import React, {useState} from "react";
import {Link} from "react-router-dom";
import AddBalanceModel from "./AddBalanceModel";
import WithdrawModel from "./WithdrawModel";
import TransferModel from "./TransferModel";

const Accounts = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [isTransferodalOpen, setIsTransferModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openWithdrawModal = () => {
    setIsWithdrawModalOpen(true);
  };

  const closeWithdrawModal = () => {
    setIsWithdrawModalOpen(false);
  };
  const openTransferModal = () => {
    setIsTransferModalOpen(true);
  };

  const closeTransferModal = () => {
    setIsTransferModalOpen(false);
  };
  return (
    <div>
      <div className="table-responsive  mt-4">
        <table className="table table-hover text-nowrap">
          <thead>
            <tr>
              <th className="border-top-0 pt-0 pb-2"></th>
              <th className="border-top-0 pt-0 pb-2">#</th>
              <th className="border-top-0 pt-0 pb-2">Name</th>
              <th className="border-top-0 pt-0 pb-2">Opening Balance</th>
              <th className="border-top-0 pt-0 pb-2">Current Balance</th>

              <th className="border-top-0 pt-0 pb-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="w-10px align-middle">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="product1"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="product1"></label>
                </div>
              </td>
              <td className="align-middle">
                <Link to="/pages/order-details">#195</Link>
              </td>
              <td className="align-middle">CASH</td>

              <td className="py-1 align-middle">
                <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                  TK - 14124
                </span>
              </td>
              <td className="align-middle">
                <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                  TK - 44444
                </span>
              </td>
              <td className="align-middle">
                <span
                  className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center cursor-pointer "
                  data-bs-toggle="dropdown">
                  <i className="bi bi-gear fs-9px fa-fw me-5px"></i> E/D
                </span>

                <div className="dropdown-menu">
                  <a className="dropdown-item" href="#/">
                    Add Balance
                  </a>
                  <a className="dropdown-item" href="#/">
                    Withdraw Balance
                  </a>
                  <a className="dropdown-item" href="#/">
                    Transfer
                  </a>
                  <a className="dropdown-item" href="#/">
                    History
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td className="w-10px align-middle">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="product2"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="product2"></label>
                </div>
              </td>
              <td className="align-middle">
                <Link to="/pages/order-details">#1949</Link>
              </td>
              <td className="align-middle">Habib</td>

              <td className="py-1 align-middle">
                <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                  TK - 411141
                </span>
              </td>
              <td className="align-middle">
                <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ">
                  TK - 445141
                </span>
              </td>
              <td className="align-middle">
                <span
                  className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center cursor-pointer "
                  data-bs-toggle="dropdown">
                  <i className="bi bi-gear fs-9px fa-fw me-5px"></i> E/D
                </span>

                <div className="dropdown-menu">
                  <button
                    className="dropdown-item btn btn-outline-theme"
                    onClick={openModal}>
                    Add Balance
                  </button>
                  <button
                    className="dropdown-item btn btn-outline-theme"
                    onClick={openWithdrawModal}>
                    Withdraw Balance
                  </button>
                  <button
                    className="dropdown-item btn btn-outline-theme"
                    onClick={openTransferModal}>
                    Transfer
                  </button>
                  <a className="dropdown-item" href="#/">
                    History
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="d-md-flex align-items-center">
        <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
          Showing 1 to 10 of 57 entries
        </div>
        <ul className="pagination mb-0 justify-content-center">
          <li className="page-item disabled">
            <a className="page-link" href="#/">
              Previous
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#/">
              1
            </a>
          </li>
          <li className="page-item active">
            <a className="page-link" href="#/">
              2
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#/">
              3
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#/">
              4
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#/">
              5
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#/">
              6
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#/">
              Next
            </a>
          </li>
        </ul>
      </div>

      {isModalOpen && (
        <AddBalanceModel isModalOpen={isModalOpen} closeModal={closeModal} />
      )}

      {isWithdrawModalOpen && (
        <WithdrawModel
          isWithdrawModalOpen={isWithdrawModalOpen}
          closeWithdrawModal={closeWithdrawModal}
        />
      )}

      {isTransferodalOpen && (
        <TransferModel
          isTransferodalOpen={isTransferodalOpen}
          closeTransferModal={closeTransferModal}
        />
      )}
    </div>
  );
};

export default Accounts;

import React, { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Barcode from "react-barcode";
import ReactToPrint from "react-to-print";
import { useTranslation } from "react-i18next";

const ViewBarcodeModal = ({ closeModal, item, show }) => {
  const { t } = useTranslation();
  const myRefs = useRef();
  const data = 20;


  return (
    <Modal show={show} onHide={closeModal} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{t("Barcode")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <div className="d-flex gap-2 flex-wrap" ref={myRefs}>
            {[...Array(data)].map((_, index) => (
              <div className="">
                <Barcode
                  key={index}
                  height={40}
                  width={2}
                  value={item?.productCode}
                />
              </div>
            ))}
          </div>

          <ReactToPrint
            trigger={() => (
              <button className="dropdown-item mt-2 text-primary">
                <i className="bi bi-printer" style={{ marginRight: "5px" }}></i>
                {t("Print Barcode")}
              </button>
            )}
            content={() => myRefs.current}
          />
         
        </div>
        
      </Modal.Body>
    </Modal>
  );
};

export default ViewBarcodeModal;

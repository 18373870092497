import React, {useEffect, useRef, useState} from "react";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {useAllSupplierGetQuery} from "../../redux/api/supplier/supplierApi";
import {Link} from "react-router-dom";
import Loader from "../../components/loader/Loader";
import Currency from "../../Shared/Currency";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const SupplierDueReport = () => {
  const store = useSelector((state) => state.store);
  const {t} = useTranslation();
  const tableRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [name, setName] = useState();
  const [nameFieldValue, setNameFieldValue] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({
    limit: pageSize,
    storeId: store.storeId,
  });
  const {data, isLoading, refetch} = useAllSupplierGetQuery(filter, {
    refetchOnMountOrArgChange: true,
  });
  const initialData = data?.data;
  useEffect(() => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      storeId: store.storeId,
    }));
  }, [store.storeId]);
  useEffect(() => {
    const updatedFilter = {...filter};
    updatedFilter.page = page;
    if (name) {
      updatedFilter.name = name;
    }
    if (searchTerm) {
      updatedFilter.searchTerm = searchTerm;
    }
    if (!searchTerm) {
      delete updatedFilter.searchTerm;
    }
    if (!nameFieldValue) {
      delete updatedFilter.name;
    }
    setFilter(updatedFilter);
    refetch();
  }, [name, refetch, searchTerm, page]);

  const handleFilterChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePrint = () => {
    const printWindow = window.open("Supplier Due Report", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title> ${t("Supplier Due Report")}</title>
          <style>
            body {
              font-family: 'Arial', sans-serif;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              border: 1px solid #ddd;
              padding: 10px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
            @media print {
              header::before {
                content: "Sales List";
                display: block;
              
              }
              table {
                font-size: 12px;
              }
            }
          </style>
        </head>
        <body>
       <h1 style={{ width: '100%', textAlign: 'center' }}>${t("Supplier Due Report")}</h1>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
          <thead>
          <tr className="text-center">
          <th>${t("Serial")}</th>
          <th>${t("Name")}</th>
          <th>${t("Email")}</th>
          <th>${t("Phone")}</th>
          <th>${t("Address")}</th>
          <th>
            ${t("total")} ${t("Due")}
          </th>
        </tr>
          </thead>
          ${initialData?.map((item, index) => {
            return `
            <tr key=${index}>
            <td className="align-middle  ">
              <Link>${index + 1}</Link>
            </td>
            <td className="align-middle">${item?.name}</td>
            <td className="align-middle">${item?.email}</td>
            <td className="align-middle">${item?.mobile}</td>

            <td className="align-middle">${item?.address}</td>

            <td className="align-middle">
              <span
                className=${`badge border 
                ${
                  item?.totalDue > 0
                    ? "border-danger text-danger"
                    : "border-success text-success"
                } 
                  px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center`}>
                ${Currency} : ${item?.totalDue}
              </span>
            </td>
          </tr>
            `;
          })}
        </table>
        </body>

     
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };
  return (
    <div>
      <div>
        <h1 className="page-header">{t("Supplier Due Report")}</h1>
        <hr className="mb-2" />
        <div className="d-flex justify-content-between">
          <div className="input-group mb-2 mt-2">
            <div className=" position-relative">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control px-35px"
                  placeholder="Filter Supplier"
                  value={searchTerm}
                  onChange={handleFilterChange}
                />
                <div
                  className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
                  style={{zIndex: 1020}}>
                  <i className="fa fa-search opacity-5"></i>
                </div>
              </div>
            </div>
          </div>
          <div className=" mt-4">
            <div>
              <button
                onClick={handlePrint}
                style={{width: "120px", height: "35px"}}
                className="btn btn-outline-theme">
                <i class="bi bi-printer pr-2" style={{marginRight: "5px"}}></i>
                {t("Print")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="data-management table-responsive mt-3" data-id="table">
        {isLoading ? (
          <Loader />
        ) : (
          <table
    
            id="datatable"
            className="table table-bordered table-xs w-100 fw-semibold text-nowrap mb-3">
            <thead>
              <tr className="text-center">
                <th>{t("Serial")}</th>
                <th>{t("Name")}</th>
                <th>{t("Email")}</th>
                <th>{t("Phone")}</th>
                <th>{t("Address")}</th>
                <th>
                  {t("total")} {t("Due")}
                </th>
              </tr>
            </thead>
            <tbody className="text-body text-center">
              {initialData?.map((item, index) => (
                <tr key={index}>
                  <td className="align-middle  ">
                    <Link>{index + 1}</Link>
                  </td>
                  <td className="align-middle">{item?.name}</td>
                  <td className="align-middle">{item?.email}</td>
                  <td className="align-middle">{item?.mobile}</td>

                  <td className="align-middle">{item?.address}</td>

                  <td className="align-middle">
                    <span
                      className={`badge border 
                      ${
                        item?.totalDue > 0
                          ? "border-danger text-danger"
                          : "border-success text-success"
                      } 
                        px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center`}>
                      {Currency} : {item?.totalDue}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default SupplierDueReport;

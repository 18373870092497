/* eslint-disable no-sequences */
import {baseApi} from "../baseApi";

export const brandsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    brandCreate: build.mutation({
      query: (brandData) => ({
        url: "/brand",
        method: "POST",
        data: brandData,
      }),
      invalidatesTags: ["brand"],
    }),

    allBrandGet: build.query({
      query: (params) => ({
        url: "/brand",
        method: "GET",
        params,
      }),
      providesTags: ["brand"],
    }),
    singleBrandGet: build.query({
      query: ({id,params}) => ({
        url: `/brand/${id}`,
        method: "GET",
        params
      }),
      providesTags: ["brand"],
    }),

    updateBrand: build.mutation({
      query: ({id, values}) => ({
        url: `/brand/${id}`,
        method: "PATCH",
        data: values,
      }),
      invalidatesTags: ["brand"],
    }),
    deleteBrand: build.mutation({
      query: ({id,storeId}) => ({
        url: `/brand/${id}`,
        method: "DELETE",
        data:{storeId:storeId},
      }),
      invalidatesTags: ["brand"],
    }),
  }),
});

export const {
  useBrandCreateMutation,
  useAllBrandGetQuery,
  useSingleBrandGetQuery,
  useUpdateBrandMutation,
  useDeleteBrandMutation,
} = brandsApi;

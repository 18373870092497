import React, {useEffect, useRef} from "react";

import DatepickerHelper from "../helpers/DatepickerHelper";
import {useDailyReportQuery} from "../../redux/api/reports/reportsApi";
import {useState} from "react";
import moment from "moment";
import ReactToPrint from "react-to-print";
import Pagination from "../../utils/Pagination";
import Loader from "../../components/loader/Loader";
import Currency from "../../Shared/Currency";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const DailyReport = () => {
  const store = useSelector((state) => state.store);
  const {t} = useTranslation();
  const [startDate, setStartDate] = useState();
  const [startDateFieldValue, setStartDateFieldValue] = useState();
  const [endDate, setEndDate] = useState();
  const [endDateFieldValue, setEndDateFieldValue] = useState();
  const [totalSales, setTotalSales] = useState();
  const [totalPurchases, setTotalPurchases] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [totalReturnedAmount, setTotalReturnedAmount] = useState(0);
  const [totalGrossProfit, setTotalGrossProfit] = useState(0);
  const [totalNetProfit, setTotalNetProfit] = useState(0);
  const [skip, setSkip] = useState(true);
  const [page, setPage] = useState(1);
  const [isPrinting, setIsPrinting] = useState(false);

  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({
    limit: pageSize,
    storeId: store.storeId,
  });

  const {data, isLoading} = useDailyReportQuery(filter, {
    skip,
    refetchOnMountOrArgChange: true,
  });
  const initialDailyReportData = data?.data?.data;

  const meta = data?.data?.meta;

  const tableRef = useRef(null);
  const handleEndDateChange = (date) => {
    setEndDateFieldValue(date);
  };
  const handleStartDateChange = (date) => {
    setStartDateFieldValue(date);
  };

  const handleSubmit = () => {
    if (!endDateFieldValue || !startDateFieldValue) {
      toast.error("Start date and end date is required");
    } else {
      const formattedStartDate = startDateFieldValue
        ? moment(startDateFieldValue).format("YYYY-MM-DD")
        : "";
      const formattedEndDate = endDateFieldValue
        ? moment(endDateFieldValue).format("YYYY-MM-DD")
        : "";

      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
    }
  };
  useEffect(() => {
    const updatedFilter = {...filter};
    updatedFilter.page = page;

    if (!startDateFieldValue) {
      delete updatedFilter.startDate;
    }
    if (startDate) {
      updatedFilter.startDate = startDate;
    }
    if (!endDateFieldValue) {
      delete updatedFilter.endDate;
    }
    if (endDate) {
      updatedFilter.endDate = endDate;
    }

    setFilter(updatedFilter);

    if (startDate && endDate) {
      setSkip(false);
    }
  }, [page, startDateFieldValue, endDate, endDateFieldValue]);

  useEffect(() => {
    let calculatedTotalSales = 0;

    initialDailyReportData?.forEach((item) => {
      calculatedTotalSales += item?.totalSales || 0;
    });

    setTotalSales(calculatedTotalSales);
    let calculatedTotalPurchases = 0;
    initialDailyReportData?.forEach((item) => {
      calculatedTotalPurchases += item?.totalPurchases || 0;
    });
    setTotalPurchases(calculatedTotalPurchases);

    let calculatedTotalExpenses = 0;
    initialDailyReportData?.forEach((item) => {
      calculatedTotalExpenses += item?.totalExpenses || 0;
    });
    setTotalExpenses(calculatedTotalExpenses);
    let calculatedTotalReturnedAmount = 0;
    initialDailyReportData?.forEach((item) => {
      calculatedTotalReturnedAmount += item?.totalReturnedAmount || 0;
    });
    setTotalReturnedAmount(calculatedTotalReturnedAmount);
    let calculatedTotalGrossProfit = 0;
    initialDailyReportData?.forEach((item) => {
      calculatedTotalGrossProfit += item?.grossProfit || 0;
    });
    setTotalGrossProfit(calculatedTotalGrossProfit);
    let calculatedTotalNetProfit = 0;
    initialDailyReportData?.forEach((item) => {
      calculatedTotalNetProfit += item?.netProfit || 0;
    });
    setTotalNetProfit(calculatedTotalNetProfit);
  }, [initialDailyReportData]);

  useEffect(() => {
    const mediaQueryList = window.matchMedia("print");

    const handleMediaQueryChange = (event) => {
      setIsPrinting(event.matches);
    };

    mediaQueryList.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQueryList.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  const handlePrint = () => {
    const printWindow = window.open("Daily Report", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title> ${t("Daily Report")}</title>
          <style>
            body {
              font-family: 'Arial', sans-serif;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              border: 1px solid #ddd;
              padding: 10px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
            @media print {
              header::before {
                content: "Sales List";
                display: block;
              
              }
              table {
                font-size: 12px;
              }
            }
          </style>
        </head>
        <body>
       <h1 style={{ width: '100%', textAlign: 'center' }}> ${t(
         "Daily Report"
       )}</h1>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
          <thead>
          <tr className="text-center">
          <th>${t("Serial")}</th>
          <th>${t("Date")}</th>
          <th>
            ${t("sale")} ${t("Amount")}
          </th>
          <th>
            ${t("purchases")} ${t("Amount")}
          </th>
          <th>${t("Expense")}</th>
          <th>${t("Returned")}</th>
          <th>${t("Gross Profit")}</th>
          <th>${t("Net Profit")}</th>
        </tr>
          </thead>
          ${initialDailyReportData?.map((item, index) => {
            return `
            <tr>
            <td>${index + 1}</td>
            <td>
              
              ${moment(item?.transactionDate).format("DD MMMM YYYY")}
            </td>
            <td>
              ${Currency} : ${item?.totalSales}
            </td>
            <td className="text-success">
              ${Currency} : ${item?.totalPurchases}
            </td>
            <td>
              ${Currency}: ${item?.totalExpenses}
            </td>
            <td>
              ${Currency} : ${item?.totalReturnedAmount}
            </td>
            <td>
             
              ${Currency} : ${item?.grossProfit}
            </td>

            <td className="text-success">
           
              ${Currency} : ${item?.netProfit}
            </td>
          </tr>
            `;
          })}

          <tfoot>
          <tr className="text-center">
            <th></th>
            <th className="text-success">${t("Total")}</th>
            <th className="text-success">
            
              ${Currency} ${totalSales}
            </th>
            <th className="text-success">
       
              ${Currency} ${totalPurchases}
            </th>
            <th className="text-success">
          
              ${Currency} ${totalExpenses}
            </th>
            <th className="text-success">
           
              ${Currency} ${totalReturnedAmount}
            </th>
            <th className="text-success">
            
              ${Currency} ${totalGrossProfit}
            </th>
            <th className="text-success">
            
              ${Currency} ${totalNetProfit}
            </th>
          </tr>
        </tfoot>
        </table>
        </body>

     
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div>
      <div>
        <h1 className="page-header">{t("Daily Report")}</h1>
        <hr className="mb-4" />

        <div className="d-flex flex-wrap gap-2">
          <div className="">
            <DatepickerHelper
              handleDateChange={handleStartDateChange}
              selectedDate={startDateFieldValue}
              placeHolder="Select start date"
            />
          </div>
          <div className=" ">
            <DatepickerHelper
              handleDateChange={handleEndDateChange}
              selectedDate={endDateFieldValue}
              placeHolder="Select end date"
            />
          </div>
          <div className="form-group ">
            <button
              onClick={handleSubmit}
              className="btn btn-primary"
              type="submit">
              <i className="fa fa-sliders"></i> {t("Filter")}
            </button>
          </div>
          <div className="form-group ">
            <button
              className="btn btn-info"
              type="button"
              onClick={() => {
                setStartDate("");
                setStartDateFieldValue("");
                setEndDate("");
                setEndDateFieldValue("");
                setSkip(true);
                const updatedFilter = {...filter};

                delete updatedFilter.startDate;
                delete updatedFilter.endDate;
                setFilter(updatedFilter);
              }}>
              {t("Reset")}
            </button>
          </div>

          <button
            style={{width: "120px", height: "35px"}}
            className="btn btn-outline-theme"
            onClick={handlePrint}>
            <i className="bi bi-printer pr-2" style={{marginRight: "5px"}}></i>
            {t("Print")}
          </button>
        </div>
      </div>

      <div className="data-management table-responsive mt-5" data-id="table">
        {isLoading ? (
          <Loader />
        ) : (
          <table
            ref={tableRef}
            id="datatable"
            className="table table-bordered table-xs w-100 fw-semibold text-nowrap mb-3">
            <thead>
              <tr className="text-center">
                <th>{t("Serial")}</th>
                <th>{t("Date")}</th>
                <th>
                  {t("sale")} {t("Amount")}
                </th>
                <th>
                  {t("purchases")} {t("Amount")}
                </th>
                <th>{t("Expense")}</th>
                <th>{t("Returned")}</th>
                <th>{t("Gross Profit")}</th>
                <th>{t("Net Profit")}</th>
              </tr>
            </thead>
            <tbody className="text-body text-center">
              {initialDailyReportData?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {" "}
                    {moment(item?.transactionDate).format("DD MMMM YYYY")}
                  </td>
                  <td>
                    {Currency} : {item?.totalSales}
                  </td>
                  <td className="text-success">
                    {Currency} : {item?.totalPurchases}
                  </td>
                  <td>
                    {Currency}: {item?.totalExpenses}
                  </td>
                  <td>
                    {Currency} : {item?.totalReturnedAmount}
                  </td>
                  <td>
                    {" "}
                    {Currency} : {item?.grossProfit}
                  </td>

                  <td className="text-success">
                    {" "}
                    {Currency} : {item?.netProfit}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr className="text-center">
                <th></th>
                <th className="text-success">{t("Total")}</th>
                <th className="text-success">
                  {" "}
                  {Currency} {totalSales}
                </th>
                <th className="text-success">
                  {" "}
                  {Currency} {totalPurchases}
                </th>
                <th className="text-success">
                  {" "}
                  {Currency} {totalExpenses}
                </th>
                <th className="text-success">
                  {" "}
                  {Currency} {totalReturnedAmount}
                </th>
                <th className="text-success">
                  {" "}
                  {Currency} {totalGrossProfit}
                </th>
                <th className="text-success">
                  {" "}
                  {Currency} {totalNetProfit}
                </th>
              </tr>
            </tfoot>
          </table>
        )}
      </div>

      <Pagination
        page={page}
        setPage={setPage}
        meta={meta}
        pageSize={pageSize}
      />
    </div>
  );
};

export default DailyReport;

/* eslint-disable no-sequences */
import {baseApi} from "../baseApi";

export const paymentTypeApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    allPaymentTypeApiGet: build.query({
      query: (params) => ({
        url: "/paymentType",
        method: "GET",
        params,
      }),
      providesTags: ["paymentType"],
    }),
  }),
});

export const {useAllPaymentTypeApiGetQuery} = paymentTypeApi;

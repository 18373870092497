import React, {useEffect, useState} from "react";

import moment from "moment";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Loader from "../../components/loader/Loader";
import {useAllCustomerGetQuery} from "../../redux/api/customer/customerApi";
import {useAllReturnsGetQuery} from "../../redux/api/return/returnApi";
import Pagination from "../../utils/Pagination";
import DatepickerHelper from "../helpers/DatepickerHelper";
import ReactSelect from "../helpers/ReactSelect";

const ReturnList = () => {
  const store = useSelector((state) => state.store);
  const [page, setPage] = useState(1);
  const [posId, setPosId] = useState();
  const [billFieldsValue, setPosFieldsValue] = useState();
  const [startDate, setStartDate] = useState();
  const [startDateFieldValue, setStartDateFieldValue] = useState();
  const [endDate, setEndDate] = useState();
  const [endDateFieldValue, setEndDateFieldValue] = useState();

  const [selectCustomer, setSelectCustomer] = useState();
  const [customerId, setCustomerId] = useState();
  const [pageSize, setPageSize] = useState(10);

  const [filter, setFilter] = useState({
    page,
    limit: pageSize,
    storeId: store.storeId,
  });

  const {data: customer} = useAllCustomerGetQuery(
    {storeId: store.storeId},
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const initialCustomerIdDataIdData = customer?.data;
  const customerOpt = initialCustomerIdDataIdData?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));
  const {data, isLoading, refetch} = useAllReturnsGetQuery(filter);

  const meta = data?.meta;
  const {t} = useTranslation();
  useEffect(() => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      storeId: store.storeId,
    }));
  }, [store.storeId]);
  useEffect(() => {
    const updatedFilter = {...filter};
    updatedFilter.page = page;
    if (posId) {
      updatedFilter.id = posId;
    }
    if (!startDateFieldValue) {
      delete updatedFilter.startDate;
    }
    if (startDate) {
      updatedFilter.startDate = startDate;
    }
    if (!endDateFieldValue) {
      delete updatedFilter.endDate;
    }
    if (endDate) {
      updatedFilter.endDate = endDate;
    }
    if (!selectCustomer) {
      delete updatedFilter.customerId;
    }
    if (customerId) {
      updatedFilter.customerId = customerId;
    }
    setFilter(updatedFilter);

    refetch();
  }, [
    customerId,
    posId,
    page,
    setStartDate,
    startDateFieldValue,
    endDate,
    endDateFieldValue,
    startDate,
    selectCustomer,
  ]);

  const handleFilterChange = (e) => {
    setPosFieldsValue(e.target.value);
  };
  const handleChange = (values) => {
    setSelectCustomer(values.value);
  };
  const handleSubmit = () => {
    setCustomerId(selectCustomer);
    setStartDate(startDateFieldValue);
    setEndDate(endDateFieldValue);
    setPosId(billFieldsValue);
  };

  const handlePrint = () => {
    const printWindow = window.open("Return-List", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title> ${t("Returned")} ${t("List")}</title>
          <style>
            body {
              font-family: 'Arial', sans-serif;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              border: 1px solid #ddd;
              padding: 10px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
            @media print {
              header::before {
                content: "Sales List";
                display: block;
              
              }
              table {
                font-size: 12px;
              }
            }
          </style>
        </head>
        <body>
       <h1 style={{ width: '100%', textAlign: 'center' }}> ${t("Returned")} ${t("List")}</h1>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
          <thead>
          <tr>
         
          <th className="border-top-0 pt-0 pb-2">${t("Invoice")}</th>
          <th className="border-top-0 pt-0 pb-2">${t("customer")}</th>
          <th className="border-top-0 pt-0 pb-2">${t("product")}</th>
          <th className="border-top-0 pt-0 pb-2">
            ${t("sale")} ${t("Date")}
          </th>
          <th className="border-top-0 pt-0 pb-2">
            ${t("Returned")} ${t("Amount")}
          </th>
   
        </tr>
          </thead>
          ${data?.data?.map((returns) => {
            return `
            <tr>
     
            <td className="align-middle">${returns?.salesId}</td>
            <td className="align-middle">${returns?.customer?.name}</td>
            <td>
              ${returns?.products
                ?.map((product) => product?.productName)
                .join(", ")}
            </td>
            <td className="py-1 align-middle">
              <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                ${moment(returns?.createdAt).format("DD MMMM YYYY")}
              </span>
            </td>
            <td className="align-middle">
              <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                TK: ${returns?.totalReturnedAmount}
              </span>
            </td>
          </tr>
            `;
          })}
        </table>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };
  return (
    <div className="mb-2">
      <header className="header text-white">
        <div className="header-info">
          <h1 className="page-header">
            {t("Returned")} {t("List")}
          </h1>
          <hr className="mb-4" />
        </div>
      </header>

      <div className="row">
        <div className="col-md-3">
          <input
            type="text"
            className="form-control"
            placeholder="Invoice no"
            value={billFieldsValue}
            onChange={handleFilterChange}
          />
        </div>
        <div className="col-lg-2">
          <DatepickerHelper
            handleDateChange={(date) => setStartDateFieldValue(date)}
            selectedDate={startDateFieldValue}
            placeHolder="Select Start Date"
          />
        </div>
        <div className="col-lg-2">
          <DatepickerHelper
            handleDateChange={(date) => setEndDateFieldValue(date)}
            selectedDate={endDateFieldValue}
            placeHolder="Select End Date"
          />
        </div>
        <div className="col-lg-3 ">
          <ReactSelect
            placeHolder="Select Customer"
            options={customerOpt}
            handleChange={handleChange}
          />
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center mt-2">
        {" "}
        <div className="mt-2 d-flex gap-2">
          <div className="form-group">
            <button
              onClick={handleSubmit}
              className="btn btn-primary"
              type="submit">
              <i className="fa fa-sliders"></i> {t("Filter")}
            </button>
          </div>
          <div className="form-group">
            <button
              onClick={() => {
                setPosId("");
                setPosFieldsValue("");
                setStartDate("");
                setStartDateFieldValue("");
                setEndDate("");
                setEndDateFieldValue("");
                setCustomerId();
                setSelectCustomer();
                const updatedFilter = {...filter};
                delete updatedFilter.id;
                delete updatedFilter.startDate;
                delete updatedFilter.endDate;
                setFilter(updatedFilter);
                refetch();
              }}
              className="btn btn-info"
              type="reset">
              {t("Reset")}
            </button>
          </div>
        </div>
        <div>
          <button
            onClick={handlePrint}
            style={{width: "120px", height: "35px"}}
            className="btn btn-outline-theme">
            <i className="bi bi-printer pr-2" style={{marginRight: "5px"}}></i>
            {t("Print")}
          </button>
        </div>
      </div>

      <div className="my-4">
        <div className="input-group mb-4">
          <div className="dropdown-menu">
            <a className="dropdown-item" href="#/">
              {t("Delete")}
            </a>
          </div>
        </div>

        {isLoading ? (
          <Loader />
        ) : (
          <div className="table-responsive">
            <table className="table table-hover text-nowrap">
              <thead>
                <tr>
                  {/* <th className="border-top-0 pt-0 pb-2"></th> */}
                  {/* <th className="border-top-0 pt-0 pb-2">#</th> */}
                  <th className="border-top-0 pt-0 pb-2">{t("Invoice")}</th>
                  <th className="border-top-0 pt-0 pb-2">{t("customer")}</th>
                  <th className="border-top-0 pt-0 pb-2">{t("product")}</th>
                  <th className="border-top-0 pt-0 pb-2">
                    {t("sale")} {t("Date")}
                  </th>
                  <th className="border-top-0 pt-0 pb-2">
                    {t("Returned")} {t("Amount")}
                  </th>
                  <th className="border-top-0 pt-0 pb-2">{t("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {data?.data?.map((returns) => (
                  <tr>
                    {/* <td className="align-middle">
                  <Link to="/pages/order-details">#195</Link>
                </td> */}
                    <td className="align-middle">{returns?.salesId}</td>
                    <td className="align-middle">{returns?.customer?.name}</td>
                    <td>
                      {returns?.products
                        ?.map((product) => product?.productName)
                        .join(", ")}
                    </td>
                    <td className="py-1 align-middle">
                      <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                        {moment(returns?.createdAt).format("DD MMMM YYYY")}
                      </span>
                    </td>
                    <td className="align-middle">
                      <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                        TK: {returns?.totalReturnedAmount}
                      </span>
                    </td>

                    <td className="align-middle">
                      <span
                        className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center cursor-pointer "
                        data-bs-toggle="dropdown">
                        <i className="bi bi-gear fs-9px fa-fw me-5px"></i> E/D
                      </span>

                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="#/">
                          {t("Delete")}
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <Pagination
          page={page}
          setPage={setPage}
          meta={meta}
          pageSize={pageSize}
        />
      </div>
    </div>
  );
};

export default ReturnList;

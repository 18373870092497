import React, {useEffect, useState} from "react";

import ReactSelect from "../helpers/ReactSelect";
import {useAllProductGetQuery} from "../../redux/api/product/productApi";
import {useAllCategoryGetQuery} from "../../redux/api/categories/categoriesApi";
import Loader from "../../components/loader/Loader";
import {calculateProducuUnitSubUnit} from "../../utils/product";
import {useLowStockReportQuery} from "../../redux/api/reports/reportsApi";
import {Link} from "react-router-dom";
import Currency from "../../Shared/Currency";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
const LowStockReport = () => {
  const store = useSelector((state) => state.store);
  const {t} = useTranslation();
  const [category, setcategory] = useState();
  const [categoryFieldValue, setcategoryFieldValue] = useState();
  const [productName, setproductName] = useState();
  const [nameFieldValue, setNameFieldValue] = useState();
  const [productFieldValue, setProductFieldValue] = useState();
  const [productId, setProductId] = useState();
  const [productCode, setproductCode] = useState();
  const [productCodeFieldValue, setproductCodeFieldValue] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({
    limit: pageSize,
    storeId: store.storeId,
  });
  const {
    data: lowStockReport,
    isLoading: lowStockReportLoading,
    refetch: lowStockRefetch,
  } = useLowStockReportQuery(filter, {
    refetchOnMountOrArgChange: true,
  });
  const initialLowStockData = lowStockReport?.data;

  const {data, refetch} = useAllProductGetQuery(filter, {
    refetchOnMountOrArgChange: true,
  });
  const initialData = data?.data;
  const meta = lowStockReport?.meta;

  useEffect(() => {
    const updatedFilter = {...filter};
    updatedFilter.page = page;

    if (!productCodeFieldValue) {
      delete updatedFilter.productCode;
    }
    if (!nameFieldValue) {
      delete updatedFilter.productName;
    }
    if (!categoryFieldValue) {
      delete updatedFilter.category;
    }

    if (!productFieldValue) {
      delete updatedFilter.productId;
    }

    if (productId) {
      updatedFilter.productId = productId;
    }
    if (category) {
      updatedFilter.category = category;
    }
    if (productName) {
      updatedFilter.productName = productName;
    }
    if (productCode) {
      updatedFilter.productCode = productCode;
    }

    setFilter(updatedFilter);

    lowStockRefetch();
  }, [productName, page, productCode, category, productId]);
  const handleChange = (selectedOption) => {
    setcategoryFieldValue(selectedOption?.value);
  };

  const handleProductChange = (selectedOption) => {
    setProductFieldValue(selectedOption?.value);
  };
  const handleSubmit = () => {
    setcategory(categoryFieldValue);

    setproductCode(productCodeFieldValue);
    setproductName(nameFieldValue);
    setProductId(productFieldValue);
  };
  const categoryFilter = {
    storeId: store?.storeId,
  };
  const {data: categoryIdData} = useAllCategoryGetQuery(categoryFilter, {
    refetchOnMountOrArgChange: true,
  });
  const initialcategoryIdData = categoryIdData?.data;

  const categoryIdOptions = initialcategoryIdData?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const productIdOptions = initialData?.map((item) => ({
    value: item?.id,
    label: item?.productName,
  }));

  const handlePrint = () => {
    const printWindow = window.open("Low Stock Report", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>${t("Low Stock Report")}</title>
          <style>
            body {
              font-family: 'Arial', sans-serif;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              border: 1px solid #ddd;
              padding: 10px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
            @media print {
              header::before {
                content: "Sales List";
                display: block;
              
              }
              table {
                font-size: 12px;
              }
            }
          </style>
        </head>
        <body>
       <h1 style={{ width: '100%', textAlign: 'center' }}>${t(
         "Low Stock Report"
       )}</h1>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
          <thead>
          <tr className="text-center">
          <th>${t("Serial")}</th>
          <th>${t("Image")}</th>
          <th>${t("product")}</th>
          <th>${t("Category")}</th>
          <th>${t("Price")}</th>
          <th>${t("sale")}</th>
          <th>${t("purchases")}</th>
          <th>${t("Stock")}</th>
          <th>
            ${t("sale")} ${t("Value")}
          </th>
        </tr>
          </thead>
          ${initialLowStockData?.map((item, index) => {
            return `
            <tr key=${item?.id}>
                  <td className="align-middle">
                    <Link>${index + 1}</Link>
                  </td>

                  <td className="align-middle">
                    <img
                      src={
                        item?.image || "https://i.ibb.co/KjdtK7T/download.png"
                      }
                      alt="ProductImage"
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                  </td>
                  <td className="align-middle">${item?.productName}</td>
                  <td className="align-middle">
                    <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      ${item?.category?.name}
                    </span>
                  </td>

                  <td className="align-middle">
                    <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      ${Currency} : ${item?.salePrice}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      ${calculateProducuUnitSubUnit(item)?.totalPurchased}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span className="px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      ${calculateProducuUnitSubUnit(item)?.sold}
                    </span>
                  </td>

                  <td className="align-middle">
                    <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      ${calculateProducuUnitSubUnit(item)?.stock}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      ${Currency} :
                      ${calculateProducuUnitSubUnit(item)?.sellValue}
                    </span>
                  </td>
                </tr>
            `;
          })}
        </table>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };
  return (
    <div>
      <div>
        <h1 className="page-header">{t("Low Stock Report")}</h1>
        <hr className="mb-4" />
        <div className="row">
          <div className="form-group col-md-3">
            <ReactSelect
              placeHolder="Select a Product"
              options={productIdOptions}
              handleChange={handleProductChange}
            />
          </div>
          <div className="form-group col-md-3">
            <input
              type="text"
              value={productCodeFieldValue}
              onChange={(e) => setproductCodeFieldValue(e.target.value)}
              className="form-control p-2"
              placeholder="product code"
            />
          </div>
          <div className="form-group col-md-3">
            <input
              type="text"
              value={nameFieldValue}
              onChange={(e) => setNameFieldValue(e.target.value)}
              className="form-control p-2"
              placeholder="product name"
            />
          </div>
          <div className="form-group col-md-3">
            <ReactSelect
              placeHolder="Select Category"
              options={categoryIdOptions}
              handleChange={handleChange}
            />
          </div>
          <div className="d-flex justify-content-between mt-2">
            <div className="form-group col-md-3 ">
              <div className=" d-flex gap-2">
                <div className="form-group">
                  <button
                    onClick={handleSubmit}
                    className="btn btn-primary"
                    type="submit">
                    <i className="fa fa-sliders"></i> {t("Filter")}
                  </button>
                </div>
                <div className="form-group">
                  <button
                    className="btn btn-info"
                    type="button"
                    onClick={() => {
                      setNameFieldValue("");
                      setcategoryFieldValue(null);
                      setProductFieldValue(null);
                      setcategory(null);
                      setproductCodeFieldValue("");
                      const updatedFilter = {...filter};
                      delete updatedFilter.productName;
                      delete updatedFilter.category;
                      delete updatedFilter.productCode;
                      delete updatedFilter.productId;
                      setFilter(updatedFilter);
                      lowStockRefetch();
                    }}>
                    {t("Reset")}
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div>
                <button onClick={handlePrint} className="btn btn-outline-theme">
                  <i className="bi bi-printer px-1"></i>
                  {t("Print")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="data-management table-responsive mt-3" data-id="table">
        {lowStockReportLoading ? (
          <Loader />
        ) : (
          <table
            id="datatable"
            className="table table-bordered table-xs w-100 fw-semibold text-nowrap mb-3">
            <thead>
              <tr className="text-center">
                <th>{t("Serial")}</th>
                <th>{t("Image")}</th>
                <th>{t("product")}</th>
                <th>{t("Category")}</th>
                <th>{t("Price")}</th>
                <th>{t("sale")}</th>
                <th>{t("purchases")}</th>
                <th> {t("Stock")}</th>
                <th>
                  {t("sale")} {t("Value")}
                </th>
              </tr>
            </thead>
            <tbody className="text-body">
              {initialLowStockData?.map((item, index) => (
                <tr key={item?.id}>
                  <td className="align-middle">
                    <Link>{index + 1}</Link>
                  </td>

                  <td className="align-middle">
                    <img
                      src={
                        item?.image || "https://i.ibb.co/KjdtK7T/download.png"
                      }
                      alt="ProductImage"
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                  </td>
                  <td className="align-middle">{item?.productName}</td>
                  <td className="align-middle">
                    <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      {item?.category?.name}
                    </span>
                  </td>

                  <td className="align-middle">
                    <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      {Currency} : {item?.salePrice}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      {calculateProducuUnitSubUnit(item)?.totalPurchased}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span className="px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      {calculateProducuUnitSubUnit(item)?.sold}
                    </span>
                  </td>

                  <td className="align-middle">
                    <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      {calculateProducuUnitSubUnit(item)?.stock}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      {Currency} :{" "}
                      {calculateProducuUnitSubUnit(item)?.sellValue}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default LowStockReport;

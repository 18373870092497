import React, { useState } from "react";
import toast from "react-hot-toast";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useExpenseCategoryCreateMutation } from "../../redux/api/expenseCategory/expenseCategory";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ExpenseCategoryAddModal = ({ isModalOpen, closeModal }) => {
  const store = useSelector((state) => state.store);
  const { t } = useTranslation();
  const [isAdding, setIsAdding] = useState(false);
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
  });

  const initialValues = {
    name: "",
  };

  const [expenseCategoryCreate] = useExpenseCategoryCreateMutation();
  const handleSubmit = async (values, { resetForm }) => {
    setIsAdding(true);
    values.storeId= store.storeId
    const res = await expenseCategoryCreate(values);
    if (res?.data) {
      toast.success("Expense Category Created Successful");
      resetForm();
    }
    setIsAdding(false);
    closeModal();
  };
  return (
    <>
      <Modal show={isModalOpen} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("Add Expense Category")} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div>
                <div className="form-group  px-2 ">
                  <Field
                    type="text"
                    name="name"
                    placeholder="Enter Expense Category"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group d-flex justify-content-end px-2 pt-4 gap-2">
                <button
                      className="btn btn-outline-default"
                      onClick={() => closeModal()}
                      disabled={isAdding}
                    >
                      {t("Cancel")}
                    </button>
                  <button
                    type="submit"
                    className="btn btn-outline-theme"
                    disabled={isAdding}
                  >
                    {isAdding ? (
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      t("Save")
                    )}
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExpenseCategoryAddModal;

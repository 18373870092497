import React from "react";
import {Formik, Field, Form, ErrorMessage} from "formik";
import * as Yup from "yup";
const WithdrawModel = ({isWithdrawModalOpen, closeWithdrawModal}) => {
  const initialValues = {
    amount: "",
    owner: "",
    note: "",
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.number().required("Amount is required"),
    owner: Yup.string().required("Owner is required"),
  });

  const handleSubmit = (values) => {
    // Handle form submission here
    console.log("Form values:", values);
  };
  return (
    <div
      className={`modal fade mt-5 ${isWithdrawModalOpen ? "show" : ""}`}
      tabIndex="-1"
      role="dialog"
      style={{display: isWithdrawModalOpen ? "block" : "none"}}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Withdraw Balance</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => closeWithdrawModal()}></button>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {({values, errors, touched}) => (
              <Form>
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="form-label">Amount</label>
                    <Field
                      type="text"
                      className={`form-control ${
                        errors.amount && touched.amount ? "is-invalid" : ""
                      }`}
                      name="amount"
                      placeholder="Enter Withdraw Amount"
                    />
                    <ErrorMessage
                      name="amount"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Owner</label>
                    <Field
                      as="select"
                      className={`form-select ${
                        errors.owner && touched.owner ? "is-invalid" : ""
                      }`}
                      name="owner">
                      <option value="">Select an Owner</option>
                      <option value="owner1">Owner 1</option>
                      <option value="owner2">Owner 2</option>
                    </Field>
                    <ErrorMessage
                      name="owner"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group">
                    <label>Note</label>
                    <Field
                      as="textarea"
                      className="form-control"
                      name="note"
                      placeholder="Enter Note"
                      rows="10"
                    />
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    className="btn btn-outline-default"
                    onClick={() => closeWithdrawModal()}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={() => closeWithdrawModal()}
                    className="btn btn-outline-theme">
                    Withdraw Balance
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default WithdrawModel;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactSelect from "../helpers/ReactSelect";
import { useAllProductGetQuery } from "../../redux/api/product/productApi";
import { useAllBrandGetQuery } from "../../redux/api/brands/brandsApi";
import { useAllCategoryGetQuery } from "../../redux/api/categories/categoriesApi";
import Loader from "../../components/loader/Loader";
import { calculateProducuUnitSubUnit } from "../../utils/product";
import Pagination from "../../utils/Pagination";
import Currency from "../../Shared/Currency";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Stocks = () => {
  const store = useSelector((state) => state.store);
  const [category, setcategory] = useState();
  const [categoryFieldValue, setcategoryFieldValue] = useState();

  const [brand, setbrand] = useState();
  const [brandFieldValue, setBrandFieldValue] = useState();

  const [productName, setproductName] = useState();
  const [nameFieldValue, setNameFieldValue] = useState();
  const [productFieldValue, setProductFieldValue] = useState();
  const [productId, setProductId] = useState();

  const [productCode, setproductCode] = useState();
  const [productCodeFieldValue, setproductCodeFieldValue] = useState();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({
    page,
    limit: pageSize,
    storeId: store.storeId,
  });
  const { data, isLoading, refetch } = useAllProductGetQuery(filter, {
    refetchOnMountOrArgChange: true,
  });
  const initialData = data?.data;
  const meta = data?.meta;
  const { t } = useTranslation();
  useEffect(() => {
    const updatedFilter = { ...filter };
    updatedFilter.page = page;

    if (!productCodeFieldValue) {
      delete updatedFilter.productCode;
    }
    if (!nameFieldValue) {
      delete updatedFilter.productName;
    }
    if (!categoryFieldValue) {
      delete updatedFilter.category;
    }

    if (!brandFieldValue) {
      delete updatedFilter.brand;
    }
    if (!productFieldValue) {
      delete updatedFilter.productId;
    }
    if (brand) {
      updatedFilter.brand = brand;
    }
    if (productId) {
      updatedFilter.productId = productId;
    }
    if (category) {
      updatedFilter.category = category;
    }
    if (productName) {
      updatedFilter.productName = productName;
    }
    if (productCode) {
      updatedFilter.productCode = productCode;
    }

    setFilter(updatedFilter);

    refetch();
  }, [productName, page, productCode, category, brand, productId]);
  const handleChange = (selectedOption) => {
    setcategoryFieldValue(selectedOption?.value);
  };

  const handleBrandChange = (selectedOption) => {
    setBrandFieldValue(selectedOption?.value);
  };

  const handleProductChange = (selectedOption) => {
    setProductFieldValue(selectedOption?.value);
  };
  const handleSubmit = () => {
    setcategory(categoryFieldValue);
    setbrand(brandFieldValue);
    setproductCode(productCodeFieldValue);
    setproductName(nameFieldValue);
    setProductId(productFieldValue);
  };

  const { data: categoryIdData } = useAllCategoryGetQuery(
    { storeId: store.storeId },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const initialcategoryIdData = categoryIdData?.data;

  const { data: brandIdData } = useAllBrandGetQuery(
    { storeId: store.storeId },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const initialbrandIdData = brandIdData?.data;
  const categoryIdOptions = initialcategoryIdData?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const brandIdOptions = initialbrandIdData?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const productIdOptions = initialData?.map((item) => ({
    value: item?.id,
    label: item?.productName,
  }));

  return (
    <div className="mb-2">
      <header className="header text-white">
        <div className="header-info">
          <h1 className="page-header">
            {t("product")} {t("Stock")}
          </h1>
          <hr className="mb-4" />
        </div>
      </header>

      <div className="row">
        <div className="form-group col-md-3">
          <ReactSelect
            placeHolder="Select a Product"
            options={productIdOptions}
            handleChange={handleProductChange}
          />
        </div>
        <div className="form-group col-md-3">
          <input
            type="text"
            value={productCodeFieldValue}
            onChange={(e) => setproductCodeFieldValue(e.target.value)}
            className="form-control p-2"
            placeholder="product code"
          />
        </div>
        <div className="form-group col-md-3">
          <input
            type="text"
            value={nameFieldValue}
            onChange={(e) => setNameFieldValue(e.target.value)}
            className="form-control p-2"
            placeholder="product name"
          />
        </div>
        <div className="form-group col-md-3">
          <ReactSelect
            placeHolder="Select Category"
            options={categoryIdOptions}
            handleChange={handleChange}
          />
        </div>
        <div className="form-group col-md-3 my-2">
          <ReactSelect
            placeHolder="Select Brand"
            options={brandIdOptions}
            handleChange={handleBrandChange}
          />
        </div>
        <div className="form-group col-md-3 ">
          <div className="mt-2 d-flex gap-2">
            <div className="form-group">
              <button
                onClick={handleSubmit}
                className="btn btn-primary"
                type="submit"
              >
                <i className="fa fa-sliders"></i> {t("Filter")}
              </button>
            </div>
            <div className="form-group">
              <button
                className="btn btn-info"
                type="button"
                onClick={() => {
                  setNameFieldValue("");
                  setcategoryFieldValue(null);
                  setBrandFieldValue(null);
                  setProductFieldValue(null);
                  setbrand(null);
                  setcategory(null);
                  setproductCodeFieldValue("");
                  const updatedFilter = { ...filter };
                  delete updatedFilter.productName;
                  delete updatedFilter.category;
                  delete updatedFilter.brand;
                  delete updatedFilter.productCode;
                  delete updatedFilter.productId;
                  setFilter(updatedFilter);
                  refetch();
                }}
              >
                {t("Reset")}
              </button>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <div className="table-responsive my-4">
          <table className="table table-hover text-nowrap">
            <thead>
              <tr>
                <th className="border-top-0 pt-0 pb-2">{t("Serial")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Image")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("product")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Category")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Price")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("purchases")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Sold")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Damage")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Returned")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Stock")}</th>
                <th className="border-top-0 pt-0 pb-2">
                  {t("sold")} {t("Value")}
                </th>
              </tr>
            </thead>
            <tbody>
              {initialData?.map((item, index) => (
                <tr key={item?.id}>
                  <td className="align-middle">
                    <Link>{index + 1}</Link>
                  </td>

                  <td className="align-middle">
                    <img
                      src={
                        item?.image || "https://i.ibb.co/KjdtK7T/download.png"
                      }
                      alt="ProductImage"
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                  </td>
                  <td className="align-middle">{item?.productName}</td>
                  <td className="align-middle">
                    <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      {item?.category?.name}
                    </span>
                  </td>

                  <td className="align-middle">
                    <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      TK - {item?.salePrice}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      {calculateProducuUnitSubUnit(item).totalPurchased}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span className="px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      {calculateProducuUnitSubUnit(item).sold}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      {calculateProducuUnitSubUnit(item).totalDamaged}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      {calculateProducuUnitSubUnit(item).totalReturned}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span className=" px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      {calculateProducuUnitSubUnit(item).stock}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      {Currency}: {calculateProducuUnitSubUnit(item).sellValue}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <Pagination
        page={page}
        setPage={setPage}
        meta={meta}
        pageSize={pageSize}
      />
    </div>
  );
};

export default Stocks;

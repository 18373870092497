/* eslint-disable no-sequences */
import { baseApi } from "../baseApi";

export const unitsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    addUnits: build.mutation({
      query: (unitData) => ({
        url: "/units",
        method: "POST",
        data: unitData,
      }),
      invalidatesTags: ["units"],
    }),

    allUnitGet: build.query({
      query: (params) => ({
        url: "/units",
        method: "GET",
        params,
      }),
      providesTags: ["units"],
    }),
    singleUnitGet: build.query({
      query: ({ id, params }) => ({
        url: `/units/${id}`,
        method: "GET",
        params: params,
      }),
      providesTags: ["units"],
    }),

    updateUnit: build.mutation({
      query: ({ id, values }) => ({
        url: `/units/${id}`,
        method: "PATCH",
        data: values,
      }),
      invalidatesTags: ["units"],
    }),

    deleteUnit: build.mutation({
      query: ({ id, storeId }) => ({
        url: `/units/${id}`,
        method: "DELETE",
        data: { storeId: storeId },
      }),
      invalidatesTags: ["units"],
    }),
  }),
});

export const {
  useAddUnitsMutation,
  useAllUnitGetQuery,
  useSingleUnitGetQuery,
  useUpdateUnitMutation,
  useDeleteUnitMutation,
} = unitsApi;

/* eslint-disable no-sequences */
import { baseApi } from "../baseApi";

export const supplierApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    supplierCreate: build.mutation({
      query: (supplierData) => ({
        url: "/supplier",
        method: "POST",
        data: supplierData,
      }),
      invalidatesTags: ["supplier"],
    }),

    allSupplierGet: build.query({
      query: (params) => ({
        url: "/supplier",
        method: "GET",
        params,
      }),
      providesTags: ["supplier"],
    }),
    singleSupplierGet: build.query({
      query: ({id,params}) => ({
        url: `/supplier/${id}`,
        method: "GET",
        params
      }),
      providesTags: ["supplier"],
    }),
    singleSupplierReport: build.query({
      query: ({id,params}) => ({
        url: `/supplier/report/${id}`,
        method: "GET",
        params
      }),
      providesTags: ["supplier"],
    }),

    updateSupplier: build.mutation({
      query: ({ id, values }) => ({
        url: `/supplier/${id}`,
        method: "PATCH",
        data: values,
      }),
      invalidatesTags: ["supplier"],
    }),
    deleteSupplier: build.mutation({
      query: ({id,storeId}) => ({
        url: `/supplier/${id}`,
        method: "DELETE",
        data:{storeId:storeId},
      }),
      invalidatesTags: ["supplier"],
    }),
    updateSupplierBalance: build.mutation({
      query: ({ id, values }) => ({
        url: `/supplier/add-balance/${id}`,
        method: "PATCH",
        data: values,
      }),
      invalidatesTags: ["supplier"],
    }),
    supplierLedger: build.query({
      query: (params) => ({
        url: "/report/supplier/ledger",
        method: "GET",
        params,
      }),
      providesTags: ["report"],
    }),
  }),
});

export const {
  useSupplierCreateMutation,
  useAllSupplierGetQuery,
  useUpdateSupplierMutation,
  useDeleteSupplierMutation,
  useSingleSupplierReportQuery,
  useSingleSupplierGetQuery,
  useUpdateSupplierBalanceMutation,
  useSupplierLedgerQuery
} = supplierApi;

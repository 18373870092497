import React, {useState} from "react";
import {Formik, Field, Form, ErrorMessage} from "formik";
import * as Yup from "yup";
import DatepickerHelper from "../helpers/DatepickerHelper";
import ReactSelect from "../helpers/ReactSelect";
import {useAllTransactionTypeApiGetQuery} from "../../redux/api/transactionType/transactionTypeApi";

import {useSalePaymentCreateMutation} from "../../redux/api/payments/paymentsApi";
import toast from "react-hot-toast";
import {useSinglePurchaseGetQuery} from "../../redux/api/purchase/purchaseApi";
import {Modal} from "react-bootstrap";
import { useSelector } from "react-redux";
const PurchasePaymentModal = ({closeModal, isModalOpen, id, userId}) => {
  const store = useSelector((state)=>state.store)
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [isAdding, setIsAdding] = useState(false);
  const [transactionTypeId, setTransactionTypeId] = useState(null);

  const {data: transactionTypeIdData} = useAllTransactionTypeApiGetQuery();
  const {data: purchases} = useSinglePurchaseGetQuery(  { id, params: { storeId: store.storeId } },{
    refetchOnMountOrArgChange:true
  });
  const singlePurchases = purchases?.data;
  const initialTransactionTypeIdData = transactionTypeIdData?.data;
  const transactionTypeIdOptions = initialTransactionTypeIdData?.map(
    (item) => ({
      value: item?.id,
      label: item?.name,
    })
  );

  const handleStartDateChange = (date) => {
    setSelectedDate(date);
  };

  const initialValues = {
    paymentDate: selectedDate,
    type: "SUPPLIER",
    userId: userId,
    transactionTypeId: transactionTypeId,
    amount: "",
    note: "",
    isWallet: false,
  };

  const validationSchema = Yup.object().shape({
    paymentDate: Yup.date().required("Payment Date is required"),
    transactionTypeId: Yup.number().required("Transaction type is required"),
    amount: Yup.number().required("Amount is required"),

    note: Yup.string(),
  });
  const [salePaymentCreate] = useSalePaymentCreateMutation();
  const handleSubmit = async (values) => {
    setIsAdding(true);
    const data = {
      ...values,
      paymentDate: selectedDate,
      userId: userId,
      type: "SUPPLIER",
      transactionTypeId: transactionTypeId,
      storeId: parseInt(store.storeId) ,
    };
    if (values?.amount > singlePurchases?.due) {
      toast.error("Payment amount must be less than or equal to due");
      setIsAdding(false);
    } else {
      const res = await salePaymentCreate({data, id});
      if (res?.data) {
        toast.success("Payment Successful");
      
      } 
      setIsAdding(false);
      closeModal();
    }
  };

  const options4 = [
    {value: true, label: "Yes"},
    {value: false, label: "No"},
  ];
  return (
    <Modal show={isModalOpen} onHide={closeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {() => (
              <Form>
                <div className="d-flex my-2">
                  <div className="form-group col-lg-6 px-2 ">
                    <label htmlFor="payment_date">
                      Payment Date<span className="field_required"></span>
                    </label>
                    <DatepickerHelper
                      handleDateChange={handleStartDateChange}
                      selectedDate={selectedDate}
                      placeHolder="Select start date"
                    />
                  </div>
                  <div className="form-group col-lg-6 px-2">
                    <label>Wallet/Direct Transaction</label>

                    <Field className="form-control" name="isWallet">
                      {(props) => {
                        const handleChange = (option) => {
                          props.form.setFieldValue("isWallet", option.value);
                        };
                        return (
                          <ReactSelect
                            placeHolder="Select wallet..."
                            options={options4}
                            handleChange={handleChange}
                          />
                        );
                      }}
                    </Field>
                    <ErrorMessage
                      name="isWallet"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>

                <div className="d-flex my-2">
                  <div className="form-group col-lg-6 px-2">
                    <label htmlFor="amount">
                      Amount<span className="field_required"></span>
                    </label>
                    <Field
                      type="number"
                      name="amount"
                      className="form-control"
                      placeholder="Enter amount..."
                      id="amount"
                    />
                  </div>

                  <div className="form-group col-6 px-2">
                    <label>Transaction Account</label>

                    <Field className="form-control" name="transactionTypeId">
                      {(props) => {
                        const handleChange = (option) => {
                          setTransactionTypeId(option.value);
                          props.form.setFieldValue(
                            "transactionTypeId",
                            option.value
                          );
                        };
                        return (
                          <ReactSelect
                            placeHolder="Select account..."
                            options={transactionTypeIdOptions}
                            handleChange={handleChange}
                          />
                        );
                      }}
                    </Field>
                    <ErrorMessage
                      name="transactionTypeId"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>

                <div className="form-group col-lg-12 px-2 my-2">
                  <label htmlFor="note">Note</label>
                  <Field
                    as="textarea"
                    name="note"
                    className="form-control"
                    placeholder="Write Note. (optional)"
                  />
                </div>

                <div className="modal-footer">
                  <button
                    className="btn btn-outline-default"
                    onClick={() => closeModal()}
                    disabled={isAdding}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-outline-theme"
                    disabled={isAdding}>
                    {isAdding ? (
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"></span>
                    ) : (
                      "    Payment"
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PurchasePaymentModal;

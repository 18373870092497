/* eslint-disable no-sequences */
import {baseApi} from "../baseApi";

export const categoriesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    categoryCreate: build.mutation({
      query: (categoriesData) => ({
        url: "/category",
        method: "POST",
        data: categoriesData,
      }),
      invalidatesTags: ["category"],
    }),

    allCategoryGet: build.query({
      query: (params) => ({
        url: "/category",
        method: "GET",
        params,
      }),
      providesTags: ["category"],
    }),
    singleCategoryGet: build.query({
      query: ({id,params}) => ({
        url: `/category/${id}`,
        method: "GET",
        params
      }),
      providesTags: ["category"],
    }),

    updateCategory: build.mutation({
      query: ({id, values}) => ({
        url: `/category/${id}`,
        method: "PATCH",
        data: values,
      }),
      invalidatesTags: ["category"],
    }),
    deleteCategory: build.mutation({
      query: ({id,storeId}) => ({
        url: `/category/${id}`,
        method: "DELETE",
        data:{storeId:storeId},
      }),
      invalidatesTags: ["category"],
    }),
  }),
});

export const {
  useCategoryCreateMutation,
  useAllCategoryGetQuery,
  useSingleCategoryGetQuery,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = categoriesApi;

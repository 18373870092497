import {Link, useParams} from "react-router-dom";
import {useCustomerSaleListMutation} from "../../redux/api/customer/customerApi";

import moment from "moment";
import React, {useEffect, useRef, useState} from "react";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import {useDeleteSalesMutation} from "../../redux/api/pos/posApi";
import toast from "react-hot-toast";

import DeleteConfirmationModal from "../deleteConfirmationModal/DeleteConfirmationModal";
import SalesPaymentsModal from "../sales/SalesPaymentsModal";
import Loader from "../../components/loader/Loader";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Currency from "../../Shared/Currency";
const SalesList = () => {
  const store= useSelector((state) => state.store)
  const { t } = useTranslation();
  const [customer, setIsCustomer] = useState([]);
  const {id} = useParams();

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [selectedItemId, setSelectedItemId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [customerSaleList] = useCustomerSaleListMutation();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await customerSaleList({
          id,
          values: {isCustomer: true,storeId:store.storeId},
        });
        setIsLoading(false);

        setIsCustomer(response?.data?.data);
      } catch (error) {
        console.error("Error fetching sales list:", error);
      }
    };

    fetchData();
  }, [id, customerSaleList]);

  const [deleteSales] = useDeleteSalesMutation({});
  const handleDelete = async (id) => {
    const res = await deleteSales({id,storeId:store.storeId});
    if (res?.data?.success === true) {
      toast.success(res?.data?.message);
    }
    setIsDeleteConfirmationModalOpen(false);
  };

  const openModal = (id, customerId) => {
    setIsModalOpen(true);
    setSelectedId(id);
    setSelectedUserId(customerId);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const tableRef = useRef(null);

  const exportAsPDF = async () => {
    const input = tableRef.current;

    if (!input) {
      return;
    }
    const canvas = await html2canvas(input);

    const tableWidth = canvas.width;
    const tableHeight = canvas.height;
    const pdfWidth = tableWidth / 9;
    const pdfHeight = tableHeight / 6.84;

    const pdf = new jsPDF("p", "mm", "a4");
    pdf.addImage(canvas, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("table.pdf");
  };

  const openDeleteConfirmationModal = (id) => {
    setIsDeleteConfirmationModalOpen(true);
    setSelectedItemId(id);
  };

  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(false);
  };

  return (
    <div>
      <header className="header text-white">
        <div className="header-info">
          <h1 className="page-header">{t("sale")} {t("List")}</h1>
          <hr className="mb-2" />
        </div>
      </header>

      <div>
        <button
          onClick={exportAsPDF}
          style={{width: "120px", height: "35px"}}
          className="btn btn-outline-theme">
          <i className="bi bi-printer pr-2" style={{marginRight: "5px"}}></i>
          {t("Print")}
        </button>
      </div>
      <div className="my-4">
        <div className="table-responsive">
          {isLoading ? (
            <Loader />
          ) : (
            <table
              ref={tableRef}
              id="datatable"
              className="table table-hover text-nowrap">
              <thead>
                <tr>
                <th className="border-top-0 pt-0 pb-2">{t("Invoice")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("customer")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("product")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Date")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Discount")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("receiveable")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Paid")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Returned")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Due")}</th>
                <th className="border-top-0 pt-0 pb-2">
                  {t("purchases")} {t("Cost")}
                </th>
                <th className="border-top-0 pt-0 pb-2">{t("profit")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Status")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {customer?.map((item, index) => (
                  <tr key={item?.id}>
                  
                    <td className="align-middle">{item?.id}</td>
                    <td className="align-middle">{item?.customer?.name}</td>
                    <td>
                      {item?.products?.map((product, index) => (
                        <>
                          <span key={index}>
                            {product?.productName}:{" "}
                            {product?.subUnits ? (
                              <>
                                {Math?.floor(
                                  product?.qty / product?.unit?.relatedByValue
                                )}{" "}
                                {product?.unit?.name}{" "}
                                {Math?.floor(
                                  product?.qty % product?.unit?.relatedByValue
                                )}{" "}
                                {product?.subUnits?.name}
                              </>
                            ) : (
                              <>
                                {product?.quantity} {product?.unit?.name}
                              </>
                            )}{" "}
                          </span>{" "}
                          <br />
                        </>
                      ))}
                    </td>
                    <td className="align-middle">
                      {moment(item?.salesDate).format("DD MMMM YYYY")}
                    </td>
                    <td className="align-middle">{item?.discount}%</td>
                    <td className="py-1 align-middle">
                      <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                       {Currency}:  {item?.receivable?.toFixed(2)}
                      </span>
                    </td>
                    <td className="align-middle">
                      <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                       {Currency}: {item?.paid}
                      </span>
                    </td>
                    <td className="align-middle">
                      <span className="badge border border-danger text-danger px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                       {Currency}:  {item?.totalReturnedAmount?.toFixed(2)}
                      </span>
                    </td>
                    <td className="align-middle">
                      <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                       {Currency}:  {item?.due?.toFixed(2)}
                      </span>
                    </td>
                    <td className="align-middle">
                      <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                       {Currency}:  {item?.totalProductPurchasesCost?.toFixed(2)}
                      </span>
                    </td>
                    <td className="align-middle">
                      <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                       {Currency}:  {item?.totalProfit?.toFixed(2)}
                      </span>
                    </td>
                    <td className="align-middle">
                      <span
                        className={`badge border  px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ${
                          item?.status === "UNPAID"
                            ? "border-danger text-danger"
                            : "border border-success text-success"
                        }`}>
                        {item?.status}
                      </span>
                    </td>
                    <td className="align-middle">
                      <span
                        className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center cursor-pointer "
                        data-bs-toggle="dropdown">
                        <i className="bi bi-gear fs-9px fa-fw me-5px"></i> E/D
                      </span>

                      <div className="dropdown-menu">
                        {/* <Link
                        to={`/sales/edit-sales/${item?.id}`}
                        className="dropdown-item">
                        <i
                          className="bi bi-pencil-square"
                          style={{marginRight: "5px"}}></i>
                        Edit
                      </Link> */}
                        <a className="dropdown-item" href="#/">
                          <i
                            className="bi bi-tv"
                            style={{marginRight: "5px"}}></i>
                          Show
                        </a>
                        <Link
                          to={`/sales/return/${item?.id}`}
                          className="dropdown-item">
                          <i
                            className="bi bi-arrow-return-right"
                            style={{marginRight: "5px"}}></i>
                          Return
                        </Link>

                        <button
                          onClick={() =>
                            openModal(item?.id, item?.customer?.id)
                          }
                          className="dropdown-item">
                          <i
                            className="bi bi-cash-coin pr-2"
                            style={{marginRight: "5px"}}></i>
                          Add Payment
                        </button>
                        <a className="dropdown-item" href="#/">
                          <i
                            className="bi bi-printer pr-2"
                            style={{marginRight: "5px"}}></i>
                          Invoice
                        </a>

                        <button
                          onClick={() => openDeleteConfirmationModal(item?.id)}
                          className="dropdown-item">
                          <i
                            className="bi bi-trash pr-2"
                            style={{marginRight: "5px"}}></i>
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      {isModalOpen && (
        <SalesPaymentsModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          id={selectedId}
          userId={selectedUserId}
        />
      )}

      {isDeleteConfirmationModalOpen && (
        <DeleteConfirmationModal
          closeModal={closeDeleteConfirmationModal}
          confirmDelete={() => handleDelete(selectedItemId)}
        />
      )}
    </div>
  );
};

export default SalesList;

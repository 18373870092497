import React from "react";
import { Card, CardBody } from "./../../components/card/card.jsx";
import { calculateProducuUnitSubUnit } from "../../utils/product.js";
import Currency from "../../Shared/Currency.js";
const AllProducts = ({ tableData, showPosItemModal }) => {
  // console.log(tableData,"hello");
  return (
    <div>
      <div className="row gx-4">
        {tableData && tableData?.length ? (
          tableData?.map((item, index) => (
            <div
              className={
                "col-xxl-2 col-xl-3 col-lg-6 col-md-4 col-sm-6 pb-4" +
                (item?.hide ? " d-none" : "")
              }
              key={index}
            >
              <Card className="h-100">
                <CardBody className="h-100 p-2 position-relative">
                  <div
                    className={"pos-product cursor-pointer"}
                    onClick={(event) => {
                      if (item?.stock > 0) {
                        showPosItemModal(event, item);
                      }
                    }}
                  >
                    <img
                      style={{
                        height: "50px",
                        objectFit: "contain",
                        objectPosition: "center",
                      }}
                      src={
                        item?.image
                          ? item.image
                          : "https://i.ibb.co/KjdtK7T/download.png"
                      }
                      alt=""
                      srcSet=""
                    />

                    <div className="info text-center">
                      <div className="fw-bolder">
                        {Currency} {item?.salePrice}
                      </div>
                      <div className="fw-bolder fs-6">{item?.productName}</div>
                      <div className="fw-bolder">
                        {calculateProducuUnitSubUnit(item).stock}
                      </div>
                    </div>

                    {item?.stock <= 0 && (
                      <div
                        className="not-available-text text-center position-absolute top-0 end-0"
                        style={{
                          backgroundColor: "#D80032",

                          padding: "5px",
                          borderRadius: "50px",
                          width: "50px",
                          fontSize: "10px",
                          lineHeight: "1",
                          fontWeight: "bold",
                        }}
                      >
                        <div style={{ color: "white" }}>Stock Out</div>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          ))
        ) : (
          <div className="col-12 fs-3 d-flex align-items-center justify-content-center h-100">
            No records found
          </div>
        )}
      </div>
    </div>
  );
};

export default AllProducts;

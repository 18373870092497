/* eslint-disable no-sequences */
import { baseApi } from "../baseApi";

export const customerApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    customerCreate: build.mutation({
      query: (customerData) => ({
        url: "/customer",
        method: "POST",
        data: customerData,
      }),
      invalidatesTags: ["customer"],
    }),

    allCustomerGet: build.query({
      query: (params) => ({
        url: "/customer",
        method: "GET",
        params,
      }),
      providesTags: ["customer"],
    }),
    singleCustomerGet: build.query({
      query: ({id,params}) => ({
        url: `/customer/${id}`,
        method: "GET",
        params
      }),
      providesTags: ["customer"],
    }),
    singleCustomerReport: build.query({
      query: ({id,params}) => ({
        url: `/customer/report/${id}`,
        method: "GET",
        params
      }),
      providesTags: ["customer"],
    }),
    customerSaleList: build.mutation({
      query: ({ id, values }) => ({
        url: `/customer/sales-list/${id}`,
        method: "POST",
        data: values,
      }),
      invalidatesTags: ["customer", "supplier"],
    }),
    updateCustomer: build.mutation({
      query: ({ id, values }) => ({
        url: `/customer/${id}`,
        method: "PATCH",
        data: values,
      }),
      invalidatesTags: ["customer"],
    }),
    deleteCustomer: build.mutation({
      query: ({id,storeId}) => ({
        url: `/customer/${id}`,
        method: "DELETE",
        data:{storeId:storeId}
      }),
      invalidatesTags: ["customer"],
    }),
    updateCustomerBalance: build.mutation({
      query: ({ id, values }) => ({
        url: `/customer/add-balance/${id}`,
        method: "PATCH",
        data: values,
      }),
      invalidatesTags: ["customer"],
    }),
  }),
});

export const {
  useCustomerCreateMutation,
  useAllCustomerGetQuery,
  useSingleCustomerGetQuery,
  useCustomerSaleListMutation,
  useUpdateCustomerMutation,
  useUpdateCustomerBalanceMutation,
  useSingleCustomerReportQuery,
  useDeleteCustomerMutation,
} = customerApi;

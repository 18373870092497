
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAllCustomerGetQuery } from "../../redux/api/customer/customerApi";
import { useCustomerLedgerQuery } from "../../redux/api/reports/reportsApi";
import Pagination from "../../utils/Pagination";
import DatepickerHelper from "../helpers/DatepickerHelper";
import ReactSelect from "../helpers/ReactSelect";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const CustomerLedger = () => {
  const store = useSelector((state) => state.store);
  const { t } = useTranslation();
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [skip, setSkip] = useState(true);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [startDateFieldValue, setStartDateFieldValue] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [endDateFieldValue, setEndDateFieldValue] = useState(new Date());
  const [selectedCustomer, setSelectedCustomer] = useState();
  
  const Cfilter ={
    storeId: store?.storeId,
  }
  const { data: initalCData } = useAllCustomerGetQuery(Cfilter,{
    refetchOnMountOrArgChange: true,
  });
  const options = initalCData?.data?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));
  const handleChange = (values) => {
    setSelectedSupplier(values.value);
  };
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({
    page,
    limit: pageSize,
    startDate,
    endDate,
    customerId: "",
    storeId: store?.storeId
  });

  const { data: ledgerData } = useCustomerLedgerQuery(filter, {
    skip,
    refetchOnMountOrArgChange: true,
  });
  const meta = ledgerData?.meta;

  const handleSubmit = () => {
    setSelectedCustomer(selectedSupplier);
    setStartDate(moment(startDateFieldValue).format("YYYY-MM-DD"));
    setEndDate(moment(endDateFieldValue).format("YYYY-MM-DD"));
  };
  useEffect(() => {
    const updatedFilter = { ...filter };
    updatedFilter.page = page;
    if (!startDateFieldValue) {
      delete updatedFilter.startDate;
    }
    if (startDate) {
      updatedFilter.startDate = startDate;
    }
    if (!endDateFieldValue) {
      delete updatedFilter.endDate;
    }
    if (endDate) {
      updatedFilter.endDate = endDate;
    }
    if (!selectedCustomer) {
      delete updatedFilter.customerId;
    }
    if (selectedCustomer) {
      updatedFilter.customerId = selectedCustomer;
    }
    if (startDate && endDate && selectedCustomer) {
      setSkip(false);
    }
    setFilter(updatedFilter);
  }, [
    startDateFieldValue,
    endDate,
    endDateFieldValue,
    startDate,
    selectedCustomer,
    page,
  ]);
  

  const handlePrint = () => {
    const printWindow = window.open("Daily Report", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title> ${t("Daily Report")}</title>
          <style>
            body {
              font-family: 'Arial', sans-serif;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              border: 1px solid #ddd;
              padding: 10px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
            @media print {
              header::before {
                content: "Sales List";
                display: block;
              
              }
              table {
                font-size: 12px;
              }
            }
          </style>
        </head>
        <body>
       <h1 style={{ width: '100%', textAlign: 'center' }}> ${t(
         "Daily Report"
       )}</h1>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
          <thead>
          <tr className="text-center">
          <th>${t("Date")}</th>
          <th>${t("Comments")}</th>
          <th>${t("Debit")}</th>
          <th>${t("Credit")}</th>
        </tr>
          </thead>
          ${ledgerData?.data?.result?.map((ledger, index) => {
            return `
            <tr>
           
            <td>${moment(ledger?.date).format("DD MMMM YYYY")}</td>
            <td>
              ${ledger?.type === "sale"
                ? "Customer Purchased"
                : "Received From Customer"}
            </td>
            <td>
              ${ledger?.type === "sale"
                ? ledger?.amountAfterDiscount
                : ""}
            </td>
            <td>${ledger?.type === "payment" ? ledger?.paid : ""}</td>
          </tr>
            `;
          })}

       
        </table>
        </body>

     
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };
  return (
    <div>
      <div>
        <h1 className="page-header">{t("Customer Ledger")}</h1>
        <hr className="mb-4" />
        <div className="row gap-3 w-100">
          <div className="col-lg-4 ">
            <ReactSelect
              placeHolder="Select a customer"
              options={options}
              handleChange={handleChange}
            />
          </div>
          <div className="d-flex col-lg-6 gap-2">
            <div className="col-lg-6 ">
              <DatepickerHelper
                handleDateChange={(date) => setStartDateFieldValue(date)}
                selectedDate={startDateFieldValue}
                placeHolder="Select date"
              />
            </div>
            <div className="col-lg-6 ">
              <DatepickerHelper
                handleDateChange={(date) => setEndDateFieldValue(date)}
                selectedDate={endDateFieldValue}
                placeHolder="Select date"
              />
            </div>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <div className="d-flex gap-2">
              <div>
                <button
                  onClick={handleSubmit}
                  style={{ width: "100px", height: "35px" }}
                  className="btn btn-outline-theme"
                >
                  {t("Filter")}
                </button>
              </div>
              {/* <div>
                <button
                  onClick={() => {
                    setStartDate(moment(new Date()).format("YYYY-MM-DD"));
                    setStartDateFieldValue(new Date());
                    setEndDate(moment(new Date()).format("YYYY-MM-DD"));
                    setEndDateFieldValue(new Date());
                    const updatedFilter = { ...filter };
                    setFilter(updatedFilter);
                    setSelectedSupplier("");
                  }}
                  style={{ width: "100px", height: "35px" }}
                  className="btn btn-outline-theme"
                >
                  <i class="bi bi-x-circle" style={{ marginRight: "5px" }}></i>
                  Reset
                </button>
              </div> */}
            </div>
            <div>
              <button
                onClick={handlePrint}
                style={{ width: "120px", height: "35px" }}
                className="btn btn-outline-theme"
              >
                <i
                  class="bi bi-printer pr-2"
                  style={{ marginRight: "5px" }}
                ></i>
                {t("Print")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {ledgerData?.data?.result?.length > 0 && (
        <div>
          <div className="mt-5">
            <div>
              <table className="table w-50 table-bordered  table-xs fw-semibold text-nowrap">
                <tbody>
                  <tr>
                    <th style={{ width: "30%" }}>{t("Account of")}:</th>
                    <td>{ledgerData?.data?.customer?.name}</td>
                  </tr>
                  <tr>
                    <th>{t("Address")}:</th>
                    <td>{ledgerData?.data?.customer?.address}</td>
                  </tr>
                  <tr>
                    <th>{t("Phone")}:</th>
                    <td>{ledgerData?.data?.customer?.mobile}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="text-center mt-5">
              <h3>{t("customer")} {t("Ledger")}</h3>
            </div>
            <div
              className="data-management table-responsive mt-3"
              data-id="table"
            >
              <table
               
                id="datatable"
                className="table table-bordered  w-100 fw-semibold text-nowrap mb-3"
              >
                <thead>
                  <tr className="text-center">
                    <th>{t("Date")}</th>
                    <th>{t("Comments")}</th>
                    <th>{t("Debit")}</th>
                    <th>{t("Credit")}</th>
                  </tr>
                </thead>
                <tbody className="text-body">
                  {ledgerData?.data?.result?.map((ledger, index) => (
                    <tr className="text-center" key={index}>
                      <td>{moment(ledger?.date).format("DD MMMM YYYY")}</td>
                      <td>
                        {ledger?.type === "sale"
                          ? "Customer Purchased"
                          : "Received From Customer"}
                      </td>
                      <td>
                        {ledger?.type === "sale"
                          ? ledger?.amountAfterDiscount
                          : ""}
                      </td>
                      <td>{ledger?.type === "payment" ? ledger?.paid : ""}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {meta && (
        <Pagination
          page={page}
          setPage={setPage}
          meta={meta}
          pageSize={pageSize}
        />
      )}
    </div>
  );
};

export default CustomerLedger;

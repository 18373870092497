import React from "react";
import {Link} from "react-router-dom";
import Pagination from "../../utils/Pagination";

const ProductPagenation = ({pageSize, meta, page, setPage}) => {
  return (
    <div className="p-4">
    <Pagination page={page}setPage={setPage} meta={meta} pageSize={pageSize} />
    </div>
  );
};

export default ProductPagenation;

import React from "react";

import {useTopSoldProductsAllTimeReportQuery} from "../../redux/api/reports/reportsApi";
import Loader from "../../components/loader/Loader";
import {calculateProducuUnitSubUnit} from "../../utils/product";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const TopProductAllTime = () => {
  const store = useSelector((state) => state.store);
  const {t} = useTranslation();

  const filter = {
    storeId: store?.storeId,
  };
  const {data, isLoading} = useTopSoldProductsAllTimeReportQuery(filter, {
    refetchOnMountOrArgChange: true,
  });
  const initialTopSoldProductsAllTimeData = data?.data;

  const handlePrint = () => {
    const printWindow = window.open("Top Product (All Time)", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>${t("Top Product (All Time)")}</title>
          <style>
            body {
              font-family: 'Arial', sans-serif;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              border: 1px solid #ddd;
              padding: 10px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
            @media print {
              header::before {
                content: "Sales List";
                display: block;
              
              }
              table {
                font-size: 12px;
              }
            }
          </style>
        </head>
        <body>
       <h1 style={{ width: '100%', textAlign: 'center' }}>${t(
         "Top Product (All Time)"
       )}</h1>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
          <thead>
          <tr className="text-center">
          <th>${t("Serial")}</th>
          <th>${t("Name")}</th>
          <th>${t("Code")}</th>
          <th>${t("sold")} ${t("Quantity")}</th>
        </tr>
          </thead>
          ${initialTopSoldProductsAllTimeData?.map((item, index) => {
            return `
            <tr key=${index} className="text-center">
            <td>${index + 1}</td>
            <td>${item?.productName}</td>
            <td>${item?.productCode ? item?.productCode : "N/A"}</td>

            <td className="text-success">
              ${calculateProducuUnitSubUnit(item)?.sold}
            </td>
          </tr>
            `;
          })}
        </table>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };
  return (
    <div>
      <div>
        <h1 className="page-header">{t("Top Product")} (All Time)</h1>
        <hr className="mb-4" />
        <div className="row gap-3 w-100">
          <div className="d-flex justify-content-between mt-2">
            <div className="">
              <button
                onClick={handlePrint}
                style={{width: "120px", height: "35px"}}
                className="btn btn-outline-theme">
                <i
                  className="bi bi-printer pr-2"
                  style={{marginRight: "5px"}}></i>
                {t("Print")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="data-management table-responsive mt-3" data-id="table">
        {isLoading ? (
          <Loader />
        ) : (
          <table
            id="datatable"
            className="table table-bordered table-xs w-100 fw-semibold text-nowrap mb-3">
            <thead>
              <tr className="text-center">
                <th>{t("Serial")}</th>
                <th>{t("Name")}</th>
                <th>{t("Code")}</th>
                <th>
                  {t("sold")} {t("Quantity")}
                </th>
              </tr>
            </thead>
            <tbody className="text-body">
              {initialTopSoldProductsAllTimeData?.map((item, index) => (
                <tr key={index} className="text-center">
                  <td>{index + 1}</td>
                  <td>{item?.productName}</td>
                  <td>{item?.productCode ? item?.productCode : "N/A"}</td>

                  <td className="text-success">
                    {calculateProducuUnitSubUnit(item)?.sold}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default TopProductAllTime;

import React, { useEffect, useState } from "react";
import DamageModal from "./DamageModal";
import DamageList from "./DamageList";
import { useAllDamageGetQuery } from "../../redux/api/damages/damagesApi";
import { Link } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import { useAllProductGetQuery } from "../../redux/api/product/productApi";
import ReactSelect from "../helpers/ReactSelect";
import Pagination from "../../utils/Pagination";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Damages = () => {
  const store = useSelector((state) => state.store);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [name, setName] = useState();

  const [nameFieldValue, setNameFieldValue] = useState();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({
    page,
    limit: pageSize,
    storeId: store.storeId,
  });
  const { data, isLoading, refetch } = useAllDamageGetQuery(filter, {
    refetchOnMountOrArgChange: true,
  });

  const initialData = data?.data;
  const meta = data?.meta;
  const { t } = useTranslation();
  useEffect(() => {
    const updatedFilter = { ...filter };
    updatedFilter.page = page;
    if (name) {
      updatedFilter.productsId = name;
    }
    if (searchTerm) {
      updatedFilter.searchTerm = searchTerm;
    }
    if (!searchTerm) {
      delete updatedFilter.searchTerm;
    }
    if (!nameFieldValue) {
      delete updatedFilter.name;
    }

    setFilter(updatedFilter);

    refetch();
  }, [name, refetch, searchTerm, page]);

  const handleChange = (values) => {
    setName(values.value);
    // setSelectedProduct(values.value);
  };

  const { data: productIdData } = useAllProductGetQuery(
    { storeId: store.storeId },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const initialPIdData = productIdData?.data;
  const productIdOptions = initialPIdData?.map((item) => ({
    value: item?.id,
    label: item?.productName,
  }));
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <header className="header text-white">
        <div className="header-info">
          <h1 className="page-header">
            {t("Damage")} {t("List")}
          </h1>
          <hr className="mb-4" />
        </div>
      </header>

      <div className="d-flex gap 5 justify-content-between">
        <div className="col-md-5">
          <ReactSelect
            placeHolder="Select a category"
            options={productIdOptions}
            handleChange={handleChange}
          />
        </div>

        <div>
          <button
            style={{ width: "180px", height: "35px" }}
            className="btn btn-outline-theme"
            onClick={openModal}
          >
            <i className="fa fa-plus-circle me-1"></i>
            {t("Add Damage")}
          </button>
        </div>
      </div>

      <div className="tab-content py-3">
        {isModalOpen && (
          <DamageModal isModalOpen={isModalOpen} closeModal={closeModal} />
        )}
        <div>
          {isLoading ? (
            <Loader />
          ) : (
            <div>
              <DamageList data={initialData} refetch={refetch} />
            </div>
          )}
        </div>
      </div>

      <Pagination
        page={page}
        setPage={setPage}
        meta={meta}
        pageSize={pageSize}
      />
    </div>
  );
};

export default Damages;

import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";

const EditPurchases = () => {
  const validationSchema = Yup.object().shape({
    supplier_id: Yup.string().required("Supplier is required"),
    purchase_date: Yup.date().required("Purchase Date is required"),
  });

  const formik = useFormik({
    initialValues: {
      supplier_id: "22",
      purchase_date: "2023-11-09",
      products: [
        {id: 1, name: "Product 1", rate: 10.0, qty: 100},
        {id: 2, name: "Product 2", rate: 15.0, qty: 150},
      ],
    },
    validationSchema,
    onSubmit: (values) => {
      console.log("Form submitted:", values);
      // Add logic to send data to the server or perform other actions
    },
  });

  return (
    <div className="col-12">
      <div>
        <h4 className="card-title">Update Purchase</h4>

        <form onSubmit={formik.handleSubmit}>
          <div className="card-body">
            <div className="d-flex align-item-center mt-4 gap-2">
              <div className="form-group col-md-6">
                <label htmlFor="supplier">Supplier</label>
                <select
                  name="supplier_id"
                  id="supplier"
                  className={`form-control ${
                    formik.touched.supplier_id && formik.errors.supplier_id
                      ? "is-invalid"
                      : ""
                  }`}
                  value={formik.values.supplier_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}>
                  <option value="22">Supplier 1</option>
                  <option value="23">Supplier 2</option>
                </select>
                {formik.touched.supplier_id && formik.errors.supplier_id && (
                  <div className="invalid-feedback">
                    {formik.errors.supplier_id}
                  </div>
                )}
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="purchase_date">Purchase Date</label>
                <input
                  type="text"
                  className={`form-control date ${
                    formik.touched.purchase_date && formik.errors.purchase_date
                      ? "is-invalid"
                      : ""
                  }`}
                  data-provide="datepicker"
                  name="purchase_date"
                  data-date-today-highlight="true"
                  data-date-format="yyyy-mm-dd"
                  value={formik.values.purchase_date}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.purchase_date &&
                  formik.errors.purchase_date && (
                    <div className="invalid-feedback">
                      {formik.errors.purchase_date}
                    </div>
                  )}
              </div>
            </div>
            <div className="form-group col-md-6 mt-2">
              <label htmlFor="Product">Product</label>
              {/* Add your product input logic here */}
            </div>

            <div className="row mt-4">
              <table id="datatable" className="table table-hover text-nowrap">
                <thead>
                  <tr>
                    <th className="border-top-0 pt-0 pb-2"></th>
                    <th className="border-top-0 pt-0 pb-2">#SL</th>
                    <th className="border-top-0 pt-0 pb-2">Product</th>
                    <th className="border-top-0 pt-0 pb-2">Rate</th>
                    <th className="border-top-0 pt-0 pb-2">Qty</th>
                    <th className="border-top-0 pt-0 pb-2">Sub Total</th>
                    <th className="border-top-0 pt-0 pb-2">
                      {" "}
                      <i className="fa fa-trash"></i>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {formik.values.products.map((product, index) => (
                    <tr key={index}>
                      <td className="w-10px align-middle">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="product1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="product1"></label>
                        </div>
                      </td>
                      <td>{index + 1}</td>
                      <td>{product.name}</td>
                      <td>
                        <input
                          type="text"
                          value={product.rate}
                          className="form-control rate"
                          name={`rate[${product.id}]`}
                          onChange={formik.handleChange}
                        />
                      </td>
                      <td>
                        <div className="form-row d-flex justify-content-center align-items-center ">
                          <label className="ml-4 mr-2">pc:</label>
                          <input
                            type="number"
                            value={product.qty}
                            className="form-control col main_qty"
                            name={`main_qty[${product.id}]`}
                            onInput={(e) => e.preventDefault()}
                            min="1"
                            onChange={formik.handleChange}
                          />
                        </div>
                      </td>
                      <td>
                        <strong>
                          <span className="sub_total">
                            {(product.rate * product.qty).toFixed(2)}
                          </span>{" "}
                          Tk
                        </strong>
                        <input
                          type="hidden"
                          name={`subtotal_input[${product.id}]`}
                          className="subtotal_input"
                          value={(product.rate * product.qty).toFixed(2)}
                        />
                      </td>
                      <td>
                        <a
                          href="#"
                          className="item-index"
                          // onClick={() => handleProductChange(product?.id)}
                          
                          >
                          <i className="fa fa-undo"></i>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>

                <tfoot className="">
                  <tr>
                    <td colSpan="4"></td>
                    <td colSpan="2">
                      <strong>
                        Grand Total:{" "}
                        <span id="total">
                          {formik.values.products
                            .reduce((acc, curr) => acc + curr.subtotal, 0)
                            .toFixed(2)}
                        </span>{" "}
                        Tk
                      </strong>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <div className="form-row">
              <div className="form-group col-md-12 mt-4">
                <button
                  type="submit"
                  id="payment_btn"
                  className="btn btn-primary mx-auto">
                  <i className="fa fa-save"></i>
                  Update
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPurchases;

import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import {
  useSingleCustomerGetQuery,
  useUpdateCustomerMutation,
} from "../../redux/api/customer/customerApi";

import toast from "react-hot-toast";
import Loader from "../../components/loader/Loader";
import { useSelector } from "react-redux";
const EditModal = ({ isModalOpen, closeModal, id }) => {
  const store= useSelector((state) => state.store)
  const { t } = useTranslation();
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [number, setNumber] = useState();
  const { data, isLoading } = useSingleCustomerGetQuery( { id, params: { storeId: store.storeId } }, {
    refetchOnMountOrArgChange: true,
  });

  const [updateCustomer] = useUpdateCustomerMutation();

  const handleSubmit = async (values, { resetForm }) => {
    setIsAddingLoading(true);
    const data ={
      ...values,
      storeId: store.storeId
    }
    const res = await updateCustomer({ id, values:data });

    if (res?.data) {
      toast.success("Customer Update Successful");
      resetForm();
    }
    setIsAddingLoading(false);
    closeModal();
  };
  const initialData = data?.data;
  const initialValues = {
    name: initialData?.name,
    email: initialData?.email,
    address: initialData?.address,
    mobile: initialData?.mobile,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Customer Name is required"),
    mobile: Yup.string().required("Phone is required"),
  });

  const validateMobile = (value) => {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^\d+$/.test(value)) {
      error = "Please enter only numbers";
    }
    return error;
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Modal show={isModalOpen} onHide={closeModal} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{t("Update Customer")} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {() => (
                <Form>
                  <div className="d-flex gap-1">
                    <div className="form-group col-md-6">
                      <label htmlFor="name">
                        {t("Name")}
                        <span className="field_required">*</span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Enter Customer Name"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="email">{t("Email")}</label>
                      <Field
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Enter Customer Email"
                      />
                    </div>
                  </div>
                  <div className="d-flex gap-1 my-3">
                    {" "}
                    <div className="form-group col-md-6">
                      <label htmlFor="address">{t("Address")}</label>
                      <Field
                        as="textarea"
                        className="form-control"
                        name="address"
                        placeholder="Write Customer Address"
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="mobile">
                        {t("Phone")}
                        <span className="field_required">*</span>
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        name="mobile"
                        placeholder="Enter Customer Phone"
                        validate={validateMobile}
                      />
                      <ErrorMessage
                        name="mobile"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      className="btn btn-outline-default"
                      onClick={() => closeModal()}
                      disabled={isAddingLoading}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-outline-theme"
                      disabled={isAddingLoading}
                    >
                      {isAddingLoading ? (
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        t("Update")
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default EditModal;

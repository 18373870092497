/* eslint-disable no-sequences */
import {baseApi} from "../baseApi";

export const posApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    salesCreate: build.mutation({
      query: (purchaseData) => ({
        url: "/sales",
        method: "POST",
        data: purchaseData,
      }),
      invalidatesTags: ["sales"],
    }),

    allSalesGet: build.query({
      query: (params) => ({
        url: "/sales",
        method: "GET",
        params,
      }),
      providesTags: ["sales"],
    }),
    singleSalesGet: build.query({
      query: ({id,params}) => ({
        url: `/sales/${id}`,
        method: "GET",
        params
      }),
      providesTags: ["sales"],
    }),

    updateSales: build.mutation({
      query: ({id, values}) => ({
        url: `/sales/${id}`,
        method: "PATCH",
        data: values,
      }),
      invalidatesTags: ["sales"],
    }),
    deleteSales: build.mutation({
      query: ({id,storeId}) => ({
        url: `/sales/${id}`,
        method: "DELETE",
        data:{storeId:storeId}
      }),
      invalidatesTags: ["sales"],
    }),
  }),
});

export const {
  useSalesCreateMutation,
  useAllSalesGetQuery,
  useSingleSalesGetQuery,
  useUpdateSalesMutation,
  useDeleteSalesMutation,
} = posApi;

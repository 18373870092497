import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAllTransactionTypeApiGetQuery } from "../../redux/api/transactionType/transactionTypeApi";
import ReactSelect from "../helpers/ReactSelect";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { usePurchaseCreateMutation } from "../../redux/api/purchase/purchaseApi";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";

const PurchaseModal = ({
  paymentModalVisible,
  handleClosePaymentModal,
  grandTotal,
  products,
  purchaseDate,
  supplierId,
}) => {
  const store = useSelector((state)=>state.store)
  const [transactionTypeId, setTransactionTypeId] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const { data: transactionTypeIdData } = useAllTransactionTypeApiGetQuery();

  const navigate = useNavigate();

  const initialTransactionTypeIdData = transactionTypeIdData?.data;
  const transactionTypeIdOptions = initialTransactionTypeIdData?.map(
    (item) => ({
      value: item?.id,
      label: item?.name,
    })
  );

  const handleTransectionChange = (values) => {
    setTransactionTypeId(values.value);
  };
  const initialValues = {
    note: "",
    paid:parseFloat(grandTotal) ,
  };

  const validationSchema = Yup.object().shape({
    isWallet: Yup.boolean().required("Please select wallet"),
  });

  const [purchaseCreate] = usePurchaseCreateMutation();

  const onSubmit = async (values, { resetForm }) => {
    setIsAdding(true);
    const data = {
      ...values,
      products,
      purchaseDate,
      supplierId,
      transactionTypeId,
      payable: parseFloat(grandTotal),
      storeId: parseInt(store?.storeId),
      
    };
    if (!values?.hasOwnProperty('isWallet')) {
      toast.error('Please select a wallet');
    }
    else {
      if (values?.paid > grandTotal) {
        toast.error("Payment amount must be less than or equal to grand total");

      } else {
        const res = await purchaseCreate(data);

        if (res?.data?.success === true) {
          toast.success(res?.data?.message);
          resetForm();

          navigate("/purchase");
          handleClosePaymentModal();
        }
      }

    }
    setIsAdding(false);
  };
  const options4 = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  return (
    <Modal show={paymentModalVisible} onHide={handleClosePaymentModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}>
          {() => (
            <Form>
              <table className="table table-bordered text-left">
                <tbody>
                  <tr>
                    <td width="50%">
                      <span className="d-flex justify-content-between align-items-center ">
                        <strong>Paying Items: </strong>
                        <strong>
                          (<span id="items">{products?.length}</span>)
                        </strong>
                      </span>
                    </td>
                    <td>
                      <span className="d-flex justify-content-between align-items-center ">
                        <strong>Total Payable: </strong>
                        <strong>
                          (<span id="payable">{grandTotal}</span> Tk)
                        </strong>
                        <input
                          type="hidden"
                          name="payable"
                          id="payable_input"
                          value="0"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <span className="d-flex justify-content-between align-items-center ">
                        <strong>Due</strong>
                        <strong className="">
                          (<span id="due">0</span> Tk)
                        </strong>
                        <input
                          type="hidden"
                          id="due_input"
                          name="due_amount"
                          value="0"
                        />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="form-row d-flex my-2 gap-1">
                <div className="form-group col-md-6">
                  <label htmlFor="transactionTypeId">
                    Transection Account
                    <span className="field_required"></span>
                  </label>
                  <ReactSelect
                    placeHolder="Select"
                    options={transactionTypeIdOptions}
                    handleChange={handleTransectionChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Wallet/Direct Transaction</label>

                  <Field className="form-control" name="isWallet">
                    {(props) => {
                      const handleChange = (option) => {
                        props.form.setFieldValue("isWallet", option.value);
                      };
                      return (
                        <ReactSelect
                          placeHolder="Select wallet..."
                          options={options4}
                          handleChange={handleChange}
                        />
                      );
                    }}
                  </Field>
                  <ErrorMessage
                    name="isWallet"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="d-flex my-2 gap-1">
                <div className="form-group col-md-6 mb-3">
                  <label htmlFor="paid">Pay Amount</label>
                  <Field
                    name="paid"
                    type="number"
                    step="any"
                    className="form-control"
                    placeholder="Pay Amount..."
                  />
                  <ErrorMessage
                    name="paid"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="note">Note</label>
                  <Field
                    name="note"
                    as="textarea"
                    className="form-control"
                    placeholder="Enter Note (Optional)"
                  />
                  <ErrorMessage
                    name="note"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="modal-footer">
                <button
                  className="btn btn-outline-default"
                  onClick={() => handleClosePaymentModal()}
                  data-bs-dismiss="modal"
                  disabled={isAdding}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-outline-theme"
                  disabled={isAdding}>
                  {isAdding ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
                  ) : (
                    "Payment"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default PurchaseModal;

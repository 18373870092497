/* eslint-disable no-sequences */
import {baseApi} from "../baseApi";

export const paymentsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    paymentCreate: build.mutation({
      query: (paymentData) => ({
        url: "/payments",
        method: "POST",
        data: paymentData,
      }),
      invalidatesTags: ["payments"],
    }),

    salePaymentCreate: build.mutation({
      query: (paymentData) => ({
        url: `/payments/solo/${paymentData.id}`,
        method: "POST",
        data: paymentData.data,
      }),
      invalidatesTags: ["payments", "sales", "purchase"],
    }),
    paymentListSupplierAndCustomer: build.mutation({
      query: ({id, values}) => ({
        url: `/payments/user/${id}`,
        method: "POST",
        data: values,
      }),
      invalidatesTags: ["customer", "supplier"],
    }),

    allPaymentGet: build.query({
      query: (params) => ({
        url: "/payments",
        method: "GET",
        params,
      }),
      providesTags: ["payments"],
    }),

    deletePayment: build.mutation({
      query: ({id,storeId}) => ({
        url: `/payments/${id}`,
        method: "DELETE",
        data:{storeId:storeId}
      }),
      invalidatesTags: ["payments"],
    }),
  }),
});

export const {
  usePaymentCreateMutation,
  useSalePaymentCreateMutation,
  useAllPaymentGetQuery,
  usePaymentListSupplierAndCustomerMutation,
  useDeletePaymentMutation,
} = paymentsApi;

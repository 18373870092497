import React from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardExpandToggler } from "../../components/card/card";

import Loader from "../../components/loader/Loader";
import moment from "moment";
import { useSingleCustomerReportQuery } from "../../redux/api/customer/customerApi";
import { useTranslation } from "react-i18next";
import Currency from "../../Shared/Currency";
import { useSelector } from "react-redux";
const CustomerReport = () => {
  const store= useSelector((state) => state.store)
  const { id } = useParams();

  const { data, isLoading } = useSingleCustomerReportQuery({ id, params: { storeId: store.storeId } }, {
    refetchOnMountOrArgChange: true,
  });

  const initalData = data?.data;
  const { t } = useTranslation();
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div>
            <h1>
              {initalData?.customerInfo?.name}{" "}
              <span className="fs-6">{t("customer")}</span>
            </h1>
          </div>
          <div className="row py-3">
            <div className="col-xl-3 col-lg-3">
              <Card className="mb-3">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">
                      {" "}
                      {t("total")} {t("purchases")}{" "}
                    </span>
                    <CardExpandToggler />
                  </div>
                  <div className="row align-items-center mb-2">
                    <div>
                      <h3 className="mb-0">
                        {Currency}{" "}
                        {(initalData?.customerInfo?.receivable)?.toFixed(2)}
                      </h3>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-xl-3 col-lg-3">
              <Card className="mb-3">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">
                      {t("total")} {t("Paid")}
                    </span>
                    <CardExpandToggler />
                  </div>
                  <div className="row align-items-center mb-2">
                    <div>
                      <h3 className="mb-0">
                        {Currency} {(initalData?.customerInfo?.paid)?.toFixed(2)}
                      </h3>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-xl-3 col-lg-3">
              <Card className="mb-3">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">
                      {t("total")} {t("Due")}
                    </span>
                    <CardExpandToggler />
                  </div>
                  <div className="row align-items-center mb-2">
                    <div>
                      <h3 className="mb-0">
                        {Currency}{" "}
                        {(initalData?.customerInfo?.totalDue)?.toFixed(2)}
                      </h3>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-xl-3 col-lg-3">
              <Card className="mb-3">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">{t("Information")}</span>
                    <CardExpandToggler />
                  </div>
                  <div className="row align-items-center mb-2">
                    <div>
                      <h3 className="mb-0 fs-6">
                        {t("Address")} {initalData?.customerInfo?.address}{" "}
                        <br />
                        {t("Phone")} {initalData?.customerInfo?.mobile}
                      </h3>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>

          <div>
            <div>
              <h4 class="card-title">
                <strong>
                  {" "}
                  {initalData?.customerInfo?.name}{" "}
                  <span className="fs-6">- {t("History")}</span>
                </strong>
              </h4>
              <hr />
              <h6>{t("Sales Report")}</h6>

              <div class="card-body">
                <div className="mt-4">
                  <table class="table table-hover text-nowrap  table table-bordered  fw-semibold text-nowrap mb-3">
                    <thead>
                      <tr>
                        <th>
                          {t("sale")} {t("Date")}
                        </th>
                        <th>
                          {t("total")} {t("product")}
                        </th>
                        <th>
                          {t("total")} {t("Bill")}
                        </th>
                        <th>{t("Paid")}</th>
                        <th>{t("Due")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {initalData?.salesReport?.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {" "}
                            {moment(item?.purchaseDate).format("DD MMMM YYYY")}
                          </td>
                          <td>{item?.products?.length}</td>
                          <td>
                            {Currency}
                            {item?.payable?.toFixed(2)}{" "}
                          </td>
                          <td>
                            {Currency}
                            {item?.paid?.toFixed(2)}
                          </td>
                          <td>
                            {" "}
                            {Currency}
                            {item?.due?.toFixed(2)}{" "}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <h6 className="mt-4">
                {t("sale")} {t("Payment")} {t("Report")}
              </h6>
              <div class="card-body">
                <div className="mt-4">
                  <table class="table table-hover text-nowrap  table table-bordered  fw-semibold text-nowrap mb-3">
                    <thead>
                      <tr>
                        <th>{t("Invoice")}</th>
                        <th>{t("Payment Date")}</th>
                        <th>
                          {t("Paid")} {t("Amount")}
                        </th>
                        <th>{t("Payment Method")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {initalData?.paymentList?.map((item, index) => (
                        <tr key={index}>
                          <td>{item?.id}</td>
                          <td>
                            {" "}
                            {moment(item?.paymentDate).format("DD MMMM YYYY")}
                          </td>
                          <td> {item?.amount} {Currency}</td>
                          <td> {0} {Currency}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerReport;

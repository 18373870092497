import React, {useEffect, useRef, useState} from "react";
import ReactSelect from "../helpers/ReactSelect";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import moment from "moment/moment";
import DatepickerHelper from "../helpers/DatepickerHelper";
import {
  useAllPurchaseGetQuery,
  useDeletePurchaseMutation,
} from "../../redux/api/purchase/purchaseApi";
import Loader from "../../components/loader/Loader";
import {useAllSupplierGetQuery} from "../../redux/api/supplier/supplierApi";
import {useAllProductGetQuery} from "../../redux/api/product/productApi";
import toast from "react-hot-toast";
import PurchasePaymentModal from "./PurchasePaymentModal";
import DeleteConfirmationModal from "../deleteConfirmationModal/DeleteConfirmationModal";
import {Pagination} from "react-bootstrap";
import Currency from "../../Shared/Currency";
import {useAllUnitGetQuery} from "../../redux/api/units/unitsApi";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
const PurchaseList = () => {
  const store = useSelector((state) => state.store);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [billNo, setBillNo] = useState();
  const [billNoFieldValue, setBillNoFieldValue] = useState();
  const [startDate, setStartDate] = useState();
  const [startDateFieldValue, setStartDateFieldValue] = useState();
  const [endDate, setEndDate] = useState();
  const [endDateFieldValue, setEndDateFieldValue] = useState();
  const [slectSupplier, setSlectSupplier] = useState();
  const [supplierId, setSupplierId] = useState();
  const [slectProduct, setSlectProduct] = useState();
  const [productId, setProductId] = useState();
  const [selectedId, setSelectedId] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState();
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [filter, setFilter] = useState({
    page,
    limit: pageSize,
    storeId: store.storeId,
  });
  const tableRef = useRef(null);
  const {data, refetch, isLoading} = useAllPurchaseGetQuery(filter);
  const {data: supplierData} = useAllSupplierGetQuery({
    storeId: store.storeId,
  });
  const {data: productData} = useAllProductGetQuery({
    storeId: store.storeId,
  });

  const [deletePurchase] = useDeletePurchaseMutation({});
  const {data: unit} = useAllUnitGetQuery(
    {storeId: store.storeId},
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const initialData = unit?.data;
  const {t} = useTranslation();
  const handleDelete = async (id) => {
    setIsAddingLoading(true);
    const res = await deletePurchase(id);
    if (res?.data?.success === true) {
      toast.success(res?.data?.message);
    }
    setIsAddingLoading(false);
    setIsDeleteConfirmationModalOpen(false);
  };

  const purchasesData = data?.data;
  const meta = data?.meta;
  useEffect(() => {
    const updatedFilter = {...filter};
    updatedFilter.page = page;
    if (billNo) {
      updatedFilter.id = billNo;
    }
    if (!billNoFieldValue) {
      delete updatedFilter.id;
    }
    if (!startDateFieldValue) {
      delete updatedFilter.startDate;
    }
    if (startDate) {
      updatedFilter.startDate = startDate;
    }
    if (!endDateFieldValue) {
      delete updatedFilter.endDate;
    }
    if (endDate) {
      updatedFilter.endDate = endDate;
    }
    if (!slectSupplier) {
      delete updatedFilter.supplierId;
    }
    if (supplierId) {
      updatedFilter.supplierId = supplierId;
    }
    if (!slectProduct) {
      delete updatedFilter.productId;
    }
    if (productId) {
      updatedFilter.productId = productId;
    }
    setFilter(updatedFilter);

    refetch();
  }, [
    page,
    billNo,
    billNoFieldValue,
    endDateFieldValue,
    startDate,
    endDate,
    endDateFieldValue,
    supplierId,
    productId,
  ]);

  const supplierOpt = supplierData?.data?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));
  const product = productData?.data?.map((item) => ({
    value: item?.id,
    label: item?.productName,
  }));

  const exportAsPDF = async () => {
    const input = tableRef.current;

    if (!input) {
      return;
    }
    const canvas = await html2canvas(input);

    const tableWidth = canvas.width;
    const tableHeight = canvas.height;
    const pdfWidth = tableWidth / 9;
    const pdfHeight = tableHeight / 6.84;

    const pdf = new jsPDF("p", "mm", "a4");
    pdf.addImage(canvas, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("table.pdf");
  };
  const handleSubmit = () => {
    // setExpenseCategory(categoryFieldValue);
    setStartDate(moment(startDateFieldValue).format());
    setEndDate(moment(endDateFieldValue).format());
    setBillNo(billNoFieldValue);
    setSupplierId(slectSupplier);
    setProductId(slectProduct);
  };

  const handleSupplierSelect = (values) => {
    setSlectSupplier(values.value);
  };
  const handleProductSelect = (values) => {
    setSlectProduct(values.value);
  };

  const openModal = (id, customerId) => {
    setIsModalOpen(true);
    setSelectedId(id);
    setSelectedUserId(customerId);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openDeleteConfirmationModal = (id) => {
    setIsDeleteConfirmationModalOpen(true);
    setSelectedItemId(id);
  };

  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(false);
  };
  const getUnitName = (id) => {
    const unit = initialData?.find((data) => data?.id === id);
    if (!unit) {
      return <Loader />;
    }
    return unit;
  };

  const handlePrint = () => {
    const printWindow = window.open("Purchases-List", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title> ${t("purchases")} ${t("List")}</title>
          <style>
            body {
              font-family: 'Arial', sans-serif;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              border: 1px solid #ddd;
              padding: 10px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
            @media print {
              header::before {
                content: "Sales List";
                display: block;
              
              }
              table {
                font-size: 12px;
              }
            }
          </style>
        </head>
        <body>
       <h1 style={{ width: '100%', textAlign: 'center' }}>  ${t(
         "purchases"
       )} ${t("List")}</h1>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
          <thead>
          <tr>
          <th className="border-top-0 pt-0 pb-2">${t("Serial")}</th>
          <th className="border-top-0 pt-0 pb-2">${t("Bill No")}</th>
          <th className="border-top-0 pt-0 pb-2">${t("supplier")}</th>
          <th className="border-top-0 pt-0 pb-2">${t("Date")}</th>
          <th className="border-top-0 pt-0 pb-2">${t("product")}</th>
          <th className="border-top-0 pt-0 pb-2">${t("payable")}</th>
          <th className="border-top-0 pt-0 pb-2">${t("Paid")}</th>
          <th className="border-top-0 pt-0 pb-2">${t("Due")}</th>
   
        </tr>
          </thead>
          ${purchasesData?.map((item, index) => {
            return `
            <tr >
              <td className="align-middle">${index + 1}</td>
                <td className="align-middle">${item?.id}</td>
                <td className="align-middle">${item?.supplier?.name}</td>
                <td className="align-middle">
                      ${moment(item?.purchaseDate).format("DD MMMM YYYY")}
                    </td>
                    <td>
                      <ul>
                        ${item?.products?.map(
                          (product, index) =>
                            ` <li key=${index}>
                            ${product?.name} |
                            ${
                              product?.subUnitsId
                                ? `
                                ${Math?.floor(
                                  product?.qty /
                                    parseInt(
                                      getUnitName(product?.unitId)
                                        ?.relatedByValue
                                    )
                                )}{" "}
                                ${getUnitName(product?.unitId).name}{" "}
                                ${Math?.floor(
                                  product?.qty %
                                    parseInt(
                                      getUnitName(product?.unitId)
                                        .relatedByValue
                                    )
                                )}{" "}
                                ${getUnitName(product?.subUnitsId)?.name}
                              `
                                : `
                                ${product?.qty}
                               ${getUnitName(product?.unitId)?.name}
                              `
                            }
                          </li>`
                        )}
                      </ul>
                    </td>

                    <td className="py-1 align-middle">
                      <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                        ${Currency}: ${item?.payable}
                      </span>
                    </td>
                    <td className="align-middle">
                      <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                        ${Currency}: ${item?.paid}
                      </span>
                    </td>
                    <td className="align-middle">
                      <span
                        className=${`badge border 
                      ${
                        item?.due > 0
                          ? "border-danger text-danger"
                          : "border-success text-success"
                      } 
                        px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center`}
                      >
                        ${Currency}: ${item?.due.toFixed(3)}
                      </span>
                    </td>
                  </tr>
            `;
          })}
        </table>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };
  return (
    <div className="mb-2">
      <header className="header text-white">
        <div className="header-info">
          <h1 className="page-header">
            {" "}
            {t("purchases")} {t("List")}
          </h1>
          <hr className="mb-3" />
        </div>
      </header>

      <div className="row">
        <div className="col-md-2">
          {/* <label htmlFor="bill_number">Bill Number</label> */}
          <input
            type="text"
            className="form-control "
            placeholder="Bill No"
            value={billNoFieldValue}
            onChange={(e) => setBillNoFieldValue(e.target.value)}
          />
        </div>
        <div className="col-md-2">
          {/* <label htmlFor="start_date">Start Date</label> */}
          <DatepickerHelper
            handleDateChange={(date) => setStartDateFieldValue(date)}
            selectedDate={startDateFieldValue}
            placeHolder="Select start date"
          />
        </div>
        <div className="col-md-2">
          {/* <label htmlFor="end_date">End Date</label> */}
          <DatepickerHelper
            handleDateChange={(date) => setEndDateFieldValue(date)}
            selectedDate={endDateFieldValue}
            placeHolder="Select end date"
          />
        </div>

        <div className="form-group col-md-3 ">
          {/* <label htmlFor="supplier">Select Supplier</label> */}
          <ReactSelect
            handleChange={handleSupplierSelect}
            placeHolder="Select Supplier"
            options={supplierOpt}
            name="supplier"
          />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        {" "}
        <div className="mt-2 d-flex gap-2">
          <div className="form-group">
            <button
              className="btn btn-primary"
              type="submit"
              onClick={handleSubmit}>
              <i className="fa fa-sliders"></i> {t("Filter")}
            </button>
          </div>
          <div className="form-group">
            <button
              className="btn btn-info"
              type="reset"
              onClick={() => {
                setBillNoFieldValue("");
                setBillNo("");
                setSlectSupplier();
                setSupplierId();

                setStartDate("");
                setStartDateFieldValue("");
                setEndDate("");
                setEndDateFieldValue("");
                const updatedFilter = {...filter};
                delete updatedFilter.id;

                delete updatedFilter.startDate;
                delete updatedFilter.endDate;
                delete updatedFilter.supplierId;
                setFilter(updatedFilter);
                refetch();
              }}>
              {t("Reset")}
            </button>
          </div>
        </div>
        <div>
          <button
            onClick={handlePrint}
            style={{width: "120px", height: "35px"}}
            className="btn btn-outline-theme">
            <i class="bi bi-printer pr-2" style={{marginRight: "5px"}}></i>
            {t("Print")}
          </button>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="my-4">
          <div className="table-responsive">
            <table
              ref={tableRef}
              id="datatable"
              className="table table-hover text-nowrap">
              <thead>
                <tr>
                  <th className="border-top-0 pt-0 pb-2">{t("Serial")}</th>
                  <th className="border-top-0 pt-0 pb-2">{t("Bill No")}</th>
                  <th className="border-top-0 pt-0 pb-2">{t("supplier")}</th>
                  <th className="border-top-0 pt-0 pb-2">{t("Date")}</th>
                  <th className="border-top-0 pt-0 pb-2">{t("product")}</th>
                  <th className="border-top-0 pt-0 pb-2">{t("payable")}</th>
                  <th className="border-top-0 pt-0 pb-2">{t("Paid")}</th>
                  <th className="border-top-0 pt-0 pb-2">{t("Due")}</th>
                  <th className="border-top-0 pt-0 pb-2">{t("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {purchasesData?.map((item, index) => (
                  <tr key={index}>
                    <td className="align-middle">{index + 1}</td>
                    <td className="align-middle">{item?.id}</td>
                    <td className="align-middle">{item?.supplier?.name}</td>
                    <td className="align-middle">
                      {moment(item?.purchaseDate).format("DD MMMM YYYY")}
                    </td>
                    <td>
                      <ul>
                        {item?.products?.map((product, index) => (
                          <li key={index}>
                            {product?.name} |{" "}
                            {product?.subUnitsId ? (
                              <>
                                {Math?.floor(
                                  product?.qty /
                                    parseInt(
                                      getUnitName(product?.unitId)
                                        ?.relatedByValue
                                    )
                                )}{" "}
                                {getUnitName(product?.unitId).name}{" "}
                                {Math?.floor(
                                  product?.qty %
                                    parseInt(
                                      getUnitName(product?.unitId)
                                        .relatedByValue
                                    )
                                )}{" "}
                                {getUnitName(product?.subUnitsId)?.name}
                              </>
                            ) : (
                              <>
                                {product?.qty}{" "}
                                {getUnitName(product?.unitId)?.name}
                              </>
                            )}{" "}
                          </li>
                        ))}
                      </ul>
                    </td>

                    <td className="py-1 align-middle">
                      <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                        {Currency}: {item?.payable}
                      </span>
                    </td>
                    <td className="align-middle">
                      <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                        {Currency}: {item?.paid}
                      </span>
                    </td>
                    <td className="align-middle">
                      <span
                        className={`badge border 
                      ${
                        item?.due > 0
                          ? "border-danger text-danger"
                          : "border-success text-success"
                      } 
                        px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center`}>
                        {Currency}: {item?.due.toFixed(3)}
                      </span>
                    </td>
                    <td className="align-middle">
                      <span
                        className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center cursor-pointer "
                        data-bs-toggle="dropdown">
                        <i className="bi bi-gear fs-9px fa-fw me-5px"></i> E/D
                      </span>

                      <div className="dropdown-menu">
                        {/* <Link
                          className="dropdown-item"
                          to={`/edit-purchases/${item?.id}`}
                        >
                          <i
                            class="bi bi-pencil-square"
                            style={{ marginRight: "5px" }}
                          ></i>
                          Edit
                        </Link> */}
                        <a className="dropdown-item" href="#/">
                          <i class="bi bi-tv" style={{marginRight: "5px"}}></i>
                          {t("Show")}
                        </a>

                        <button
                          onClick={() =>
                            openModal(item?.id, item?.supplier?.id)
                          }
                          className="dropdown-item">
                          <i
                            className="bi bi-cash-coin pr-2"
                            style={{marginRight: "5px"}}></i>
                          {t("Add Payment")}
                        </button>
                        <a className="dropdown-item" href="#/">
                          <i
                            class="bi bi-printer pr-2"
                            style={{marginRight: "5px"}}></i>
                          {t("Invoice")}
                        </a>

                        <button
                          onClick={() => openDeleteConfirmationModal(item?.id)}
                          className="dropdown-item">
                          <i
                            className="bi bi-trash pr-2"
                            style={{marginRight: "5px"}}></i>
                          {t("Delete")}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <Pagination
        page={page}
        setPage={setPage}
        meta={meta}
        pageSize={pageSize}
      />
      {isModalOpen && (
        <PurchasePaymentModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          id={selectedId}
          userId={selectedUserId}
        />
      )}

      {isDeleteConfirmationModalOpen && (
        <DeleteConfirmationModal
          isAddingLoading={isAddingLoading}
          closeModal={closeDeleteConfirmationModal}
          confirmDelete={() => handleDelete(selectedItemId)}
        />
      )}
    </div>
  );
};

export default PurchaseList;

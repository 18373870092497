import React from "react";
import { Navigate } from "react-router-dom";
import Error from "../Shared/Error.jsx";
import NewProfile from "../pages/Profile/NewProfile.jsx";
import Bank from "../pages/bank/AddBank.jsx";
import Brands from "../pages/brands/Brands.jsx";
import Categories from "../pages/categories/Categories.jsx";
import CurrentMonthReport from "../pages/currentMonthReport/CurreentMonthReport.jsx";
import Customers from "../pages/customer/Customer.jsx";
import CustomerPaymentList from "../pages/customer/CustomerPaymentList.jsx";
import CustomerReport from "../pages/customer/CustomerReport.jsx";
import SalesList from "../pages/customer/SalesList.jsx";
import CustomerDueReport from "../pages/customerDueReport/CustomerDueReport.jsx";
import CustomerLedger from "../pages/customerLedger/CustomerLedger.jsx";
import DailyReport from "../pages/dailyReport/DailyReport.jsx";
import Damages from "../pages/damages/Damages.jsx";
import ExpenseCategory from "../pages/expenses/ExpenseCategory.jsx";
import Expenses from "../pages/expenses/Expenses.jsx";
import Login from "../pages/login/Login.jsx";
import LowStockReport from "../pages/lowStockReport/LowStockReport.jsx";
import Owners from "../pages/owners/Owners.js";
import Payments from "../pages/payments/Payments.jsx";
import Products from "../pages/products/Products.jsx";
import ProfitLossReport from "../pages/profitLossReport/ProfitLossReport.jsx";
import PurchasesReport from "../pages/purchaseReport/PurchaseReport.jsx";
import AddPurchases from "../pages/purchases/AddPurchases.jsx";
import EditPurchases from "../pages/purchases/EditPurchases.jsx";
import PurchaseList from "../pages/purchases/PurchaseList.jsx";
import Return from "../pages/returns/Return.jsx";
import ReturnList from "../pages/returns/ReturnList.jsx";
import Edit from "../pages/sales/Edit.jsx";
import Sales from "../pages/sales/Sales.jsx";
import Settings from "../pages/settings/settings.js";
import SRManages from "../pages/srmanages/SRManages.jsx";
import Stocks from "../pages/stocks/Stocks.jsx";
import SummeryReport from "../pages/summeryReport/SummeryReport.jsx";
import Supplier from "../pages/supplier/Supplier.jsx";
import SupplierPaymentList from "../pages/supplier/SupplierPaymentList.jsx";
import SupplierPurchaseList from "../pages/supplier/SupplierPurchaseList.jsx";
import SupplierReport from "../pages/supplier/SupplierReport.jsx";
import SupplierDueReport from "../pages/supplierDueReport/SupplierDueReport.jsx";
import SupplierLedger from "../pages/supplierLedger/SupplierLedger.jsx";
import TodayReport from "../pages/todayReport/TodayReport.jsx";
import TopCustomers from "../pages/topCustomer/TopCustomer.jsx";
import TopProductAllTime from "../pages/topProductAllTime/TopProductAllTime.jsx";
import TopProducts from "../pages/topProducts/TopProducts.jsx";
import Units from "../pages/unit/Units.js";
import App from "./../app.jsx";
import Dashboard from "./../pages/dashboard/dashboard.js";
import EmailCompose from "./../pages/email/compose.js";
import PosCustomerOrder from "./../pages/pos/customer-order.js";
import PrivateRoute from "./PrivateRoute.jsx";
import Self from "../pages/self/Self.jsx";
import Clients from "../pages/clients/Clients.jsx";

const token = localStorage.getItem("role");

const admin = [
  {
    path: "*",
    errorElement: <Error />,
    element: <App />,

    children: [
      { path: "", element: <Navigate to="/dashboard" /> },

      {
        path: "dashboard",

        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
      },
      { path: "owners", element: <Owners /> },
      { path: "login", element: <Login /> },
      { path: "bank-accounts", element: <Bank /> },
      {
        path: "sms",
        element: (
          <PrivateRoute>
            <EmailCompose />
          </PrivateRoute>
        ),
      },

      {
        path: "unit/*",
        element: (
          <PrivateRoute>
            <Units />
          </PrivateRoute>
        ),
      },
      {
        path: "brands/*",
        element: (
          <PrivateRoute>
            <Brands />
          </PrivateRoute>
        ),
      },
      {
        path: "self",
        element: (
          <PrivateRoute>
            <Self />
          </PrivateRoute>
        ),
      },
      {
        path: "products",
        element: (
          <PrivateRoute>
            <Products />
          </PrivateRoute>
        ),
      },
      {
        path: "clients",
        element: (
          <PrivateRoute>
            <Clients />
          </PrivateRoute>
        ),
      },
      // {
      //   path: "add-product",
      //   element: (
      //     <PrivateRoute>
      //       <AddProducts />
      //     </PrivateRoute>
      //   ),
      // },
      // {
      //   path: "edit-product/:id",
      //   element: (
      //     <PrivateRoute>
      //       <EditProducts />
      //     </PrivateRoute>
      //   ),
      // },
      {
        path: "categories",
        element: (
          <PrivateRoute>
            <Categories />
          </PrivateRoute>
        ),
      },
      {
        path: "expenses",
        element: (
          <PrivateRoute>
            <Expenses />
          </PrivateRoute>
        ),
      },
      {
        path: "expense-category",
        element: (
          <PrivateRoute>
            <ExpenseCategory />
          </PrivateRoute>
        ),
      },
      {
        path: "payments",
        element: (
          <PrivateRoute>
            <Payments />
          </PrivateRoute>
        ),
      },

      {
        path: "customers",
        element: (
          <PrivateRoute>
            <Customers />
          </PrivateRoute>
        ),
      },
      {
        path: "customers/sales-list/:id",
        element: (
          <PrivateRoute>
            <SalesList />
          </PrivateRoute>
        ),
      },
      {
        path: "customers/payment-list/:id",
        element: (
          <PrivateRoute>
            <CustomerPaymentList />
          </PrivateRoute>
        ),
      },
      {
        path: "customers/report-list/:id",
        element: (
          <PrivateRoute>
            <CustomerReport />
          </PrivateRoute>
        ),
      },
      {
        path: "peoples",
        element: (
          <PrivateRoute>
            <SRManages />
          </PrivateRoute>
        ),
      },
      {
        path: "suppliers",
        element: (
          <PrivateRoute>
            <Supplier />
          </PrivateRoute>
        ),
      },
      {
        path: "suppliers/purchase-list/:id",
        element: (
          <PrivateRoute>
            <SupplierPurchaseList />
          </PrivateRoute>
        ),
      },
      {
        path: "suppliers/payment-list/:id",
        element: (
          <PrivateRoute>
            <SupplierPaymentList />
          </PrivateRoute>
        ),
      },
      {
        path: "suppliers/report-list/:id",
        element: (
          <PrivateRoute>
            <SupplierReport />
          </PrivateRoute>
        ),
      },

      {
        path: "sales/*",
        children: [
          {
            path: "sale",
            element: (
              <PrivateRoute>
                <Sales />
              </PrivateRoute>
            ),
          },
          {
            path: "edit-sales/:id",
            element: (
              <PrivateRoute>
                <Edit />
              </PrivateRoute>
            ),
          },
          {
            path: "returnlist",
            element: (
              <PrivateRoute>
                <ReturnList />
              </PrivateRoute>
            ),
          },
          {
            path: "return/:id",
            element: (
              <PrivateRoute>
                <Return />
              </PrivateRoute>
            ),
          },
          {
            path: "damages",
            element: (
              <PrivateRoute>
                <Damages />
              </PrivateRoute>
            ),
          },
          {
            path: "stock",
            element: (
              <PrivateRoute>
                <Stocks />
              </PrivateRoute>
            ),
          },
        ],
      },

      {
        path: "add-purchase",
        element: (
          <PrivateRoute>
            <AddPurchases />
          </PrivateRoute>
        ),
      },
      {
        path: "purchase",
        element: (
          <PrivateRoute>
            <PurchaseList />
          </PrivateRoute>
        ),
      },
      {
        path: "edit-purchases/:id",
        element: (
          <PrivateRoute>
            <EditPurchases />
          </PrivateRoute>
        ),
      },
      {
        path: "customer-order",

        element: (
          <PrivateRoute>
            <PosCustomerOrder />
          </PrivateRoute>
        ),
      },

      {
        path: "profit-loss",
        element: (
          <PrivateRoute>
            <ProfitLossReport />
          </PrivateRoute>
        ),
      },
      {
        path: "today",
        element: (
          <PrivateRoute>
            <TodayReport />
          </PrivateRoute>
        ),
      },
      {
        path: "current-month",
        element: (
          <PrivateRoute>
            <CurrentMonthReport />
          </PrivateRoute>
        ),
      },
      {
        path: "summery",
        element: (
          <PrivateRoute>
            <SummeryReport />
          </PrivateRoute>
        ),
      },
      {
        path: "daily",
        element: (
          <PrivateRoute>
            <DailyReport />
          </PrivateRoute>
        ),
      },
      {
        path: "customer-due",
        element: (
          <PrivateRoute>
            <CustomerDueReport />
          </PrivateRoute>
        ),
      },
      {
        path: "supplier-due",
        element: (
          <PrivateRoute>
            <SupplierDueReport />
          </PrivateRoute>
        ),
      },
      {
        path: "low-stock",
        element: (
          <PrivateRoute>
            <LowStockReport />
          </PrivateRoute>
        ),
      },
      {
        path: "top-customer",
        element: (
          <PrivateRoute>
            <TopCustomers />
          </PrivateRoute>
        ),
      },
      {
        path: "top-product",
        element: (
          <PrivateRoute>
            <TopProducts />
          </PrivateRoute>
        ),
      },
      {
        path: "top-product-all-time",
        element: (
          <PrivateRoute>
            <TopProductAllTime />
          </PrivateRoute>
        ),
      },
      {
        path: "purchase-report",
        element: (
          <PrivateRoute>
            <PurchasesReport />
          </PrivateRoute>
        ),
      },
      {
        path: "customer-ledger",
        element: (
          <PrivateRoute>
            <CustomerLedger />
          </PrivateRoute>
        ),
      },
      {
        path: "supplier-ledger",
        element: (
          <PrivateRoute>
            <SupplierLedger />
          </PrivateRoute>
        ),
      },
      {
        path: "settings",
        element: (
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        ),
      },
      {
        path: "profile",
        element: (
          <PrivateRoute>
            <NewProfile />
          </PrivateRoute>
        ),
      },
    ],
  },
];
const SR = [
  {
    path: "*",
    errorElement: <Error />,
    element: <App />,

    children: [
      { path: "", element: <Navigate to="/dashboard" /> },

      {
        path: "dashboard",

        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
      },

      { path: "login", element: <Login /> },

      {
        path: "expenses",
        element: (
          <PrivateRoute>
            <Expenses />
          </PrivateRoute>
        ),
      },
      {
        path: "expense-category",
        element: (
          <PrivateRoute>
            <ExpenseCategory />
          </PrivateRoute>
        ),
      },

      // {
      //   path: "customers",
      //   element: (
      //     <PrivateRoute>
      //       <Customers />
      //     </PrivateRoute>
      //   ),
      // },
      // {
      //   path: "customers/sales-list/:id",
      //   element: (
      //     <PrivateRoute>
      //       <SalesList />
      //     </PrivateRoute>
      //   ),
      // },
      // {
      //   path: "customers/payment-list/:id",
      //   element: (
      //     <PrivateRoute>
      //       <CustomerPaymentList />
      //     </PrivateRoute>
      //   ),
      // },
      // {
      //   path: "customers/report-list/:id",
      //   element: (
      //     <PrivateRoute>
      //       <CustomerReport />
      //     </PrivateRoute>
      //   ),
      // },

      {
        path: "sales/*",
        children: [
          {
            path: "sale",
            element: (
              <PrivateRoute>
                <Sales />
              </PrivateRoute>
            ),
          },
          {
            path: "edit-sales/:id",
            element: (
              <PrivateRoute>
                <Edit />
              </PrivateRoute>
            ),
          },
          {
            path: "returnlist",
            element: (
              <PrivateRoute>
                <ReturnList />
              </PrivateRoute>
            ),
          },
          {
            path: "return/:id",
            element: (
              <PrivateRoute>
                <Return />
              </PrivateRoute>
            ),
          },
          {
            path: "damages",
            element: (
              <PrivateRoute>
                <Damages />
              </PrivateRoute>
            ),
          },
          {
            path: "stock",
            element: (
              <PrivateRoute>
                <Stocks />
              </PrivateRoute>
            ),
          },
        ],
      },

      {
        path: "customer-order",

        element: (
          <PrivateRoute>
            <PosCustomerOrder />
          </PrivateRoute>
        ),
      },
      {
        path: "settings",
        element: (
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        ),
      },
      {
        path: "profile",
        element: (
          <PrivateRoute>
            <NewProfile />
          </PrivateRoute>
        ),
      },
    ],
  },
];
const AppRoute = token === "SR" ? SR : admin;
export default AppRoute;

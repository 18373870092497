import React, {useState} from "react";

import Accounts from "./Accounts";
import AccountModal from "./AccountModal";
const AddBank = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div >
    <header className="header text-white py-3">
        <div className="header-info">
          <h1 className="page-header">Accounts</h1>
          <hr className="mb-4" />
        </div>
      </header>
      <div className=" mt-2">
        <button
          style={{width: "150px", height: "35px"}}
          className="btn btn-outline-theme"
          onClick={openModal}>
          <i className="fa fa-plus-circle me-1"></i>Add Account
        </button>
      </div>
      {isModalOpen && (
        <AccountModal isModalOpen={isModalOpen} closeModal={closeModal} />
      )}
      <Accounts />
    </div>
  );
};

export default AddBank;

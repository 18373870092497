import React from "react";
import {Formik, Field, Form, ErrorMessage} from "formik";
import * as Yup from "yup";
const AccountModal = ({closeModal, isModalOpen}) => {
  const initialValues = {
    name: "",
    opening_balance: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Account Name is required"),
    opening_balance: Yup.number().required("Opening Balance is required"),
  });

  const onSubmit = (values, actions) => {
    // Handle form submission here

    actions.setSubmitting(false);
  };
  return (
    <div
      className={`modal fade mt-5 ${isModalOpen ? "show" : ""}`}
      tabIndex="-1"
      role="dialog"
      style={{display: isModalOpen ? "block" : "none"}}>
      <div style={{display: "block"}} className="modal-dialog ">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">New Account</h5>
            <button
              type="button"
              onClick={() => closeModal()}
              className="btn-close"
              data-bs-dismiss="modal"></button>
          </div>

          <div className="card-body px-2">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}>
              <Form>
                <div className="form-row  ">
                  <div className="form-group  mt-4">
                    <label htmlFor="name">Account Name</label>
                    <Field
                      type="text"
                      name="name"
                      placeholder="Enter Account Name"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group  mt-4 mb-4">
                    <label htmlFor="opening_balance">Opening Balance</label>
                    <Field
                      type="number"
                      name="opening_balance"
                      placeholder="Opening Balance"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="opening_balance"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    onClick={() => closeModal()}
                    className="btn btn-outline-default"
                    data-bs-dismiss="modal">
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={() => closeModal()}
                    className="btn btn-outline-theme">
                    Save
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountModal;

/* eslint-disable no-sequences */
import {baseApi} from "../baseApi";

export const returnApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    returnsCreate: build.mutation({
      query: (returnsData) => ({
        url: "/returns",
        method: "POST",
        data: returnsData,
      }),
      invalidatesTags: ["returns"],
    }),

    allReturnsGet: build.query({
      query: (params) => ({
        url: "/returns",
        method: "GET",
        params,
      }),
      providesTags: ["returns"],
    }),
    singleReturnGet: build.query({
      query: ({id,params}) => ({
        url: `/returns/${id}`,
        method: "GET",
        params
      }),
      providesTags: ["returns"],
    }),

    updateReturn: build.mutation({
      query: ({id, values}) => ({
        url: `/returns/${id}`,
        method: "PATCH",
        data: values,
      }),
      invalidatesTags: ["returns"],
    }),
    deleteReturn: build.mutation({
      query: (id) => ({
        url: `/returns/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["returns"],
    }),
  }),
});

export const {
  useReturnsCreateMutation,
  useAllReturnsGetQuery,
  useSingleReturnGetQuery,
  useUpdateReturnMutation,
  useDeleteReturnMutation,
} = returnApi;

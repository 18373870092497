import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Loader from "../../components/loader/Loader";
import { useSingleSalesGetQuery } from "../../redux/api/pos/posApi";
import { useAllUnitGetQuery } from "../../redux/api/units/unitsApi";
import { useReturnsCreateMutation } from "../../redux/api/return/returnApi";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Return = () => {
  const store = useSelector((state) => state.store);
  const { id } = useParams();
  const [grandTotal, setGrandTotal] = useState(0);
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const { data, isLoading } = useSingleSalesGetQuery(
    { id, params: { storeId: store.storeId } },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [returnsCreate] = useReturnsCreateMutation();
  const { t } = useTranslation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payable = grandTotal - (data?.data?.discount / 100) * grandTotal;
    const returnData = {
      customerId: data?.data?.customer?.id,
      salesId: parseInt(id),
      products: products,
      payable: payable,
      storeId: store?.storeId,
    };

    const isConfirmed = window.confirm("Are you sure?");
    if (isConfirmed) {
      setIsAddingLoading(true)
      try {
        const res = await returnsCreate(returnData);

        if (res?.data) {
          toast.success("Return Successful");
        } else {
          toast.error("Error creating Returns");
        }
      } catch (error) {
        toast.error("An error occurred. Please try again.");
      }
    }
    setProducts([])
    setIsAddingLoading(false)
  };

  useEffect(() => {
    if (data?.data?.products) {
      const productsWithDefaultValues = data.data.products.map((product) => ({
        ...product,
        qty: product?.subUnits
          ? product?.quantity * parseInt(product?.unit.relatedByValue) +
            product?.subQuantity
          : product?.quantity,
        mainQty: product?.quantity,
        subQty: product?.subUnits ? product?.subQuantity : 0,
        total: product?.subUnits
          ? ((product?.quantity * parseInt(product?.unit.relatedByValue) +
              product?.subQuantity) /
              parseInt(product?.unit.relatedByValue)) *
            product?.salePrice
          : product?.salePrice * product?.quantity,
        totalPurchase: product?.subUnits
          ? ((product?.quantity * parseInt(product?.unit.relatedByValue) +
              product?.subQuantity) /
              parseInt(product?.unit.relatedByValue)) *
            product?.salePrice
          : product?.salePrice * product?.quantity,
      }));
      // calculateTotal(productsWithDefaultValues);
      setProducts(productsWithDefaultValues);
    }
  }, [data?.data?.products, isLoading]);

  useEffect(() => {
    if (products?.length) {
      let newTotal = 0;
      products.forEach((product) => {
        newTotal += product?.total;
      });
      setTotal(newTotal.toFixed(3));
      setGrandTotal(newTotal.toFixed(3));
    } else {
    }
  }, [products]);

  // const { data: unitsData } = useAllUnitGetQuery();

  const removeProduct = (id) => {
    setProducts((prevProducts) => {
      const updatedProducts = prevProducts.filter(
        (product) => product?.id !== id
      );
      // calculateTotal(updatedProducts);
      return updatedProducts;
    });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="main-content">
          <div className="row">
            <div className="col-md-4">
              <div>
                <div className="card-header">
                  <h3>
                    {t("Basic")} {t("Details")}
                  </h3>
                </div>
                <div className="card-body">
                  <table className="table table-striped table-bordered">
                    <tbody>
                      {/* ... Basic Details Table Rows ... */}
                      <tr>
                        <th>{t("Invoice")}</th>
                        <td>{data?.data?.id}</td>
                      </tr>
                      <tr>
                        <th>
                          {t("customer")} {t("Name")}:
                        </th>
                        <td>{data?.data?.customer?.name}</td>
                      </tr>
                      <tr>
                        <th>
                          {t("product")} {t("Price")}:
                        </th>
                        <td>{0}</td>
                      </tr>
                      <tr>
                        <th>{t("Discount")}:</th>
                        <td>{data?.data?.discount}</td>
                      </tr>
                      <tr>
                        <th>
                          {t("total")} {t("receiveable")}:
                        </th>
                        <td>{data?.data?.customer?.receivable?.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <th>
                          {t("total")} {t("Paid")}:
                        </th>
                        <td>{data?.data?.customer?.paid?.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <th>{t("Due")}:</th>
                        <td>{data?.data?.due?.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <th>
                          {t("Returned")} {t("Product")} {t("Price")}:
                        </th>
                        <td>{0}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <div>
                <div className="card-header">
                  <h3>
                    {t("Returned")} {t("product")}
                  </h3>
                </div>
                <div className="card-body">
                  <form
                    method="POST"
                    className="return_form"
                    onSubmit={handleSubmit}
                  >
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th style={{ maxWidth: "10%" }}>{t("Serial")}</th>
                          <th>{t("Name")}</th>
                          <th>
                            {t("Unit")} {t("Price")}
                          </th>
                          <th>{t("Ordered Quantity")}</th>
                          <th>{t("Returned Quantity")}</th>
                          <th>{t("Price")}</th>
                          <th>{t("Actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products?.map((product, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{product?.productName}</td>

                            <td>{product?.salePrice}</td>
                            <td>
                              {product?.subUnits && product?.unit ? (
                                <>
                                  {product.quantity} {product?.unit?.name}{" "}
                                  {product.subQuantity}{" "}
                                  {product?.subUnits?.name}
                                </>
                              ) : product?.unit ? (
                                <>
                                  {product.quantity} {product?.unit?.name}
                                </>
                              ) : (
                                0
                              )}
                            </td>
                            <td>
                              <div>
                                <input
                                  type="number"
                                  placeholder={product?.unit?.name}
                                  value={product?.mainQty}
                                  onChange={(e) => {
                                    const updatedProduct = {
                                      ...product,
                                      mainQty: parseInt(e.target.value),
                                    };

                                    setProducts((prevProducts) => {
                                      const updatedProducts = [...prevProducts];
                                      updatedProducts[index] = updatedProduct;
                                      // calculateTotal(updatedProducts);
                                      return updatedProducts;
                                    });
                                  }}
                                  onBlur={(e) => {
                                    const qty = product?.subUnits
                                      ? product?.mainQty *
                                          parseInt(
                                            product?.unit.relatedByValue
                                          ) +
                                        product?.subQty
                                      : product?.mainQty;
                                    const salesPrice = product?.salePrice;
                                    const purchaseCost = product?.purchaseCost;
                                    const totalPrice = qty * salesPrice;
                                    const totalPurchase = qty * purchaseCost;
                                    const totalCost = product?.subUnits
                                      ? totalPrice /
                                        Number(product?.unit.relatedByValue)
                                      : totalPrice;
                                    const totalPurchaseCost = product?.subUnits
                                      ? totalPurchase /
                                        Number(product?.unit.relatedByValue)
                                      : totalPurchase;
                                    const updatedProduct = {
                                      ...product,
                                      qty,
                                      total: totalCost,
                                      totalPurchase: totalPurchaseCost,
                                    };

                                    setProducts((prevProducts) => {
                                      const updatedProducts = [...prevProducts];
                                      updatedProducts[index] = updatedProduct;
                                      // calculateTotal(updatedProducts);
                                      return updatedProducts;
                                    });
                                  }}
                                />
                              </div>
                              {product?.subUnits ? (
                                <div className="mt-2">
                                  <input
                                    type="number"
                                    placeholder={product?.subUnits?.name}
                                    value={product?.subQty}
                                    onChange={(e) => {
                                      const updatedProduct = {
                                        ...product,
                                        subQty: parseInt(e.target.value),
                                      };

                                      setProducts((prevProducts) => {
                                        const updatedProducts = [
                                          ...prevProducts,
                                        ];
                                        updatedProducts[index] = updatedProduct;
                                        // calculateTotal(updatedProducts);
                                        return updatedProducts;
                                      });
                                    }}
                                    onBlur={(e) => {
                                      const qty = product?.subUnits
                                        ? product?.mainQty *
                                            parseInt(
                                              product?.unit.relatedByValue
                                            ) +
                                          product?.subQty
                                        : product?.mainQty;
                                      const salesPrice = product?.salePrice;
                                      const purchaseCost =
                                        product?.purchaseCost;
                                      const totalPrice = qty * salesPrice;
                                      const totalPurchase = qty * purchaseCost;
                                      const totalCost = product?.subUnits
                                        ? totalPrice /
                                          Number(product?.unit.relatedByValue)
                                        : totalPrice;
                                      const totalPurchaseCost =
                                        product?.subUnits
                                          ? totalPurchase /
                                            Number(product?.unit.relatedByValue)
                                          : totalPurchase;
                                      const updatedProduct = {
                                        ...product,
                                        qty,
                                        total: totalCost,
                                        totalPurchase: totalPurchaseCost,
                                      };
                                      // calculateTotal(updatedProduct);
                                      setProducts((prevProducts) => {
                                        const updatedProducts = [
                                          ...prevProducts,
                                        ];
                                        updatedProducts[index] = updatedProduct;
                                        return updatedProducts;
                                      });
                                    }}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>{product?.total?.toFixed(2)}</td>
                            <td>
                              <button
                                onClick={() => removeProduct(product?.id)}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td style={{ textAlign: "right" }} colSpan="6">
                            {t("total")}:
                          </td>
                          <td>
                            <span className="return_total_price">{total}</span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: "right" }} colSpan="6">
                            {t("Discount")}:
                          </td>
                          <td>
                            <span className="discount_amount">
                              {data?.data?.discount}
                            </span>
                            {/* <input
                              type="text"
                              name="calculated_discount"
                              className="calculated_discount"
                              value="0"
                              hidden
                            /> */}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: "right" }} colSpan="6">
                            {t("After Discount")}
                          </td>
                          <td>
                            <input
                              type="text"
                              name="return_product_value"
                              className="return_amount"
                              value={
                                grandTotal -
                                (data?.data?.discount / 100) * grandTotal
                              }
                              readOnly
                            />
                          </td>
                        </tr>
                      </tfoot>
                    </table>

                    <div className="row">
                      <div className="col-md-8">
                        <div className="form-group">
                          <label htmlFor="" hidden>
                            Considering The Paid Amount & Discount Amount -- You
                            Should Return:
                          </label>
                          <h4 className="cus_should_payable" hidden>
                            2930
                          </h4>
                          <input
                            type="number"
                            name="should_pay"
                            value="0"
                            className="form-control cus_should_pay"
                            hidden
                          />
                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-outline-theme"
                      disabled={isAddingLoading}
                    >
                      {isAddingLoading ? (
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        "Return"
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Return;

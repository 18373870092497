import React, { useEffect, useState } from "react";
import ReactSelect from "../helpers/ReactSelect";
import { useAllProductGetQuery } from "../../redux/api/product/productApi";
import { Link } from "react-router-dom";
import { useAllCategoryGetQuery } from "../../redux/api/categories/categoriesApi";
import { useAllBrandGetQuery } from "../../redux/api/brands/brandsApi";
import Loader from "../../components/loader/Loader";
import ProductsList from "./ProductsList";
import Pagination from "../../utils/Pagination";
import AddProductModal from "./AddProductModal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Products = () => {
  const [addProductModal, setAddProductModal] = React.useState(false);
  const store = useSelector((state) => state.store);
  const [category, setcategory] = useState();

  const [brand, setbrand] = useState();

  const [productName, setproductName] = useState();

  const [categoryFieldValue, setcategoryFieldValue] = useState("");
  const [brandFieldValue, setBrandFieldValue] = useState("");
  const [nameFieldValue, setNameFieldValue] = useState("");

  const [productCode, setproductCode] = useState();
  const [productCodeFieldValue, setproductCodeFieldValue] = useState();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({
    page,
    limit: pageSize,
    storeId: store.storeId,
  });
  const { data, isLoading, refetch } = useAllProductGetQuery(filter, {
    refetchOnMountOrArgChange: true,
  });
  const initialData = data?.data;
  const meta = data?.meta;
  const { t } = useTranslation();
  useEffect(() => {
    const updatedFilter = { ...filter };
    updatedFilter.page = page;

    if (!productCodeFieldValue) {
      delete updatedFilter.productCode;
    }
    if (!nameFieldValue) {
      delete updatedFilter.productName;
    }
    if (!categoryFieldValue) {
      delete updatedFilter.category;
    }

    if (!brandFieldValue) {
      delete updatedFilter.brand;
    }
    if (brand) {
      updatedFilter.brand = brand;
    }
    if (category) {
      updatedFilter.category = category;
    }
    if (productName) {
      updatedFilter.productName = productName;
    }
    if (productCode) {
      updatedFilter.productCode = productCode;
    }

    setFilter(updatedFilter);

    refetch();
  }, [productName, page, productCode, category, brand]);
  useEffect(() => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      storeId: store.storeId,
    }));
  }, [store.storeId]);
  const handleChange = (values) => {
    setcategoryFieldValue(values.value);
  };
  const handleBrandChange = (values) => {
    setBrandFieldValue(values.value);
  };
  const handleSubmit = () => {
    setcategory(categoryFieldValue);
    setbrand(brandFieldValue);
    setproductCode(productCodeFieldValue);
    setproductName(nameFieldValue);

    // resetForm();
  };

  const { data: categoryIdData } = useAllCategoryGetQuery(
    { storeId: store.storeId },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const initialcategoryIdData = categoryIdData?.data;

  const { data: brandIdData } = useAllBrandGetQuery(
    { storeId: store.storeId },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const initialbrandIdData = brandIdData?.data;
  const categoryIdOptions = initialcategoryIdData?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const brandIdOptions = initialbrandIdData?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));
  console.log(addProductModal);
  return (
    <>
      <div>
        <header className="header text-white">
          <div className="header-info d-flex justify-content-between">
            <h1 className="page-header">
              {t("product")} {t("List")}{" "}
            </h1>
            <Link>
              <span
                style={{ width: "150px", height: "35px" }}
                className="btn btn-outline-theme"
                onClick={() => setAddProductModal(true)}
              >
                <i className="fa fa-plus-circle me-1"></i>
                {t("Add Product")}
              </span>
            </Link>
          </div>
          <hr className="mb-2" />
        </header>

        <div className="row gap-3 w-100 mt-3">
          <div className="d-flex col-lg-5 gap-3">
            <div className="col-lg-6 ">
              <input
                type="text"
                value={productCodeFieldValue}
                onChange={(e) => setproductCodeFieldValue(e.target.value)}
                className="form-control p-2"
                placeholder="product code"
              />
            </div>
            <div className="col-lg-6  ">
              <input
                type="text"
                value={nameFieldValue}
                onChange={(e) => setNameFieldValue(e.target.value)}
                className="form-control p-2"
                placeholder="product name"
              />
            </div>
          </div>
          <div className="col-lg-3 ">
            <ReactSelect
              placeHolder="Select a category"
              options={categoryIdOptions}
              handleChange={handleChange}
            />
          </div>
          <div className="col-lg-3 ">
            <ReactSelect
              placeHolder="Select a brand"
              options={brandIdOptions}
              handleChange={handleBrandChange}
            />
          </div>
        </div>
        <div className="mt-3 d-flex gap-2">
          <div className="form-group">
            <button
              onClick={handleSubmit}
              className="btn btn-primary"
              type="submit"
            >
              <i className="fa fa-sliders"></i> {t("Filter")}
            </button>
          </div>
          <div className="form-group">
            <button
              className="btn btn-info"
              type="button"
              onClick={() => {
                setNameFieldValue("");
                setcategoryFieldValue(null);
                setBrandFieldValue(null);
                setbrand(null);
                setcategory(null);
                setproductCodeFieldValue("");

                const updatedFilter = { ...filter };
                delete updatedFilter.productName;
                delete updatedFilter.category;
                delete updatedFilter.brand;
                delete updatedFilter.productCode;
                setFilter(updatedFilter);
                refetch();
              }}
            >
              {t("Reset")}
            </button>
          </div>
        </div>

        <div className="row py-3">
          {isLoading ? (
            <Loader />
          ) : (
            <ProductsList data={initialData} refetch={refetch} />
          )}
        </div>
        {addProductModal && (
          <AddProductModal
            show={addProductModal}
            hide={() => setAddProductModal(false)}
          />
        )}
      </div>{" "}
      <Pagination
        page={page}
        setPage={setPage}
        meta={meta}
        pageSize={pageSize}
      />
    </>
  );
};

export default Products;

/* eslint-disable no-sequences */
import { baseApi } from "../baseApi";

export const purchaseApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    currencyCreate: build.mutation({
      query: (values) => ({
        url: "/currency",
        method: "POST",
        data: values,
      }),
      invalidatesTags: ["currency"],
    }),

    getCurrency: build.query({
      query: (params) => ({
        url: "/currency",
        method: "GET",
        params,
      }),
      providesTags: ["currency"],
    }),
    getSettings: build.query({
      query: (params) => ({
        url: "/store",
        method: "GET",
        params,
      }),
      providesTags: ["settings,store"],
    }),
    getSingleSettings: build.query({
      query: (id) => ({
        url: `/store/${id}`,
        method: "GET",
        
      }),
      providesTags: ["settings"],
    }),
    singlePurchaseGet: build.query({
      query: (id) => ({
        url: `/purchase/${id}`,
        method: "GET",
      }),
      providesTags: ["purchase"],
    }),

    updateSettings: build.mutation({
      query: ({ id, currency }) => ({
        url: `/store/${id}`,
        method: "PATCH",
        data: currency,
      }),
      invalidatesTags: ["settings,store"],
    }),
  }),
});

export const {
  useCurrencyCreateMutation,
  useGetCurrencyQuery,
  useGetSettingsQuery,
  useUpdateSettingsMutation,
  useGetSingleSettingsQuery,

} = purchaseApi;

import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
import { useState } from "react";
import ReactSelect from "../helpers/ReactSelect";
import { useCustomerCreateMutation } from "../../redux/api/customer/customerApi";
import { useAllSrGetQuery } from "../../redux/api/sr/srApi";
import toast from "react-hot-toast";
import Loader from "../../components/loader/Loader";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const CustomerModal = ({ isModalOpen, closeModal }) => {
  const store= useSelector((state) => state.store)
  const [selecteSR, setselecteSR] = useState(null);
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [cusomerCreate] = useCustomerCreateMutation();
  const { t } = useTranslation();
  const filter ={
    storeId: store.storeId
  }
  const { data, isLoading } = useAllSrGetQuery(filter,{
    refetchOnMountOrArgChange: true,
  });

  const initialValues = {
    name: "",
    srId: null,
    email: "",
    address: "",
    mobile: "",
    receivable: 0,
    walletBalance: 0,
    paid: 0,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Customer Name is required"),
    mobile: Yup.string().required("Phone is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    // Combine selecteSR with values

    setIsAddingLoading(true);
    const combinedValues = { ...values, srId: selecteSR, storeId: store.storeId };
    // console.log(combinedValues);
    // return;

    const res = await cusomerCreate(combinedValues);

    if (res?.data) {
      toast.success("Customer Create Successful");
      resetForm(initialValues);
    }
    setIsAddingLoading(false);
    closeModal();
  };

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (!data) {
    return <div>No data available</div>;
  }

  // Assuming data is an array of objects with a 'name' property
  const options = data?.data?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const handleSRChange = (values) => {
    setselecteSR(values.value);
  };
  const validateMobile = (value) => {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^\d+$/.test(value)) {
      error = "Please enter only numbers";
    }
    return error;
  };
  return (
    <Modal show={isModalOpen} onHide={closeModal} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("AddCustomer")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <div className="d-flex gap-1">
                <div className="form-group col-md-6">
                  <label className="py-1" htmlFor="name">
                    {t("Name")}
                    <span className="field_required">*</span>
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder="Enter Customer Name"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label  className="py-1" htmlFor="direct_transaction">
                    {t("Select")} {t("SR")}
                  </label>
                  <ReactSelect
                    placeHolder="~Select~"
                    options={options}
                    handleChange={handleSRChange}
                  />
                </div>
              </div>
              <div className="d-flex gap-1">
                {" "}
                <div className="form-group col-md-6">
                  <label  className="py-1" htmlFor="email">{t("Email")}</label>
                  <Field
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="Enter Customer Email"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label  className="py-1" htmlFor="mobile">
                    {t("Phone")}<span className="field_required">*</span>
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    name="mobile"
                    placeholder="Enter Customer Phone"
                    validate={validateMobile}
                  />
                  <ErrorMessage
                    name="mobile"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="d-flex gap-1">
                <div className="form-group col-md-4">
                  <label  className="py-1" htmlFor="paid">Opening Payable</label>
                  <Field type="number" className="form-control" name="paid" />
                </div>
                <div className="form-group col-md-4">
                  <label  className="py-1" htmlFor="receivable">Opening Receivable</label>
                  <Field
                    type="number"
                    className="form-control"
                    name="receivable"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label  className="py-1" htmlFor="walletBalance">Wallet Balance</label>
                  <Field
                    type="number"
                    className="form-control"
                    name="walletBalance"
                  />
                </div>
              </div>

              <div className="form-group col-md-12 my-2">
                <label  className="py-1" htmlFor="address">{t("Address")}</label>
                <Field
                  as="textarea"
                  className="form-control"
                  name="address"
                  placeholder="Write Customer Address"
                />
              </div>

              <div className="modal-footer">
                <button
                  className="btn btn-outline-default"
                  onClick={() => closeModal()}
                  disabled={isAddingLoading}
                >
                  {t("Cancel")}
                </button>
                <button
                  type="submit"
                  className="btn btn-outline-theme"
                  disabled={isAddingLoading}
                >
                  {isAddingLoading ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    t("AddCustomer")
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
    // <div
    //   className={`modal fade mt-5 ${isModalOpen ? "show" : ""}`}
    //   tabIndex="-1"
    //   role="dialog"
    //   style={{ display: isModalOpen ? "block" : "none" }}
    // >
    //   <div style={{ display: "block" }} className="modal-dialog modal-lg">
    //     <div className="modal-content">
    //       <div className="modal-header">
    //         <h5 className="modal-title">Add Customer</h5>
    //         <button
    //           type="button"
    //           onClick={() => closeModal()}
    //           className="btn-close"
    //           data-bs-dismiss="modal"
    //         ></button>
    //       </div>
    //       <div className="tab-pane fade show active px-2 py-2">

    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default CustomerModal;

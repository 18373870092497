/* eslint-disable no-sequences */
import { baseApi } from "../baseApi";

export const expenseApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    expenseCreate: build.mutation({
      query: (expenseData) => ({
        url: "/expense",
        method: "POST",
        data: expenseData,
      }),
      invalidatesTags: ["expense"],
    }),

    allExpenseGet: build.query({
      query: (params) => ({
        url: "/expense",
        method: "GET",
        params,
      }),
      providesTags: ["expense"],
    }),
    singleExpenseGet: build.query({
      query: ({ id, params }) => ({
        url: `/expense/${id}`,
        method: "GET",
        params,
      }),
      providesTags: ["expense"],
    }),

    updateExpense: build.mutation({
      query: ({ id, values }) => ({
        url: `/expense/${id}`,
        method: "PATCH",
        data: values,
      }),
      invalidatesTags: ["expense"],
    }),
    deleteExpense: build.mutation({
      query: ({ id, storeId }) => ({
        url: `/expense/${id}`,
        method: "DELETE",
        data: { storeId: storeId },
      }),
      invalidatesTags: ["expense"],
    }),
  }),
});

export const {
  useExpenseCreateMutation,
  useAllExpenseGetQuery,
  useUpdateExpenseMutation,
  useSingleExpenseGetQuery,
  useDeleteExpenseMutation,
} = expenseApi;

/* eslint-disable no-sequences */
import {baseApi} from "../baseApi";

export const smsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    smsCreate: build.mutation({
      query: (smsData) => ({
        url: "/sms",
        method: "POST",
        data: smsData,
      }),
      invalidatesTags: ["sms"],
    }),
  }),
});

export const {useSmsCreateMutation} = smsApi;

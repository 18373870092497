import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ReactSelect from "../helpers/ReactSelect";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Card, CardBody, CardExpandToggler } from "../../components/card/card";
import {
  useAllSalesGetQuery,
  useDeleteSalesMutation,
} from "../../redux/api/pos/posApi";
import moment from "moment/moment";
import Loader from "../../components/loader/Loader";
import toast from "react-hot-toast";

import DatepickerHelper from "../helpers/DatepickerHelper";
import { useAllCustomerGetQuery } from "../../redux/api/customer/customerApi";
import SalesPaymentsModal from "./SalesPaymentsModal";
import Pagination from "../../utils/Pagination";
import DeleteConfirmationModal from "../deleteConfirmationModal/DeleteConfirmationModal";
import Currency from "../../Shared/Currency";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";

const Sales = () => {
  const store = useSelector((state) => state.store);
  const [isPrinting, setIsPrinting] = useState(false);
  const [page, setPage] = useState(1);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [selectedItemId, setSelectedItemId] = useState();
  const [billNo, setBillNo] = useState();
  const [billFieldsValue, setBillFieldsValue] = useState();
  const [startDate, setStartDate] = useState();
  const [startDateFieldValue, setStartDateFieldValue] = useState();
  const [endDate, setEndDate] = useState();
  const [endDateFieldValue, setEndDateFieldValue] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectCustomer, setSelectCustomer] = useState();
  const [customerId, setCustomerId] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [filter, setFilter] = useState({
    page,
    limit: pageSize,
    storeId: store.storeId,
  });
  const { data, isLoading, refetch } = useAllSalesGetQuery(filter,{
    refetchOnMountOrArgChange: true,
  });
  const { data: customer } = useAllCustomerGetQuery(
    { storeId: store.storeId },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const initialCustomerIdDataIdData = customer?.data;
  const customerOpt = initialCustomerIdDataIdData?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));
  const salesList = data?.data?.salesList;
  const report = data?.data?.today;
  const meta = data?.meta;
  const { t } = useTranslation();
  useEffect(() => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      storeId: store.storeId,
    }));
  }, [store.storeId]);
  useEffect(() => {
    const updatedFilter = { ...filter };
    updatedFilter.page = page;
    if (billNo) {
      updatedFilter.id = billNo;
    }
    if (!billNo) {
      delete updatedFilter.id;
    }
    if (!startDateFieldValue) {
      delete updatedFilter.startDate;
    }
    if (startDate) {
      updatedFilter.startDate = startDate;
    }
    if (!endDateFieldValue) {
      delete updatedFilter.endDate;
    }
    if (endDate) {
      updatedFilter.endDate = endDate;
    }
    if (!selectCustomer) {
      delete updatedFilter.customerId;
    }
    if (customerId) {
      updatedFilter.customerId = customerId;
    }
    setFilter(updatedFilter);

    refetch();
  }, [
    customerId,
    billNo,

    page,
    startDateFieldValue,
    endDate,
    endDateFieldValue,

    startDate,
    selectCustomer,
  ]);

  const handleFilterChange = (e) => {
    setBillFieldsValue(e.target.value);
  };
  const handleChange = (values) => {
    setSelectCustomer(values.value);
  };
  const handleSubmit = () => {
    setCustomerId(selectCustomer);
    if (startDateFieldValue) {
      setStartDate(moment(startDateFieldValue).format());
    }
    if (endDateFieldValue) {
      setEndDate(moment(endDateFieldValue).format());
    }

    setBillNo(billFieldsValue);
  };

  const openModal = (id, customerId) => {
    setIsModalOpen(true);
    setSelectedId(id);
    setSelectedUserId(customerId);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [deleteSales] = useDeleteSalesMutation({});
  const handleDelete = async (id) => {
    setIsAddingLoading(true);

    const res = await deleteSales({ id, storeId: store.storeId });
    if (res?.data?.success === true) {
      toast.success(res?.data?.message);
    }
    setIsAddingLoading(false);
    setIsDeleteConfirmationModalOpen(false);
  };

  const tableRef = useRef(null);

  if (isLoading) {
    return <Loader />;
  }

  const openDeleteConfirmationModal = (id) => {
    setIsDeleteConfirmationModalOpen(true);
    setSelectedItemId(id);
  };

  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(false);
  };

  const handlePrint = () => {
    const printWindow = window.open("sales-list", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Sales List</title>
          <style>
            body {
              font-family: 'Arial', sans-serif;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              border: 1px solid #ddd;
              padding: 10px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
            @media print {
              header::before {
                content: "Sales List";
                display: block;
              
              }
              table {
                font-size: 12px;
              }
            }
          </style>
        </head>
        <body>
       <h1 style={{ width: '100%', textAlign: 'center' }}>${t("Sale List")} </h1>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
          <thead>
            <tr>
            <th style={{ borderTop: '1px solid #ddd', paddingTop: '0', paddingBottom: '2px' }}>
                Invoice
              </th>
              <th style={{ borderTop: '1px solid #ddd', paddingTop: '0', paddingBottom: '2px' }}>
                Customer
              </th>
              <th style={{ borderTop: '1px solid #ddd', paddingTop: '0', paddingBottom: '2px' }}>
                Product
              </th>
              <th style={{ borderTop: '1px solid #ddd', paddingTop: '0', paddingBottom: '2px' }}>
                Date
              </th>
              <th style={{ borderTop: '1px solid #ddd', paddingTop: '0', paddingBottom: '2px' }}>
                Discount
              </th>
              <th style={{ borderTop: '1px solid #ddd', paddingTop: '0', paddingBottom: '2px' }}>
                Receiveable
              </th>
              <th style={{ borderTop: '1px solid #ddd', paddingTop: '0', paddingBottom: '2px' }}>
                Paid
              </th>
              <th style={{ borderTop: '1px solid #ddd', paddingTop: '0', paddingBottom: '2px' }}>
              Returned
              </th>
              <th style={{ borderTop: '1px solid #ddd', paddingTop: '0', paddingBottom: '2px' }}>
              Due
              </th>
              <th style={{ borderTop: '1px solid #ddd', paddingTop: '0', paddingBottom: '2px' }}>
              Purchases Cost
              </th>
              <th style={{ borderTop: '1px solid #ddd', paddingTop: '0', paddingBottom: '2px' }}>
              Profit
              </th>
              <th style={{ borderTop: '1px solid #ddd', paddingTop: '0', paddingBottom: '2px' }}>
              Status
              </th>
             
            </tr>
          </thead>
          ${salesList?.map((item) => {
            return `
              <tr key=${item?.id} >
                <td style={{ verticalAlign: 'middle' }}>${item?.id}</td>
                <td style={{ verticalAlign: 'middle' }}>${
                  item?.customer?.name
                }</td>
                <td>
                   ${item?.products?.map(
                     (product, index) =>
                       `<span>
                       <span key={index}>
                         ${product?.productName}:
                         ${
                           product?.subUnits
                             ? `${Math?.floor(
                                 product?.qty / product?.unit?.relatedByValue
                               )} ${product?.unit?.name} ${Math.floor(
                                 product?.qty % product?.unit?.relatedByValue
                               )} ${product?.subUnits?.name}`
                             : `${product.quantity} ${product?.unit?.name}`
                         }
                       </span>
                       <br />
                     </span>`
                   )}
                </td>
                <td>${moment(item?.salesDate).format("DD MMMM YYYY")}</td>
                <td>${item?.discount}%</td>
                <td>${(item?.amountAfterDiscount).toFixed(2)}</td>
                <td>${Currency} : ${item?.paid}</td>
                <td>${Currency} : ${(item?.totalReturnedAmount).toFixed(2)}</td>
                <td>${Currency} : ${(item?.due).toFixed(2)}</td>
                <td>${Currency} : ${(item?.totalProductPurchasesCost).toFixed(
              2
            )}</td>
                <td>${Currency} : ${(item?.totalProfit).toFixed(2)}</td>
                <td>${item?.status}</td>
              </tr>
            `;
          })}
        </table>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div className="mb-2">
      <div className="d-flex justify-content-between align-items-center">
        {" "}
        <h2> {t("Sale List")} </h2>
        <div>
          <Link
            to="/customer-order"
            style={{ width: "120px", height: "35px" }}
            className="btn btn-outline-theme"
          >
            <i
              className="bi bi-plus-square-fill pr-2"
              style={{ marginRight: "5px" }}
            ></i>
            {t("New")} {t("sale")}
          </Link>
        </div>
      </div>
      <div className="row py-3">
        <div className="col-xl-3 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <span className="flex-grow-1">
                  {" "}
                  {t("today")} {t("sold")}
                </span>
                <CardExpandToggler />
              </div>
              <div className="row align-items-center mb-2">
                <div>
                  <h3 className="mb-0">
                    {Currency} {report?.todayTotalSold?.toFixed(2) || 0.0}
                  </h3>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-xl-3 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <span className="flex-grow-1">
                  {t("today")} {t("Received")}
                </span>
                <CardExpandToggler />
              </div>
              <div className="row align-items-center mb-2">
                <div>
                  <h3 className="mb-0">
                    {Currency} {report?.todayTotalPaid?.toFixed(2) || 0.0}
                  </h3>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-xl-3 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <span className="flex-grow-1">
                  {" "}
                  {t("today")} {t("profit")}
                </span>
                <CardExpandToggler />
              </div>
              <div className="row align-items-center mb-2">
                <div>
                  <h3 className="mb-0">
                    {Currency} {report?.totalProfit?.toFixed(2) || 0.0}
                  </h3>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-xl-3 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <span className="flex-grow-1">
                  {t("total")} {t("sold")}
                </span>
                <CardExpandToggler />
              </div>
              <div className="row align-items-center mb-2">
                <div>
                  <h3 className="mb-0">
                    {Currency} {data?.data?.totalSold?.toFixed(2) || 0.0}
                  </h3>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>

      <div className="row">
        <div className="col-md-3">
          <input
            type="text"
            className="form-control"
            placeholder="Invoice Number"
            value={billFieldsValue}
            onChange={handleFilterChange}
          />
        </div>
        <div className="col-lg-2">
          <DatepickerHelper
            handleDateChange={(date) => setStartDateFieldValue(date)}
            selectedDate={startDateFieldValue}
            placeHolder="Select Start Date"
          />
        </div>
        <div className="col-lg-2">
          <DatepickerHelper
            handleDateChange={(date) => setEndDateFieldValue(date)}
            selectedDate={endDateFieldValue}
            placeHolder="Select End Date"
          />
        </div>
        <div className="col-lg-3 ">
          <ReactSelect
            placeHolder="Select Customer"
            options={customerOpt}
            handleChange={handleChange}
          />
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center mt-2">
        {" "}
        <div className="mt-2 d-flex gap-2">
          <div className="form-group">
            <button
              onClick={handleSubmit}
              className="btn btn-primary"
              type="submit"
            >
              <i className="fa fa-sliders"></i> {t("Filter")}
            </button>
          </div>
          <div className="form-group">
            <button
              onClick={() => {
                setBillNo("");
                setBillFieldsValue("");
                setStartDate("");
                setStartDateFieldValue("");
                setEndDate("");
                setEndDateFieldValue("");
                setCustomerId();
                setSelectCustomer();
                const updatedFilter = { ...filter };
                delete updatedFilter.id;
                delete updatedFilter.startDate;
                delete updatedFilter.endDate;
                setFilter(updatedFilter);
                refetch();
              }}
              className="btn btn-info"
              type="reset"
            >
              {t("Reset")}
            </button>
          </div>
        </div>
        <div>
          <button onClick={handlePrint} className="btn btn-outline-theme">
            <i className="bi bi-printer" style={{ marginRight: "5px" }}></i>
            {t("Print")}
          </button>
        </div>
      </div>

      <div className="my-4">
        <div className="table-responsive">
          <table ref={tableRef} className="table table-hover text-nowrap">
            <thead>
              <tr>
                
                <th className="border-top-0 pt-0 pb-2">{t("Invoice")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("customer")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("product")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Date")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Discount")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("receiveable")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Paid")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Returned")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Due")}</th>
                <th className="border-top-0 pt-0 pb-2">
                  {t("purchases")} {t("Cost")}
                </th>
                <th className="border-top-0 pt-0 pb-2">{t("profit")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Status")}</th>
                <th className="border-top-0 pt-0 pb-2">{t("Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {salesList?.map((item, index) => (
                <tr key={item?.id}>
                  <td className="align-middle">{item?.id}</td>

                  <td className="align-middle">{item?.customer?.name}</td>
                  <td>
                    {item?.products?.map((product, index) => (
                      <>
                        <span key={index}>
                          {product?.productName}:{" "}
                          {product?.subUnits ? (
                            <>
                              {Math?.floor(
                                product?.qty / product?.unit?.relatedByValue
                              )}{" "}
                              {product?.unit?.name}{" "}
                              {Math.floor(
                                product?.qty % product?.unit?.relatedByValue
                              )}{" "}
                              {product?.subUnits?.name}
                            </>
                          ) : (
                            <>
                              {product.qty} {product?.unit?.name}
                            </>
                          )}{" "}
                        </span>{" "}
                        <br />
                      </>
                    ))}
                  </td>
                  <td className="align-middle">
                    {moment(item?.salesDate).format("DD MMMM YYYY")}
                  </td>
                  <td className="align-middle">{item?.discount}%</td>
                  <td className="py-1 align-middle">
                    <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      {Currency} : {(item?.amountAfterDiscount).toFixed(2)}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      {Currency} : {item?.paid}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span
                      className={`badge border 
                      ${
                        item?.totalReturnedAmount < 0
                          ? "border-danger text-danger"
                          : "border-success text-success"
                      } 
                        px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center`}
                    >
                      {Currency} : {(item?.totalReturnedAmount).toFixed(2)}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span
                      className={`badge border 
                      ${
                        item?.due > 0
                          ? "border-danger text-danger"
                          : "border-success text-success"
                      } 
                        px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center`}
                    >
                      {Currency} : {(item?.due).toFixed(2)}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      {Currency} :{" "}
                      {(item?.totalProductPurchasesCost).toFixed(2)}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      {Currency} : {(item?.totalProfit).toFixed(2)}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span
                      className={`badge border  px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ${
                        item?.status === "UNPAID"
                          ? "border-danger text-danger"
                          : "border border-success text-success"
                      }`}
                    >
                      {item?.status}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span
                      className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center cursor-pointer "
                      data-bs-toggle="dropdown"
                    >
                      <i className="bi bi-gear fs-9px fa-fw me-5px"></i> E/D
                    </span>

                    <div className="dropdown-menu">
                      {/* <Link
                        to={`/sales/edit-sales/${item?.id}`}
                        className="dropdown-item">
                        <i
                          className="bi bi-pencil-square"
                          style={{marginRight: "5px"}}></i>
                        Edit
                      </Link> */}
                      {/* <a
                        className="dropdown-item"
                        href={`${process.env.REACT_APP_BASE_URL}/invoice/${item?.id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          className="bi bi-tv"
                          style={{ marginRight: "5px" }}
                        ></i>
                        Show
                      </a> */}
                      <Link
                        to={`/sales/return/${item?.id}`}
                        className="dropdown-item"
                      >
                        <i
                          className="bi bi-arrow-return-right"
                          style={{ marginRight: "5px" }}
                        ></i>
                        {t("Returned")}
                      </Link>

                      <button
                        onClick={() => openModal(item?.id, item?.customer?.id)}
                        className="dropdown-item"
                      >
                        <i
                          className="bi bi-cash-coin pr-2"
                          style={{ marginRight: "5px" }}
                        ></i>
                        {t("Add Payment")}
                      </button>
                      <a
                        className="dropdown-item"
                        href={`${process.env.REACT_APP_BASE_URL}/invoice/${item?.id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          className="bi bi-printer pr-2"
                          style={{ marginRight: "5px" }}
                        ></i>
                        {t("Invoice")}
                      </a>

                      <button
                        onClick={() => openDeleteConfirmationModal(item?.id)}
                        className="dropdown-item"
                      >
                        <i
                          className="bi bi-trash pr-2"
                          style={{ marginRight: "5px" }}
                        ></i>
                        {t("Delete")}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          meta={meta}
          pageSize={pageSize}
        />
      </div>

      {isModalOpen && (
        <SalesPaymentsModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          id={selectedId}
          userId={selectedUserId}
        />
      )}

      {isDeleteConfirmationModalOpen && (
        <DeleteConfirmationModal
          isAddingLoading={isAddingLoading}
          closeModal={closeDeleteConfirmationModal}
          confirmDelete={() => handleDelete(selectedItemId)}
        />
      )}
    </div>
  );
};

export default Sales;

import React, { useEffect, useState } from "react";
import ExpenseModal from "./ExpenseModal";
import ExpenseList from "./ExpenseList";

import ReactSelect from "../helpers/ReactSelect";
import { useAllExpenseGetQuery } from "../../redux/api/expense/expenseApi";

import Loader from "../../components/loader/Loader";
import DatepickerHelper from "../helpers/DatepickerHelper";
import Pagination from "../../utils/Pagination";
import { useAllExpenseCategoryGetQuery } from "../../redux/api/expenseCategory/expenseCategory";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const Expenses = () => {
  const store = useSelector((state) => state.store);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [name, setName] = useState();
  const [expenseCategory, setExpenseCategory] = useState();
  const [startDate, setStartDate] = useState();
  const [startDateFieldValue, setStartDateFieldValue] = useState();
  const [endDate, setEndDate] = useState();
  const [endDateFieldValue, setEndDateFieldValue] = useState();
  const [nameFieldValue, setNameFieldValue] = useState();
  const [categoryFieldValue, setCategoryFieldValue] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({
    limit: pageSize,
    storeId: store.storeId,
  });
  const { t } = useTranslation();
  const { data, isLoading, refetch } = useAllExpenseGetQuery(filter, {
    refetchOnMountOrArgChange: true,
  });
  const initialData = data?.data;
  const meta = data?.meta;

  useEffect(() => {
    const updatedFilter = { ...filter };
    updatedFilter.page = page;
    if (name) {
      updatedFilter.name = name;
    }
    if (searchTerm) {
      updatedFilter.searchTerm = searchTerm;
    }
    if (!searchTerm) {
      delete updatedFilter.searchTerm;
    }
    if (!nameFieldValue) {
      delete updatedFilter.name;
    }
    if (!categoryFieldValue) {
      delete updatedFilter.expenseCategoryId;
    }
    if (expenseCategory) {
      updatedFilter.expenseCategoryId = expenseCategory;
    }
    if (!startDateFieldValue) {
      delete updatedFilter.startDate;
    }
    if (startDate) {
      updatedFilter.startDate = startDate;
    }
    if (!endDateFieldValue) {
      delete updatedFilter.endDate;
    }
    if (endDate) {
      updatedFilter.endDate = endDate;
    }

    setFilter(updatedFilter);

    refetch();
  }, [
    name,
    refetch,
    searchTerm,
    page,
    expenseCategory,
    categoryFieldValue,
    setStartDate,
    startDateFieldValue,
    endDate,
    endDateFieldValue,
  ]);

  const handleSubmit = () => {
    setExpenseCategory(categoryFieldValue);
    setStartDate(startDateFieldValue);
    setEndDate(endDateFieldValue);
    setName(nameFieldValue);
  };

  const handleFilterChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const { data: categoryIdData } = useAllExpenseGetQuery({
    storeId: store.storeId,
  });
  const initialcategoryIdData = categoryIdData?.data;
  const { data: expenseCategoryIdData } = useAllExpenseCategoryGetQuery({
    storeId: store.storeId,
  });
  const initialExpenseCategoryIdData = expenseCategoryIdData?.data;

  const categoryIdOptions = initialExpenseCategoryIdData?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const handleChange = (values) => {
    setCategoryFieldValue(values.value);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <header className="header text-white">
        <div className="header-info">
          <h1 className="page-header">
            {t("expense")} {t("List")}
          </h1>
          <hr className="mb-3" />
        </div>
      </header>

      <div className="d-flex gap 5">
        <div className="input-group mb-2">
          <div className=" position-relative">
            <div className="input-group">
              <input
                type="text"
                className="form-control px-35px"
                placeholder="Filter Expense"
                value={searchTerm}
                onChange={handleFilterChange}
              />
              <div
                className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
                style={{ zIndex: 1020 }}
              >
                <i className="fa fa-search opacity-5"></i>
              </div>
            </div>
          </div>
        </div>

        <button
          style={{ width: "170px", height: "35px" }}
          className="btn btn-outline-theme"
          onClick={openModal}
        >
          <i className="fa fa-plus-circle me-1"></i>
          {t("Add Expense")}
        </button>
      </div>

      <div className="row gap-3 w-100">
        <div className="col-lg-3 ">
          {/* <label htmlFor="Category">
            Select Category<span className="field_required"></span>
          </label> */}
          <ReactSelect
            placeHolder="Select Category"
            options={categoryIdOptions}
            handleChange={handleChange}
          />
        </div>

        <div className="d-flex col-lg-5 gap-2">
          <div className="col-lg-6 ">
            {/* <label htmlFor="account_type">
              Start Date<span className="field_required"></span>
            </label> */}
            <DatepickerHelper
              handleDateChange={(date) => setStartDateFieldValue(date)}
              selectedDate={startDateFieldValue}
              placeHolder="Select start date"
            />
          </div>
          <div className="col-lg-6  ">
            {/* <label htmlFor="account_type">
              End Date<span className="field_required"></span>
            </label> */}
            <DatepickerHelper
              handleDateChange={(date) => setEndDateFieldValue(date)}
              selectedDate={endDateFieldValue}
              placeHolder="Select end date"
            />
          </div>
        </div>
        <div className="mt-1 d-flex gap-2">
          <div className="form-group">
            <button
              onClick={handleSubmit}
              className="btn btn-primary"
              type="submit"
            >
              <i className="fa fa-sliders"></i> {t("Filter")}
            </button>
          </div>
          <div className="form-group">
            <button
              className="btn btn-info"
              type="button"
              onClick={() => {
                setName("");
                setExpenseCategory("");
                setNameFieldValue("");
                setCategoryFieldValue(null);
                setStartDate("");
                setStartDateFieldValue("");
                setEndDate("");
                setEndDateFieldValue("");
                const updatedFilter = { ...filter };
                delete updatedFilter.name;
                delete updatedFilter.expenseCategoryId;
                delete updatedFilter.startDate;
                delete updatedFilter.endDate;
                setFilter(updatedFilter);
                refetch();
              }}
            >
              {t("Reset")}
            </button>
          </div>
        </div>
      </div>

      <div className="tab-content py-4">
        {isModalOpen && (
          <ExpenseModal isModalOpen={isModalOpen} closeModal={closeModal} />
        )}
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <ExpenseList data={initialData} refetch={refetch} />
          </div>
        )}
      </div>

      <Pagination
        page={page}
        setPage={setPage}
        meta={meta}
        pageSize={pageSize}
      />
    </div>
  );
};

export default Expenses;

import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useAllUnitGetQuery} from "../../redux/api/units/unitsApi";
import {useAllProductGetQuery} from "../../redux/api/product/productApi";
import {useSingleSalesGetQuery} from "../../redux/api/pos/posApi";

const Edit = () => {
  const {id} = useParams();
  const [totalQty, setTotalQty] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);

  const [searchedProduct, setSearchedProduct] = useState("");
  const [matchingProducts, setMatchingProducts] = useState([]);
  const {data, isLoading} = useSingleSalesGetQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const singleData = data?.data;

  useEffect(() => {
    if (singleData?.products) {
      setProducts(singleData?.products);
    }
  }, [singleData?.products, isLoading]);

  const calculateTotal = (newProducts) => {
    let newTotal = 0;
    if (Array.isArray(newProducts)) {
      newProducts.forEach((product) => {
        const totalCost = product?.subUnits
          ? product?.salePrice *
            (product?.quantity /
              parseInt(
                unitsData?.data?.find((u) => u?.id === product?.unit)
                  ?.relatedByValue
              ))
          : product?.salePrice * product?.quantity;
        newTotal += totalCost;
      });
    } else {
      newTotal += newProducts?.total;
    }
    setTotal(newTotal.toFixed(3));
    setGrandTotal(newTotal.toFixed(3));
  };

  const {data: productData, isLoading: productLoading} = useAllProductGetQuery({
    refetchOnMountOrArgChange: true,
  });
  const {data: unitsData} = useAllUnitGetQuery();

  const initialData = productData?.data;

  const addProduct = (selectedProduct) => {
    const newProduct = {
      productName: selectedProduct.productName,
      id: selectedProduct.id,
      productCode: selectedProduct.productCode,
      categoryId: parseInt(selectedProduct.categoryId),
      unit: parseInt(selectedProduct.unitId),
      subUnits: parseInt(selectedProduct.subUnitsId),
      iamge: selectedProduct.image,
      salePrice: parseInt(selectedProduct.salePrice),
      quantity: 0,
      mainqty: 0,
      subqty: 0,
      total: 0,
    };
    setProducts((prevProducts) => {
      const newProducts = [...prevProducts, newProduct];
      setSearchedProduct("");
      setMatchingProducts([]);
      calculateTotal(newProducts);
      return newProducts;
    });
  };

  const removeProduct = (index) => {
    setProducts((prevProducts) => {
      const updatedProducts = prevProducts.filter((_, i) => i !== index);
      calculateTotal(updatedProducts);
      return updatedProducts;
    });
  };

  const handleProductSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchedProduct(searchTerm);

    const filteredProducts = initialData?.filter((product) =>
      product?.productName.toLowerCase().includes(searchTerm)
    );
    setMatchingProducts(filteredProducts);
  };

  const handleProductSelection = (selectedProduct) => {
    addProduct(selectedProduct);
  };

  const productRows = products?.map((product, index) => (
    <tr key={index}>
      <td>{product?.productName}</td>

      <td>
        <div>
          <input
            type="number"
            placeholder={
              unitsData?.data?.find((u) => u.id === product?.unit)?.name
            }
            onChange={(e) => {
              const qtyInSub = product?.subUnits
                ? parseInt(e.target.value) *
                  parseInt(
                    unitsData?.data?.find((u) => u.id === product?.unit)
                      ?.relatedByValue
                  )
                : parseInt(e.target.value);
              const updatedProduct = {
                ...product,
                mainqty: qtyInSub,
              };

              setProducts((prevProducts) => {
                const updatedProducts = [...prevProducts];
                updatedProducts[index] = updatedProduct;
                calculateTotal(updatedProducts);
                return updatedProducts;
              });
            }}
            onBlur={(e) => {
              const totalCost = product?.subUnits
                ? product?.salePrice *
                  (product?.quantity /
                    parseInt(
                      unitsData?.data?.find((u) => u.id === product?.unit)
                        ?.relatedByValue
                    ))
                : product?.salePrice * product?.quantity;

              const updatedProduct = {
                ...product,
                quantity: product?.mainqty + product?.subqty,
                total: totalCost,
              };

              setProducts((prevProducts) => {
                const updatedProducts = [...prevProducts];
                updatedProducts[index] = updatedProduct;
                calculateTotal(updatedProducts);
                return updatedProducts;
              });
            }}
          />
        </div>
        {product?.subUnits ? (
          <div className="mt-2">
            <input
              type="number"
              placeholder={
                unitsData?.data?.find((u) => u.id === product?.subUnits)?.name
              }
              onChange={(e) => {
                const updatedProduct = {
                  ...product,
                  subqty: parseInt(e.target.value),
                };

                setProducts((prevProducts) => {
                  const updatedProducts = [...prevProducts];
                  updatedProducts[index] = updatedProduct;
                  calculateTotal(updatedProducts);
                  return updatedProducts;
                });
              }}
              onBlur={(e) => {
                const totalCost = product?.subUnits
                  ? product?.salePrice *
                    (product?.quantity /
                      parseInt(
                        unitsData?.data?.find((u) => u?.id === product?.unit)
                          ?.relatedByValue
                      ))
                  : product?.salePrice * product?.quantity;

                const updatedProduct = {
                  ...product,
                  quantity: product?.mainqty + product?.subqty,
                  total: totalCost,
                };

                setProducts((prevProducts) => {
                  const updatedProducts = [...prevProducts];
                  updatedProducts[index] = updatedProduct;
                  calculateTotal(updatedProducts);
                  return updatedProducts;
                });
              }}
            />
          </div>
        ) : (
          ""
        )}
      </td>

      <td>
        <input
          type="number"
          value={product?.salePrice}
          onChange={(e) => {
            const updatedProduct = {
              ...product,
              salePrice: parseInt(e.target.value),
            };
            const updatedProducts = [...products];
            updatedProducts[index] = updatedProduct;
            setProducts(updatedProducts);
          }}
        />
      </td>
      <td>
        {product?.subUnits
          ? (
              product?.salePrice *
              (product?.quantity /
                parseInt(
                  unitsData?.data?.find((u) => u?.id === product?.unit)
                    ?.relatedByValue
                ))
            ).toFixed(2)
          : (product?.salePrice * product?.quantity).toFixed(2)}
      </td>
      <td>
        <button onClick={() => removeProduct(index)}>
          <i className="fa fa-trash"></i>
        </button>
      </td>
    </tr>
  ));

  return (
    <div className="mt-4">
      <form>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="Product">Product</label>
            <input
              type="text"
              id="product_search"
              value={searchedProduct}
              onChange={handleProductSearch}
              className="form-control ui-autocomplete-input"
              placeholder="Write product."
              autoComplete="off"
            />
            {matchingProducts.length > 0 && (
              <ul className="list-group mt-2 " style={{zIndex: "6000"}}>
                {matchingProducts?.map((product) => (
                  <li
                    key={product.id}
                    className="list-group-item"
                    onClick={() => handleProductSelection(product)}>
                    {product?.productName}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-4">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="border-top-0 pt-1 pb-1">Name</th>
                  <th className="border-top-0 pt-1 pb-1">Quantity</th>
                  <th className="border-top-0 pt-1 pb-1">Price</th>
                  <th className="border-top-0 pt-1 pb-1">Sub Total</th>
                  <th className="border-top-0 pt-1 pb-1">
                    <i className="fa fa-undo"></i>
                  </th>
                </tr>
              </thead>

              <tbody>{productRows}</tbody>

              <tfoot>
                <tr>
                  <th className="text-center" colSpan="2">
                    Total Qty: <strong id="totalQty">{totalQty}</strong>
                  </th>
                  <th className="text-center" colSpan="3">
                    Total: <strong id="totalAmount">{grandTotal}</strong> Tk
                  </th>
                </tr>
              </tfoot>
            </table>

            <div className="form-group text-center">
              <button type="submit" id="submit-btn" className="btn btn-primary">
                <i className="fa fa-undo"></i>
                Update
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Edit;

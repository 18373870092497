import React from "react";
import { Card, CardBody } from "./../../components/card/card.jsx";
import Currency from "../../Shared/Currency.js";
const AddtoCartModal = ({
  modalData,
  deductModalQty,
  modalQuantity,
  addModalQty,
  addToCart,
  deductModalSubQty,
  modalSubUnitQuantity,
  addModalSubQty,
}) => {
  // console.log(modalData);
  return (
    <div>
      {modalData && (
        <Card>
          <CardBody className="p-0">
            <button
              data-bs-dismiss="modal"
              className="btn-close position-absolute top-0 end-0 m-4"
            >
              &nbsp;
            </button>
            <div className="modal-pos-product">
              <div className="modal-pos-product-img">
                <div
                  className="img"
                  style={{
                    backgroundImage: modalData?.image
                      ? "url(" + modalData?.image + ")"
                      : "url(https://i.ibb.co/KjdtK7T/download.png)",
                  }}
                ></div>
              </div>
              <div className="modal-pos-product-info">
                <div className="h4 mb-2">{modalData?.productName}</div>
                <div className="text-white text-opacity-50 mb-2">
                  {modalData?.description}
                </div>
                <div className="h4 mb-3">{Currency} {modalData?.salePrice} </div>
                <div>
                  <label htmlFor="" className="mb-2">
                    {modalData?.unit?.name}
                  </label>
                  <div className="d-flex mb-3">
                    {/* <button
                      className="btn btn-outline-theme"
                      onClick={(event) => deductModalQty(event)}
                    >
                      <i className="fa fa-minus"></i>
                    </button> */}
                    <input
                      type="number"
                      value={modalQuantity}
                      // readOnly
                      onChange={addModalQty}
                      className="form-control w-full fw-bold mx-2 bg-white bg-opacity-25 border-0 text-center"
                    />
                    {/* <button
                      className="btn btn-outline-theme"
                      onClick={(event) => addModalQty(event)}
                    >
                      <i className="fa fa-plus"></i>
                    </button> */}
                  </div>
                </div>
                {modalData?.subUnits && (
                  <div>
                    <label htmlFor="" className="mb-2">
                      {modalData?.subUnits?.name}
                    </label>
                    <div className="d-flex mb-3">
                      {/* <button
                        className="btn btn-outline-theme"
                        onClick={(event) => deductModalSubQty(event)}
                      >
                        <i className="fa fa-minus"></i>
                      </button> */}
                      <input
                        type="number"
                        value={modalSubUnitQuantity}
                        onChange={addModalSubQty}
                        // readOnly
                        className="form-control w-full fw-bold mx-2 bg-white bg-opacity-25 border-0 text-center"
                      />
                      {/* <button
                        className="btn btn-outline-theme"
                        onClick={(event) => addModalSubQty(event)}
                      >
                        <i className="fa fa-plus"></i>
                      </button> */}
                    </div>
                  </div>
                )}
                <hr className="mx-n4" />

                <div className="row">
                  <div className="col-4">
                    <button
                      className="btn btn-default h4 mb-0 d-block w-100 rounded-0 py-3"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-8">
                    <button
                      className="btn btn-success w-100 d-flex justify-content-center align-items-center rounded-0 py-3 h4 m-0"
                      onClick={addToCart}
                    >
                      Add to cart{" "}
                      <i className="bi bi-plus fa-2x ms-2 my-n3"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default AddtoCartModal;

import React, { useState } from "react";

import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import moment from "moment";
import { useDeletePaymentMutation } from "../../redux/api/payments/paymentsApi";
import DeleteConfirmationModal from "../deleteConfirmationModal/DeleteConfirmationModal";
import Currency from "../../Shared/Currency";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const PaymentList = ({ refetch, data, tableRef }) => {
  const store = useSelector((state)=> state.store)
  const { t } = useTranslation();
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [selectedItemId, setSelectedItemId] = useState();
  const [deletePayment] = useDeletePaymentMutation({});

  const handleDelete = async (id) => {
    const res = await deletePayment({id, storeId: store.storeId });
    if (res?.data?.success === true) {
      toast.success(res?.data?.message);
    }
    setIsDeleteConfirmationModalOpen(false);
  };
  const openDeleteConfirmationModal = (id) => {
    setIsDeleteConfirmationModalOpen(true);
    setSelectedItemId(id);
  };

  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(false);
  };

  return (
    <div>
      <div className="table-responsive my-2">
        <table
          id="datatable"
          className="table table-bordered table-xl text-center py-3 w-100 fw-semibold text-nowrap  "
        >
          <thead>
            <tr>
              <th className="border-top-0 pt-2 pb-2">{t("Serial")}</th>
              <th className="border-top-0 pt-2 pb-2">{t("Details")}</th>
              <th className="border-top-0 pt-2 pb-2">{t("Payment Date")}</th>
              <th className="border-top-0 pt-2 pb-2">{t("Amount")}</th>
              <th className="border-top-0 pt-2 pb-2">{t("Payment Type")}</th>
              <th className="border-top-0 pt-2 pb-2">{t("Wallet Payment")} </th>
              <th className="border-top-0 pt-2 pb-2">{t("Note")} </th>
              <th className="border-top-0 pt-2 pb-2">{t("Actions")} </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td className="align-middle">
                  <Link>{index + 1}</Link>
                </td>
                <td className="align-middle">
                  <table className="table table-bordered table-xs w-100 fw-semibold text-nowrap   mb-3">
                    <tbody>
                      <tr>
                        {item?.customerAccount ? (
                          <th>
                            {t("customer")} {t("Name")}
                          </th>
                        ) : (
                          <th>
                            {t("supplier")} {t("Name")}
                          </th>
                        )}

                        <td>
                          {item?.customerAccount
                            ? item?.customerAccount.name
                            : item?.supplierAccount.name}
                        </td>
                      </tr>
                      <tr>
                        <th>{t("Phone")}:</th>
                        <td>
                          {item?.customerAccount?.mobile
                            ? item?.customerAccount?.mobile
                            : item?.supplierAccount.mobile}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td className="align-middle">
                  <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                    {moment(item?.paymentDate).format("DD MMMM YYYY")}
                  </span>
                </td>
                <td className="align-middle">
                  <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                    {Currency} : {item?.amount}
                  </span>
                </td>
                <td className="align-middle">
                  <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                    {item?.paymentType?.name}
                  </span>
                </td>
                <td className="align-middle">
                  <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                    {item?.isWallet === true ? "YES" : "NO"}
                  </span>
                </td>
                <td className="align-middle">{item?.note}</td>

                <td className="align-middle">
                  <span
                    className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center cursor-pointer "
                    data-bs-toggle="dropdown"
                  >
                    <i className="bi bi-gear fs-9px fa-fw me-5px"></i> E/D
                  </span>

                  <div className="dropdown-menu">
                    <a className="dropdown-item" href="#/">
                      <i
                        class="bi bi-printer pr-2"
                        style={{ marginRight: "5px" }}
                      ></i>
                      {t("Receipt")}
                    </a>
                    <button
                      onClick={() => openDeleteConfirmationModal(item?.id)}
                      className="dropdown-item"
                    >
                      <i
                        className="bi bi-trash pr-2"
                        style={{ marginRight: "5px" }}
                      ></i>
                      {t("Delete")}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isDeleteConfirmationModalOpen && (
        <DeleteConfirmationModal
          closeModal={closeDeleteConfirmationModal}
          confirmDelete={() => handleDelete(selectedItemId)}
        />
      )}

      <div ref={tableRef} style={{ display: "none" }} className="px-2">
        <h2 style={{ textAlign: "center", padding: "1.5rem 0" }}>
          Payment List
        </h2>
        <div style={{ overflowX: "auto", margin: "0.5rem 0" }}>
          <table
            id="datatable"
            style={{
              width: "100%",
              borderCollapse: "collapse",
              textAlign: "center",
              whiteSpace: "nowrap",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    borderTop: "0",
                    paddingTop: "0",
                    paddingBottom: "0.2rem",
                  }}
                >
                  Sl
                </th>
                <th
                  style={{
                    borderTop: "0",
                    paddingTop: "0",
                    paddingBottom: "0.2rem",
                  }}
                >
                  Details
                </th>
                <th
                  style={{
                    borderTop: "0",
                    paddingTop: "0",
                    paddingBottom: "0.2rem",
                  }}
                >
                  Payment Date
                </th>
                <th
                  style={{
                    borderTop: "0",
                    paddingTop: "0",
                    paddingBottom: "0.2rem",
                  }}
                >
                  Amount
                </th>
                <th
                  style={{
                    borderTop: "0",
                    paddingTop: "0",
                    paddingBottom: "0.2rem",
                  }}
                >
                  Payment Type
                </th>
                <th
                  style={{
                    borderTop: "0",
                    paddingTop: "0",
                    paddingBottom: "0.2rem",
                  }}
                >
                  Wallet Payment
                </th>
                <th
                  style={{
                    borderTop: "0",
                    paddingTop: "0",
                    paddingBottom: "0.2rem",
                  }}
                >
                  Note
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr key={index}>
                  <td style={{ verticalAlign: "middle" }}>
                    <Link >{index + 1}</Link>
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        border: "1px solid #dee2e6",
                        fontSize: "1rem",
                        fontWeight: "600",
                        whiteSpace: "nowrap",
                        marginBottom: "0.3rem",
                      }}
                    >
                      <tbody>
                        <tr>
                          {item?.customerAccount ? (
                            <th
                              style={{
                                border: "1px solid #dee2e6",
                                padding: "0.5rem",
                              }}
                            >
                              Customer Name:
                            </th>
                          ) : (
                            <th
                              style={{
                                border: "1px solid #dee2e6",
                                padding: "0.5rem",
                              }}
                            >
                              Supplier Name:
                            </th>
                          )}
                          <td
                            style={{
                              border: "1px solid #dee2e6",
                              padding: "0.5rem",
                            }}
                          >
                            {item?.customerAccount
                              ? item?.customerAccount.name
                              : item?.supplierAccount.name}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{
                              border: "1px solid #dee2e6",
                              padding: "0.5rem",
                            }}
                          >
                            Phone:
                          </th>
                          <td
                            style={{
                              border: "1px solid #dee2e6",
                              padding: "0.5rem",
                            }}
                          >
                            {item?.customerAccount?.mobile
                              ? item?.customerAccount?.mobile
                              : item?.supplierAccount.mobile}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    <span
                      style={{
                        border: "1px solid #28a745",
                        color: "#28a745",
                        padding: "0.5rem 0.8rem",
                        borderRadius: "0.2rem",
                        fontSize: "0.75rem",
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      {moment(item?.paymentDate).format("DD MMMM YYYY")}
                    </span>
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    <span
                      style={{
                        border: "1px solid #28a745",
                        color: "#28a745",
                        padding: "0.5rem 0.8rem",
                        borderRadius: "0.2rem",
                        fontSize: "0.75rem",
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      TK - {item?.amount}
                    </span>
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    <span
                      style={{
                        border: "1px solid #28a745",
                        color: "#28a745",
                        padding: "0.5rem 0.8rem",
                        borderRadius: "0.2rem",
                        fontSize: "0.75rem",
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      {item?.paymentType?.name}
                    </span>
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    <span
                      style={{
                        border: "1px solid #28a745",
                        color: "#28a745",
                        padding: "0.5rem 0.8rem",
                        borderRadius: "0.2rem",
                        fontSize: "0.75rem",
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      {item?.isWallet === true ? "YES" : "NO"}
                    </span>
                  </td>
                  <td style={{ verticalAlign: "middle" }}>{item?.note}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PaymentList;

import React, {useState} from "react";
import toast from "react-hot-toast";

import {Link} from "react-router-dom";
import {useDeleteSrMutation} from "../../redux/api/sr/srApi";
import EditModal from "./EditModal";
import DeleteConfirmationModal from "../deleteConfirmationModal/DeleteConfirmationModal";
import Currency from "../../Shared/Currency";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const SRList = ({data}) => {
  const storeId = useSelector((state) => state.store.storeId)
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);

  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [deleteSr] = useDeleteSrMutation({});

  const handleDelete = async (id) => {
    setIsAddingLoading(true);
    const res = await deleteSr({id,storeId});

    if (res?.data?.success === true) {
      toast.success(res?.data?.message);
    }
    setIsAddingLoading(false);
    setIsDeleteConfirmationModalOpen(false);
  };

  const openModal = (id) => {
    setIsModalOpen(true);
    setSelectedItemId(id);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openDeleteConfirmationModal = (id) => {
    setIsDeleteConfirmationModalOpen(true);
    setSelectedItemId(id);
  };

  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(false);
  };
  return (
    <div className=" mb-2">
      <div className="table-responsive my-5">
        <table className="table table-hover text-nowrap">
          <thead>
            <tr>
       
              <th className="border-top-0 pt-0 pb-2">{t("Serial")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Name")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Email")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Phone")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Address")}</th>
              {/* <th className="border-top-0 pt-0 pb-2">Receivable</th>
              <th className="border-top-0 pt-0 pb-2">Paid </th>
              <th className="border-top-0 pt-0 pb-2">Due </th> */}
              <th className="border-top-0 pt-0 pb-2">{t("customer")} </th>
              <th className="border-top-0 pt-0 pb-2">{t("Actions")} </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
               
                <td className="align-middle">
                  <Link to="">{index + 1}</Link>
                </td>
                <td className="align-middle">{item?.name}</td>
                <td className="align-middle">{item?.email}</td>
                <td className="align-middle">
                  <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                    {item?.mobile}
                  </span>
                </td>

                <td className="align-middle">{item?.address}</td>
                {/* <td className="align-middle">
                  <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                    {Currency} : 809,4452
                  </span>
                </td>
                <td className="align-middle">
                  <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                    {Currency} : 8052251
                  </span>
                </td>
                <td className="align-middle">
                  <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                    {Currency} : 44444
                  </span>
                </td> */}
                <td className="align-middle">
                  <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                   {item?.Customer?.length}
                  </span>
                </td>

                <td className="align-middle">
                  <span
                    className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center cursor-pointer "
                    data-bs-toggle="dropdown">
                    <i className="bi bi-gear fs-9px fa-fw me-5px"></i> E/D
                  </span>

                  <div className="dropdown-menu">
                    <button
                      onClick={() => openModal(item?.id)}
                      className="dropdown-item">
                      <i
                        class="bi bi-pencil-square"
                        style={{marginRight: "5px"}}></i>
                      {t("Edit")}
                    </button>
                    {/* <a className="dropdown-item" href="#/">
                      <i
                        class="bi bi-printer pr-2"
                        style={{marginRight: "5px"}}></i>
                      {t("Receipt")}
                    </a> */}
                    <button
                      onClick={() => openDeleteConfirmationModal(item?.id)}
                      className="dropdown-item">
                      <i
                        className="bi bi-trash pr-2"
                        style={{marginRight: "5px"}}></i>
                      {t("Delete")}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isDeleteConfirmationModalOpen && (
        <DeleteConfirmationModal
          isAddingLoading={isAddingLoading}
          closeModal={closeDeleteConfirmationModal}
          confirmDelete={() => handleDelete(selectedItemId)}
        />
      )}
      {isModalOpen && (
        <EditModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          id={selectedItemId}
        />
      )}
    </div>
  );
};

export default SRList;

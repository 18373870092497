/* eslint-disable no-sequences */
import {baseApi} from "../baseApi";

export const selfApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    selfCreate: build.mutation({
      query: (selfData) => ({
        url: "/self",
        method: "POST",
        data: selfData,
      }),
      invalidatesTags: ["self"],
    }),

    allSelf: build.query({
      query: (params) => ({
        url: "/self",
        method: "GET",
        params,
      }),
      providesTags: ["self"],
    }),
    // singleProductGet: build.query({
    //   query: ({id,params}) => ({
    //     url: `/products/${id}`,
    //     method: "GET",
    //     params
    //   }),
    //   providesTags: ["products"],
    // }),

    updateSelf: build.mutation({
      query: ({id, values}) => ({
        url: `/self/${id}`,
        method: "PATCH",
        data: values,
      }),
      invalidatesTags: ["self"],
    }),
    deleteSelf: build.mutation({
      query: ({id,storeId}) => ({
        url: `/self/${id}`,
        method: "DELETE",
        data:{storeId},
      }),
      invalidatesTags: ["self"],
    }),
  }),
});

export const {
useSelfCreateMutation,
useAllSelfQuery,
useUpdateSelfMutation,
useDeleteSelfMutation
} = selfApi;

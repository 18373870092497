import React, { useEffect, useState } from "react";
import { Card } from "../../components/card/card.jsx";
import { NavScrollTo } from "../../components/nav-scroll-to/nav-scroll-to.jsx";

import toast from "react-hot-toast";
import { singleImageUpload } from "../../hooks/ImageUpload.js";
import {
  useUpdatePasswordMutation,
  useUpdateProfileMutation,
  useUserProfileGetQuery,
} from "../../redux/api/profile/profileApi.js";

function NewProfile() {
  const { data } = useUserProfileGetQuery();
  const [updateProfile] = useUpdateProfileMutation();
  const [updatePassword] = useUpdatePasswordMutation();
  const profile = data?.data;

  const [rightNav, setRightNav] = useState("profile");
  const [image, setImage] = useState();
  const [name, setName] = useState();
  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  useEffect(() => {
    setName(profile?.name);
  }, [data,profile?.name]);
  const handleChangeUploadImage = async (event) => {
    const image = event.target.files[0];

    const formData = new FormData();

    formData.append("image", image);

    try {
      await singleImageUpload(formData, setImage);
    } catch (error) {
      alert(error.message);
    }
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    const values = {
      name: name,
      profileImageURL: image,
    };
    const data = {
      id: profile?.id,
      values,
    };
    const res = await updateProfile(data);
    if (res?.data?.success === true) {
      toast.success("Profile Updated Successfully");
    }
  };
  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    const values = {
      newPassword,
      currentPassword,
    };

    const res = await updatePassword(values);
    if (res?.data?.success === true) {
      toast.success("Password Updated Successfully");
      setCurrentPassword("");
      setNewPassword("");
    }
  };

  return (
    <div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="row">
              <div className="col-xl-9">
                <div
                  className={`mb-5 ${
                    rightNav === "profile" ? "d-block" : "d-none"
                  } p-3 `}
                  id="profile-card"
                >
                  <h4>
                    <i className="bi bi-person-circle text-theme"></i> Profile
                  </h4>
                  <p>Update Your Profile.</p>
                  <Card>
                    <div className="p-3">
                      <form>
                        <div>
                          <div className="flexbox gap-items-4 mt-2">
                            <div className="flex-grow">
                              <h5>
                                {profile?.roles} : {profile?.name}
                              </h5>
                              <div className="d-flex flex-column flex-sm-row gap-y gap-items-2 mt-3">
                                <input
                                  type="file"
                                  name="image"
                                  onChange={handleChangeUploadImage}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>

                          <hr />

                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group do-float">
                                <input
                                  className="form-control"
                                  type="text"
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <input
                                className="form-control"
                                type="text"
                                name="email"
                                value={profile?.email}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mt-3 d-flex justify-content-end">
                          <button
                            style={{ width: "160px", height: "35px" }}
                            className="btn btn-outline-theme"
                            onClick={(e) => handleProfileUpdate(e)}
                          >
                            Update
                          </button>
                        </div>
                      </form>
                    </div>
                  </Card>
                </div>
                <div
                  className={`mb-5 ${
                    rightNav === "password" ? "d-block" : "d-none"
                  } p-3`}
                  id="passwrod-card"
                >
                  <h4>
                    <i className="bi bi-person-circle text-theme"></i> Profile
                  </h4>
                  <p>Change Password.</p>
                  <Card className="p-3">
                    <form>
                      <div className="row mt-3">
                        {" "}
                        <div className="col-md-6">
                          <input
                            className="form-control"
                            type="password"
                            name="currentPassword"
                            placeholder="Enter Current Password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="password"
                              name="newPassword"
                              placeholder="Enter New Password"
                              value={newPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mt-3 d-flex justify-content-end">
                        <button
                          style={{ width: "160px", height: "35px" }}
                          className="btn btn-outline-theme"
                          onClick={(e) => handleUpdatePassword(e)}
                        >
                          Update Password
                        </button>
                      </div>
                    </form>
                  </Card>
                </div>
              </div>

              <div className="col-xl-3">
                <NavScrollTo>
                  <nav className="nav">
                    <button
                      className={`nav-link ${
                        rightNav === "profile" ? "active" : ""
                      }`}
                      data-toggle="scroll-to"
                      onClick={() => setRightNav("profile")}
                    >
                      Profile
                    </button>

                    <button
                      className={`nav-link ${
                        rightNav === "password" ? "active" : ""
                      }`}
                      data-toggle="scroll-to"
                      onClick={() => setRightNav("password")}
                    >
                      Change Password
                    </button>
                  </nav>
                </NavScrollTo>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewProfile;

/* eslint-disable no-sequences */
import { baseApi } from "../baseApi";

export const reportsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getDashboardData: build.query({
      query: (params) => ({
        url: "/dashboard",
        method: "GET",
        params,
      }),
      providesTags: ["dashboard"],
    }),
  }),
});

export const { useGetDashboardDataQuery } = reportsApi;

import React, { useState } from "react";
import EditModal from "./EditModal";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { useDeleteUnitMutation } from "../../redux/api/units/unitsApi";
import DeleteConfirmationModal from "../deleteConfirmationModal/DeleteConfirmationModal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const UnitList = ({ data, refetch }) => {
  const store = useSelector((state) => state.store);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState();
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [deleteUnit] = useDeleteUnitMutation({});
  const { t } = useTranslation();
  const handleDelete = async (id) => {
    setIsAddingLoading(true);

    const res = await deleteUnit({ id, storeId: store?.storeId });
    if (res?.data?.success === true) {
      toast.success(res?.data?.message);
    }
    setIsAddingLoading(false);
    setIsDeleteConfirmationModalOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openDeleteConfirmationModal = (id) => {
    setIsDeleteConfirmationModalOpen(true);
    setSelectedItemId(id);
  };

  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(false);
  };
  return (
    <div className=" mb-2">
      <div className="table-responsive mt-2">
        <table className="table table-hover text-nowrap">
          <thead>
            <tr>
              <th className="border-top-0 pt-0 pb-2">{t("Serial")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Name")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Related To")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Related Sign")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Related By")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Result")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Actions")} </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td className="align-middle  ">
                  <Link>{index + 1}</Link>
                </td>
                <td className="align-middle">{item?.name}</td>
                <td className="align-middle">
                  {item?.relatedUnits?.name ? item?.relatedUnits?.name : "-"}{" "}
                </td>
                <td className="align-middle">
                  <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                    {item?.operator ? item?.operator : "-"}
                  </span>
                </td>

                <td className="align-middle">
                  {item?.relatedByValue ? item?.relatedByValue : "-"}
                </td>

                <td className="align-middle">
                  <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                    {item?.name} = 1 {item?.relatedUnits?.name} {item?.operator}{" "}
                    {item?.relatedByValue}
                  </span>
                </td>

                <td className="align-middle">
                  <span
                    className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center cursor-pointer "
                    data-bs-toggle="dropdown"
                  >
                    <i className="bi bi-gear fs-9px fa-fw me-5px"></i> E/D
                  </span>

                  <div className="dropdown-menu">
                    {/* <button
                      onClick={() => openModal(item?.id)}
                      className="dropdown-item">
                      <i
                        className="bi bi-pencil-square"
                        style={{marginRight: "5px"}}></i>
                      Edit
                    </button> */}

                    <button
                      onClick={() => openDeleteConfirmationModal(item?.id)}
                      className="dropdown-item"
                    >
                      <i
                        className="bi bi-trash pr-2"
                        style={{ marginRight: "5px" }}
                      ></i>
                      {t("Delete")}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <EditModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          id={selectedItemId}
        />
      )}

      {isDeleteConfirmationModalOpen && (
        <DeleteConfirmationModal
          isAddingLoading={isAddingLoading}
          closeModal={closeDeleteConfirmationModal}
          confirmDelete={() => handleDelete(selectedItemId)}
        />
      )}
    </div>
  );
};

export default UnitList;

/* eslint-disable no-sequences */
import {baseApi} from "../baseApi";

export const expenseCategory = baseApi.injectEndpoints({
  endpoints: (build) => ({
    expenseCategoryCreate: build.mutation({
      query: (expenseCategoryData) => ({
        url: "/expenseCategory",
        method: "POST",
        data: expenseCategoryData,
      }),
      invalidatesTags: ["expenseCategory"],
    }),

    allExpenseCategoryGet: build.query({
      query: (params) => ({
        url: "/expenseCategory",
        method: "GET",
        params,
      }),
      providesTags: ["expenseCategory"],
    }),
    singleExpenseCategoryGet: build.query({
      query: ({id,params}) => ({
        url: `/expenseCategory/${id}`,
        method: "GET",
        params
      }),
      providesTags: ["brand"],
    }),

    updateExpenseCategory: build.mutation({
      query: ({id, values}) => ({
        url: `/expenseCategory/${id}`,
        method: "PATCH",
        data: values,
      }),
      invalidatesTags: ["expenseCategory"],
    }),
    deleteExpenseCategory: build.mutation({
      query: ({id,storeId}) => ({
        url: `/expenseCategory/${id}`,
        method: "DELETE",
        data:{storeId:storeId},
      }),
      invalidatesTags: ["expenseCategory"],
    }),
  }),
});

export const {
  useExpenseCategoryCreateMutation,
  useAllExpenseCategoryGetQuery,
  useSingleExpenseCategoryGetQuery,
  useUpdateExpenseCategoryMutation,
  useDeleteExpenseCategoryMutation,
} = expenseCategory;

import React, { useEffect, useRef, useState } from "react";
import PaymentsModal from "./PaymentsModal";
import PaymentList from "./PaymentList";
import { useAllPaymentGetQuery } from "../../redux/api/payments/paymentsApi";
import ReactSelect from "../helpers/ReactSelect";
import Loader from "../../components/loader/Loader";
import { useAllCustomerGetQuery } from "../../redux/api/customer/customerApi";
import { useAllSupplierGetQuery } from "../../redux/api/supplier/supplierApi";
import DatepickerHelper from "../helpers/DatepickerHelper";
import Pagination from "../../utils/Pagination";
import ReactToPrint from "react-to-print";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const Payments = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const store = useSelector((state) => state.store);
  const [customerAccountId, setCustomerAccountId] = useState();
  const [customerFieldValue, setCustomerFieldValue] = useState();
  const [supplierAccountId, setSupplierAccountId] = useState();
  const [supplierFieldValue, setSupplierFieldValue] = useState();
  const [startDate, setStartDate] = useState();
  const [startDateFieldValue, setStartDateFieldValue] = useState();
  const [endDate, setEndDate] = useState();
  const [endDateFieldValue, setEndDateFieldValue] = useState();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({
    limit: pageSize,
    storeId: store.storeId,
  });
  const { data, isLoading, refetch } = useAllPaymentGetQuery(filter, {
    refetchOnMountOrArgChange: true,
  });
  const initialData = data?.data;
  const meta = data?.meta;
  const tableRef = useRef(null);
  useEffect(() => {
    const updatedFilter = { ...filter };
    updatedFilter.page = page;

    if (!setCustomerFieldValue) {
      delete updatedFilter.customerAccountId;
    }
    if (customerAccountId) {
      updatedFilter.customerAccountId = customerAccountId;
    }
    if (!setSupplierFieldValue) {
      delete updatedFilter.supplierAccountId;
    }
    if (supplierAccountId) {
      updatedFilter.supplierAccountId = supplierAccountId;
    }
    if (!startDateFieldValue) {
      delete updatedFilter.startDate;
    }
    if (startDate) {
      updatedFilter.startDate = startDate;
    }
    if (!endDateFieldValue) {
      delete updatedFilter.endDate;
    }
    if (endDate) {
      updatedFilter.endDate = endDate;
    }

    setFilter(updatedFilter);

    refetch();
  }, [
    refetch,

    page,
    customerAccountId,
    supplierAccountId,
    supplierFieldValue,
    customerFieldValue,
    setStartDate,
    startDateFieldValue,
    endDate,
    endDateFieldValue,
    startDate,
  ]);

  const handleCustomerChange = (values) => {
    setCustomerFieldValue(values.value);
  };
  const handleSupplierChange = (values) => {
    setSupplierFieldValue(values.value);
  };

  const handleSubmit = () => {
    setCustomerAccountId(customerFieldValue);
    setSupplierAccountId(supplierFieldValue);
    setStartDate(startDateFieldValue);
    setEndDate(endDateFieldValue);
  };

  const { data: supplierIdData } = useAllSupplierGetQuery({
    storeId: store.storeId,
  });
  const initialSupplierIdDataIdData = supplierIdData?.data;
  const supplierOptions = initialSupplierIdDataIdData?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));
  const { data: customerIdData } = useAllCustomerGetQuery({
    storeId: store.storeId,
  });
  const initialCustomerIdDataIdData = customerIdData?.data;
  const customerOptions = initialCustomerIdDataIdData?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    const clone = tableRef.current.cloneNode(true);
    clone.style.display = "block";
    printWindow.document.body.appendChild(clone);
    printWindow.print();
  };
  return (
    <div>
      <header className="header text-white ">
        <div className="header-info d-flex justify-content-between">
          <h1 className="page-header">
            {" "}
            {t("Payment")} {t("List")}{" "}
          </h1>
          <div className="mb-3">
            <button
              style={{ width: "170px", height: "35px" }}
              className="btn btn-outline-theme"
              onClick={openModal}
            >
              <i className="fa fa-plus-circle me-1"></i>
              {t("Add Payment")}
            </button>
          </div>
        </div>
        <hr className="mb-3" />
      </header>

      <div className="row gap-3 w-100">
        <div className="col-lg-3 ">
          <ReactSelect
            placeHolder="Select customer"
            options={customerOptions}
            handleChange={handleCustomerChange}
          />
        </div>
        <div className="col-lg-3 ">
          <ReactSelect
            placeHolder="Select Supplier "
            options={supplierOptions}
            handleChange={handleSupplierChange}
          />
        </div>
        <div className="d-flex col-lg-5 gap-2">
          <div className="col-md-6">
            <DatepickerHelper
              handleDateChange={(date) => setStartDateFieldValue(date)}
              selectedDate={startDateFieldValue}
              placeHolder="Select start date"
            />
          </div>
          <div className="col-md-6">
            <DatepickerHelper
              handleDateChange={(date) => setEndDateFieldValue(date)}
              selectedDate={endDateFieldValue}
              placeHolder="Select end date"
            />
          </div>
        </div>
        <div className="d-flex justify-content-between my-3">
          <div className="mt-1 d-flex gap-2">
            <div className="form-group">
              <button
                onClick={handleSubmit}
                className="btn btn-primary"
                type="submit"
              >
                <i className="fa fa-sliders"></i> {t("Filter")}
              </button>
            </div>
            <div className="form-group">
              <button
                className="btn btn-info"
                type="button"
                onClick={() => {
                  setCustomerAccountId("");
                  setCustomerFieldValue("");
                  setSupplierAccountId("");
                  setSupplierFieldValue("");
                  setStartDate("");
                  setStartDateFieldValue("");
                  setEndDate("");
                  setEndDateFieldValue("");
                  const updatedFilter = { ...filter };
                  delete updatedFilter.customerAccountId;
                  delete updatedFilter.supplierAccountId;
                  delete updatedFilter.startDate;
                  delete updatedFilter.endDate;
                  setFilter(updatedFilter);
                  refetch();
                }}
              >
                {t("Reset")}
              </button>
            </div>
          </div>
          <div>
            <button
              onClick={handlePrint}
              style={{ width: "120px", height: "35px" }}
              className="btn btn-outline-theme"
            >
              <i
                className="bi bi-printer pr-2"
                style={{ marginRight: "5px" }}
              ></i>
              {t("Print")}
            </button>
          </div>
        </div>
      </div>
      <div className="tab-content ">
        {isModalOpen && (
          <PaymentsModal isModalOpen={isModalOpen} closeModal={closeModal} />
        )}
        <div>
          {isLoading ? (
            <Loader />
          ) : (
            <PaymentList
              tableRef={tableRef}
              data={initialData}
              refetch={refetch}
            />
          )}
        </div>
      </div>

      <Pagination
        page={page}
        setPage={setPage}
        meta={meta}
        pageSize={pageSize}
      />
    </div>
  );
};

export default Payments;

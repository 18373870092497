import React from "react";
import { Card, CardBody } from "./../../components/card/card.jsx";

const AllCategory = ({
  initialData,
  selectCategory,
  setSelectCategory,
  showType,
}) => {
  return (
    <div>
      {initialData &&
        initialData?.map((category, index) => (
          <li className="nav-item" key={index}>
            <div
              className={
                "nav-link cursor-pointer" +
                (selectCategory === category?.id ? " active" : "")
              }
              onClick={(event) =>
                showType(event, setSelectCategory(category?.id))
              }
            >
              <Card>
                <CardBody>
                  <div className="d-flex flex-column align-items-center gap-2">
                    {" "}
                    <img
                      style={{
                        height: "40px",
                        width: "40px",
                        objectFit:"contain"
                      }}
                      src={
                        category?.image ||
                        "https://i.ibb.co/KjdtK7T/download.png"
                      }
                      alt=""
                    />
                    <span>{category?.name.slice(0, 15)}</span>
                    {/* {category?.name && category.name.length > 10 && <br />} */}
                  </div>
                </CardBody>
              </Card>
            </div>
          </li>
        ))}
    </div>
  );
};

export default AllCategory;

import React, { useState } from "react";
import Select from "react-select";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Card, CardBody } from "../../components/card/card";
import { Modal } from "react-bootstrap";
import { singleImageUpload } from "../../hooks/ImageUpload";
import {
  useSingleProductGetQuery,
  useUpdateProductMutation,
} from "../../redux/api/product/productApi";
import { useAllCategoryGetQuery } from "../../redux/api/categories/categoriesApi";
import { useAllBrandGetQuery } from "../../redux/api/brands/brandsApi";
import toast from "react-hot-toast";
import CategoriesModal from "../categories/CategoriesModal";
import Loader from "../../components/loader/Loader";
import QuillField from "./QuillField";
import BrandsModal from "../brands/BrandsModal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAllSelfQuery } from "../../redux/api/self/selfApi";
const EditProductModal = ({ closeModal, isModalOpen, id }) => {
  const store = useSelector((state) => state.store);
  const filter = {
    storeId: store?.storeId,
  };
  const { t } = useTranslation();
  const [isAddingLoading, setIsAddingLoading] = useState(false);

  const [image, setImage] = useState();
  const handleChangeUploadImage = async (event) => {
    const image = event.target.files[0];

    const formData = new FormData();

    formData.append("image", image);

    try {
      await singleImageUpload(formData, setImage);
    } catch (error) {
      alert(error.message);
    }
  };
  const { data, isLoading } = useSingleProductGetQuery( { id, params: { storeId: store.storeId } }, {
    refetchOnMountOrArgChange: true,
  });

  const [addcategoryIdModal, setAddcategoryIdModal] = useState(false);
  const [addbrandIdModal, setAddbrandIdModal] = useState(false);

  const { data: categoryIdData } = useAllCategoryGetQuery(filter,{
    refetchOnMountOrArgChange: true,
  });
  const initialcategoryIdData = categoryIdData?.data;
  const categoryIdOptions = initialcategoryIdData?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));
  const intialCatOp = categoryIdOptions?.find(
    (category) => category?.value === data?.data?.categoryId
  );

  const { data: brandIdData } = useAllBrandGetQuery(filter,{
    refetchOnMountOrArgChange: true,
  });
  const initialbrandIdData = brandIdData?.data;

  const brandIdOptions = initialbrandIdData?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const intialBatOp = brandIdOptions?.find(
    (brand) => brand?.value === data?.data?.brandId
  );

  const initialValues = {
    productName: data?.data?.productName,
    productCode: data?.data.productCode,
    categoryId: data?.data.categoryId,
    selfsId: data?.data.selfsId,
    salePrice: data?.data?.salePrice,
    purchaseCost: data?.data?.purchaseCost,
    productDetails: data?.data?.productDetails,
    image: data?.data?.image,
  };

  const [updateProduct] = useUpdateProductMutation();

  const handleSubmit = async (values, { resetForm }) => {
    setIsAddingLoading(true);

    values.image = image;
    values.storeId = parseInt(store.storeId) 
    const res = await updateProduct({ values, id });

    if (res?.data) {
      toast.success(res?.data?.message);
      resetForm();
      closeModal();
    }
    setIsAddingLoading(false);
  };

  const validationSchema = Yup.object({
    productName: Yup.string().required("Product Name is required"),
    categoryId: Yup.string().required("category is required"),
    purchaseCost: Yup.number()
      .required("Purchase Cost is required")
      .positive("Purchase Cost must be a positive number"),
    salePrice: Yup.number()
      .required("Selling Price is required")
      .positive("Selling Price must be a positive number"),
  });
  const { data: seltData } = useAllSelfQuery(filter, {
    refetchOnMountOrArgChange: true,
  });
  const initialSelfData = seltData?.data;
  const selfsOptions = initialSelfData?.map((item) => {
    return {
      value: item?.id,
      label: item?.name,
    };
  });
  const intialSelf = selfsOptions?.find(
    (self) => self?.value === data?.data?.selfsId
  );
  console.log(intialSelf,initialSelfData)
  return (
    <Modal show={isModalOpen} onHide={closeModal} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Edit Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="">
              <Card className="mb-3 p-2">
                <CardBody>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    <Form>
                      <div className="d-flex justify-content-center gap-2 py-3">
                        <div className="form-group col-md-4 px-2">
                          <label htmlFor="productName">{t("Name")}</label>
                          <Field
                            type="text"
                            name="productName"
                            className="form-control"
                            placeholder="Enter Product Name"
                            id="productName"
                          />
                          <ErrorMessage
                            name="productName"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="form-group col-md-4 px-2">
                          <label htmlFor="productCode">{t("Code")}</label>
                          <Field
                            type="text"
                            name="productCode"
                            className="form-control"
                            placeholder="Enter Product Code"
                            id="productCode"
                          />
                          <ErrorMessage
                            name="productCode"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="form-group col-md-4 ">
                      <label htmlFor="productCode">{t("Self")}</label>
                      <Field className="form-control" name="selfsId">
                        {(props) => {
                          const handleChange = (option) => {
                            props.form.setFieldValue(
                              "selfsId",
                              option.value
                            );
                          };
                          return (
                            <Select
                              classNamePrefix="react-select"
                              options={selfsOptions}
                              isSearchable
                              defaultValue={intialSelf}
                              onChange={handleChange}
                            />
                          );
                        }}
                      </Field>
                    </div>
                      </div>

                      <div className="d-flex justify-content-between  gap-2 py-3">
                        <div className="form-group col-md-6 px-2">
                          <label htmlFor="categoryId">{t("Category")}</label>
                          <Field className="form-control" name="categoryId">
                            {(props) => {
                              const handleChange = (option) => {
                                props.form.setFieldValue(
                                  "categoryId",
                                  option.value
                                );
                              };
                              return (
                                <Select
                                  classNamePrefix="react-select"
                                  options={categoryIdOptions}
                                  isSearchable
                                  defaultValue={intialCatOp}
                                  onChange={handleChange}
                                />
                              );
                            }}
                          </Field>
                          <ErrorMessage
                            name="categoryId"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        {/* <div className="col-md-6 px-2">
                  <button
                    style={{width: "150px", height: "35px"}}
                    className="btn btn-outline-theme mt-3"
                    onClick={() => setAddcategoryIdModal(true)}>
                    <i className="fa fa-plus-circle me-1"></i>Add
                  </button>
                </div> */}
                      </div>

                      <div className="d-flex justify-content-between  gap-2 py-3">
                        <div className="form-group col-md-6 px-2">
                          <label htmlFor="categoryId">{t("Brand")}</label>
                          <Field className="form-control" name="brandId">
                            {(props) => {
                              const handleChange = (option) => {
                                props.form.setFieldValue(
                                  "brandId",
                                  option.value
                                );
                              };
                              return (
                                <Select
                                  classNamePrefix="react-select"
                                  options={brandIdOptions}
                                  isSearchable
                                  defaultValue={intialBatOp}
                                  onChange={handleChange}
                                />
                              );
                            }}
                          </Field>
                        </div>
                        {/* <div className="form-group col-md-6 px-2">
                  <button
                    style={{width: "150px", height: "35px"}}
                    className="btn btn-outline-theme mt-3"
                    onClick={() => setAddbrandIdModal(true)}>
                    <i className="fa fa-plus-circle me-1"></i>Add
                  </button>
                </div> */}
                      </div>

                      <div className="form-group col-md-6 px-2">
                        <label htmlFor="salePrice">
                          {t("sale")} {t("Price")}
                        </label>
                        <Field
                          type="number"
                          name="salePrice"
                          className="form-control"
                          placeholder="Enter Selling Price"
                        />
                        <ErrorMessage
                          name="salePrice"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="form-group col-md-6 px-2 py-3">
                        <label htmlFor="purchaseCost">
                          {t("purchases")} {t("Cost")}
                        </label>
                        <Field
                          type="number"
                          name="purchaseCost"
                          className="form-control"
                          placeholder="Enter Purchase Cost"
                        />
                        <ErrorMessage
                          name="purchaseCost"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="form-group col-12 px-2">
                        <label htmlFor="productDetails">
                          {"product"} {t("Detail")}
                        </label>
                        <Field
                          name="productDetails"
                          as="div"
                          component={QuillField}
                        />
                      </div>

                      <div className="form-group col-12 px-2 pb-4 pt-5">
                        <label htmlFor="productImage">{t("Image")}</label>
                        <input
                          type="file"
                          name="image"
                          onChange={handleChangeUploadImage}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="image"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <button
                        type="submit"
                        className="btn btn-outline-theme"
                        disabled={isAddingLoading}
                      >
                        {isAddingLoading ? (
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Update Product"
                        )}
                        <i className="fa fa-save px-2"></i>
                      </button>
                    </Form>
                  </Formik>
                </CardBody>
              </Card>
              {addcategoryIdModal && (
                <CategoriesModal
                  isModalOpen={addcategoryIdModal}
                  closeModal={() => setAddcategoryIdModal(false)}
                />
              )}
              {addbrandIdModal && (
                <BrandsModal
                  isModalOpen={addbrandIdModal}
                  closeModal={() => setAddbrandIdModal(false)}
                />
              )}
            </div>
          )}
        </>
      </Modal.Body>
    </Modal>
  );
};

export default EditProductModal;

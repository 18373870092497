/* eslint-disable no-sequences */
import { baseApi } from "../baseApi";

export const storeApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getStores: build.query({
      query: (params) => ({
        url: "/store",
        method: "GET",
        params,
      }),
      providesTags: ["store"],
    }),
    createStore: build.mutation({
      query: (values) => ({
        url: "/store",
        method: "POST",
        data: values,
      }),
      invalidatesTags: ["store"],
    }),
  }),
});

export const { useGetStoresQuery, useCreateStoreMutation } = storeApi;

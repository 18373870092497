import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import {
  useAllUnitGetQuery,
  useSingleUnitGetQuery,
  useUpdateUnitMutation,
} from "../../redux/api/units/unitsApi";
import Loader from "../../components/loader/Loader";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";

const EditModal = ({isModalOpen, closeModal, id}) => {
  const { t } = useTranslation();
  const {datas, isLoadings} = useAllUnitGetQuery({
    refetchOnMountOrArgChange: true,
  });
  const initialData = datas?.data;

  const {data, isLoading} = useSingleUnitGetQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const [updateUnit] = useUpdateUnitMutation();
  const initialValues = {
    name: data?.name,
    relatedToUnits: data?.data.relatedToUnits,
    operator: data?.data.operator,
    relatedByValue: data?.data.relatedByValue,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required("Unit Name is required"),
    }),
    onSubmit: async (values, {resetForm}) => {
      values.relatedToUnits = parseInt(values.relatedToUnits);
      const res = await updateUnit(values);
      if (res?.data) {
        toast.success("Unit Update Successfully");
        resetForm(initialValues);
      }
      closeModal();
    },
  });

  if (isLoadings) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>No data available</div>;
  }

  const relatedToUnit = initialData?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const getRelatedToUnit = (value) => {
    const data = relatedToUnit?.find((unit) => unit?.value === value);
    return data?.label;
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (

        <Modal show={isModalOpen} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("Delete Confirm")} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
                <div className="card-body p-3">
                  <div className="form-row">
                    <div className="d-flex justify-content-center gap-2">
                      <div className="form-group col-md-6 py-2">
                        <label style={{paddingBottom: "5px"}} htmlFor="name">
                        {t("Name")}
                        </label>
                        <input
                          type="text"
                          className="form-control py-2"
                          name="name"
                          onChange={formik.handleChange}
                          value={formik.values.name}
                          placeholder="e.g. Kg"
                        />
                        {formik.touched.name && formik.errors.name && (
                          <div className="text-danger">
                            {formik.errors.name}
                          </div>
                        )}
                      </div>
                      <div className="form-group col-md-6 py-2">
                        <label
                          style={{paddingBottom: "5px"}}
                          htmlFor="relatedToUnits">
                          {t("Related To Unit")}
                        </label>
                        <select
                          name="relatedToUnits"
                          id="relatedToUnits"
                          className="form-select form-select-lg bg-white bg-opacity-5"
                          onChange={formik.handleChange}
                          value={formik.values.relatedToUnits}>
                          <option value=""> {t("Select")} {t("Unit")}</option>
                          {relatedToUnit?.map((item) => (
                            <option value={item.value} key={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                        {formik.touched.relatedToUnits &&
                          formik.errors.relatedToUnits && (
                            <div className="text-danger">
                              {formik.errors.relatedToUnits}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="d-flex justify-content-center gap-2">
                      <div className="form-group col-md-6 py-2">
                        <label
                          style={{paddingBottom: "5px"}}
                          htmlFor="operator">
                         {t("Operator")}
                        </label>
                        <select
                          name="operator"
                          id="operator"
                          className="form-select form-select-lg bg-white bg-opacity-5 py-2"
                          onChange={formik.handleChange}
                          value={formik.values.operator}>
                          <option value="">{t("Select Operator Sign")}</option>
                          <option value="*">(*) Multiply Operator</option>
                          {/* Add other options here */}
                        </select>
                        {formik.touched.operator && formik.errors.operator && (
                          <div className="text-danger">
                            {formik.errors.operator}
                          </div>
                        )}
                      </div>
                      <div className="form-group col-md-6 py-2">
                        <label
                          style={{paddingBottom: "5px"}}
                          htmlFor="relatedByValue">
                          {t("Related By Value")}
                        </label>
                        <input
                          type="text"
                          name="relatedByValue"
                          onChange={formik.handleChange}
                          value={formik.values.relatedByValue}
                          className="form-control py-2"
                        />
                        {formik.touched.relatedByValue &&
                          formik.errors.relatedByValue && (
                            <div className="text-danger">
                              {formik.errors.relatedByValue}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <p className="text-center">
                  1 {formik.values.name} = 1
                  {getRelatedToUnit(formik.values.relatedToUnits)}
                  {formik.values.operator}
                  {formik.values.relatedByValue}
                </p>

                <div className="modal-footer">
                  <button
                    className="btn btn-outline-default"
                    onClick={() => closeModal()}>
                    {t("Cancel")}
                  </button>
                  <button type="submit" className="btn btn-outline-theme">
                    <i className="fa fa-plus-circle me-1"></i>
                    {t("Update")}
                  </button>
                </div>
              </form>
        </Modal.Body>
    
      </Modal>

      )}
    </>
  );
};

export default EditModal;

import React, { useEffect, useContext, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { AppSettings } from "./../../config/app-settings.js";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useLoginMutation } from "../../redux/api/auth/loginApi.js";
import Loader from "../../components/loader/Loader.jsx";

function PagesLogin() {
  const context = useContext(AppSettings);
  const [isAddingLoading, setIsAddingLoading] = useState(false);

  const [login,{isLoading}] = useLoginMutation();
  const storedAccessToken = localStorage.getItem("access-token");
  // const { data: storesData } = useGetStoresQuery({
  //   refetchOnMountOrArgChange: true,
  // });
  useEffect(() => {
    context.setAppHeaderNone(true);
    context.setAppSidebarNone(true);
    context.setAppContentClass("p-0");

    return function cleanUp() {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass("");
    };

    // eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      setIsAddingLoading(true);
      const res = await login(values);
      if (res?.data?.success === true) {
        if (res?.data) {
          localStorage.setItem("access-token", res?.data.data?.token);
          localStorage.setItem("role", res?.data.data?.role);
          if (res?.data.data?.role === "ADMIN") {
            localStorage.setItem("storeId",res?.data.data?.storeId[0]?.id);
          
            
            <Navigate to="/" />;
          } else if (
            res?.data.data?.role === "SR" ||
            res?.data.data?.role === "OWNER"
          ) {
            localStorage.setItem("storeId", res?.data.data?.storeId[0]?.id);
            
            <Navigate to="/" />;
          }
          window.location.reload();
          toast.success("Login Successfully!");
        }
      }
      setIsAddingLoading(false);
    },
  });

  if (storedAccessToken) {
    return <Navigate to="/" />;
  }
if(isLoading){
return <Loader/>
}
  return (
    <div className="login">
      <div className="login-content">
        <form onSubmit={formik.handleSubmit}>
          <h1 className="text-center">Sign In</h1>
          <div className="text-inverse text-opacity-50 text-center mb-4">
            For your protection, please verify your identity.
          </div>
          <div className="mb-3">
            <label className="form-label">
              Email Address <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control form-control-lg ${
                formik.touched.email && formik.errors.email ? "is-invalid" : ""
              }`}
              placeholder=""
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="invalid-feedback">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="mb-3">
            <div className="d-flex">
              <label className="form-label">
                Password <span className="text-danger">*</span>
              </label>
              <a
                href="#/"
                className="ms-auto text-inverse text-decoration-none text-opacity-50"
              >
                Forgot password?
              </a>
            </div>
            <input
              type="password"
              className={`form-control form-control-lg ${
                formik.touched.password && formik.errors.password
                  ? "is-invalid"
                  : ""
              }`}
              placeholder=""
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="invalid-feedback">{formik.errors.password}</div>
            ) : null}
          </div>
          {/* <div className="mb-3">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="customCheck1"
                {...formik.getFieldProps("rememberMe")}
              />
              <label className="form-check-label" htmlFor="customCheck1">
                Remember me
              </label>
            </div>
          </div> */}

          <button
            type="submit"
            className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3"
            disabled={isAddingLoading}
          >
            {isAddingLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Sign In"
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

export default PagesLogin;

/* eslint-disable no-sequences */
import {baseApi} from "../baseApi";

export const accountTypeApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    allAccountTypeApiGet: build.query({
      query: (params) => ({
        url: "/accountType",
        method: "GET",
        params,
      }),
      providesTags: ["accountType"],
    }),
  }),
});

export const {useAllAccountTypeApiGetQuery} = accountTypeApi;

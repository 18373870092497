import DatepickerHelper from "../helpers/DatepickerHelper";

import moment from "moment";
import React, { useEffect, useState } from "react";
import Loader from "../../components/loader/Loader";
import { useAllProfitlossGetQuery } from "../../redux/api/reports/reportsApi";
import Pagination from "../../utils/Pagination";
import Currency from "../../Shared/Currency";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ProfitLossReport = () => {
  const store = useSelector((state) => state.store);
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState();
  const [startDateFieldValue, setStartDateFieldValue] = useState();
  const [endDate, setEndDate] = useState();
  const [endDateFieldValue, setEndDateFieldValue] = useState();
  const [skip, setSkip] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({
    limit: pageSize,
    storeId: store?.storeId
  });

  const { data, isLoading, isError, refetch } = useAllProfitlossGetQuery(
    filter,

    { skip, refetchOnMountOrArgChange: true }
  );

  const initialData = data?.data;
  const meta = data?.meta;
  console.log(meta);
  useEffect(() => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      storeId: store.storeId,
    }));
  }, [store.storeId]);
  useEffect(() => {
    const updatedFilter = { ...filter };
    updatedFilter.page = page;

    if (!startDateFieldValue) {
      delete updatedFilter.startDate;
    }
    if (startDate) {
      updatedFilter.startDate = startDate;
    }
    if (!endDateFieldValue) {
      delete updatedFilter.endDate;
    }
    if (endDate) {
      updatedFilter.endDate = endDate;
    }

    setFilter(updatedFilter);

    if (startDate && endDate) {
      setSkip(false);
    }
  }, [page, setStartDate, startDateFieldValue, endDate, endDateFieldValue]);

  const handleSubmit = () => {
    const formattedStartDate = startDateFieldValue
      ? moment(startDateFieldValue).format("YYYY-MM-DD")
      : "";
    const formattedEndDate = endDateFieldValue
      ? moment(endDateFieldValue).format("YYYY-MM-DD")
      : "";

    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  };

  return (
    <div>
      <div>
        <h1 className="page-header">{t("Profit Loss Report")}</h1>
        <hr className="mb-4" />
        <div className="d-flex flex-wrap gap-3 ">
          <div className="d-flex  gap-2 mr-3">
            <div className="col-lg-6 ">
              <DatepickerHelper
                handleDateChange={(date) => setStartDateFieldValue(date)}
                selectedDate={startDateFieldValue}
                placeHolder="Select start date"
              />
            </div>
            <div className="col-lg-6  ">
              <DatepickerHelper
                handleDateChange={(date) => setEndDateFieldValue(date)}
                selectedDate={endDateFieldValue}
                placeHolder="Select end date"
              />
            </div>
          </div>
          <div className="form-group ">
            <button
              onClick={handleSubmit}
              className="btn btn-primary"
              type="submit"
            >
              <i className="fa fa-sliders"></i> {t("Filter")}
            </button>
          </div>
          <div className="form-group ">
            <button
              className="btn btn-info"
              type="button"
              onClick={() => {
                setStartDate("");
                setStartDateFieldValue("");
                setEndDate("");
                setEndDateFieldValue("");
                setSkip(true);
                const updatedFilter = { ...filter };

                delete updatedFilter.startDate;
                delete updatedFilter.endDate;
                setFilter(updatedFilter);
              }}
            >
              {t("Reset")}
            </button>
          </div>
        </div>
      </div>

      <div className="row py-3">
        <div className="col-xl-12">
          <div className="table-responsive mt-3">
            {isError ? (
              <div>Error loading data</div>
            ) : isLoading ? (
              <Loader />
            ) : (
              <table className="table table-hover text-nowrap ">
                <thead>
                  <tr>
                    <th className="border-top-0 pt-0 pb-2">{t("Serial")}</th>
                    <th className="border-top-0 pt-0 pb-2">{t("Month")}</th>
                    <th className="border-top-0 pt-0 pb-2"> {t("sale")}</th>
                    <th className="border-top-0 pt-0 pb-2">
                      {t("Purchase Cost")}
                    </th>
                    <th className="border-top-0 pt-0 pb-2">{t("Gross Profit")}</th>
                    <th className="border-top-0 pt-0 pb-2">{t("Administrative Expense")} </th>
                    <th className="border-top-0 pt-0 pb-2"> {t("Before-tax")} {t("Net Profit")} </th>
                    <th className="border-top-0 pt-0 pb-2"> {t("Tax")}</th>
                    <th className="border-top-0 pt-0 pb-2"> {t("After-tax")} {t("Net Profit")} </th>
                  </tr>
                </thead>
                <tbody>
                  {initialData?.map((item, index) => (
                    <tr key={index}>
                      <td className="align-middle">{index + 1}</td>
                      <td className="align-middle">
                        {moment(item?.month).format("MMMM")}  {moment(item?.year).format("yyyy")}
                      </td>
                      <td className="align-middle"> {Currency}:{item?.totalSales.toFixed(2)}</td>
                      <td className="align-middle"> {Currency}:{item?.totalPurchases.toFixed(2)}</td>
                      <td className="align-middle"> {Currency}:{item?.grossProfit.toFixed(2)}</td>
                      <td className="align-middle">
                        {Currency}: {item?.totalExpenses.toFixed(2)}
                      </td>

                      <td className="align-middle">
                        {" "}
                        <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                        {Currency}:{item?.taxExcludedNetProfit.toFixed(2)}{" "}
                        </span>
                      </td>
                      <td className="align-middle">
                        {" "}
                        <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                        {Currency}:{item?.tax.toFixed(2)}{" "}
                        </span>
                      </td>
                      <td className="align-middle">
                        {" "}
                        <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                        {Currency}:{item?.taxIncludedNetProfit.toFixed(2)}{" "}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
{/* 
      <Pagination
        page={page}
        setPage={setPage}
        meta={meta || 0}
        pageSize={pageSize}
      /> */}
    </div>
  );
};

export default ProfitLossReport;
